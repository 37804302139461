import {AngularFireDatabase} from '@angular/fire/compat/database';
import {Injectable} from '@angular/core';
import {AngularFirestore, AngularFirestoreCollection} from '@angular/fire/compat/firestore';
import {Observable} from 'rxjs/';
import {map, switchMap} from 'rxjs/operators';
import {ApiService} from "./api";

@Injectable()
export class RealTimeService {

  private notificacionesCollection: AngularFirestoreCollection<any>;
  notificaciones: Observable<any[]>;

  constructor(public db: AngularFirestore,private api:ApiService) {
    this.notificacionesCollection = this.db.collection<any>('notificaciones');
    this.iniciarNotificaciones()
  }

  iniciarNotificaciones(){
    this.notificaciones = this.notificacionesCollection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          return { id, ...data };
        });
      })
    );
  }
  getNotificaciones() {
    return this.notificaciones;
  }

  getNotificacion(id) {
    return this.notificacionesCollection.doc<any>(id).valueChanges();
  }
  updateNotificacion(data: any, id: string) {
    return this.notificacionesCollection.doc(id).update(data);
  }
  addNotificacion(data: any) {
    return this.notificacionesCollection.add(data);
  }
  removeNotificacion(id) {
    return this.notificacionesCollection.doc(id).delete();
  }

}
