import { Component, OnInit } from '@angular/core';
import { Mrn } from 'src/app/providers/mrn';
import {ApiService} from "../../providers/api";

@Component({
  selector: 'app-pagos',
  templateUrl: './pagos.component.html',
})
export class PagosComponent implements OnInit {

  constructor(public mrn:Mrn,public api: ApiService,) { }

  ngOnInit(): void {
  }

  guardarPago() {
    this.mrn.formPagos.patchValue({
      transaccion_id: this.mrn.transaccionSeleccionada.id,
    });
    this.mrn.abonar_factura(this.mrn.formPagos.value);
  }
  calcularTotal(event) {
    this.mrn.totalFacturasAPagar = 0
    for(let item of this.mrn.facturasSeleccionadas){
      this.mrn.totalFacturasAPagar += parseInt(item.saldo_pendiente_pago)
    }
  }
  propietarioFactura(transaccion: any) {
    if(this.mrn.facturasSeleccionadas.length){
      return transaccion.nodo.id != this.mrn.facturasSeleccionadas[0]['nodo_id'];
    }
  }
  pagoFacturas() {
    this.mrn.verFormPagosMulti = true;
    this.mrn.formPagos.patchValue({
      abono :this.mrn.totalFacturasAPagar
    })
  }
  seleccionarFactura(transaccion: any) {
    this.mrn.transaccionSeleccionada = ''
    this.mrn.transaccionSeleccionada = transaccion;
    this.mrn.pagoSeleccionado = ''
    this.mrn.ver_pagos_facturas = true
    this.mrn.get_abonos_factura(transaccion)
  }
}
