import {Component, OnInit} from '@angular/core';
import {AppMainComponent} from './app.main.component';
import {ApiService} from "./providers/api";
import {Mrn} from "./providers/mrn";
//import {NgxQrcodeElementTypes, NgxQrcodeErrorCorrectionLevels} from "@techiediaries/ngx-qrcode";
import {ConfirmationService, MessageService} from "primeng/api";
import * as moment from 'moment';
import {Mensaje, RTDatabaseService} from "./providers/realtime_database";

@Component({
    selector: 'app-topbar',
    templateUrl: './app.topbar.component.html',
})
export class AppTopBarComponent implements OnInit {

    activeItem: number;
    valorVenta = 50000
    imgLoaded = false;
    day_detect = [];
    frase = '';
    constructor(public appMain: AppMainComponent,
                public api:ApiService,
                public mrn:Mrn,
                private confirmationService: ConfirmationService,private messageService: MessageService) {

        this.mrn.loading = false
        this.mrn.crearControles();
        if(this.api.nodoActual['id'] == 1){
            this.mrn.SALDOWS = {
                usuario:this.mrn.USUARIO_MSV,
                password:this.mrn.PWD_MVS,
                canal_transaccion:2,
                bolsa:'M',
            }
            this.mrn.saldos_ms(this.mrn.SALDOWS)
        }

    }

    mobileMegaMenuItemClick(index) {
        this.appMain.megaMenuMobileClick = true;
        this.activeItem = this.activeItem === index ? null : index;
    }

    ngOnInit() {
        this.formatAMPM();
        
    }

    guardarUsuario() {
        if(this.mrn.formUsuario.value['id']){
            if(this.mrn.formUsuario.value['password2']){
                this.confirmationService.confirm({
                    message: 'Quiere cambair la contraseña al usuario '+this.mrn.formUsuario.value['username']+'?',
                    accept: () => {
                        this.mrn.formUsuario.patchValue({
                            password:this.mrn.formUsuario.value['password2'].toLowerCase()
                        });
                        this.mrn.updatePwdUsuario(this.mrn.formUsuario.value)
                    }
                });
            }else {
                this.mrn.updateUsuario(this.mrn.formUsuario.value)
            }
        }else {
            this.mrn.formUsuario.patchValue({
                password:this.mrn.formUsuario.value['password2'],
                nodo_id:this.api.nodoActual['id']
            });
            this.mrn.addUsuario()
        }
    }

    solicitarSaldo() {
        let obj = {
            nodo : this.api.nodoActual['id'],
            usuario: this.api.usuario['id'],
            tipoServicio: this.mrn.formTransaccion.value['tipoServicio'],
            tipo_transaccion : this.mrn.formTransaccion.value['tipo_transaccion'],
            medioSolicitud: 'Web',
            valor: this.mrn.formTransaccion.value['valor'],
            saldo_pendiente_pago:this.mrn.formTransaccion.value['valor'],
            soporte : this.mrn.formTransaccion.value['valor']?this.mrn.formTransaccion.value['valor']:''
        }

        this.api.post_soap('solicitar_saldo',obj).subscribe(
            data => {
                let respuesta = JSON.parse(data)
                if(respuesta.data){
                    this.mrn.transaccionSeleccionada = respuesta.data
                    this.confirmationService.confirm({
                        message: respuesta.mensaje,
                        rejectVisible: false,
                        accept: () => {}});
                    this.mrn.getMisSolicitudesSaldo();
                    this.mrn.verSubirArchivo = this.mrn.formTransaccion.value['tipo_transaccion'] == 'SSC' ? true : false;
                    this.mrn.verFormTransaccion = false;
                    this.mrn.formTransaccion.reset();
                }else{
                    this.confirmationService.confirm({
                        message: respuesta.mensaje,
                        rejectVisible: false,
                        accept: () => {}});
                }
                this.mrn.dbl_click_prevent()
            }
        )
    }

    verificar_valor_maximo(valor_maximo: any,valor) {

        if (valor > valor_maximo){
            this.confirmationService.confirm({
                message: 'Usted no posee esa cantidad en ganacias.',
                rejectVisible:false,
                accept: () => {
                    this.mrn.formTransaccion.patchValue({
                        valor:valor_maximo
                    })
                }
            });
        }
    }

    canjearGanancia() {
        this.confirmationService.confirm({
            message: 'Esta seguro de canjear '+this.mrn.separadorDeMiles(this.mrn.formTransaccion.value['valor'])+' de sus ganancias?',
            accept: () => {
                if(this.mrn.formTransaccion.value['valor']){
                    if(this.mrn.formTransaccion.value['valor'] <= this.mrn.misBoslsasDinero.utilidad){
                        this.mrn.formTransaccion.patchValue({
                            usuario_id:this.api.usuario['id'],
                            nodo_id:this.api.nodoActual['id'],
                            tipo_transaccion:'CJG',
                            tipo_datos: 'postpago',
                            tipo_red: 'wifi',
                            app_ver: 'n/a',
                        })
                        this.mrn.addCanjeGanancia()
                    }else {
                        alert("El valor a trasladar supera el monto disponible.")
                    }

                }else{
                    alert("Debe digitar el valor a trasladar.")
                }
            }
        });

    }

    verificarCargaImg() {
        this.imgLoaded = true;
    }

    verificarEvento(date: any) {
        return this.mrn.fechas_pago.filter(item=>item.getDate()==date).length?true:false
    }

     formatAMPM() {
         setInterval(()=>{
             let hoy =  new Date
             let hours = hoy.getHours();
             let minutes = hoy.getMinutes();
             let segundo = hoy.getSeconds();
             let ampm;
             if(hours >= 1 && hours <=12){
                 ampm = ['Buen dia','fas fa-sun','am']
             }
             else if(hours >= 13 && hours <=18){
                 ampm = ['Buena tarde','fas fa-cloud-sun','pm']
             }
             else if(hours >= 19){
                 ampm = ['Buena noche','fas fa-moon','pm']
             }
             let strTime = hoy
             this.day_detect = [ampm[0],strTime,ampm[1]]
         },1000)
    }


    cambiarMensaje() {
        const min = 0;
        const max = 19;
        const randomNum = Math.floor(Math.random() * (max - min + 1)) + min;
        let mensajes = [
            "Nada es particularmente difícil si lo divides en pequeñas tareas",
            "El fracaso es simplemente la oportunidad de comenzar de nuevo, esta vez más inteligentemente",
            "El éxito se logra al aprovechar las oportunidades que se presentan",
            "La calidad significa hacer lo correcto cuando nadie está mirando",
            "El trabajo en equipo es la clave del éxito",
            "La mayoría de las personas gastan más tiempo y energía en hablar de los problemas que en solucionarlos",
            "No encuentres la culpa, encuentra el remedio",
            "Si todo lo que haces es lo que siempre has hecho, entonces todo lo que obtendrás es lo que siempre has obtenido",
            "El verdadero éxito llega cuando la preparación se encuentra con la oportunidad",
            "No encuentres la falta, encuentra una solución",
            "El fracaso es la oportunidad de comenzar de nuevo con más inteligencia",
            "La mejor manera de predecir el futuro es crearlo",
            "La excelencia es hacer lo ordinario de manera extraordinaria",
            "No hay secretos para el éxito. Es el resultado de la preparación, el trabajo duro y el aprendizaje del fracaso",
            "Pon tu corazón, mente y alma en tu trabajo y el éxito seguirá",
            "El que piensa que puede y el que piensa que no puede, ambos tienen razón",
            "Nunca dejes que tu trabajo se interponga en el camino de tus sueños",
            "La mejor forma de predecir tu futuro es crearlo",
            "Las dificultades son oportunidades disfrazadas",
            "El éxito no es la clave de la felicidad. La felicidad es la clave del éxito. Si amas lo que haces, tendrás éxito",
        ]
        this.frase =  mensajes[randomNum]
    }

    refreshStatus(){
        this.mrn.getMisBolsasDinero()
        this.mrn.getMiCredito()
        if(this.api.nodoActual['id'] == 1){//Si es Mrn Colombia .....
            this.mrn.SALDOWS = {
                usuario:this.mrn.USUARIO_MSV,
                password:this.mrn.PWD_MVS,
                canal_transaccion:2,
                bolsa:'M',
            }
            this.mrn.saldos_ms(this.mrn.SALDOWS)

        }
    }
}
