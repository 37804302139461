import {Component, OnInit} from '@angular/core';
import {ConfirmationService, MessageService, PrimeNGConfig} from 'primeng/api';
import { ApiService } from './providers/api';
import {Mrn} from "./providers/mrn";
import {PushNotificationService} from "./providers/push-notification";
import {Router} from "@angular/router";
import {HttpHeaders} from "@angular/common/http";

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
})
export class AppComponent implements OnInit{

    horizontalMenu: boolean;

    darkMode = false;

    menuColorMode = 'light';

    menuColor = 'layout-menu-light';

    themeColor = 'blue';

    layoutColor = 'blue';

    ripple = true;

    inputStyle = 'outlined';

    constructor(private primengConfig: PrimeNGConfig,
                private mrn:Mrn,
                private notificacion:PushNotificationService,
                private toast:MessageService,
                public api: ApiService,
                private route: Router,
                private confirmationService: ConfirmationService,) {



        this.notificacion.requestPermission().then(token => {
            if(token){
                this.mrn.tokenMessage = token;
            }
        });
        if(JSON.parse(localStorage.getItem('usuario'))){
            this.api.usuario = JSON.parse(localStorage.getItem('usuario'))
            this.api.nodoActual = this.api.usuario['nodo'];
            this.api.headersAll = new HttpHeaders().set('Content-Type','application/json')
                .set('Authorization','Token '+this.api.usuario['token']);
            this.api.optionsAll = { headers: this.api.headersAll};
            this.mrn.getMunicipios();
            this.mrn.getMisBolsasDinero();
            this.mrn.getMiCredito()
            this.mrn.getProveedores()
            this.mrn.getFacturasPendientes(this.api.nodoActual,false)
            this.api.usuario['puntoAcceso'] = this.mrn.tokenMessage
            this.mrn.registrarPuntoDeAcceso(this.api.usuario)
            if (!this.api.nodoActual['mora']) {
                if (this.api.nodoActual['tipo'] == 'Distribuidor') {
                    this.route.navigate(['/aliados'])
                }
                if (this.api.nodoActual['tipo'] == 'Comercio') {
                    this.mrn.activeState = [true, true];
                    this.mrn.getCatServicio();
                    this.mrn.getLastVentasByNodo()
                    this.mrn.getComisiones(this.api.nodoActual)
                    this.route.navigate(['/ventas'])
                }
            } else {
                this.mrn.getNodoPadre()
                this.mrn.getFacturasMora(this.api.nodoActual,false)
                this.route.navigate(['/pagos'])
            }

        }
        else {
            this.route.navigate(['/login'])
        }
    }

    ngOnInit() {
        if (this.esAmbienteMovil()) {
            console.log('Estás en un entorno móvil.');
        } else {
            console.log('No estás en un entorno móvil.');
        }

        this.notificacion.receiveMessage().subscribe(
            payload => {
                if(payload.notification.body){
                    this.toast.add({severity:'success', summary:'MRN Colombia', detail:payload.notification.body,life:10000});
                }
                switch(payload.data.accion) {
                    case 'SS': {
                        this.mrn.getSolicitudesSaldo();
                        this.mrn.getMisBolsasDinero();
                        this.mrn.getSolicitudesSaldo()
                        break;
                    }
                    case 'ACTND': {
                        this.mrn.getNodoById(this.api.nodoActual,true)
                        break;
                    }
                    case 'ASS': {
                        this.mrn.getMisSolicitudesSaldo();
                        this.mrn.getMisBolsasDinero();
                        this.mrn.getMiCredito()
                        break;
                    }
                    case 'CPROV': {
                        this.mrn.getMisSolicitudesSaldo();
                        this.mrn.getMisBolsasDinero();
                        this.mrn.getMiCredito()
                        break;
                    }
                    case 'AJS': {
                        this.mrn.getMisBolsasDinero();
                        break;
                    }
                    case 'VEN': {
                        this.mrn.SALDOWS = {
                            usuario:this.mrn.USUARIO_MSV,
                            password:this.mrn.PWD_MVS,
                            canal_transaccion:2,
                            bolsa:'M',
                        }
                        //this.mrn.saldos_ms(this.mrn.SALDOWS)
                        this.mrn.getMisBolsasDinero();
                        break;
                    }
                    case 'TRF': {
                        //this.mrn.get_transacciones_fallidas();
                        //TODO implementar activeState
                        this.route.navigate(['/aliados'])
                        break;
                    }
                    case 'APSCR': {
                        this.mrn.getMisBolsasDinero();
                        this.mrn.getMiCredito()
                        this.mrn.getMisSolicitudesSaldo();
                        this.mrn.getNodoById(this.api.nodoActual,true)
                        break;
                    }
                    case 'ACMC': {
                        this.mrn.getMiCredito()
                        break;
                    }
                    case 'SSRCH': {
                        this.mrn.getMisSolicitudesSaldo();
                        break;
                    }
                    case 'USM': {
                        this.api.logOut()
                        break;
                    }
                    case 'ACTCOM': {
                        this.mrn.getCatServicio();
                        this.mrn.getComisiones(this.api.nodoActual)
                        break;
                    }
                    default: {
                        //statements;
                        break;
                    }
                }
            }
        );
        this.primengConfig.ripple = true;
    }

    esAmbienteMovil() {
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) || window.innerWidth <= 800;
    }

}
