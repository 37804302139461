import {Component} from '@angular/core';
import {ApiService} from "../providers/api";
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {Router} from "@angular/router";
import {Mrn} from "../providers/mrn";
import {GoogleLoginProvider, SocialAuthService, SocialUser} from "angularx-social-login";
import {AuthService} from "../providers/auth.service";
import {ConfirmationService, MessageService} from "primeng/api";
import {isUndefined} from "lodash";
import {HttpHeaders} from "@angular/common/http";
import {AngularFireDatabase} from "@angular/fire/compat/database";
import {RealTimeService} from "../providers/firebase-realtime";
import {Mensaje, RTDatabaseService} from "../providers/realtime_database";
import {environment} from "../../environments/environment";


@Component({
    selector: 'app-login',
    templateUrl: './app.login.component.html',
})
export class AppLoginComponent {
    loginForm: UntypedFormGroup;
    dark: boolean;
    checked: boolean;
    user: SocialUser;
    Student: Mensaje[];
    tokenRecap= ''
    constructor(public api: ApiService, private fb: UntypedFormBuilder,
                private route: Router, public mrn: Mrn,
                private socialAuthService: SocialAuthService,
                private authService: AuthService,
                private confirmationService: ConfirmationService,
                private db:AngularFireDatabase,
                ) {
    }

    ngOnInit(): void {
        if(environment.production){
            this.loginForm = this.fb.group({
                id: [''],
                username: ['', Validators.required],
                password: ['', Validators.required],
                email: [''],
                recaptcha: ['', Validators.required]
            });
        }else {
            this.loginForm = this.fb.group({
                id: [''],
                username: ['', Validators.required],
                password: ['', Validators.required],
                email: [''],
                recaptcha: ['']
            });
        }

        this.mrn.crearControles();

    }

    loginGoogle() {
        this.mrn.loading = true;
        this.mrn.loadingText = 'Verificando usuario'
        this.authService.googleLogin();
    }

    googleSignin(): void {
        this.socialAuthService.signIn(GoogleLoginProvider.PROVIDER_ID);
    }

    login() {
            if(this.loginForm.valid){
                this.loginForm.patchValue({
                    username: this.loginForm.value['username'].trim().toLowerCase(),
                    password: this.loginForm.value['password'].trim(),
                })
                this.mrn.loading = true;
                this.mrn.bad_login =  ''
                this.mrn.loadingText = 'Verificando usuario'
                this.api.login('api-token-auth', this.loginForm.value)
                    .subscribe(
                        data => {
                            if (!isUndefined(data)) {
                                this.api.usuario = data;
                                localStorage.setItem('usuario',JSON.stringify(data))
                                this.api.nodoActual = data['nodo'];
                                this.api.headersAll = new HttpHeaders().set('Content-Type','application/json')
                                    .set('Authorization','Token '+data['token']);
                                this.api.optionsAll = { headers: this.api.headersAll};
                                this.mrn.loading = false;
                                this.mrn.getMunicipios();
                                this.mrn.getMisBolsasDinero();
                                this.mrn.getMiCredito()
                                this.mrn.getProveedores()
                                this.mrn.getFacturasPendientes(this.api.nodoActual,false)
                                this.api.usuario['puntoAcceso'] = this.mrn.tokenMessage
                                this.mrn.registrarPuntoDeAcceso(this.api.usuario)
                                if (!this.api.nodoActual['mora']) {
                                    if (this.api.nodoActual['tipo'] == 'Distribuidor') {
                                        this.route.navigate(['/aliados'])
                                    }
                                    if (this.api.nodoActual['tipo'] == 'Comercio') {
                                        this.mrn.activeState = [true, true];
                                        this.mrn.getCatServicio();
                                        this.mrn.getLastVentasByNodo()
                                        this.mrn.getComisiones(this.api.nodoActual)
                                        this.route.navigate(['/ventas'])
                                    }
                                } else {
                                    this.mrn.getNodoPadre()
                                    this.mrn.getFacturasMora(this.api.nodoActual,false)
                                    this.route.navigate(['/pagos'])
                                }
                            }else {
                                this.mrn.bad_login = 'El usuario o la contraseña son incorrectos.'
                            }
                        }
                    )
            }
    }

    updateUserPwd(usuario_pwd_recovery) {
        this.api.headersAll = new HttpHeaders().set('Content-Type','application/json');
        this.api.optionsAll = { headers: this.api.headersAll};
        usuario_pwd_recovery.password = usuario_pwd_recovery.identificacion
        this.mrn.updatePwdUsuarioRec(usuario_pwd_recovery)
    }


}

