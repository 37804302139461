import {Component} from '@angular/core';
import { Mrn } from './providers/mrn';

@Component({
    selector: 'app-footer',
    template: `
        <div class="layout-footer">
			<!--<div class="logo-text">
                <div class="text">
                    <h3>$ {{(mrn.creditoByNodo?mrn.creditoByNodo.montoAutorizado:0)|number}}</h3>
                    <span>Credito autorizado</span>
                </div>
                <div class="text">
                    <h3>$ {{(mrn.creditoByNodo?mrn.creditoByNodo.montoDisponible:0)|number}}</h3>
                    <span>Credito disponible</span>
                </div>
                <div class="text">
                    <h3>$ {{(mrn.creditoByNodo?mrn.creditoByNodo.montoUtilizado:0)|number}}</h3>
                    <span>Credito utilizado</span>
                </div>
			</div>-->
			<div class="icons">
                <div class="logo-text">
                    <img src="assets/layout/images/logo-mirage@2x.png" alt="mirage-layout" />
                    <div class="text">
                        <h1>MRN Colombia</h1>
                        <span>Plataforma de recargas Ver.2.0</span>
                    </div>
                </div>
			</div>
        </div>
    `
})
export class AppFooterComponent {
        constructor(public mrn: Mrn) {
        }
}
