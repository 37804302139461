<div class="login-body" [ngClass]="{'layout-dark': dark, 'layout-light': !dark}"  (keydown.enter)="login()" >
    <div class="login-content">
        <!--<div class="night-switch">
            <p-button styleClass="dark-switch"  icon="pi pi-circle-on" (click)="dark = true"></p-button>
            <p-button styleClass="light-switch"  icon="pi pi-circle-off" (click)="dark = false"></p-button>
        </div>-->
        <div class="login-panel">
            <div class="login-panel-content">
                <div class="logo">
                    <img src="../../assets/layout/images/logo-mirage@2x.png" alt="mirage-layout"/>
                </div>
                <h1>Acceso a MRN</h1>
                <h5 class="{{mrn.isrestored?'autorizado':'no-autorizado'}}" *ngIf="mrn.bad_login">{{mrn.bad_login}}</h5>
               <!-- <div class="buttons">
                    <button style="width: 100%" pButton type="button" (click)="loginGoogle()"
                            label="Ingresar con Google" icon="pi pi-google"></button>
                </div>-->
                <p-divider></p-divider>
                <form [formGroup]="loginForm">
                    <div class="forms">
                        <div class="login-input-wrapper">
                            <label for="username">Usuario</label>
                            <input formControlName="username" id="username" pInputText
                                   placeholder="Escriba su usuario..."/>
                            <i class="pi pi-user"></i>
                        </div>
                        <div class="login-input-wrapper">
                            <label for="password">Contraseña</label>
                            <input formControlName="password" type="password" id="password"
                                   placeholder="Escriba su contraseña" pPassword [feedback]="false"/>
                            <i class="pi pi-lock"></i>
                        </div>
                        <ngx-recaptcha2 #captchaElem [siteKey]="'6LdFtBMkAAAAADbHtwczTMKdjeSANDf-tnjnSyqB'"
                                        formControlName="recaptcha">
                        </ngx-recaptcha2>
                        <!--<div class="p-col-12">
                            <p-checkbox [ngModelOptions]="{standalone: true}" [(ngModel)]="checked" label="Recuerdame"
                                        value="SaveInfo" binary="true"></p-checkbox>
                        </div>-->
                        <div class="buttons">
                            <button [disabled]="!loginForm.valid" style="width: 100%" pButton type="button" (click)="login()"
                                   label="Ingresar"></button>
                        </div>
                        <div style="text-align: center" class="buttons">
                            <a style="width: 100%;padding: 15px" href="#" class="secondary-button"
                               (click)="mrn.ver_rec_pwd = true;mrn.msj_recovery='';mrn.formRecoveryPwd.reset();mrn.usuario_pwd_recovery = ''">Perdio su contraseña?</a>
                        </div>
                        <!--<div style="text-align: center" class="buttons">
                            <a style="width: 100%;padding: 15px" href="#" class="secondary-button"
                               (click)="probarConexion()">probar conexion</a>
                        </div>-->
                    </div>
                </form>
            </div>
        </div>
        <div class="desert"></div>
    </div>
</div>
<p-dialog header="Recuperación de contraseña" [style]="{width: '30vw'}" [(visible)]="mrn.ver_rec_pwd" [modal]="true">
    <form [formGroup]="mrn.formRecoveryPwd">
        <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-12"  >
                <div *ngIf="!mrn.usuario_pwd_recovery" style="text-align: center;color:#5E81ACFF">
                    <i class="fas fa-tired" style="font-size:5em;"></i>
                    <h5 style="padding:10px;text-align: justify;">
                        Olvido su contraseña? Por favor diligencie el siguiente formulario.
                        MRN Colombia verificara sus datos y restableceremos su contraseña a la contraseña inicial. </h5>
                </div>

                <div *ngIf="mrn.usuario_pwd_recovery" style="text-align: center;color:#5E81ACFF">
                    <i class="fas fa-laugh-beam" style="font-size:5em"></i>
                    <h5  style="padding:10px;text-align: justify">
                        Bienvenido! {{mrn.usuario_pwd_recovery.first_name}}
                        {{mrn.usuario_pwd_recovery.last_name}}, para restablecer la contraseña presiona el boton Aceptar.</h5>
                </div>
            </div>
            <div class="p-field p-col-12">
                <p-divider></p-divider>
            </div>

            <div *ngIf="mrn.msj_recovery" class="p-field p-col-12">
                <h5 class="no-autorizado" >{{mrn.msj_recovery}}</h5>
            </div>
            <div *ngIf="!mrn.usuario_pwd_recovery" class="p-field p-col-12 p-md-6 ">
                <label for="user">Usuario</label>
                <input formControlName="username" id="user" pInputText placeholder="Escriba su usuario..."/>
            </div>
            <div *ngIf="!mrn.usuario_pwd_recovery" class="p-field p-col-12 p-md-6">
                <label for="identificacion">N°. Identificacion</label>
                <input formControlName="identificacion" type="text" id="identificacion"
                       placeholder="Escriba su identificacion" pInputText/>
            </div>
            <div *ngIf="!mrn.usuario_pwd_recovery" class="p-field p-col-12">
                <button [disabled]="!mrn.formRecoveryPwd.valid" style="width: 100%" pButton type="button"
                        (click)="mrn.validar_usuario()" label="Validar datos"></button>
            </div>

            <div *ngIf="mrn.usuario_pwd_recovery" class="p-field p-col-12">
                <button [disabled]="!mrn.formRecoveryPwd.valid" style="width: 100%" pButton type="button"
                        (click)="updateUserPwd(mrn.usuario_pwd_recovery)" label="Aceptar"></button>
            </div>

        </div>
    </form>
</p-dialog>
<p-toast></p-toast>
