<div class="p-grid">
    <div class="p-col-12 p-md-5">
        <div class="card">
                <h5 *ngIf="mrn.nodoSeleccionado">Comisiones de {{mrn.nodoSeleccionado.representanteLegal}}</h5>
                <div *ngIf="mrn.nodoSeleccionado">
                    <p-table #dtComisiones [value]="mrn.comisiones" [rowHover]="true"
                             [responsive]="true" [paginator]="true" [rows]="10"  [globalFilterFields]="['proveedorEmpresa.empresa.nom_empresa']">
                        <ng-template pTemplate="caption">
                            <div class="p-d-flex">
                                <span class="p-input-icon-left p-ml-auto">
                                    <i class="pi pi-search"></i>
                                    <input pInputText type="text"
                                           (input)="dtComisiones.filterGlobal($event.target.value, 'contains')"
                                           placeholder="Buscar Empresa"/>
                                </span>
                            </div>
                        </ng-template>
                    <ng-template pTemplate="header">
                        <tr>
                            <th style="width:10%"></th>
                            <th>Empresa</th>
                            <th style="width:15%">Comision</th>
                            <th style="width:20%">Actualizacion</th>
                            <th style="width:20%"></th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-comision>
                        <tr>
                            <td>
                                <i class="pi pi-circle-on {{comision.activo?'activo':'inactivo'}}"></i>
                            </td>
                            <td>{{comision.proveedorEmpresa.empresa.nom_empresa}}</td>
                            <td style="text-align:center">{{comision.micomision}} {{comision.micomision<50?'%':'pesos'}}</td>
                            <td style="text-align:center">{{comision.updated_at|date}}</td>
                            <td style="text-align:center">
                                <i pTooltip="{{comision.activo?'Inactivar':'Activar'}} Comision"
                                   class="pi pi-{{comision.activo?'times-circle':'check-circle'}} accion-tabla"
                                   (click)="desactivarComision(comision)"></i>
                                <i pTooltip="Editar Comision" (click)="editarComisionAliado(comision)"
                                   class="pi pi-pencil accion-tabla"></i>
                                <i pTooltip="Ver Productos" class="pi pi-eye accion-tabla"
                                   (click)="
                                    mrn.comisionAliadoSeleccionada = comision;
                                    mrn.getProductosProveedor(comision.proveedorEmpresa.proveedor.id,comision.proveedorEmpresa.empresa.id)"></i>
                            </td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="emptymessage">
                            <tr>
                                <td [attr.colspan]="5">
                                    Seleccione una empresa para ver los productos codificados.
                                </td>
                            </tr>
                    </ng-template>
                </p-table>
                </div>
            </div>
    </div>
    <div class="p-col-12 p-md-7">
        <div class="card" *ngIf="mrn.comisionAliadoSeleccionada">
            <h5>Usted tiene {{mrn.productosByProveedor.length}}
                productos de {{mrn.comisionAliadoSeleccionada?mrn.comisionAliadoSeleccionada.proveedorEmpresa.empresa.nom_empresa:''}}
                que puede comercializar.</h5>
            <p-table [value]="mrn.productosByProveedor"
                     [paginator]="true" [rowHover]="true" [responsive]="true" [rows]="10">
                <ng-template pTemplate="header">
                    <tr>
                        <th>Producto</th>
                        <th style="width:15%">Valor</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-producto>
                    <tr>
                        <td>{{producto.producto.nom_producto}}</td>
                        <td style="text-align:right">$ {{producto.producto.valor_producto|number}}</td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td [attr.colspan]="2">
                            Aun no hay Productos.
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
</div>
<p-dialog [modal]="true" header="Editar comisiones" [(visible)]="mrn.verFormComisionAliado" [style]="{width: '20vw'}">
    <table class="tabla_calculadora" *ngIf="mrn.comisionAliadoSeleccionada" style="margin: 15px 0 25px 0">
        <tr>
            <td>Empresa</td>
            <th>{{mrn.comisionAliadoSeleccionada.proveedorEmpresa.empresa.nom_empresa}}</th>
        </tr>
        <tr>
            <td>Mi comision</td>
            <th>{{comisionDistribuidor.micomision > 50?'$'+comisionDistribuidor.micomision:comisionDistribuidor.micomision+'%'}}</th>
        </tr>
    </table>
    <form [formGroup]="mrn.formComision">
        <div class="p-fluid p-formgrid p-grid">
            <div class="p-field  p-col-12 p-md-12">
                <label for="comisionComercio">Comision</label>
                <input #valor (input)="verificarValorComision(valor.value)"  formControlName="micomision" id="comisionComercio" type="number" pInputText>
            </div>
        </div>
    </form>
    <p-footer style="text-align:right">
        <div *ngIf="mrn.formComision.valid" style="text-align:right">
            <button (click)="guardarComision()" type="button" pButton pRipple label="Guardar" ></button>
        </div>
    </p-footer>
</p-dialog>
<p-dialog header="Productos registrados" [(visible)]="mrn.verProductosEmpresa" [style]="{width: '50vw'}">
    <div class="card">
        <h5>Usted tiene {{mrn.productosByProveedor.length}}
            productos de {{mrn.comisionSeleccionada?mrn.comisionSeleccionada.proveedorEmpresa.empresa.nom_empresa:''}}
            que puede comercializar.</h5>
        <p-table [value]="mrn.productosByProveedor"
                 [paginator]="true" [rowHover]="true" [responsive]="true" [rows]="10">
            <ng-template pTemplate="header">
                <tr>
                    <th style="width:11%">Codigo Proveedor</th>
                    <th>Producto</th>
                    <th style="width:15%">Valor</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-producto>
                <tr>
                    <td style="text-align:right">{{producto.codigo_producto}}</td>
                    <td>{{producto.producto.nom_producto}}</td>
                    <td style="text-align:right">$ {{producto.producto.valor_producto|number}}</td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td [attr.colspan]="3">
                        Aun no hay Productos.
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</p-dialog>
