<div class="p-grid">
    <div class="p-col-12 p-md-12">
        <p-tabView>
            <p-tabPanel header="Informaciòn de mi comercio">
                <div *ngIf="mrn.nodoSeleccionado" class="card">
                    <h5><i class="{{mrn.nodoSeleccionado.icon}}" style="margin-right: 10px;font-size:1.1em"></i>{{mrn.nodoSeleccionado.razonSocial}}</h5>
                    <div class="p-grid">
                        <div class="p-col-12 p-md-5">
                            <ul>
                                <div *ngIf="mrn.usuarioByNodo">
                                    <li style="list-style: none;padding:6px"><h5 style="font-weight: bold">Información del Administrador</h5></li>
                                    <li style="list-style: none;padding:6px"><span style="font-weight: bold">Nombres :</span> <span style="float: right">{{mrn.usuarioByNodo.first_name}}</span></li>
                                    <li style="list-style: none;padding:6px"><span style="font-weight: bold">Telefono :</span> <span style="float: right">{{mrn.usuarioByNodo.telefono}}</span></li>
                                    <li style="list-style: none;padding:6px"><span style="font-weight: bold">Direccion :</span> <span style="float: right">{{mrn.usuarioByNodo.direcccion}}</span></li>
                                    <li style="list-style: none;padding:6px"><span style="font-weight: bold">Email :</span> <span style="float: right">{{mrn.usuarioByNodo.email}}</span></li>
                                </div>
                                <p-divider></p-divider>
                                <li style="list-style: none;padding:6px"><h5 style="font-weight: bold">Información
                                    del {{mrn.nodoSeleccionado.tipo}}</h5></li>
                                <li style="list-style: none;padding:6px"><span
                                        style="font-weight: bold">Razon Social :</span> <span
                                        style="float: right">{{mrn.nodoSeleccionado.razonSocial}}</span></li>
                                <li style="list-style: none;padding:6px"><span
                                        style="font-weight: bold">fecha de vinculación :</span> <span
                                        style="float: right">{{mrn.nodoSeleccionado.fecha_vinculacion|date}}</span></li>
                                <li style="list-style: none;padding:6px"><span
                                        style="font-weight: bold">Telefono :</span> <span
                                        style="float: right">{{mrn.nodoSeleccionado.telefono}}</span></li>
                                <li style="list-style: none;padding:6px"><span
                                        style="font-weight: bold">Tipo Aliado :</span> <span
                                        style="float: right">{{mrn.nodoSeleccionado.tipo}}</span></li>
                                <li style="list-style: none;padding:6px"><span style="font-weight: bold">Email :</span>
                                    <span style="float: right">{{mrn.nodoSeleccionado.email}}</span></li>
                                <li style="list-style: none;padding:6px"><span style="font-weight: bold">Solicitudes de credito diarias :</span>
                                    <span style="float: right">{{mrn.nodoSeleccionado.solicitudes_de_credito_dia}}</span></li>
                                <li style="list-style: none;padding:6px"><span style="font-weight: bold">Se aprobaran las solicitudes de credito de manera :</span>
                                    <span style="float: right">{{mrn.nodoSeleccionado.aprobacion_credito_automatico?'Automatica':'Manual'}}</span></li>
                                <li style="list-style: none;padding:6px"><span style="font-weight: bold">Tipo de comision :</span>
                                    <span style="float: right">{{mrn.nodoSeleccionado.comisionAnticipada ? 'Comision anticipada' : 'Comision por venta'}}</span>
                                </li>
                                <li *ngIf="api.nodoActual['tipoComision']=='CA'" style="list-style: none;padding:6px"><span style="font-weight: bold">Porcentaje de comision :</span>
                                    <span style="float: right">{{mrn.nodoSeleccionado.comisionAnticipada}} %</span></li>
                                <p-divider></p-divider>
                                <!--<div *ngIf="mrn.distribuidorAdmin">
                                    <li style="list-style: none;padding:6px"><h5 style="font-weight: bold">Información del Distribuidor</h5></li>
                                    <li style="list-style: none;padding:6px"><span style="font-weight: bold">Nombres :</span> <span style="float: right">{{mrn.distribuidorAdmin.first_name}}</span></li>
                                    <li style="list-style: none;padding:6px"><span style="font-weight: bold">Telefono :</span> <span style="float: right">{{mrn.distribuidorAdmin.telefono}}</span></li>
                                    <li style="list-style: none;padding:6px"><span style="font-weight: bold">Direccion :</span> <span style="float: right">{{mrn.distribuidorAdmin.direccion}}</span></li>
                                    <li style="list-style: none;padding:6px"><span style="font-weight: bold">Email :</span> <span style="float: right">{{mrn.distribuidorAdmin.email}}</span></li>
                                </div>-->
                                <div *ngIf="mrn.distribuidor">
                                    <li style="list-style: none;padding:6px"><h5 style="font-weight: bold">Información del {{mrn.distribuidor.tipo}}</h5></li>
                                    <li style="list-style: none;padding:6px"><span style="font-weight: bold">Razon Social :</span> <span style="float: right">{{mrn.distribuidor.razonSocial}}</span></li>
                                    <li style="list-style: none;padding:6px"><span style="font-weight: bold">Telefono :</span> <span style="float: right">{{mrn.distribuidor.telefono}}</span></li>
                                    <li style="list-style: none;padding:6px"><span style="font-weight: bold">Tipo Aliado :</span> <span style="float: right">{{mrn.distribuidor.tipo}}</span></li>
                                    <li style="list-style: none;padding:6px"><span style="font-weight: bold">Email :</span> <span style="float: right">{{mrn.distribuidor.email}}</span></li>
                                </div>
                            </ul>
                        </div>
                        <div class="p-col-12 p-md-7">
                            <ul>
                                <li style="list-style: none;padding:6px"><h5 style="font-weight: bold">Direcciones
                                    Registradas de {{mrn.nodoSeleccionado.tipo}}</h5></li>
                                <li *ngFor="let direccion of mrn.direcciones_nodo;let i = index"
                                    style="list-style: none;padding:6px"><span style="font-weight: bold">
                                    Direccion {{i + 1}} :</span> <span style="float: right">{{direccion.direccion}}</span></li>
                                <p-divider></p-divider>
                                <li style="list-style: none;padding:6px;color:gray;font-weight: bold;cursor:pointer"
                                    (click)="mrn.verFormDireccion = true"><span> Agregar diereccion
                                    <i style="float: right;font-weight: bold" class="pi pi-plus"></i></span></li>
                            </ul>
                            <ul>
                                <li style="list-style: none;padding:6px"><h5 style="font-weight: bold">Referencia comerciales
                                    registradas de {{mrn.nodoSeleccionado.tipo}}</h5></li>
                                <ul *ngFor="let referencia of mrn.stringToJson(mrn.nodoSeleccionado.ref_comercial);let i = index">
                                    <p-divider></p-divider>
                                    <li style="list-style: none;padding:6px">
                                        <span style="font-weight: bold">Empresa:</span>
                                        <span style="float: right">{{referencia.nom_empresa}}</span>
                                    </li>
                                    <li style="list-style: none;padding:6px">
                                        <span style="font-weight: bold">Representante legal:</span>
                                        <span style="float: right">{{referencia.repr_legal}}</span>
                                    </li>
                                    <li style="list-style: none;padding:6px">
                                        <span style="font-weight: bold">Telefono:</span>
                                        <span style="float: right">{{referencia.telefono}}</span>
                                    </li>
                                    <li style="list-style: none;padding:6px">
                                        <span style="font-weight: bold">Correo electronico:</span>
                                        <span style="float: right">{{referencia.correo}}</span>
                                    </li>
                                </ul>
                                <p-divider></p-divider>
                                <li style="list-style: none;padding:6px;color:gray;font-weight: bold;cursor:pointer"
                                    (click)="mrn.verFormReferencia = true"><span> Agregar referencia comercial
                                    <i style="float: right;font-weight: bold" class="pi pi-plus"></i></span></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </p-tabPanel>
            <p-tabPanel header="Gestion de mis solicitudes saldo">
                <div class="card">
                    <p-table #dtfacpen [rowHover]="true" [responsive]="true" [value]="mrn.misSolicitudesSaldo"
                             [globalFilterFields]="['id','valor','dias_mora']"
                             [(selection)]="mrn.facturasSeleccionadas"
                             responsiveLayout="scroll" (onRowSelect)="calcularTotal($event)"
                             (onRowUnselect)="calcularTotal($event)">
                        <ng-template pTemplate="caption">
                            <button label="Solicitud saldo" pTooltip="Solicitud saldo" pButton pRipple type="button" icon="fas fa-hand-holding-usd"
                                    class="accion-tabla p-button-rounded p-button-info" (click)="mrn.formTransaccion.reset();mrn.verFormTransaccion = true"></button>
                            <button label="Actualizar tabla" pTooltip="Actualizar tabla" pButton pRipple type="button"
                                    icon="fas fa-sync-alt" (click)="mrn.getMisSolicitudesSaldo()"
                                    class="accion-tabla p-button-rounded p-button-info"></button>
                        </ng-template>
                        <ng-template pTemplate="header">
                            <tr>
                                <th style="width: 3rem"></th>
                                <th style="width:10%">Id</th>
                                <th style="width:10%">Fecha de aprobación</th>
                                <th style="width:10%">limite de pago</th>
                                <th style="width:10%">Valor solicitado</th>
                                <th style="width:10%">Saldo para pago</th>
                                <th style="width:10%">Dias de mora</th>
                                <th style="width:10%" pSortableColumn="tipo_transaccion">Tipo <p-sortIcon field="code"></p-sortIcon></th>
                                <th style="width:15%">Estado de solicitud</th>
                                <th style="width:15%">Estado de pago</th>
                                <th style="width:10%"></th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-transaccion>
                            <tr>
                                <td>
                                    <p-tableCheckbox *ngIf="transaccion.estadoPago != 'Pago en revision'"
                                                     [value]="{'id':transaccion.id,'valor':transaccion.valor,'nodo_id':transaccion.nodo.id,
                                                'repr_legal':transaccion.nodo.representanteLegal,'nodo':transaccion.nodo
                                                ,'saldo_pendiente_pago':transaccion.saldo_pendiente_pago}"
                                                     [disabled]="propietarioFactura(transaccion)"></p-tableCheckbox>
                                </td>
                                <td style="text-align: center" class="accion-tabla"
                                    (click)="mrn.transaccionSeleccionada = transaccion;mrn.verDetalletransaccion = true">{{transaccion.id}}</td>
                                <td>{{transaccion.tipo_transaccion == 'SSC'?'---':(transaccion.fecha_aprobacion|date)}}</td>
                                <td>{{transaccion.tipo_transaccion == 'SSC'?'---':(transaccion.fecha_pago|date)}}</td>
                                <td style="text-align: right">$ {{transaccion.valor|number}}</td>
                                <td style="text-align: right">$ {{transaccion.saldo_pendiente_pago|number}}</td>
                                <td style="text-align: center">{{transaccion.dias_mora}} dias</td>
                                <td style="text-align: center">{{transaccion.tipo_transaccion == 'SSC'?'Contado':'Credito'}}</td>
                                <td style="text-align: center">{{transaccion.estado}}</td>
                                <td style="text-align: center">{{transaccion.estadoPago}}</td>
                                <td style="text-align: center">
                                    <i pTooltip="Ver pagos" *ngIf="transaccion.saldo_pendiente_pago<transaccion.valor && transaccion.tipo_transaccion == 'SSCR'" (click)="seleccionarFactura(transaccion)"
                                       class="accion-tabla fas fa-cash-register"></i>
                                </td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="summary">
                            <div class="p-grid">
                                <div *ngIf="mrn.facturasSeleccionadas.length" class="p-col-6 p-md-12"
                                     style="text-align:center">
                                        <span style="font-size:1.5em">Facturas de {{mrn.facturasSeleccionadas[0]['repr_legal']}}
                                            : </span>
                                    <p-chips [(ngModel)]="mrn.facturasSeleccionadas"
                                             (onRemove)="calcularTotal($event)">
                                        <ng-template let-item pTemplate="item">
                                            {{item.id}} | ${{item.saldo_pendiente_pago|number}}
                                        </ng-template>
                                    </p-chips>
                                </div>
                                <div *ngIf="mrn.facturasSeleccionadas.length" class="p-col-6 p-md-12"
                                     style="text-align:center">
                                    <button pButton pRipple type="button" class="p-button-rounded p-button-info"
                                            label="Pagar ${{mrn.totalFacturasAPagar|number}}"
                                            (click)="pagoFacturas()"></button>
                                </div>
                            </div>
                        </ng-template>
                    </p-table>
                </div>
            </p-tabPanel>
            <p-tabPanel header="Historial de solicitudes">
                <div class="card">
                    <p-table dataKey="id" [rowHover]="true" [responsive]="true" [value]="mrn.historial_solicitudes_saldo">
                        <ng-template pTemplate="caption">
                            <div class="p-grid">
                                <div class="p-col-12 p-md-3">
                                    <div class="p-grid p-fluid p-formgrid">
                                        <div class=" p-field p-col-12 p-md-6" >
                                            <label for="fi">Desde</label>
                                            <input type="date" #fInicial pInputText id="fi">
                                        </div>
                                        <div class="p-field p-col-12 p-md-6">
                                            <label for="ff">Hasta</label>
                                            <input type="date" #fFinal pInputText id="ff">
                                        </div>
                                        <div class="p-col-12 p-md-6">
                                            <button (click)="mrn.getHistorialMisSolicitudesSaldo(fInicial.value,fFinal.value)"
                                                    type="button" pButton pRipple styleClass="p-button-danger"
                                                    label="Consultar"></button>
                                        </div>
                                        <div class="p-col-12 p-md-6" *ngIf="fFinal.value && fInicial.value">
                                            <button (click)="mrn.historial_solicitudes_saldo = [];fFinal.value='';fInicial.value=''"
                                                    type="button" pButton pRipple styleClass="p-button-danger"
                                                     label="Limpiar"></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="header">
                            <tr>
                                <th style="width:5%">Id</th>
                                <th style="width:13%">Fecha solicitud</th>
                                <th>
                                    <p-columnFilter placeholder="Representante legal" type="text" field="nodo.representanteLegal" [showMenu]="false" ></p-columnFilter>
                                </th>
                                <th style="width:15%">
                                    <p-columnFilter field="tipo_transaccion" [showMenu]="false" matchMode="equals">
                                        <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                            <p-dropdown [ngModel]="value" [options]="mrn.formaPago" (onChange)="filter($event.value)" placeholder="Formas de pago" [showClear]="true">
                                                <ng-template let-option pTemplate="item">
                                                    <span>{{option.label}}</span>
                                                </ng-template>
                                            </p-dropdown>
                                        </ng-template>
                                    </p-columnFilter>
                                </th>
                                <th style="width:15%">
                                    <p-columnFilter field="tipoServicio" matchMode="equals" [showMenu]="false">
                                        <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                            <p-dropdown [ngModel]="value" [options]="mrn.tipoServicioTransaccion" (onChange)="filter($event.value)" placeholder="Comisión" [showClear]="true">
                                                <ng-template let-option pTemplate="item">
                                                    <span>{{option.label}}</span>
                                                </ng-template>
                                            </p-dropdown>
                                        </ng-template>
                                    </p-columnFilter>
                                </th>
                                <th style="width:8%">Valor solicitado</th>
                                <th style="width:10%">
                                    <p-columnFilter field="estado" matchMode="equals" [showMenu]="false">
                                        <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                            <p-dropdown [ngModel]="value" [options]="mrn.estadosTransaccion" (onChange)="filter($event.value)" placeholder="Estado" [showClear]="true">
                                                <ng-template let-option pTemplate="item">
                                                    <span>{{option.label}}</span>
                                                </ng-template>
                                            </p-dropdown>
                                        </ng-template>
                                    </p-columnFilter>
                                </th>
                                <th>Estado de pago</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-transaccion>
                            <tr>
                                <td>{{transaccion.id}}</td>
                                <td style="text-align:center">{{transaccion.created_at|date:"dd-MM-yyyy"}} {{transaccion.hour_at|date:'shortTime'}}</td>
                                <td>{{transaccion.nodo.representanteLegal}}</td>
                                <td style="text-align: center">{{(transaccion.tipo_transaccion == 'SSC')?'Contado':'Credito'}}</td>
                                <td>{{(transaccion.tipoServicio=='CV')?'Comision venta':'Comision anticipada'}}</td>
                                <td style="text-align: right">
                                    <span style="float: right">$ {{transaccion.valor|number}}</span>
                                </td>
                                <td style="text-align: center">
                                    {{transaccion.estado}}
                                    <!--<p-chip *ngIf="transaccion.estado=='Aprobado'" label="{{transaccion.estado}}" styleClass="p-mr-2 chip-aprobado"></p-chip>
                                    <p-chip *ngIf="transaccion.estado=='Rechazado'" label="{{transaccion.estado}}" styleClass="p-mr-2 chip-rechazado"></p-chip>
                                    <p-chip *ngIf="transaccion.estado=='Pendiente'" label="{{transaccion.estado}}" styleClass="p-mr-2 chip-pendiente"></p-chip>
                                    <p-chip *ngIf="transaccion.estado=='Pagado'" label="{{transaccion.estado}}" styleClass="p-mr-2 chip-disponible"></p-chip>-->
                                </td>
                                <td style="text-align: center">
                                    {{transaccion.estadoPago}}
                                    <!--<p-chip *ngIf="transaccion.estadoPago=='Pendiente'" label="{{transaccion.estadoPago}}" styleClass="p-mr-2 chip-pendiente"></p-chip>
                                    <p-chip *ngIf="transaccion.estadoPago=='Pago aceptado'" label="{{transaccion.estadoPago}}" styleClass="p-mr-2 chip-aprobado"></p-chip>-->
                                </td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="summary">
                            <div class="flex align-items-center justify-content-between">
                                Se encontraron {{mrn.historial_solicitudes_saldo.length}} transacciones.
                            </div>
                        </ng-template>
                        <ng-template pTemplate="emptymessage" let-columns>
                            <tr>
                                <td [attr.colspan]="8">
                                    <div class="card" style="background: rgba(147,226,255,0.67)">
                                        <p><i style="font-size:1.8em" class="fas fa-info-circle accion-tabla">
                                        </i>Para realizar una consulta por favor seleccione una Fecha inicial y un Fecha final.</p>
                                    </div>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </p-tabPanel>
            <p-tabPanel header="Mis usuarios">
                <div class="card">
                    <p-table [rowHover]="true" [responsive]="true" [value]="mrn.usuariosByNodo" #dtUsuarios
                             [globalFilterFields]="['first_name','username','telefono','identificacion','cargo']">
                        <ng-template pTemplate="caption">
                            <div class="caption_content">
                                <button pButton pRipple type="button" label="Nuevo usuario"
                                        (click)="mrn.verFormUsuarioPP = true;mrn.formUsuario.reset()"></button>
                                <span class="p-input-icon-left ml-auto">
                                    <i class="pi pi-search"></i>
                                    <input pInputText type="text" (input)="dtUsuarios.filterGlobal($event.target.value, 'contains')" placeholder="Buscar Usuario" />
                                </span>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="header">
                            <tr>
                                <th style="width:5%">Estado</th>
                                <th>Nombres</th>
                                <th style="width:13%">Usuario</th>
                                <th style="width:13%">Identificacion</th>
                                <th style="width:13%">Cargo</th>
                                <th style="width:13%"></th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-usuario>
                            <tr>
                                <td style="text-align: center">
                                    <i pTooltip="{{usuario.is_active?'activo':'inactivo'}}"
                                       class="pi pi-circle-on {{usuario.is_active?'activo':'inactivo'}}"></i>
                                </td>
                                <td>{{usuario.first_name}}</td>
                                <td>{{usuario.username}}</td>
                                <td style="text-align: center">{{usuario.identificacion}}</td>
                                <td style="text-align: center">{{usuario.cargo}}</td>
                                <td style="text-align: center">
                                    <i pTooltip="Ver permisos de acceso" style="margin-right: 10px"
                                       class="pi pi-unlock accion-tabla"
                                       (click)="mrn.usuarioSeleccionado = usuario;mrn.getPermisos()"></i>
                                    <i *ngIf="usuario.cargo!='Administrador'"
                                       pTooltip="{{usuario.is_active?'Bloquear usuario':'Activar usuario'}}"
                                       class="pi {{usuario.is_active?'pi-user-minus':'pi-user-plus'}}
                            accion-tabla" (click)="cambioEstado(usuario)"></i>
                                    <i pTooltip="Editar usuario" style="margin-right: 10px"
                                       class="pi pi-pencil accion-tabla"
                                       (click)="mrn.llenarformUsuario(usuario);mrn.verFormUsuario = true"></i>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </p-tabPanel>
            <p-tabPanel header="Mis equipos asignados">
                coming soon....
            </p-tabPanel>
        </p-tabView>
    </div>
</div>
<p-dialog header="Agregar Diereccion" [(visible)]="mrn.verFormDireccion" [style]="{width: '20vw'}">
    <div class="p-fluid p-formgrid p-grid">
        <div class="card">
            <form [formGroup]="mrn.formDireccion">
                <div class="p-fluid p-formgrid p-grid">
                    <div class="p-field p-col-12 ">
                        <label for="dire">Nueva Direccion</label>
                        <input id="dire" type="text" formControlName="direccion" pInputText>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <p-footer>
        <div style="padding-top: 15px;text-align:right">
            <button *ngIf="mrn.formDireccion.value['direccion']" pButton pRipple type="button" label="Guardar"
                    (click)="mrn.guardar_direccion(api.nodoActual)"></button>
        </div>
    </p-footer>
</p-dialog>
<p-dialog header="Subir soporte de pago" closable="false" closeOnEscape="false" [(visible)]="mrn.verSubirArchivo" [style]="{width: '30vw'}">
    <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-12 p-md-12">
            <!-- <h5 class="is-size-1">Subir soporte de pago</h5>-->
            <p-fileUpload [customUpload]="true" [uploadLabel]="'Cargar'" [chooseLabel]="'Seleccionar archivo'"
                          [cancelLabel]="'Cancelar'" (uploadHandler)="mrn.subirArchivo($event)"
                          maxFileSize="1000000"></p-fileUpload>
        </div>
    </div>
</p-dialog>
<p-dialog header="Editar/Crear Usuarios auxiliares" [(visible)]="mrn.verFormUsuarioPP" [style]="{width: '35vw'}">
    <form [formGroup]="mrn.formUsuario">
        <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-12 p-md-12 ">
                <p-divider align="left">
                    <div class="p-d-inline-flex p-ai-center">
                        <i class="fas fa-house-user p-mr-2"></i>
                        <b>Informacion personal</b>
                    </div>
                </p-divider>
            </div>
            <div class="p-field p-col-12 p-md-6 ">
                <label for="identificacion">Número de Identificacion</label>
                <input formControlName="identificacion" #identificacion
                       (input)="mrn.verificar_user_data(identificacion.value,'')" id="identificacion"
                       type="number" pInputText>
                <small *ngIf="mrn.formUsuario.value['identificacion']" style="color: {{(mrn.identificacion_available=='Este número de identificacion ya existe!')?'darkred':'darkgreen'}}"
                       id="id-help">{{mrn.identificacion_available}}</small>
            </div>
            <div class="p-field p-col-12 p-md-6 ">
                <label for="first_name">Nombres</label>
                <input id="first_name" formControlName="first_name" type="text" pInputText>
            </div>
            <!--<div class="p-field p-col-12 p-md-6 ">
                <label for="last_name">Apellidos</label>
                <input id="last_name" formControlName="last_name" type="text" pInputText>
            </div>-->
            <div class="p-field p-col-12 p-md-6 ">
                <label for="tele">Número de telefono celular</label>
                <p-inputMask id="tele" mask="999-999-99-99" formControlName="telefono"
                             [unmask]="true"></p-inputMask>
            </div>
            <div class="p-field p-col-12 p-md-6 ">
                <label for="dir">Direccion</label>
                <input id="dir" type="text" formControlName="direccion" pInputText>
            </div>
            <div class="p-field p-col-12 p-md-12 ">
                <p-divider align="left">
                    <div class="p-d-inline-flex p-ai-center">
                        <i class="fas fa-user-cog p-mr-2"></i>
                        <b>Informacion de la cuenta de usuario</b>
                    </div>
                </p-divider>
            </div>
            <div class="p-field p-col-12 p-md-6 "
                 *ngIf="api.nodoActual['tipo']=='Distribuidor'">
                <label for="tipo">Tipo Usuario</label>
                <p-dropdown appendTo="body" id="tipo" [options]="mrn.cargos" formControlName="cargo" placeholder="Selecciona un tipo"
                            optionLabel="label" optionValue="value"></p-dropdown>
            </div>
            <div class="p-field p-col-12 p-md-6 ">
                <label for="username">Nombre de Usuario</label>
                <input id="username" #username (input)="mrn.verificar_user_data('',username.value)"
                       formControlName="username" type="text" pInputText>
                <small *ngIf="mrn.formUsuario.value['username']" style="color: {{(mrn.username_available=='Este nombre de usuario ya existe!')?'darkred':'darkgreen'}}"
                       id="username1-help">{{mrn.username_available}}</small>
            </div>
            <div class="p-field p-col-12 p-md-6">
                <label for="pwd">Contraseña</label>
                <span class="p-input-icon-right">
                    <i  *ngIf="!mrn.formUsuario.value['id']" class="fas fa-question-circle"
                        pTooltip="Recuerde que por defecto la contraseña inicial sera el numero de identificacion."></i>
                    <input id="pwd" type="text" formControlName="password2" pInputText>
                </span>
                <small *ngIf="mrn.formUsuario.value['id']" id="pwdsm">Digite una contraseña para cambiarla.</small>
            </div>
            <div class="p-field p-col-12 p-md-6 ">
                <label for="correo">Email</label>
                <input id="correo" type="email" ngModel email="true" formControlName="email" pInputText>
            </div>
        </div>
    </form>
    <p-footer>
        <div style="padding-top: 15px;text-align:right">
            <button *ngIf="mrn.formUsuario.valid"
                    pButton pRipple type="button" label="Guardar" (click)="guardarUsuario()"></button>
        </div>
    </p-footer>
</p-dialog>
<p-dialog [modal]="true" header="Detalle de pagos a factura" [(visible)]="mrn.ver_pagos_facturas" [modal]="true"
          [style]="{width: '50vw'}">
    <div class="card" *ngIf="mrn.transaccionSeleccionada">
        <div style="padding-bottom:15px">
            <div class="p-grid">
                <div class="p-col-12 p-md-6">
                    <table class="tabla_calculadora">
                        <tr>
                            <td>Numero Transaccion</td>
                            <th style="text-align:right">{{mrn.transaccionSeleccionada.id}}</th>
                        </tr>
                        <tr>
                            <td>Total a pagar</td>
                            <th style="text-align:right">$ {{mrn.transaccionSeleccionada.valor|number}}</th>
                        </tr>
                        <tr>
                            <td>Total abonado</td>
                            <th style="text-align:right">
                                $ {{mrn.transaccionSeleccionada.valor - mrn.transaccionSeleccionada.saldo_pendiente_pago|number}}</th>
                        </tr>
                        <tr>
                            <td>Saldo</td>
                            <th style="text-align:right">
                                $ {{mrn.transaccionSeleccionada.saldo_pendiente_pago|number}}</th>
                        </tr>
                    </table>
                </div>
                <div class="p-col-12 p-md-6">
                    <div style="text-align: center;padding: 25px" *ngIf="mrn.pagoSeleccionado">
                        <img style="width: 100%" src="{{mrn.pagoSeleccionado.soporte}}" alt="">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="card">
        <h5>Pagos Realizados</h5>
        <p-table [rowHover]="true" [responsive]="true" [value]="mrn.abonos">
            <ng-template pTemplate="header">
                <tr>
                    <th>Comprobante</th>
                    <th>Entidad reacudo</th>
                    <th>Numero de comprobante</th>
                    <th>Valor de abono</th>
                    <th>Fecha</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-pago>
                <tr>
                    <td style="text-align: center">
                        <i pTooltip="Ver comprobante de pago."
                           (click)="mrn.pagoSeleccionado = pago"
                           *ngIf="pago.soporte" class="fas fa-file-alt" style="cursor: pointer"></i>
                    </td>
                    <td>{{pago.entidad}}</td>
                    <td>{{pago.numero_recibo}}</td>
                    <td>${{pago.abono|number}}</td>
                    <td>{{pago.created_at|date}}</td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</p-dialog>
<p-dialog [modal]="true" header="Abonar a factura" [(visible)]="mrn.verFormPagosMulti" [modal]="true"
          [style]="{width: '50vw'}">
    <div class="card">
        <h5>Facturas a pagar</h5>
        <div class="card">
            <form [formGroup]="mrn.formPagos">
                <div class="p-fluid p-formgrid p-grid">
                    <div class="p-field p-col-12 p-md-6">
                        <div class="p-fluid p-formgrid p-grid">
                            <div class="p-field p-col-12 p-md-12">
                                <label for="abono">Abono a la factura</label>
                                <p-inputNumber formControlName="abono" id="abono" mode="currency" currency="COP"
                                               [minFractionDigits]="0"></p-inputNumber>
                            </div>
                            <!--<div class="p-field p-col-12 p-md-6">
                                <label for="numero_recibo">Comprobante de recaudo</label>
                                <input #numero (input)="mrn.verificar_soporte_pago(numero.value)"
                                       formControlName="numero_recibo" id="numero_recibo" type="text" pInputText>
                                <small>{{mrn.recibo_ok}}</small>
                            </div>-->
                            <div class="p-field p-col-12 p-md-12">
                                <label for="entidad">Entidad recaudo</label>
                                <p-dropdown appendTo="body" id="entidad" [options]="mrn.entidad_recaudo"
                                            formControlName="entidad"
                                            placeholder="Selecciona una entidad" optionLabel="label"
                                            optionValue="value"></p-dropdown>
                            </div>
                        </div>
                    </div>
                    <div class="p-field p-col-12 p-md-6 subir_soporte">
                        <div *ngIf="mrn.formPagos.value['soporte']">
                            <img src="{{mrn.formPagos.value['soporte']}}" style="width: 100%" alt="">
                        </div>
                        <div *ngIf="!mrn.formPagos.value['soporte']">
                            <i class="fas fa-upload" style="font-size: 5em;cursor: pointer"
                               pTooltip="Subir comprobante de pago."
                               (click)="mrn.ver_subir_soporte_pago_fac_mora = true"></i>
                        </div>
                        <p *ngIf="!mrn.formPagos.value['soporte']" style="padding: 15px 0 15px 0">Subir Comprobante de
                            pago.</p>
                    </div>
                </div>
            </form>
        </div>
        <p-table [rowHover]="true" [responsive]="true" [value]="mrn.facturasSeleccionadas"
                 [globalFilterFields]="['id','valor','dias_mora']"
                 responsiveLayout="scroll">
            <ng-template pTemplate="header">
                <tr>
                    <th style="width:10%">Id</th>
                    <th style="width:20%">Valor solicitado</th>
                    <th style="width:20%">Valor saldo</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-transaccion>
                <tr>
                    <td>{{transaccion.id}}</td>
                    <td style="text-align: right">$ {{transaccion.valor|number}}</td>
                    <td style="text-align: right">$ {{transaccion.saldo_pendiente_pago|number}}</td>
                </tr>
            </ng-template>
            <ng-template pTemplate="summary">
                <div class="p-col-6 p-md-12" style="text-align:center">
                    <button pButton pRipple type="button" class="p-button-rounded p-button-info"
                            label="Pagar facturas" [disabled]="!mrn.formPagos.valid"
                            (click)="mrn.pagarFacturasPendientesRedRevision(this.mrn.facturasSeleccionadas)"></button>
                </div>
            </ng-template>
        </p-table>
    </div>
</p-dialog>
<p-dialog [modal]="true" header="Subir soporte de pago" [(visible)]="mrn.ver_subir_soporte_pago_fac_mora"
          [style]="{width: '30vw'}">
    <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-12 p-md-12">
            <p-fileUpload [customUpload]="true" [uploadLabel]="'Cargar'" [chooseLabel]="'Seleccionar archivo'"
                          [cancelLabel]="'Cancelar'"
                          (uploadHandler)="mrn.subir_soporte_PFM($event,mrn.formPagos.value['numero_recibo'])"
                          maxFileSize="1000000"></p-fileUpload>
        </div>
    </div>
</p-dialog>
