<p-tabView styleClass="tabview-custom" (onChange)="mrn.empresaSeleccionada ='';mrn.togleVentas(0,true)">
    <p-tabPanel *ngFor="let categoria of mrn.lista_comisiones_venta let i = index" [selected]="i == 0">
       <ng-template pTemplate = "header">
            <span  (click)="seleccionarCategoria(categoria)">{{categoria['data'].proveedorEmpresa.empresa.catServicio.nombre}}</span>
        </ng-template>
        <p-accordion>
            <p-accordionTab [(selected)]="mrn.activeState[0]"
                            header="{{categoria['data'].proveedorEmpresa.empresa.catServicio.nombre}}">
                <div class="p-grid" style="text-align: center">
                    <div *ngFor="let empresa of categoria.items" style="text-align: center;"
                         class="{{categoria.items.length<=3?'p-md-3':'p-md-2'}}">
                        <img style="width: 40%;cursor: pointer" src="{{empresa['imagen']}}" alt=""
                             (click)="seleccionarEmpresa(empresa)">
                    </div>
                </div>
            </p-accordionTab>
            <p-accordionTab
                    *ngIf="mrn.empresaSeleccionada && mrn.empresaSeleccionada.catServicio.nombre == 'Recargas y Paquetes'"
                    header="{{mrn.empresaSeleccionada.nom_empresa}}" [(selected)]="mrn.activeState[1]">
                <p-tabView (onChange)="mrn.productoCodificadoSeleccionado = '';mrn.formVentasRecargas.reset()">
                    <p-tabPanel header="Venta de paquetes" >
                        <div class="card">
                            <h5>Venta de paquetes</h5>
                            <div style="margin-top: 20px">
                                <form [formGroup]="mrn.formVentasRecargas">
                                    <div class="p-fluid p-formgrid p-grid">
                                        <div class="p-col-12 p-md-4 ">
                                            <div class="p-fluid p-formgrid p-grid">
                                                <div class="p-col-12 p-md-12">
                                                    <div style="width: 100%;text-align: center;padding: 15px 0 25px 0"
                                                         *ngIf="mrn.empresaSeleccionada">
                                                        <img style="width: 30%;cursor: pointer" src="{{mrn.empresaSeleccionada.imagen}}" alt="">
                                                    </div>
                                                </div>
                                                <div class="p-col-12 p-md-12" *ngIf="mrn.empresaSeleccionada.nom_empresa == 'Directv'">
                                                    <p-inputMask [style]="{'font-size':'3em'}" id="tel"
                                                                 placeholder="Numero de tarjeta"
                                                                 formControlName="telefono" mask="9999 9999 9999" [unmask]="true"
                                                                 [autoFocus]="true" maxlength="12"></p-inputMask>
                                                </div>
                                                <div class="p-col-12 p-md-12" *ngIf="mrn.empresaSeleccionada.nom_empresa != 'Directv'">
                                                    <p-inputMask [style]="{'font-size':'3em'}" id="telo"
                                                                 placeholder="Numero Celular"
                                                                 formControlName="telefono" mask="999 999 99 99" [unmask]="true"
                                                                 [autoFocus]="true" maxlength="10"></p-inputMask>
                                                </div>
                                                <div class="p-col-12 p-md-12" style="margin-top: 15px">
                                                    <div  class="total_card_container">
                                                        <div class="total_card" >
                                                            <span style="text-align: justify;font-size: 1.2em" *ngIf="mrn.productoCodificadoSeleccionado" >
                                                                {{mrn.productoCodificadoSeleccionado.producto.nom_producto}}</span>
                                                        </div>
                                                    </div>
                                                    <div  class="total_card_container">
                                                        <div class="total_card" >
                                                            <span class="total_card_title_sell">Valor paquete</span>
                                                            <span class="total_card-value_sell" >${{ mrn.formVentasRecargas.value['valor'] | number }}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div *ngIf="mrn.messageSell" class="p-col-12 p-md-12">
                                                    <p-messages [(value)]="mrn.messageSell" [enableService]="false"></p-messages>
                                                </div><!--Mensaje pre venta-->
                                                <div class="p-col-12 p-md-12">
                                                    <div style="text-align:center;padding-top:25px">
                                                        <button [disabled]="!mrn.formVentasRecargas.valid || !mrn.formVentasRecargas.value['telefono']||
                                        !(mrn.formVentasRecargas.value['valor'] <= mrn.misBoslsasDinero.saldo_disponible)"
                                                                pButton pRipple type="button" (click)="mrn.venderRecarga(false)"
                                                                label="Venta desde saldo"></button>
                                                    </div>
                                                </div>
                                                <div class="p-col-12 p-md-12">
                                                    <div style="text-align:center;padding-top:25px">
                                                        <button [disabled]="!mrn.formVentasRecargas.valid || !mrn.formVentasRecargas.value['telefono']
                                        || !(mrn.formVentasRecargas.value['valor'] <= mrn.misBoslsasDinero.utilidad)"
                                                                pButton pRipple type="button" class="p-button-info"
                                                                label="Venta desde ganancias" (click)="mrn.venderRecarga(true)"></button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="p-field p-col-12 p-md-8">
                                            <div class="p-fluid p-formgrid p-grid">
                                                <div class="p-col-12 p-md-12">
                                                    <p-divider>
                                                        <div class="inline-flex align-items-center">
                                                            <b>Paquetes de Datos, minutos, SMS, etc.</b>
                                                        </div>
                                                    </p-divider>
                                                </div>
                                                <div class="p-col-12 p-md-12">
                                                    <p-table [value]="mrn.productosByProveedorSinTiempoAlAire" selectionMode="single"
                                                             [(selection)]="mrn.productoCodificadoSeleccionado"
                                                             [paginator]="true" [rows]="10"
                                                             currentPageReportTemplate="Viendo {first} hasta {last} de {totalRecords} registros" [rowsPerPageOptions]="[10,25,50]"
                                                             [globalFilterFields]="['producto.nom_producto','producto.valor_producto']"
                                                             (onRowSelect)="mrn.verVentaProductos = true;seleccionarPaquete()"
                                                             [rowHover]="true" [responsive]="true" #dt1>
                                                        <ng-template pTemplate="caption">
                                                            <div class="p-fluid p-formgrid p-grid">
                                                                <div class="p-input-icon-left p-field p-col-12">
                                                                    <i class="pi pi-search"></i>
                                                                    <input pInputText style="width: 100%" type="text"
                                                                           (input)="dt1.filterGlobal($event.target.value, 'contains')"
                                                                           placeholder="Buscar paquete"/>
                                                                </div>
                                                            </div>
                                                        </ng-template>
                                                        <ng-template pTemplate="header">
                                                            <tr>
                                                                <th>Producto</th>
                                                                <th style="width:15%">Valor</th>
                                                            </tr>
                                                        </ng-template>
                                                        <ng-template pTemplate="body" let-producto>
                                                            <tr [pSelectableRow]="producto">
                                                                <td>
                                                                    <i *ngIf="producto.producto.descuento_promosional"
                                                                       class="fas fa-star accion-tabla" style="color: darkgoldenrod"
                                                                       pTooltip="Vendiendo este producto {{producto.producto.empresa.nom_empresa}}
                                               te regala $ {{producto.producto.descuento_promosional|number}}
                                               de ganacia adicional."></i>
                                                                    {{producto.producto.nom_producto}}</td>
                                                                <td style="text-align: right">${{producto.producto.valor_producto|number}}</td>
                                                            </tr>
                                                        </ng-template>
                                                        <ng-template pTemplate="emptymessage">
                                                            <tr>
                                                                <td [attr.colspan]="3">
                                                                    Por favor seleccione una categoria.
                                                                </td>
                                                            </tr>
                                                        </ng-template>
                                                    </p-table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </p-tabPanel>
                    <p-tabPanel header="Venta de tiempo al aire">
                        <div class="card">
                            <h5>Venta de tiempo al aire</h5>
                            <div style="margin-top: 20px">
                                <form [formGroup]="mrn.formVentasRecargas">
                                    <div class="p-fluid p-formgrid p-grid">
                                        <div class="p-col-12 p-md-4 ">
                                        </div><!-- espacio en blanco-->
                                        <div class="p-col-12 p-md-4 ">
                                            <div class="p-fluid p-formgrid p-grid">
                                                <div class="p-col-12 p-md-12">
                                                    <div style="width: 100%;text-align: center;padding: 15px 0 25px 0"
                                                         *ngIf="mrn.empresaSeleccionada">
                                                        <img style="width: 30%;cursor: pointer" src="{{mrn.empresaSeleccionada.imagen}}" alt="">
                                                    </div>
                                                </div>
                                                <div class="p-col-12 p-md-12" *ngIf="mrn.empresaSeleccionada.nom_empresa == 'Directv'">
                                                    <p-inputMask [style]="{'font-size':'3em'}" id="tel"
                                                                 placeholder="Numero de tarjeta"
                                                                 formControlName="telefono" mask="9999 9999 9999" [unmask]="true"
                                                                 [autoFocus]="true" maxlength="12"></p-inputMask>
                                                </div>
                                                <div class="p-col-12 p-md-12" *ngIf="mrn.empresaSeleccionada.nom_empresa != 'Directv'">
                                                    <p-inputMask [style]="{'font-size':'3em'}" id="telo"
                                                                 placeholder="Numero Celular"
                                                                 formControlName="telefono" mask="999 999 99 99" [unmask]="true"
                                                                 [autoFocus]="true" maxlength="10"></p-inputMask>
                                                </div>
                                                <div class="p-col-12 p-md-12">
                                                    <p-divider>
                                                        <div class="inline-flex align-items-center">
                                                            <b>Valores para venta de tiempo al aire</b>
                                                        </div>
                                                    </p-divider>
                                                </div>
                                                <div class="p-col-12 p-md-12 item_center"  *ngIf="mrn.empresaSeleccionada.nom_empresa != 'Directv'">
                                                    <button [disabled]="!mrn.formVentasRecargas.value['telefono']" pButton pRipple type="button"
                                                            (click)="cambiarValor(1000)" label="$ 1.000"
                                                            class="{{valorVenta ==1000?'p-button-info':'p-button-outlined'}} p-button-rounded chip-padding"></button>
                                                    <button [disabled]="!mrn.formVentasRecargas.value['telefono']" pButton pRipple type="button"
                                                            (click)="cambiarValor(2000)" label="$ 2.000"
                                                            class="{{valorVenta ==2000?'p-button-info':'p-button-outlined'}} p-button-rounded chip-padding"></button>
                                                    <button [disabled]="!mrn.formVentasRecargas.value['telefono']" pButton pRipple type="button"
                                                            (click)="cambiarValor(3000)" label="$ 3.000"
                                                            class="{{valorVenta ==3000?'p-button-info':'p-button-outlined'}} p-button-rounded chip-padding"></button>
                                                </div>
                                                <div class="p-col-12 p-md-12 item_center"  *ngIf="mrn.empresaSeleccionada.nom_empresa != 'Directv'">
                                                    <button [disabled]="!mrn.formVentasRecargas.value['telefono']" pButton pRipple type="button"
                                                            (click)="cambiarValor(5000)" label="$ 5.000"
                                                            class="{{valorVenta ==5000?'p-button-info':'p-button-outlined'}} p-button-rounded chip-padding"></button>
                                                    <button [disabled]="!mrn.formVentasRecargas.value['telefono']" pButton pRipple type="button"
                                                            (click)="cambiarValor(10000)" label="$ 10.000"
                                                            class="{{valorVenta ==10000?'p-button-info':'p-button-outlined'}} p-button-rounded chip-padding"></button>
                                                    <button [disabled]="!mrn.formVentasRecargas.value['telefono']" pButton pRipple type="button"
                                                            (click)="cambiarValor(20000)" label="$ 20.000"
                                                            class="{{valorVenta ==20000?'p-button-info':'p-button-outlined'}} p-button-rounded chip-padding"></button>
                                                </div>
                                                <div class="p-col-12 p-md-12 item_center"  *ngIf="mrn.empresaSeleccionada.nom_empresa == 'Directv'">
                                                    <button [disabled]="!mrn.formVentasRecargas.value['telefono']" pButton pRipple type="button"
                                                            (click)="cambiarValor(10000)" label="$ 10.000"
                                                            class="{{valorVenta ==10000?'p-button-info':'p-button-outlined'}} p-button-rounded chip-padding"></button>
                                                    <button [disabled]="!mrn.formVentasRecargas.value['telefono']" pButton pRipple type="button"
                                                            (click)="cambiarValor(15000)" label="$ 15.000"
                                                            class="{{valorVenta ==15000?'p-button-info':'p-button-outlined'}} p-button-rounded chip-padding"></button>
                                                    <button [disabled]="!mrn.formVentasRecargas.value['telefono']" pButton pRipple type="button"
                                                            (click)="cambiarValor(20000)" label="$ 20.000"
                                                            class="{{valorVenta ==20000?'p-button-info':'p-button-outlined'}} p-button-rounded chip-padding"></button>
                                                </div>
                                                <div class="p-col-12 p-md-12 item_center"  *ngIf="mrn.empresaSeleccionada.nom_empresa == 'Directv'">
                                                    <button [disabled]="!mrn.formVentasRecargas.value['telefono']" pButton pRipple type="button"
                                                            (click)="cambiarValor(25000)" label="$ 25.000"
                                                            class="{{valorVenta ==25000?'p-button-info':'p-button-outlined'}} p-button-rounded chip-padding"></button>
                                                </div>
                                                <div class="p-col-12 p-md-12" style="margin-top: 15px">
                                                    <label for="valorVentas">Valor de la venta personalizado</label>
                                                    <p-inputNumber formControlName="valor" id="valorVentas"
                                                                   [inputStyle]="{'font-size': '3em'}" (onInput)="valorPersonalizado()"
                                                                   placeholder="Valor de venta"></p-inputNumber>
                                                </div>
                                                <div *ngIf="mrn.messageSell" class="p-col-12 p-md-12">
                                                    <p-messages [(value)]="mrn.messageSell" [enableService]="false"></p-messages>
                                                </div><!--Mensaje pre venta-->
                                                <div class="p-col-12 p-md-12">
                                                    <div style="text-align:center;padding-top:25px">
                                                        <button [disabled]="!mrn.formVentasRecargas.valid || !mrn.formVentasRecargas.value['telefono']||
                                        !(mrn.formVentasRecargas.value['valor'] <= mrn.misBoslsasDinero.saldo_disponible)"
                                                                pButton pRipple type="button" (click)="mrn.venderRecarga(false)"
                                                                label="Venta desde saldo"></button>
                                                    </div>
                                                </div>
                                                <div class="p-col-12 p-md-12">
                                                    <div style="text-align:center;padding-top:25px">
                                                        <button [disabled]="!mrn.formVentasRecargas.valid || !mrn.formVentasRecargas.value['telefono']
                                        || !(mrn.formVentasRecargas.value['valor'] <= mrn.misBoslsasDinero.utilidad)"
                                                                pButton pRipple type="button" class="p-button-info"
                                                                label="Venta desde ganancias" (click)="mrn.venderRecarga(true)"></button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="p-col-12 p-md-4 ">
                                        </div><!-- espacio en blanco-->
                                    </div>
                                </form>
                            </div>
                        </div>
                    </p-tabPanel>
                </p-tabView>
            </p-accordionTab><!--Recargas y paquetes-->
            <p-accordionTab *ngIf="mrn.empresaSeleccionada && mrn.empresaSeleccionada.catServicio.nombre == 'Pines'"
                            header="{{mrn.empresaSeleccionada.nom_empresa}}" [(selected)]="mrn.activeState[1]">
                <form [formGroup]="mrn.formVentasPines">
                    <div class="p-fluid p-formgrid p-grid">
                        <div class="p-col-12 p-md-4 ">
                            <div class="p-fluid p-formgrid p-grid">
                                <div class="p-col-12 p-md-12">
                                    <div style="width: 100%;text-align: center;padding: 15px 0 25px 0"
                                         *ngIf="mrn.empresaSeleccionada">
                                        <img style="width: 30%;cursor: pointer" src="{{mrn.empresaSeleccionada.imagen}}" alt="">
                                    </div>
                                </div>
                                <div class="p-col-12 p-md-12">
                                    <p-inputMask [style]="{'font-size':'3em'}" placeholder="Numero Celular"
                                                 formControlName="telefono" mask="999 999 99 99" [unmask]="true"
                                                 [autoFocus]="true" maxlength="10"></p-inputMask>
                                </div>
                                <div class="p-col-12 p-md-12" style="margin-top: 15px">
                                    <label for="email1">Correo electrónico</label>
                                    <input style="font-size: 3em" id="email1" type="email" formControlName="email" pInputText>
                                </div>
                                <div class="p-col-12 p-md-12" style="margin-top: 15px">
                                    <label for="valorVentasa">Valor de la venta personalizado</label>
                                    <p-inputNumber formControlName="valor" id="valorVentasa"
                                                   [inputStyle]="{'font-size': '3em'}" (onInput)="valorPersonalizado()"
                                                   placeholder="Valor de venta"></p-inputNumber>
                                </div>
                                <div *ngIf="mrn.messageSell" class="p-col-12 p-md-12">
                                    <p-messages [(value)]="mrn.messageSell" [enableService]="false"></p-messages>
                                </div><!--Mensaje pre venta-->
                                <div class="p-col-12 p-md-6">
                                    <div style="text-align:center;padding-top:25px">
                                        <button [disabled]="!mrn.formVentasPines.valid ||
                                        !(mrn.formVentasPines.value['valor'] <= mrn.misBoslsasDinero.saldo_disponible)"
                                                pButton pRipple type="button" (click)="mrn.venderPines(false)"
                                                label="Venta con saldo disponible"></button>
                                    </div>
                                </div>
                                <div class="p-col-12 p-md-6">
                                    <div style="text-align:center;padding-top:25px">
                                        <button [disabled]="!mrn.formVentasPines.valid
                                        || !(mrn.formVentasPines.value['valor'] <= mrn.misBoslsasDinero.utilidad)"
                                                pButton pRipple type="button" class="p-button-info"
                                                (click)="mrn.venderPines(true)"
                                                label="Venta con saldo de ganancias"></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="p-field p-col-12 p-md-8">
                            <div class="p-fluid p-formgrid p-grid">
                                <div class="p-col-12 p-md-12">
                                    <p-divider>
                                        <div class="inline-flex align-items-center">
                                            <b>Productos registrados</b>
                                        </div>
                                    </p-divider>
                                </div>
                                <div class="p-col-12 p-md-12">
                                    <p-table [value]="mrn.productosByProveedor" selectionMode="single"
                                             [(selection)]="mrn.productoCodificadoSeleccionado"
                                             (onRowSelect)="mrn.verVentaProductos = true"
                                             [globalFilterFields]="['producto.nom_producto','producto.valor_producto']"
                                             (onRowSelect)="seleccionarPin()"
                                             [rowHover]="true" [responsive]="true">
                                        <ng-template pTemplate="header">
                                            <tr>
                                                <th>Producto</th>
                                                <th style="width:15%">Valor</th>
                                            </tr>
                                        </ng-template>
                                        <ng-template pTemplate="body" let-producto>
                                            <tr [pSelectableRow]="producto">
                                                <td>
                                                    <i *ngIf="producto.producto.descuento_promosional"
                                                       class="fas fa-star accion-tabla" style="color: darkgoldenrod"
                                                       pTooltip="Vendiendo este producto {{producto.producto.empresa.nom_empresa}}
                                               te regala $ {{producto.producto.descuento_promosional|number}}
                                               de ganacia adicional."></i>
                                                    {{producto.producto.nom_producto}}</td>
                                                <td style="text-align: right">${{producto.producto.valor_producto|number}}</td>
                                            </tr>
                                        </ng-template>
                                        <ng-template pTemplate="emptymessage">
                                            <tr>
                                                <td [attr.colspan]="3">
                                                    Por favor seleccione una categoria.
                                                </td>
                                            </tr>
                                        </ng-template>
                                    </p-table>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </p-accordionTab><!--pines-->
            <p-accordionTab *ngIf="mrn.empresaSeleccionada && mrn.empresaSeleccionada.catServicio.nombre == 'Certificados'"
                            header="{{mrn.empresaSeleccionada.nom_empresa}}" [(selected)]="mrn.activeState[1]">
                <form [formGroup]="mrn.formVentasCertificados" *ngIf="mrn.empresaSeleccionada.nom_empresa == 'SNR'">
                    <div class="p-fluid p-formgrid p-grid">
                        <div class="p-col-12 p-md-3 item_center">
                        </div>
                        <div class="p-col-12 p-md-6 item_center">
                            <div class="p-fluid p-formgrid p-grid">
                                <div class="p-col-12 p-md-12">
                                    <div style="width: 100%;text-align: center;padding: 15px 0 25px 0"
                                         *ngIf="mrn.empresaSeleccionada">
                                        <img style="width: 30%;cursor: pointer" src="{{mrn.empresaSeleccionada.imagen}}" alt="">
                                    </div>
                                </div>
                                <div class="p-col-12 p-md-12">
                                    <p-divider>
                                        <div class="inline-flex align-items-center">
                                            <b>Diligencie estos datos para la consulta del certificado</b>
                                        </div>
                                    </p-divider>
                                </div>
                                <div class="p-col-12 p-md-6 p-field">
                                    <label for="oficina">Oficina</label>
                                    <p-dropdown id="oficina" [options]="mrn.productosByProveedorSinTiempoAlAire"
                                                formControlName="oficina" optionLabel="producto.nom_producto"
                                                optionValue="codigo_producto"
                                                [filter]="true" filterBy="producto.nom_producto" [showClear]="true"
                                                placeholder="Seleccione una oficina">
                                    </p-dropdown>
                                </div>
                                <div class="p-col-12 p-md-6 p-field">
                                    <label for="matricula">Numero de matricula del inmueble</label>
                                    <input formControlName="matricula" id="matricula" type="number" pInputText>
                                </div>
                                <div class="p-col-12 p-md-12 p-field">
                                    <ul *ngIf="mrn.formVentasCertificados.value['direccion']">
                                        <li class="lista_credito">
                                            <span style="float: right">
                                                <div style="display: flex;flex-flow: column wrap;align-items: end">
                                                    <span style="color: #4c566a;font-size: 2em;font-weight: bold">{{mrn.formVentasCertificados.value['direccion']}}</span>
                                                    <span style="color:gray">Direccion del inmueble </span>
                                                </div>
                                            </span>
                                        </li>
                                        <p-divider></p-divider>
                                        <li class="lista_credito">
                                            <span style="float: right">
                                                <div style="display: flex;flex-flow: column wrap;align-items: end">
                                                    <span style="color: #4c566a;font-size: 2em;font-weight: bold">${{mrn.formVentasCertificados.value['valor']|number}}</span>
                                                    <span style="color:gray">Valor del certificado</span>
                                                </div>
                                            </span>
                                        </li>
                                        <p-divider></p-divider>
                                    </ul>
                                </div>
                                <div class="p-col-12 p-md-6 p-field">
                                    <button [disabled]="!mrn.formVentasCertificados.value['matricula']
                                        || !mrn.formVentasCertificados.value['oficina']"
                                            pButton pRipple type="button" id="btn"
                                            label="Consultar certificado" (click)="mrn.consultarCertificado()"></button>
                                </div><!--Boton de consulta-->
                                <div class="p-col-12 p-md-6 p-field">
                                    <button pButton pRipple type="button" label="Limpiar" (click)="limpiarConsulta()"></button>
                                </div><!--Boton de limpiar-->
                                <div *ngIf="mrn.formVentasCertificados.value['direccion']" class="p-col-12 p-md-12">
                                    <p-divider>
                                        <div class="inline-flex align-items-center">
                                            <b>Diligencie estos datos para la venta del certificado</b>
                                        </div>
                                    </p-divider>
                                </div>
                                <div *ngIf="mrn.formVentasCertificados.value['direccion']" class="p-col-12 p-md-4 p-field">
                                    <label for="matricula">Numero Celular</label>
                                    <p-inputMask formControlName="telefono" mask="999 999 99 99" [unmask]="true"
                                                 [autoFocus]="true" maxlength="10"
                                                 placeholder="Digite el numero de celular"></p-inputMask>
                                </div>
                                <div *ngIf="mrn.formVentasCertificados.value['direccion']" class="p-col-12 p-md-4 p-field">
                                    <label for="noDocumento">Numero documento del propietario</label>
                                    <input formControlName="noDocumento" id="noDocumento" type="number"
                                           placeholder="Digite el numero de documento" pInputText>
                                </div>
                                <div *ngIf="mrn.formVentasCertificados.value['direccion']" class="p-col-12 p-md-4 p-field">
                                    <label for="email">Correo electronico del propietario</label>
                                    <input formControlName="email" id="email" type="email"
                                           placeholder="Digite el correo electronico" pInputText>
                                </div>
                                <div *ngIf="mrn.messageSell" class="p-col-12 p-md-12">
                                    <p-messages [(value)]="mrn.messageSell" [enableService]="false"></p-messages>
                                </div><!--Mensaje pre venta-->
                                <div *ngIf="mrn.formVentasCertificados.value['direccion']" class="p-col-12 p-md-6">
                                    <div style="text-align:center;margin-top:25px">
                                        <button [disabled]="!mrn.formVentasCertificados.valid ||
                                        !(mrn.formVentasCertificados.value['valor'] <= mrn.misBoslsasDinero.saldo_disponible)"
                                                pButton pRipple type="button" (click)="ver_confirmacion(false)"
                                                label="Venta con saldo disponible"></button>
                                    </div>
                                </div>
                                <div *ngIf="mrn.formVentasCertificados.value['direccion']" class="p-col-12 p-md-6">
                                    <div style="text-align:center;margin-top:25px">
                                        <button [disabled]="!mrn.formVentasCertificados.valid
                                        || !(mrn.formVentasCertificados.value['valor'] <= mrn.misBoslsasDinero.utilidad)"
                                                pButton pRipple type="button" class="p-button-info" (click)="ver_confirmacion(true)"
                                                label="Venta con saldo de ganancias"></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-3 item_center"></div>
                        <!-- <div class="p-col-12 p-md-6 ">
                             <div class="p-fluid p-formgrid p-grid">
                                 <div class="p-col-12 p-md-12 ">
                                     <table class="tabla_calculadora">
                                         <tr>
                                             <th [colSpan]="2" style="text-align: center">Resultado de la consulta</th>
                                         </tr>
                                         <tr>
                                             <th>Oficina</th>
                                             <td>Tuquerres</td>
                                         </tr>
                                         <tr>
                                             <th>Numero Matricula</th>
                                             <td>3543526854</td>
                                         </tr>
                                         <tr>
                                             <th>Direccion</th>
                                             <td>Calle 25 tuquerres</td>
                                         </tr>
                                         <tr>
                                             <th>Numero celular</th>
                                             <td>317 855 12 66</td>
                                         </tr>
                                         <tr>
                                             <th>Documento solicitante</th>
                                             <td>13072667</td>
                                         </tr>
                                         <tr>
                                             <th>Correo electronico</th>
                                             <td>algo@gmail.com</td>
                                         </tr>
                                         <tr>
                                             <th>Correo electronico</th>
                                             <td>algo@gmail.com</td>
                                         </tr>
                                         <tr>
                                             <th>Valor certificado</th>
                                             <td>$ 25.000</td>
                                         </tr>
                                     </table>
                                 </div>
                                 &lt;!&ndash;<div class="p-col-12 p-md-12 item_center">
                                     <div class="p-col-12 p-md-6">
                                         <div style="text-align:center;margin-top:25px">
                                             <button [disabled]="!mrn.formVentasCertificados.valid ||
                                                 !(mrn.formVentasCertificados.value['valor'] <= mrn.misBoslsasDinero.saldo_disponible)"
                                                     pButton pRipple type="button"
                                                     label="Venta con saldo disponible"></button>
                                         </div>
                                     </div>
                                     <div class="p-col-12 p-md-6">
                                         <div style="text-align:center;margin-top:25px">
                                             <button [disabled]="!mrn.formVentasCertificados.valid
                                                 || !(mrn.formVentasCertificados.value['valor'] <= mrn.misBoslsasDinero.utilidad)"
                                                     pButton pRipple type="button" class="p-button-info"
                                                     label="Venta con saldo de ganancias"></button>
                                         </div>
                                     </div>
                                 </div>&ndash;&gt;
                             </div>
                         </div>-->
                    </div>
                </form>
                <form [formGroup]="mrn.formVentasCertificados" *ngIf="mrn.empresaSeleccionada.nom_empresa == 'RUNT'">
                    <div class="p-fluid p-formgrid p-grid">
                        <div class="p-col-12 p-md-3 item_center">
                        </div>
                        <div class="p-col-12 p-md-6 item_center">
                            <div class="p-fluid p-formgrid p-grid">
                                <div class="p-col-12 p-md-12">
                                    <div style="width: 100%;text-align: center;padding: 15px 0 25px 0"
                                         *ngIf="mrn.empresaSeleccionada">
                                        <img style="width: 30%;cursor: pointer" src="{{mrn.empresaSeleccionada.imagen}}" alt="">
                                    </div>
                                </div>
                                <div class="p-col-12 p-md-12">
                                    <p-divider>
                                        <div class="inline-flex align-items-center">
                                            <b>Diligencie estos datos para la consulta del certificado</b>
                                        </div>
                                    </p-divider>
                                </div>
                                <div class="p-col-12 p-md-6 p-field">
                                    <label for="matricula1">Numero de placa</label>
                                    <input formControlName="matricula" id="matricula1" type="text" pInputText>
                                </div>
                                <div class="p-col-12 p-md-6 p-field">
                                    <label for="email1a">Correo electronico</label>
                                    <input formControlName="email" id="email1a" type="text" pInputText>
                                </div>
                                <div class="p-col-12 p-md-6 p-field">
                                    <label for="telefono1">Celular</label>
                                    <input formControlName="telefono" id="telefono1" type="number" pInputText>
                                </div>
                                <div class="p-col-12 p-md-6 p-field">

                                </div>
                                <div *ngIf="mrn.formVentasCertificados.value['matricula']" class="p-col-12 p-md-6">
                                    <div style="text-align:center;margin-top:25px">
                                        <button [disabled]="!mrn.formVentasCertificados.valid ||
                                        !(mrn.formVentasCertificados.value['valor'] <= mrn.misBoslsasDinero.saldo_disponible)"
                                                pButton pRipple type="button" (click)="mrn.venderCertificado(false)"
                                                label="Venta con saldo disponible"></button>
                                    </div>
                                </div>
                                <div *ngIf="mrn.formVentasCertificados.value['matricula']" class="p-col-12 p-md-6">
                                    <div style="text-align:center;margin-top:25px">
                                        <button [disabled]="!mrn.formVentasCertificados.valid
                                        || !(mrn.formVentasCertificados.value['valor'] <= mrn.misBoslsasDinero.utilidad)"
                                                pButton pRipple type="button" class="p-button-info" (click)="mrn.venderCertificado(true)"
                                                label="Venta con saldo de ganancias"></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-3 item_center"></div>
                        <!-- <div class="p-col-12 p-md-6 ">
                             <div class="p-fluid p-formgrid p-grid">
                                 <div class="p-col-12 p-md-12 ">
                                     <table class="tabla_calculadora">
                                         <tr>
                                             <th [colSpan]="2" style="text-align: center">Resultado de la consulta</th>
                                         </tr>
                                         <tr>
                                             <th>Oficina</th>
                                             <td>Tuquerres</td>
                                         </tr>
                                         <tr>
                                             <th>Numero Matricula</th>
                                             <td>3543526854</td>
                                         </tr>
                                         <tr>
                                             <th>Direccion</th>
                                             <td>Calle 25 tuquerres</td>
                                         </tr>
                                         <tr>
                                             <th>Numero celular</th>
                                             <td>317 855 12 66</td>
                                         </tr>
                                         <tr>
                                             <th>Documento solicitante</th>
                                             <td>13072667</td>
                                         </tr>
                                         <tr>
                                             <th>Correo electronico</th>
                                             <td>algo@gmail.com</td>
                                         </tr>
                                         <tr>
                                             <th>Correo electronico</th>
                                             <td>algo@gmail.com</td>
                                         </tr>
                                         <tr>
                                             <th>Valor certificado</th>
                                             <td>$ 25.000</td>
                                         </tr>
                                     </table>
                                 </div>
                                 &lt;!&ndash;<div class="p-col-12 p-md-12 item_center">
                                     <div class="p-col-12 p-md-6">
                                         <div style="text-align:center;margin-top:25px">
                                             <button [disabled]="!mrn.formVentasCertificados.valid ||
                                                 !(mrn.formVentasCertificados.value['valor'] <= mrn.misBoslsasDinero.saldo_disponible)"
                                                     pButton pRipple type="button"
                                                     label="Venta con saldo disponible"></button>
                                         </div>
                                     </div>
                                     <div class="p-col-12 p-md-6">
                                         <div style="text-align:center;margin-top:25px">
                                             <button [disabled]="!mrn.formVentasCertificados.valid
                                                 || !(mrn.formVentasCertificados.value['valor'] <= mrn.misBoslsasDinero.utilidad)"
                                                     pButton pRipple type="button" class="p-button-info"
                                                     label="Venta con saldo de ganancias"></button>
                                         </div>
                                     </div>
                                 </div>&ndash;&gt;
                             </div>
                         </div>-->
                    </div>
                </form>
            </p-accordionTab><!--certificados-->
            <p-accordionTab *ngIf="mrn.empresaSeleccionada && mrn.empresaSeleccionada.catServicio.nombre == 'Apuestas'"
                            header="{{mrn.empresaSeleccionada.nom_empresa}}" [(selected)]="mrn.activeState[1]">
                <form [formGroup]="mrn.formVentasApuestas">
                    <div class="p-fluid p-formgrid p-grid">
                        <div class="p-col-12 p-md-4"></div>
                        <div class="p-col-12 p-md-4">
                            <div class="p-fluid p-formgrid p-grid">
                                <div class="p-col-12 p-md-12">
                                    <div style="width: 100%;text-align: center;padding: 15px 0 25px 0"
                                         *ngIf="mrn.empresaSeleccionada">
                                        <img style="width: 30%;cursor: pointer" src="{{mrn.empresaSeleccionada.imagen}}" alt="">
                                    </div>
                                </div>
                                <div class="p-col-12 p-md-12" style="margin-top: 15px">
                                    <label for="documento">Numero de documento</label>
                                    <p-inputNumber formControlName="documento" id="documento"
                                                   [inputStyle]="{'font-size': '3em'}"></p-inputNumber>
                                </div>
                                <div class="p-col-12 p-md-12" style="margin-top: 25px">
                                    <p-inputMask [style]="{'font-size':'3em'}" placeholder="Numero Celular"
                                                 formControlName="celular" mask="999 999 99 99" [unmask]="true"
                                                 [autoFocus]="true" maxlength="10"></p-inputMask>
                                </div>
                                <div class="p-col-12 p-md-12" style="margin-top: 15px">
                                    <label for="valVent">Valor de la recarga</label>
                                    <p-inputNumber readonly formControlName="valor" id="valVent"
                                                   [inputStyle]="{'font-size': '3em'}" (onInput)="valorPersonalizado()"
                                                   placeholder="Valor de la recarga"></p-inputNumber>
                                </div>
                                <div *ngIf="mrn.messageSell" class="p-col-12 p-md-12">
                                    <p-messages [(value)]="mrn.messageSell" [enableService]="false"></p-messages>
                                </div><!--Mensaje pre venta-->
                                <div class="p-col-12 p-md-6">
                                    <div style="text-align:center;padding-top:25px">
                                        <button [disabled]="!mrn.formVentasApuestas.valid ||
                                        !(mrn.formVentasApuestas.value['valor'] <= mrn.misBoslsasDinero.saldo_disponible)"
                                                pButton pRipple type="button" (click)="mrn.venderApuestas(false)"
                                                label="Venta con saldo disponible"></button>
                                    </div>
                                </div>
                                <div class="p-col-12 p-md-6">
                                    <div style="text-align:center;padding-top:25px">
                                        <button [disabled]="!mrn.formVentasApuestas.valid
                                        || !(mrn.formVentasApuestas.value['valor'] <= mrn.misBoslsasDinero.utilidad)"
                                                pButton pRipple type="button" class="p-button-info" (click)="mrn.venderApuestas(true)"
                                                label="Venta con saldo de ganancias"></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-4"></div>
                    </div>
                </form>
            </p-accordionTab><!--Apuestas-->
            <p-accordionTab *ngIf="mrn.empresaSeleccionada && mrn.empresaSeleccionada.catServicio.nombre == 'SOAT'"
                            header="{{mrn.empresaSeleccionada.nom_empresa}}" [(selected)]="mrn.activeState[1]">
                <form [formGroup]="mrn.formVentasSoat">
                    <div class="p-fluid p-formgrid p-grid">
                        <div class="p-col-12 p-md-3"></div>
                        <div class="p-col-12 p-md-6 item_center">
                            <div class="p-fluid p-formgrid p-grid">
                                <div class="p-col-12 p-md-12">
                                    <div style="width: 100%;text-align: center;padding: 15px 0 25px 0"
                                         *ngIf="mrn.empresaSeleccionada">
                                        <img style="width: 20%;cursor: pointer" src="{{mrn.empresaSeleccionada.imagen}}" alt="">
                                    </div>
                                </div>
                                <div class="p-col-12 p-md-12">
                                    <p-divider>
                                        <div class="inline-flex align-items-center">
                                            <b>Diligencie estos datos para la consulta del SOAT</b>
                                        </div>
                                    </p-divider>
                                </div>
                                <div class="p-col-12 p-md-4 p-field">
                                    <label for="tipoDocumento">Tipo de documento</label>
                                    <p-dropdown formControlName="tipo_documento" [options]="mrn.tiposId" optionLabel="label"
                                                id="tipoDocumento" optionValue="value" placeholder="Seleccione uno">
                                    </p-dropdown>
                                </div>
                                <div class="p-col-12 p-md-4 p-field">
                                    <label for="noDocument">Numero de documento</label>
                                    <input formControlName="documento" id="noDocument" type="number" #numero (input)="convertirMayusculas(numero.value)" pInputText>
                                </div>
                                <div class="p-col-12 p-md-4 p-field">
                                    <label for="placa">Placa del vehiculo</label>
                                    <input  formControlName="placa" id="placa" type="text" pInputText>
                                </div>
                                <div class="p-col-12 p-md-6 p-field">
                                    <button [disabled]="!mrn.formVentasSoat.value['tipo_documento']|| !mrn.formVentasSoat.value['placa']"
                                            pButton pRipple type="button"
                                            label="Consultar SOAT" (click)="mrn.consulta_placasoat_ms(false)"></button>
                                </div>
                                <div class="p-col-12 p-md-6 p-field">
                                    <button pButton pRipple type="button" label="Limpiar" (click)="mrn.formVentasSoat.reset()"></button>
                                </div>
                                <!--Boton de consulta-->
                                    <div *ngIf="mrn.formVentasSoat.value['valor']" class="p-col-12 p-md-12">
                                        <p-divider>
                                            <div class="inline-flex align-items-center">
                                                <b>Diligencie estos datos para la venta del SOAT</b>
                                            </div>
                                        </p-divider>
                                    </div>
                                    <div *ngIf="mrn.formVentasSoat.value['valor']" class="p-col-12 p-md-4 p-field">
                                        <label for="nombres">Nombres</label>
                                        <input formControlName="nombres" id="nombres" type="text"  pInputText>
                                    </div>
                                    <div *ngIf="mrn.formVentasSoat.value['valor']" class="p-col-12 p-md-4 p-field">
                                        <label for="apellidos">Apellidos</label>
                                        <input formControlName="apellidos" id="apellidos" type="text"  pInputText>
                                    </div>
                                    <div *ngIf="mrn.formVentasSoat.value['valor']" class="p-col-12 p-md-4 p-field">
                                        <label for="celular">Numero de celular del tomador</label>
                                        <input formControlName="celular" id="celular" type="text"  pInputText>
                                    </div>
                                    <div *ngIf="mrn.formVentasSoat.value['valor']" class="p-col-12 p-md-4 p-field">
                                        <label for="email_soat">Correo electronico del tomador</label>
                                        <input formControlName="email_soat" id="email_soat" type="text"  pInputText>
                                    </div>
                                    <div *ngIf="mrn.formVentasSoat.value['valor']" class="p-col-12 p-md-4 p-field">
                                        <label for="direccion1">Direccion</label>
                                        <input formControlName="direccion" id="direccion1" type="text"  pInputText>
                                    </div>
                                    <div *ngIf="mrn.formVentasSoat.value['valor']" class="p-col-12 p-md-4 p-field">
                                        <label for="ciudade">Departamento / Municipio</label>
                                        <p-dropdown id="ciudade" [options]="mrn.municipiosFiltrados" [(ngModel)]="mrn.ciudad"
                                                    [ngModelOptions]="{standalone: true}"
                                                    optionLabel="nombre_mpio" optionValue="codigo_municipio"
                                                    [filter]="true" filterBy="nombre_mpio" [showClear]="true"
                                                    placeholder="Seleccione un municipio" (onChange)="seleccionarMunicipio(mrn.ciudad)">
                                        </p-dropdown>

                                    </div>
                                    <div *ngIf="mrn.formVentasSoat.value['valor']" class="p-col-12 p-md-12">
                                        <p-divider>
                                            <div class="inline-flex align-items-center">
                                                <b>Información del vehículo</b>
                                            </div>
                                        </p-divider>
                                    </div>
                                    <div *ngIf="mrn.formVentasSoat.value['valor']" class="p-col-12 p-md-4 p-field">
                                        <label for="marca">Marca</label>
                                        <input readonly formControlName="marca" id="marca" type="text"  pInputText>
                                    </div>
                                    <div *ngIf="mrn.formVentasSoat.value['valor']" class="p-col-12 p-md-4 p-field">
                                        <label for="aaaa_modelo">Modelo</label>
                                        <input readonly formControlName="aaaa_modelo" id="aaaa_modelo" type="text"  pInputText>
                                    </div>
                                    <div *ngIf="mrn.formVentasSoat.value['valor']" class="p-col-12 p-md-4 p-field">
                                        <label for="lineaVehiculo">Línea</label>
                                        <input readonly formControlName="lineaVehiculo" id="lineaVehiculo" type="text"  pInputText>
                                    </div>
                                    <div *ngIf="mrn.formVentasSoat.value['valor']" class="p-col-12 p-md-4 p-field">
                                        <label for="departamentoMatricula">Departamento de matricula</label>
                                        <input readonly formControlName="departamentoMatricula" id="departamentoMatricula" type="text"  pInputText>
                                    </div>
                                    <div *ngIf="mrn.formVentasSoat.value['valor']" class="p-col-12 p-md-4 p-field">
                                        <label for="motor">Numero de motor</label>
                                        <input formControlName="motor" id="motor" type="text"  pInputText>
                                     </div>
                                    <div *ngIf="mrn.formVentasSoat.value['valor']" class="p-col-12 p-md-4 p-field">
                                        <label for="chasis">Numero de chasis</label>
                                        <input formControlName="chasis" id="chasis" type="text"  pInputText>
                                    </div>
                                    <div *ngIf="mrn.formVentasSoat.value['valor']" class="p-col-12 p-md-4 p-field">
                                        <label for="vin">VIN</label>
                                        <input formControlName="vin" id="vin" type="text"  pInputText>
                                    </div>
                                    <div *ngIf="mrn.formVentasSoat.value['valor']" class="p-col-12 p-md-12">
                                        <p-divider>
                                            <div class="inline-flex align-items-center">
                                                <b>Información de la poliza</b>
                                            </div>
                                        </p-divider>
                                    </div>
                                    <div *ngIf="mrn.formVentasSoat.value['valor']" style="text-align:right;width:100%">
                                        <ul>
                                            <li class="lista_credito">
                                        <span style="float: right">
                                            <div style="display: flex;flex-flow: column wrap;align-items: end">
                                                <span style="color: #4c566a;font-size: 3em;font-weight: bold">${{mrn.formVentasSoat.value['valor']|number}}</span>
                                                <span style="color:gray">Valor total poliza </span>
                                            </div>
                                        </span>
                                            </li>
                                            <p-divider></p-divider>
                                            <li class="lista_credito">
                                        <span style="float: right">
                                            <div style="display: flex;flex-flow: column wrap;align-items: end">
                                                <span style="color: #4c566a;font-size: 2em;font-weight: bold">{{mrn.formVentasSoat.value['inicio_vigencia']|date}}</span>
                                                <span style="color:gray">Inicio vigencia</span>
                                            </div>
                                        </span>
                                            </li>
                                            <p-divider></p-divider>
                                        </ul>
                                    </div>

                                <!--botones-->
                                <div *ngIf="mrn.messageSell" class="p-col-12 p-md-12">
                                    <p-messages [(value)]="mrn.messageSell" [enableService]="false"></p-messages>
                                </div><!--Mensaje pre venta-->
                                <div *ngIf="mrn.formVentasSoat.value['valor']" class="p-col-12 p-md-6">
                                    <div style="text-align:center;margin-top:25px">
                                        <button [disabled]="!mrn.formVentasSoat.valid ||
                                        !(mrn.formVentasCertificados.value['valor'] <= mrn.misBoslsasDinero.saldo_disponible)"
                                                pButton pRipple type="button" (click)="mrn.recargar_soat_ms()"
                                                label="Venta con saldo disponible"></button>
                                    </div>
                                </div>
                                <div *ngIf="mrn.formVentasSoat.value['valor']" class="p-col-12 p-md-6">
                                    <div style="text-align:center;margin-top:25px">
                                        <button [disabled]="!mrn.formVentasCertificados.valid
                                        || !(mrn.formVentasCertificados.value['valor'] <= mrn.misBoslsasDinero.utilidad)"
                                                pButton pRipple type="button" class="p-button-info"
                                                label="Venta con saldo de ganancias"></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-3"></div>
                    </div>
                </form>
            </p-accordionTab><!--SOAT-->
            <p-accordionTab *ngIf="mrn.empresaSeleccionada && mrn.empresaSeleccionada.catServicio.nombre == 'Recaudos' && mrn.proveedorSeleccionado.nombre_prov == 'Practisistemas'"
                            header="{{mrn.empresaSeleccionada.nom_empresa}}" [(selected)]="mrn.activeState[1]">
                <div>
                    <div class="p-fluid p-formgrid p-grid">
                        <div class="p-col-12 p-md-3 item_center">
                        </div>
                        <div class="p-col-12 p-md-6 item_center">
                            <div class="p-fluid p-formgrid p-grid">
                                <div class="p-col-12 p-md-12">
                                    <div style="width: 100%;text-align: center;padding: 15px 0 25px 0"
                                         *ngIf="mrn.empresaSeleccionada">
                                        <img style="width: 30%;cursor: pointer" src="{{mrn.empresaSeleccionada.imagen}}" alt="">
                                    </div>
                                </div>
                                <div class="p-col-12 p-md-12">
                                    <p-divider>
                                        <div class="inline-flex align-items-center">
                                            <b>Pago de facturas</b>
                                        </div>
                                    </p-divider>
                                </div>
                                <div class="p-col-12 p-md-6 p-field">
                                    <label for="oficina">Convenios</label>
                                    <p-autoComplete [(ngModel)]="convenio_seleccionado" [suggestions]="mrn.convenios_filtradosV2" (completeMethod)="mrn.get_convenios_practisistemas($event.query)" field="nombre" [minLength]="1"></p-autoComplete>
                                </div>
                                <div class="p-col-12 p-md-6 p-field">
                                    <label for="oficina">Referencia de pago</label>
                                    <input type="text" pInputText #referencia>
                                </div>
                                <div class="p-col-12 p-md-6 p-field">
                                    <button [disabled]="!referencia.value" pButton label="Consultar factura" (click)="consultar_referencia(referencia.value)"></button>
                                </div><!--boton de consulta-->
                                <div class="p-col-12 p-md-6 p-field">
                                    <button pButton label="Limpiar" (click)="convenio_seleccionado='';referencia.value='';telefono_convenio='';recibo = ''"></button>
                                </div><!--Limpiar-->
                                <!--<div class="p-col-12 p-md-6 p-field">
                                    <button pButton pRipple type="button"
                                            (click)="mrn.imprimir_recibo()"
                                            label="Imprimir"></button>
                                </div>--><!--Imprimir-->
                            </div>
                        </div>
                        <div class="p-col-12 p-md-3 item_center"></div>
                    </div>
                </div>
            </p-accordionTab><!--Recaudos-->
            <p-accordionTab header="Ultimas transacciones" [(selected)]="mrn.activeState[2]">
                <p-table [value]="mrn.ventas_by_nodo" [paginator]="true" [rows]="10"
                         [rowHover]="true" [responsive]="true">
                    <ng-template pTemplate="caption">
                        <div class="p-grid">
                            <div class="p-md-6" style="text-align: left">
                                <input pInputText type="text" placeholder="Buscar por celular"
                                       [(ngModel)]="celular" (input)="mrn.get_ventas_by_celular(celular)" />
                            </div>
                            <div style="text-align: right" class="p-md-6">
                                <button pButton label="Actualizar" (click)="mrn.getVentasByNodo()"></button>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template pTemplate="header">
                        <tr>
                            <th style="width:7%"></th>
                            <th style="width:10%">Fecha y hora</th>
                            <th style="width:10%">Telefono</th>
                            <th style="width:10%">Venta desde</th>
                            <th style="width:10%">Operador</th>
                            <th>Producto</th>
                            <th style="width:10%">Ultimo Saldo</th>
                            <th style="width:10%">Valor de la venta</th>
                            <th style="width:10%">Saldo actual</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-venta>
                        <tr [style]="{'background-color':((venta.codigo_resultado != '001' && venta.codigo_resultado != '00')?' #d088a5':'')}">
                            <td style="text-align: center" class="accion-tabla"
                                (click)="mrn.transaccionSeleccionada = venta;mrn.verDetalletransaccion = true">
                                <i style="margin-right: 5px" class="fas fa-search"></i>{{venta.id}}</td>
                            <td style="text-align:center">{{venta.created_at|date}} {{venta.hour_at|date:'shortTime'}}</td>
                            <td style="text-align:center">{{venta.numeroDestino}}</td>
                            <td style="text-align:left">{{venta.venta_desde}}</td>
                            <td class="wrap">{{venta.producto_venta.producto.empresa.nom_empresa}}</td>
                            <td class="wrap">
                                <i *ngIf="venta.producto_venta.producto.descuento_promosional"
                                   class="fas fa-star accion-tabla" style="color: darkgoldenrod"
                                   pTooltip="Vendiendo este producto {{venta.producto_venta.producto.empresa.nom_empresa}}
                                               te regala $ {{venta.producto_venta.producto.descuento_promosional|number}}
                                               de ganacia adicional."></i>
                                {{venta.producto_venta.producto.nom_producto}}
                            </td>
                            <td style="text-align: right">${{venta.ultimoSaldo|number}}</td>
                            <td style="text-align: right">${{venta.valor|number}}</td>
                            <td style="text-align: right">${{venta.saldo_actual|number}}</td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="emptymessage">
                        <tr>
                            <td [attr.colspan]="7">
                                No se encontro ningun registro
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </p-accordionTab>
        </p-accordion>
    </p-tabPanel>
</p-tabView>
<p-dialog header="MRN colombia - pago de facturas" [style]="{width: '40vw'}" [(visible)]="mrn.verInfoAdicionalFacturas"
          [modal]="true">
    <img style="width:100%" src="assets/mrn_tips_recaudo_facturas.jpg">
</p-dialog>
<p-dialog header="Detalle de transacción" [style]="{width: '30vw'}" [(visible)]="mrn.verDetalletransaccion"
          [modal]="true">
    <div class="p-grid">
        <div class="p-col-12" style="padding-right:40px ">
            <ul>
                <div *ngIf="mrn.transaccionSeleccionada">
                    <li style="list-style: none;padding:6px"><h5 style="font-weight: bold">Transacción
                        N° {{mrn.transaccionSeleccionada.id}}</h5></li>
                    <li style="list-style: none;padding:6px"><span style="font-weight: bold">Saldo Inicial:</span> <span
                            style="float: right">$ {{mrn.transaccionSeleccionada.ultimoSaldo|number}}</span></li>
                    <li style="list-style: none;padding:6px"><span style="font-weight: bold">Valor venta :</span> <span
                            style="float: right">$ {{mrn.transaccionSeleccionada.valor|number}}</span></li>
                    <li style="list-style: none;padding:6px"><span style="font-weight: bold">Saldo final :</span> <span
                            style="float: right">$ {{mrn.transaccionSeleccionada.saldo_actual|number}}</span></li>
                    <li style="list-style: none;padding:6px"><span style="font-weight: bold">Usuario :</span> <span
                            style="float: right">{{mrn.transaccionSeleccionada.usuario.first_name}} {{mrn.transaccionSeleccionada.usuario.last_name}}</span>
                    </li>
                    <li style="list-style: none;padding:6px"><span style="font-weight: bold">Número celular:</span>
                        <span style="float: right">{{mrn.transaccionSeleccionada.numeroDestino}}</span></li>
                    <li *ngIf="mrn.transaccionSeleccionada.numeroAprobacion" style="list-style: none;padding:6px"><span style="font-weight: bold">Numero aprobacion:</span>
                        <span style="float: right">{{mrn.transaccionSeleccionada.numeroAprobacion}}</span></li>
                    <li style="list-style: none;padding:6px"><span style="font-weight: bold">Estado :</span> <span
                            style="float: right">{{mrn.transaccionSeleccionada.estado}}</span></li>
                    <li style="list-style: none;padding:6px"><span style="font-weight: bold">Fecha de venta :</span>
                        <span style="float: right">{{mrn.transaccionSeleccionada.created_at|date}}</span></li>
                    <li style="list-style: none;padding:6px"><span style="font-weight: bold">Código aprobación :</span>
                        <span style="float: right">{{mrn.transaccionSeleccionada.codigo_transaccion_externa}}</span></li>
                    <li style="list-style: none;padding:6px" *ngIf="mrn.transaccionSeleccionada.producto_venta"><span style="font-weight: bold">
                        {{mrn.transaccionSeleccionada.referencia_pago != 'n/a'?'Convenio :':'Codigo producto :'}}
                    </span>
                        <span style="float: right">{{mrn.transaccionSeleccionada.referencia_pago != 'n/a'?mrn.transaccionSeleccionada.convenio_pago:mrn.transaccionSeleccionada.producto_venta.codigo_producto}}</span></li>

                    <li style="list-style: none;padding:6px"><span style="font-weight: bold">{{mrn.transaccionSeleccionada.referencia_pago != 'n/a'?'Referencia':'Producto en venta'}}</span></li>
                    <li style="list-style: none;padding:6px"><p style="text-align: justify">
                        {{mrn.transaccionSeleccionada.referencia_pago != 'n/a'?mrn.transaccionSeleccionada.referencia_pago:mrn.transaccionSeleccionada.producto_venta.producto.nom_producto}}</p></li>

                    <li *ngIf="mrn.transaccionSeleccionada.observacion" style="list-style: none;padding:6px"><span style="font-weight: bold">Observacion :</span></li>
                    <li *ngIf="mrn.transaccionSeleccionada.observacion" style="list-style: none;padding:6px"><p style="text-align: right">
                        {{mrn.transaccionSeleccionada.observacion}}</p></li>

                    <li style="list-style: none;padding:6px"><span style="font-weight: bold">Resultado:</span> <span
                            style="float: right">{{mrn.transaccionSeleccionada.resultado}}</span></li>
                    <li style="list-style: none;padding:6px"><span style="font-weight: bold">Codigo resultado:</span>
                        <span style="float: right">{{mrn.transaccionSeleccionada.codigo_resultado}}</span></li>
                    <li style="list-style: none;padding:6px" *ngIf="mrn.transaccionSeleccionada.soporte"><span style="font-weight: bold">Soporte :</span>
                        <span style="float: right"><a href="{{mrn.transaccionSeleccionada.soporte}}"></a></span></li>
                </div>
            </ul>
        </div>
    </div>
    <p-footer>
        <button pButton pRipple type="button"
                (click)="mrn.imprimir_recibo(mrn.transaccionSeleccionada)"
                label="Imprimir"></button>
    </p-footer>
</p-dialog>
<p-dialog header="Consulta de convenio" [style]="{width: '30vw'}" [(visible)]="ver_resumen_convenio"
          [modal]="true">
    <div class="p-grid">
        <div class="p-col-12" style="padding-right:40px ">
            <div class="p-grid" *ngIf="recibo">
                <div class="p-col-12"  style="padding-top:20px;text-align: center">
                    <span style="color: #4c566a;font-size: 5em;font-weight: bold">$ {{recibo.valorPago|number}}</span>
                </div>
                <div class="p-col-12">
                    <span style="padding-top:20px;float: right">
                             <div style="display: flex;flex-flow: column wrap;align-items: end">
                                 <span style="color: #4c566a;font-size: 1.5em;font-weight: bold">{{recibo.nconvenio}}</span>
                                 <span style="color:gray">Nombre convenio</span>
                             </div>
                        </span>
                </div>
                <div class="p-col-12">
                    <span style="padding-top:20px;float: right">
                             <div style="display: flex;flex-flow: column wrap;align-items: end">
                                 <span style="color: #4c566a;font-size: 1.5em;font-weight: bold">{{recibo.tipo==0?'Facturas normales':'Energia o gas'}}</span>
                                 <span style="color:gray">Tipo convenio</span>
                             </div>
                        </span>
                </div>
                <div class="p-col-12">
                    <span style="padding-top:20px;float: right">
                             <div style="display: flex;flex-flow: column wrap;align-items: end">
                                 <span style="color: #4c566a;font-size: 1.5em;font-weight: bold">{{recibo.pagoParcial?'Si':'No'}}</span>
                                 <span style="color:gray">Pago parcial</span>
                             </div>
                        </span>
                </div>
                <div class="p-col-12">
                    <span style="padding-top:20px;float: right">
                             <div style="display: flex;flex-flow: column wrap;align-items: end">
                                 <span style="color: #4c566a;font-size: 2em;font-weight: bold">{{recibo.referencia}}</span>
                                 <span style="color:gray">Referencia de pago</span>
                             </div>
                        </span>
                </div>
                <div class="p-col-12">
                    <span style="padding-top:20px;float: right">
                             <div style="display: flex;flex-flow: column wrap;align-items: end">
                                 <span style="color: #4c566a;font-size: 1.5em;font-weight: bold">$ {{(recibo.pagoParcial?valor_factura:0)|number}}</span>
                                 <span style="color:gray">Valor de pago parcial</span>
                             </div>
                        </span>
                </div>
            </div>
        </div>
    </div>
    <ng-template pTemplate="footer">
        <div class="p-grid" *ngIf="recibo">
            <div class="p-col-12 p-md-6 p-field" >
                <label *ngIf="recibo.pagoParcial" for="valorRecaudo">Valor pagar</label>
                <p-inputNumber *ngIf="recibo.pagoParcial"  [style]="{width: '100%'}" id="valorRecaudo" [(ngModel)]="valor_factura" placeholder="Valor parcial"></p-inputNumber>
            </div>
            <div class="p-col-12 p-md-6 p-field">
                <label for="valorRecaudo">Número de celular</label>
                <input style="width: 100%" type="text" pInputText [(ngModel)]="telefono_convenio" placeholder="Número de celular">
            </div>
            <div class="p-col-12 p-md-12 p-field" style="text-align: center" *ngIf="api.nodoActual['clave_venta']">
                <input style="width: 30%"  type="password" (input)="verificarClave()" pInputText [(ngModel)]="clave_ventas" placeholder="Clave de ventas">
            </div>
            <div class="p-col-12 p-md-6 p-field">
                <button style="width: 100%" [disabled]="!telefono_convenio || !(getvalorPago(valor_factura) <= mrn.misBoslsasDinero.saldo_disponible) //|| !this.clave_ventas"
                        pButton label="Pagar con saldo" (click)="pagar_factura(getvalorPago(valor_factura),telefono_convenio,false)"></button>
            </div>
            <div class="p-col-12 p-md-6 p-field">
                <button style="width: 100%" [disabled]="!telefono_convenio || !(getvalorPago(valor_factura) <= mrn.misBoslsasDinero.utilidad)//|| !this.clave_ventas"
                        pButton label="Pagar con ganancias" (click)="pagar_factura(getvalorPago(valor_factura),telefono_convenio,true)"></button>
            </div>

        </div>
    </ng-template>
</p-dialog>
<p-dialog header="Confirmar informacion" [style]="{width: '30vw'}" [(visible)]="mrn.ver_resumen_SNR"
          [modal]="true">
    <div class="p-grid">
        <div class="p-col-12" style="padding-right:40px ">
            <div class="p-grid">
                <div class="p-col-12">
                    <span style="padding-top:30px;float: left">
                             <div style="display: flex;flex-flow: column wrap;align-items: start">
                                 <span style="color: #4c566a;font-size: 1.5em;font-weight: bold">{{mrn.formVentasCertificados.value['oficina']}}</span>
                                 <span style="color:gray">Oficina</span>
                             </div>
                        </span>
                </div>
                <div class="p-col-12">
                    <span style="padding-top:30px;float: left">
                             <div style="display: flex;flex-flow: column wrap;align-items: start">
                                 <span style="color: #4c566a;font-size: 2em;font-weight: bold">$ {{mrn.formVentasCertificados.value['matricula']}}</span>
                                 <span style="color:gray">Número de matricula del inmueble</span>
                             </div>
                        </span>
                </div>
                <div class="p-col-12">
                    <span style="padding-top:30px;float: left">
                             <div style="display: flex;flex-flow: column wrap;align-items: start">
                                 <span style="color: #4c566a;font-size: 2em;font-weight: bold">{{mrn.formVentasCertificados.value['direccion']}}</span>
                                 <span style="color:gray">Dirección del inmueble</span>
                             </div>
                        </span>
                </div>
                <div class="p-col-12">
                    <span style="padding-top:30px;float: left">
                             <div style="display: flex;flex-flow: column wrap;align-items: start">
                                 <span style="color: #4c566a;font-size: 2em;font-weight: bold">${{mrn.formVentasCertificados.value['valor']|number}}</span>
                                 <span style="color:gray">Valor del certificado</span>
                             </div>
                        </span>
                </div>
                <div class="p-col-12">
                    <span style="padding-top:30px;float: left">
                             <div style="display: flex;flex-flow: column wrap;align-items: start">
                                 <span style="color: #4c566a;font-size: 2em;font-weight: bold">{{mrn.formVentasCertificados.value['telefono']}}</span>
                                 <span style="color:gray">Número de celular</span>
                             </div>
                        </span>
                </div>
                <div class="p-col-12">
                    <span style="padding-top:30px;float: left">
                             <div style="display: flex;flex-flow: column wrap;align-items: start">
                                 <span style="color: #4c566a;font-size: 2em;font-weight: bold">{{mrn.formVentasCertificados.value['noDocumento']}}</span>
                                 <span style="color:gray">Número de documento del propietario</span>
                             </div>
                        </span>
                </div>
                <div class="p-col-12">
                    <span style="padding-top:30px;float: left">
                             <div style="display: flex;flex-flow: column wrap;align-items: start">
                                 <span style="color: #4c566a;font-size: 2em;font-weight: bold">{{mrn.formVentasCertificados.value['email']}}</span>
                                 <span style="color:gray">Correo electrónico del propietario</span>
                             </div>
                        </span>
                </div>
            </div>
        </div>
    </div>
    <p-footer>
        <div style="text-align:right;">
            <button pButton pRipple type="button" label="Vender"
                    (click)="mrn.venderCertificado(this.venta_ganancias)"></button>
            <button pButton pRipple type="button" class="p-button-secondary" label="Cancelar"
                    (click)="mrn.ver_resumen_SNR = false"></button>
        </div>
    </p-footer>
</p-dialog>
<p-dialog header="Certificado SNR" [style]="{width: '30vw'}" [(visible)]="mrn.ver_SNR"
          [modal]="true">
    <div class="p-grid">
        <div class="p-col-12" style="padding-right:40px ">
            <div class="p-grid">
                <div class="p-col-12">
                    <span style="padding-top:30px;float: left">
                             <div style="display: flex;flex-flow: column wrap;align-items: start">
                                 <span style="color: #4c566a;font-size: 1.5em;font-weight: bold">{{mrn.formVentasCertificados.value['oficina']}}</span>
                                 <span style="color:gray">Oficina</span>
                             </div>
                        </span>
                </div>
                <div class="p-col-12">
                    <span style="padding-top:30px;float: left">
                             <div style="display: flex;flex-flow: column wrap;align-items: start">
                                 <span style="color: #4c566a;font-size: 2em;font-weight: bold">$ {{mrn.formVentasCertificados.value['matricula']}}</span>
                                 <span style="color:gray">Número de matricula del inmueble</span>
                             </div>
                        </span>
                </div>
                <div class="p-col-12">
                    <span style="padding-top:30px;float: left">
                             <div style="display: flex;flex-flow: column wrap;align-items: start">
                                 <span style="color: #4c566a;font-size: 2em;font-weight: bold">{{mrn.formVentasCertificados.value['direccion']}}</span>
                                 <span style="color:gray">Dirección del inmueble</span>
                             </div>
                        </span>
                </div>
                <div class="p-col-12">
                    <span style="padding-top:30px;float: left">
                             <div style="display: flex;flex-flow: column wrap;align-items: start">
                                 <span style="color: #4c566a;font-size: 2em;font-weight: bold">${{mrn.formVentasCertificados.value['valor']|number}}</span>
                                 <span style="color:gray">Valor del certificado</span>
                             </div>
                        </span>
                </div>
                <div class="p-col-12">
                    <span style="padding-top:30px;float: left">
                             <div style="display: flex;flex-flow: column wrap;align-items: start">
                                 <span style="color: #4c566a;font-size: 2em;font-weight: bold">{{mrn.formVentasCertificados.value['telefono']}}</span>
                                 <span style="color:gray">Número de celular</span>
                             </div>
                        </span>
                </div>
                <div class="p-col-12">
                    <span style="padding-top:30px;float: left">
                             <div style="display: flex;flex-flow: column wrap;align-items: start">
                                 <span style="color: #4c566a;font-size: 2em;font-weight: bold">{{mrn.formVentasCertificados.value['noDocumento']}}</span>
                                 <span style="color:gray">Número de documento del propietario</span>
                             </div>
                        </span>
                </div>
                <div class="p-col-12">
                    <span style="padding-top:30px;float: left">
                             <div style="display: flex;flex-flow: column wrap;align-items: start">
                                 <span style="color: #4c566a;font-size: 2em;font-weight: bold">{{mrn.formVentasCertificados.value['email']}}</span>
                                 <span style="color:gray">Correo electrónico del propietario</span>
                             </div>
                        </span>
                </div>
                <div class="p-col-12">
                    <span style="padding-top:30px;float: left">
                             <div style="display: flex;flex-flow: column wrap;align-items: start">
                                 <span style="color: #4c566a;font-size: 2em;font-weight: bold">{{mrn.formVentasCertificados.value['soporte']}}</span>
                                 <span style="color:gray">Ver certificado</span>
                             </div>
                        </span>
                </div>
            </div>
        </div>
    </div>
    <p-footer>
        <div style="text-align:right;">
            <button pButton pRipple type="button" label="Aceptar"
                    (click)="mrn.formVentasCertificados.reset();mrn.ver_SNR=false"></button>
        </div>
    </p-footer>
</p-dialog>
<p-dialog header="Resultado de la venta" [style]="{width: '30vw'}" [(visible)]="mrn.verResultadoVenta"
          [modal]="true">
    <div class="p-grid">
        <div class="p-col-12" style="padding-right:40px ">
            <ul>
                <div *ngIf="mrn.trans_resultado_venta">
                    <li style="list-style: none;padding:6px"><h5 style="font-weight: bold">
                        <i [style]="{'color':mrn.trans_resultado_venta.codigo_resultado == '001'||mrn.trans_resultado_venta.codigo_resultado == '00'?'darkgreen':'darkred','margin-right':'10px'}"
                           [class]="mrn.trans_resultado_venta.codigo_resultado == '001'||mrn.trans_resultado_venta.codigo_resultado == '00'?'fas fa-check-circle':'fas fa-times-circle'"></i>Transacción
                        N° {{mrn.trans_resultado_venta.id}}</h5></li>
                    <li style="list-style: none;padding:6px"><span style="font-weight: bold">Saldo antes de la venta:</span> <span
                            style="float: right">$ {{mrn.trans_resultado_venta.ultimoSaldo|number}}</span></li>
                    <li style="list-style: none;padding:6px"><span style="font-weight: bold">Valor venta :</span> <span
                            style="float: right">$ {{mrn.trans_resultado_venta.valor|number}}</span></li>
                    <li style="list-style: none;padding:6px"><span style="font-weight: bold">Saldo despues de la venta :</span> <span
                            style="float: right">$ {{mrn.trans_resultado_venta.saldo_actual|number}}</span></li>
                    <li style="list-style: none;padding:6px"><span style="font-weight: bold">Número celular:</span>
                        <span style="float: right">{{mrn.trans_resultado_venta.numeroDestino}}</span></li>
                    <li style="list-style: none;padding:6px"><span style="font-weight: bold">Fecha de venta :</span>
                        <span style="float: right">{{mrn.trans_resultado_venta.hour_at| date:'short'}}</span></li>
                    <li style="list-style: none;padding:6px"  *ngIf="this.recibo"><span style="font-weight: bold">Convenio :</span></li>
                    <li style="list-style: none;padding:6px"  *ngIf="this.recibo"><p style="text-align: justify">{{this.recibo.nconvenio?this.recibo.nconvenio:this.recibo.nombre}}</p></li>
                    <li style="list-style: none;padding:6px" *ngIf="!this.recibo"><span style="font-weight: bold">Producto en venta :</span></li>
                    <li style="list-style: none;padding:6px" *ngIf="!this.recibo"><p style="text-align: justify">{{mrn.trans_resultado_venta.nom_producto}}</p></li>
                    <li style="list-style: none;padding:6px"><span style="font-weight: bold">Resultado:</span> <span
                            style="float: right">{{mrn.trans_resultado_venta.resultado}}</span></li>
                    <li style="list-style: none;padding:6px"><span style="font-weight: bold">Codigo resultado:</span>
                        <span style="float: right">{{mrn.trans_resultado_venta.codigo_resultado}}</span></li>
                </div>
            </ul>
        </div>
    </div>
    <p-footer>
        <button pButton pRipple type="button" (click)="mrn.verResultadoVenta = false;convenio_seleccionado = '';recibo = ''"
                label="Aceptar"></button>
        <button pButton pRipple type="button"
                (click)="mrn.verResultadoVenta = false;mrn.activeState = [true,false];convenio_seleccionado = '';mrn.imprimir_recibo(mrn.trans_resultado_venta)"
                label="Imprimir"></button>
    </p-footer>
</p-dialog>
