import { Component, OnInit } from "@angular/core";
import { Mrn } from "../../providers/mrn";
import { ApiService } from "../../providers/api";
import { ConfirmationService } from "primeng/api";
import * as moment from "moment";
import * as FileSaver from "file-saver";
@Component({
  selector: "app-aliados",
  templateUrl: "./aliados.component.html",
})
export class AliadosComponent implements OnInit {
  montoDisponible = 0;
  imgLoaded = false;
  paneles = [true, false];
  pieData: any;
  ampliar_imagen = false;
  soporte_requerido: boolean = true;
  dataset_ventas_seleccionado = [];
  stackedOptions: any;
  hoy: any;
  net_query_value;
  net_query_opt = [
    { label: "Pagos de mis aliados", value: "red" },
    { label: "Mis pagos", value: "propios" },
  ];
  motivos = [
    { label: "No se adjunto un comprobante", value: "No se adjunto un comprobante" },
    { label: "Comprobante ilegible", value: "Comprobante ilegible" },
    { label: "Comprobante alterado", value: "Comprobante alterado" },
    {label: "El valor del pago no corresponde",value: "El valor del pago no corresponde",},
  ];
  opciones_soporte = [{label: 'Si', value: true}, {label: 'No', value: false}];
  lista_categorias = []
  protected readonly alert = alert;
  fecha_inicial = this.mrn.getPastDate(60);
  constructor(
    public mrn: Mrn,
    public api: ApiService,
    private confirmationService: ConfirmationService
  ) {}

  ngOnInit(): void {
    this.mrn.crearControles();
    this.mrn.facturasPendientesRed = [];
    this.mrn.nodosDirectos = [];
    if(this.api.nodoActual['id']!= 1){
      this.mrn.getFacturasPendientesRed(this.api.nodoActual,'Todo')
      this.mrn.getNodosTodosDirectos();
    }
    this.mrn.getMisComisiones(this.api.nodoActual);
    this.mrn.get_pagos_entrantes();
    this.mrn.getZonas();
    this.hoy = moment().format("YYYY-MM-DD");
    this.mrn.getCategorias()

    this.stackedOptions = {
      tooltips: {
        mode: "index",
        intersect: false,
      },
      responsive: true,
      scales: {
        xAxes: [
          {
            stacked: true,
          },
        ],
        yAxes: [
          {
            stacked: true,
          },
        ],
      },
    };
    this.pieData = {
      labels: ["Rotativo semanal", "Rotativo diario", "Especial fin de semana"],
      datasets: [
        {
          data: [0, 0, 0],
          backgroundColor: [
            "rgb(54, 162, 235)",
            "rgb(255, 205, 86)",
            "rgb(130,75,192)",
          ],
        },
      ],
    };
  }

  ngOnDestroy(): void {
    this.mrn.nodoSeleccionado = "";
  }

  guardarNodo() {
    this.mrn.formUsuario.patchValue({
      password: this.mrn.formUsuario.value["identificacion"],
    });
    this.mrn.formNodo.patchValue({
      nodoPadre: this.api.nodoActual ? this.api.nodoActual["id"] : 0,
      red: this.mrn.addString(
        this.api.nodoActual ? this.api.nodoActual["red"] : "0",
        this.api.nodoActual ? this.api.nodoActual["id"] : "0"
      ),
      comisiones: "n/a",
      activo: true,
      label: this.mrn.formNodo.value["razonSocial"],
      tipoComision:
        this.mrn.formNodo.value["tipo"] == "Distribuidor"
          ? "CV"
          : this.mrn.formNodo.value["tipoComision"],
      icon:
        this.mrn.formNodo.value["tipo"] == "Distribuidor"
          ? "pi pi-sitemap"
          : "pi pi-shopping-cart",
      aprobacion_credito_automatico: this.mrn.formNodo.value[
        "aprobacion_credito_automatico"
      ]
        ? true
        : false,
      comisionAnticipada: this.mrn.formNodo.value["comisionAnticipada"]
        ? this.mrn.formNodo.value["comisionAnticipada"]
        : 0,
    });
    this.mrn.addNodo();
  }

  guardarNodoUsuario() {
    this.mrn.form_nodo_usuario.patchValue({
      nodoPadre: this.api.nodoActual ? this.api.nodoActual["id"] : 0,
      red: this.mrn.addString(
        this.api.nodoActual ? this.api.nodoActual["red"] : "0",
        this.api.nodoActual ? this.api.nodoActual["id"] : "0"
      ),
      comisiones: "n/a",
      activo: true,
      label: this.mrn.form_nodo_usuario.value["razonSocial"],
      tipoComision:
        this.mrn.form_nodo_usuario.value["tipo"] == "Distribuidor"
          ? "CV"
          : this.mrn.form_nodo_usuario.value["tipoComision"],
      icon:
        this.mrn.form_nodo_usuario.value["tipo"] == "Distribuidor"
          ? "pi pi-sitemap"
          : "pi pi-shopping-cart",
      aprobacion_credito_automatico: this.mrn.form_nodo_usuario.value["aprobacion_credito_automatico"],
      comisionAnticipada: this.mrn.form_nodo_usuario.value["comisionAnticipada"]
        ? this.mrn.form_nodo_usuario.value["comisionAnticipada"] : 0,
      imprime:this.mrn.form_nodo_usuario.value["imprime"]?true:false
    });
    this.mrn.addNodoUsuario();
  }

  updateDireccion() {
    this.mrn.nodoSeleccionado.direccion = this.mrn.addDireccion(
      this.mrn.formNodo.value["direccion"],
      this.mrn.nodoSeleccionado.direccion
    );
    this.mrn.updateDireccionNodo();
  }

  updateRefComercial() {
    this.mrn.nodoSeleccionado.ref_comercial = this.mrn.addReferencia(
      this.mrn.REF_COMERCIAL,
      this.mrn.nodoSeleccionado.ref_comercial
    );
    this.mrn.updateDireccionNodo();
  }

  seleccionarNodo(nodo) {
    this.paneles[0] = false;
    this.paneles[1] = true;
    this.mrn.nodoSeleccionado = nodo;
    this.mrn.ventas_by_fecha = [];
    this.mrn.getEquiposLibresByNodo();
    this.mrn.getUsuarioByNodo();
    this.mrn.getUsuarioByNodo(this.mrn.nodoSeleccionado);
    this.mrn.get_direcciones_nodo(this.mrn.nodoSeleccionado);
    this.mrn.getComisiones(this.mrn.nodoSeleccionado);
    this.mrn.getCreditoByNodo(this.mrn.nodoSeleccionado);
    this.mrn.getFacturasMora(this.mrn.nodoSeleccionado, false);
    this.mrn.getFacturasPendientes(this.mrn.nodoSeleccionado, false);
  }

  guardarAsignacion() {
    if (this.mrn.formEquipoNodo.value["id"]) {
      this.mrn.updateEquipoNodo();
    } else {
      this.mrn.formEquipoNodo.patchValue({
        nodo_id: this.mrn.nodoSeleccionado.id,
        equipo_id: this.mrn.equipoSeleccionado.id,
        fechaDevolucion: null,
        ordenDevolucion: "",
      });
      this.mrn.addEquipoNodo();
    }
  }

  guardarCreditos() {
    if (this.mrn.formCreditos.value["id"]) {
      this.mrn.formCreditos.patchValue({
        montoDisponible:
          this.mrn.formCreditos.value["montoAutorizado"] -
          this.mrn.formCreditos.value["montoUtilizado"],
      });
      this.mrn.updateCreditos();
    } else {
      this.mrn.formCreditos.patchValue({
        nodo_id: this.mrn.nodoSeleccionado.id,
        montoUtilizado: 0,
        montoDisponible: this.mrn.formCreditos.value["montoAutorizado"],
        activo: true,
      });
      this.mrn.addCreditos();
    }
  }

  inactivarNodod(nodo: any) {
    this.confirmationService.confirm({
      message:
        "Desea " + (nodo.activo ? "desactivar" : "activar") + " a este aliado?",
      accept: () => {
        nodo.activo = !nodo.activo;
        this.mrn.updateNodo(nodo);
      },
    });
  }

  guardarPago() {
    this.mrn.formPagos.patchValue({
      transaccion_id: this.mrn.transaccionSeleccionada.id,
    });

    this.mrn.abonar_factura(this.mrn.formPagos.value);
  }

  seleccionarFactura(transaccion: any) {
    this.mrn.transaccionSeleccionada = "";
    this.mrn.transaccionSeleccionada = transaccion;
    this.mrn.pagoSeleccionado = "";
    this.mrn.ver_pagos_facturas = true;
    this.mrn.get_abonos_factura(transaccion);
  }

  solicitarSaldoTercero() {
    this.confirmationService.confirm({
      message:
          "Desea asignar saldo a "+this.mrn.nodoSeleccionado.razonSocial+' por valor de '+this.mrn.separadorDeMiles(this.mrn.formTransaccion.value["valor"])+'?',
      accept: () => {
              this.mrn.formTransaccion.patchValue({
                nodo_id: this.mrn.nodoSeleccionado.id,
                usuario_id: this.api.usuario["id"],
                tipo: "Solicitud de saldo",
                tipoServicio: this.mrn.nodoSeleccionado.tipoComision,
                estado: "Pendiente",
                ultimoSaldo: 0,
                dias_mora: 0,
                fecha_aprobacion: null,
                fecha_pago: null,
                medioSolicitud: 'Web',
                saldo_pendiente_pago: this.mrn.formTransaccion.value["valor"],
              });
              this.mrn.addTransaccionTercero();
      },
    });
  }

  crearMenuALiado($event: MouseEvent, menualiados, nodo: any) {
    this.mrn.nodoSeleccionado = nodo.nodo;
    this.mrn.menuAliados = [
      {
        label: "Opciones",
        items: [
          {
            label: "Reversar saldo",
            icon: "fas fa-share",
            disabled: nodo.saldo_disponible <= 0 ? true : false,
            command: () => {
              this.mrn.valorAjuste = 0
              this.mrn.motivo_ajuste = ''
              this.mrn.processing = false
              this.mrn.verAjusteSaldo = true;
              this.mrn.getBolsasDinero(this.mrn.nodoSeleccionado);
            },
          },
          {
            label: "Contactar aliado",
            icon: "fab fa-whatsapp",
            command: () => {
              this.mrn.sendWpMessage(this.mrn.nodoSeleccionado.telefono);
            },
          },
          { separator: true },
          {
            label: this.mrn.nodoSeleccionado.activo
              ? "Desactivar nodo"
              : "Activar nodo",
            icon: this.mrn.nodoSeleccionado.activo
              ? "fas fa-times"
              : "fas fa-check",
            command: () => {
              this.inactivarNodod(this.mrn.nodoSeleccionado);
            },
          },
          {
            label: "Generar Codigo QR",
            icon: "fas fa-qrcode",
            command: () => {
              this.mrn.generarCodigo(this.mrn.nodoSeleccionado);
            },
          },
        ],
      },
    ];
    menualiados.toggle($event);
  }

  solicitar_saldo(nodo: any) {
    this.mrn.processing = false
    this.mrn.nodoSeleccionado = nodo;
    this.mrn.getCreditoByNodo(nodo);
    this.mrn.formTransaccion.reset();
    this.mrn.verFormTransaccionTercero = true;
  }

  actualizarMiComision(comision: any) {
    comision.utilidad_comercio = comision.micomision - comision.comisionComercio;
    comision.utilidad_distribuidor = comision.micomision - comision.comisionDistribuidor;
    this.mrn.llenarFormComision(comision);
    this.mrn.updateMiComision();
  }

  verificarValorComisionDistr(value) {
    if (parseFloat(value) > this.mrn.formComision.value["micomision"]) {
      this.mrn.formComision.patchValue({
        comisionDistribuidor: this.mrn.formComision.value["micomision"],
      });
    }
  }

  verificarValorComisionCom(value) {
    if (parseFloat(value) > this.mrn.formComision.value["micomision"]) {
      this.mrn.formComision.patchValue({
        comisionComercio: this.mrn.formComision.value["micomision"],
      });
    }
  }

  calcularTotal(event) {
    this.mrn.totalFacturasAPagar = 0;
    for (let item of this.mrn.facturasSeleccionadas) {
      this.mrn.totalFacturasAPagar += parseInt(item.saldo_pendiente_pago);
    }
  }

  propietarioFactura(transaccion: any) {
    if (this.mrn.facturasSeleccionadas.length) {
      return (
        transaccion.nodo.id != this.mrn.facturasSeleccionadas[0]["nodo_id"]
      );
    }
  }

  pagoFacturas() {
    this.mrn.verFormPagosMulti = true;
    this.mrn.formPagos.patchValue({
      abono: this.mrn.totalFacturasAPagar,
    });
  }

  getTotalVentas(ventas) {
    let total = 0;
    for (let i of ventas) {
      if (i.codigo_resultado == "001" || i.codigo_resultado == "00") {
        total += i.valor;
      }
    }
    return total;
  }
  getTotalVentasDesdeSaldo(ventas) {
    let total = 0;
    for (let i of ventas) {
      if(i.venta_desde == 'Saldo'){
        if (i.codigo_resultado == "001" || i.codigo_resultado == "00")total += i.valor;
      }
    }
    return total;
  }
  getTotalVentasDesdeGanancias(ventas) {
    let total = 0;
    for (let i of ventas) {
      if(i.venta_desde == 'Ganancias'){
        if (i.codigo_resultado == "001" || i.codigo_resultado == "00")total += i.valor;
      }
    }
    return total;
  }
  getTotalGanancias(ventas) {
    let total = 0
    for (let i of ventas){
      if(i.estado == 'Aprobado') {
        total += parseInt(i.ganancia)
      }
    }
    return total
  }
  calcular_max(value: string) {
    if (value) {
      return moment(value).add(30, "days").format("YYYY-MM-DD");
    }
  }

  verDetalleVentas(event: any) {
    this.dataset_ventas_seleccionado = [];
    if (event.element._index == 0) {
      this.dataset_ventas_seleccionado = this.mrn.ventas_exitosas;
    } else {
      this.dataset_ventas_seleccionado = this.mrn.ventas_fallidas;
    }
  }

  crear_documento_export(data_set) {
    let excel = [];
    for (let item of data_set) {
      let f = moment(item.hour_at);
      let fecha = f.date() +"-" + f.month() +"-" +f.year() +" " +f.hour() +":" +f.minutes();

      let obj = {
        
        "transaccion": item.id,
        "canal de venta": item.medioVenta,
        "representante legal": item.representante_legal,
        "saldo inicial": item.ultimoSaldo,
        "valor": item.valor,
        "saldo slado final": item.saldo_actual,
        "numero destino": item.numeroDestino,
        "empresa": item.nombre_empresa,
        "fecha y hora": fecha,
        "producto": item.nom_producto,
        "codigo aprobracion": item.codigo_transaccion_externa,
        "resultado": item.resultado,
      };
      excel.push(obj);
    }
    return excel;
  }

  exportExcel_saldos() {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(
        this.crear_documento_export_saldo(this.mrn.historial_solicitudes_saldo)
      );
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer: any = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      this.saveAsExcelFile(excelBuffer, "solicitudes_slado_");
    });
  }
  crear_documento_export_saldo(data_set) {
    let excel = [];
    for (let item of data_set) {
      let f = moment(item.hour_at);
      let fecha =
        f.date() +
        "-" +
        (f.month() + 1) +
        "-" +
        f.year() +
        " " +
        f.hour() +
        ":" +
        f.minutes();
      let tipo = "";
      if (
        item.nodo.id == this.api.nodoActual["id"] &&
        item.tipo_transaccion != "AJS"
      ) {
        tipo = "Compra de saldo";
      } else if (
        item.nodo.id != this.api.nodoActual["id"] &&
        item.tipo_transaccion != "AJS"
      ) {
        tipo = "Venta de saldo";
      } else if (item.tipo_transaccion == "AJS") {
        tipo = "Reversión de saldo";
      }
      let obj = {
        transaccion: item.id,
        tipo: tipo,
        "fecha y hora solicitud": fecha,
        "representante legal": item.nodo.representanteLegal,
        "Numero de identificacion": item.nodo.identificacion_nodo,
        "Forma de pago": item.tipo_transaccion == "SSC" ? "Contado" : "Credito",
        Comision:
          item.tipoServicio == "CV" ? "Comision venta" : "Comision anticipada",
        "Saldo anterior": item.ultimo_saldo_distribuidor,
        "Valor solicitado": item.valor,
        "Saldo actual": item.saldo_actual_distribuidor,
        estado: item.estado,
        "estado pago": item.estadoPago,
      };
      excel.push(obj);
    }
    return excel;
  }
  crear_documento_export_cartera(data_set) {
    let excel = [];
    for (let item of data_set) {
      let fap = moment(item.fecha_aprobacion);
      let fecha_apr = fap.date() + "-" + (fap.month() + 1) + "-" + fap.year();
      let flim = moment(item.fecha_pago);
      let fecha_lim = flim.date() + "-" + (flim.month() + 1) + "-" + flim.year();
      let obj = {
        transaccion: item.id,
        "representante legal": item.nodo.representanteLegal,
        "fecha de aprobacion": fecha_apr,
        "fecha limite de pago": fecha_lim,
        "Valor solicitado": item.valor,
        "Saldo para pago": item.saldo_pendiente_pago,
        "dias mora": item.dias_mora,
        "estado pago": item.estadoPago,
      };
      excel.push(obj);
    }
    return excel;
  }
  crear_documento_export_pagos(data_set) {
    let excel = [];
    console.log(data_set)
    for (let item of data_set) {
      let fecha = moment(item.hour_at).format('YYYY-MM-DD ,h:mm:ss')
      let obj = {
        "Id Pago": item.id,
        "Id transaccion": item.transaccion.id,
        "Valor solicitado": item.transaccion.valor,
        "Aliado Id": item.transaccion.nodo.identificacion_nodo,
        "Nombre aliado": item.transaccion.nodo.representanteLegal,
        "Pagado por": item.usuario.first_name + ' ' + item.usuario.last_name ,
        "Fecha de pago": fecha,
        "Abono": item.abono,
        "Entidad": item.entidad,
        "Estado Pago": item.estadoPago,
      };
      excel.push(obj);
    }
    return excel;
  }

  crear_documento_export_movimientos(data_set) {
    let excel = [];
    for (let item of data_set) {
     let fecha = moment(item.hour_at).format('YYYY-MM-DD ,h:mm:ss')
      let obj = {
        "tipo transaccion": this.getTipoTransaccion(item),
        "Id transaccion": item.id,
        "desde": item.venta_desde?item.venta_desde:'n/a',
        "fecha y hora": fecha,
        "saldo inicial": item.ultimoSaldo,
        "valor": item.valor,
        "saldo final": item.saldo_actual,
        "estado": item.estado,
      };
      excel.push(obj);
    }
    return excel;
  }
  getTipoTransaccion(transaccion){
    let tipo = ''
    if(transaccion.nodo == this.mrn.nodoSeleccionado.id){
      if(transaccion.tipo_transaccion == 'VEN')tipo = 'Venta'
      if(transaccion.tipo_transaccion == 'SSC')tipo = 'Compra saldo contado'
      if(transaccion.tipo_transaccion == 'SSCR')tipo = 'Compra saldo credito'
      if(transaccion.tipo_transaccion == 'AJS')tipo = 'Reversion de saldo'
      if(transaccion.tipo_transaccion == 'CJG')tipo = 'Traslado de ganancias'
    }else {
      if(transaccion.tipo_transaccion == 'VEN')tipo = 'Venta'
      if(transaccion.tipo_transaccion == 'SSC')tipo = 'Venta saldo contado'
      if(transaccion.tipo_transaccion == 'SSCR')tipo = 'Venta saldo credito'
      if(transaccion.tipo_transaccion == 'AJS')tipo = 'Reversion de saldo'
      if(transaccion.tipo_transaccion == 'CJG')tipo = 'Traslado de ganancias'
    }
    return tipo
  }
  exportExcel(dataset) {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(
        this.crear_documento_export(dataset)
      );
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer: any = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      this.saveAsExcelFile(excelBuffer, "ventas");
    });
  }
  exportExcelCarteta() {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(
          this.crear_documento_export_cartera(this.mrn.facturasPendientesRed)
      );
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer: any = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      this.saveAsExcelFile(excelBuffer, "cartera");
    });
  }
  exportExcelPagos() {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(
        this.crear_documento_export_pagos(this.mrn.pagos_by_fecha_red)
      );
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer: any = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      let f  = new Date();
      let hoy = (f.getMonth() + 1) + '/' + f.getFullYear();
      this.saveAsExcelFile(excelBuffer, "Reporte pagos "+hoy);
    });
  }
  exportExcelMovimientos() {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(
          this.crear_documento_export_movimientos(this.mrn.movimientos_by_fecha)
      );
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer: any = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      this.saveAsExcelFile(excelBuffer, "movimientos");
    });
  }
  saveAsExcelFile(buffer: any, fileName: string): void {
    let EXCEL_TYPE =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    let EXCEL_EXTENSION = ".xlsx";
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE,
    });
    FileSaver.saveAs(
      data,
      fileName + "_reporte_" + new Date().getTime() + EXCEL_EXTENSION
    );
  }
  getGnanancia() {
    let json = JSON.parse(this.mrn.transaccionSeleccionada.ganancia_red);
    let ganancia = json.filter(
      (item) => item.nodo == this.api.nodoActual["id"]
    )[0];
    return [ganancia.valor, ganancia.comision];
  }

  getFilteredData(event: any) {
    this.mrn.dataFiltrada = event.filteredValue;
  }

  getTotalVentasSaldo() {
    let total = 0;
    if (this.mrn.dataFiltrada) {
      for (let i of this.mrn.dataFiltrada) {
        if (i.nodo.id != this.api.nodoActual["id"]) {
          total += i.valor;
        }
      }
    }
    return total;
  }

  getTotalComprasSaldo() {
    let total = 0;
    if (this.mrn.dataFiltrada) {
      for (let i of this.mrn.dataFiltrada) {
        if (i.nodo.id == this.api.nodoActual["id"]) {
          total += i.valor;
        }
      }
    }
    return total;
  }

  asignarEvento(event) {
    this.mrn.motivo_seleccionado = event.value;
  }

  verificarCargaImg() {
    this.imgLoaded = true;
  }

  select_option_support(event: any) {
      this.mrn.soporte_requerido = event.value
      this.mrn.formTransaccion.patchValue({
        soporte:event.value?'':'no requerido',
        numeroAprobacion:event.value?'':'no requerido'
      });
  }

  filtarComisiones(event: any) {
     let lista_temp= this.mrn.misComisiones
    this.lista_categorias = lista_temp.filter(item => item.proveedorEmpresa.empresa.catServicio.id == event.id)
  }

  select_zone(value: { id: any; }) {
    this.mrn.form_nodo_usuario.patchValue({
      zona_id:value.id
    })
    this.mrn.zone_list = false;
  }

    convertir_minusculas() {
      this.mrn.form_nodo_usuario.patchValue({
        username : this.mrn.form_nodo_usuario.value['username'].toLowerCase()
      })
    }

  cambiarComision(tipoComision) {
    if(tipoComision == 'CA'){
      this.confirmationService.confirm({
        message:
            "Desea cambiar a este aliado a COMISION POR VENTA?",
        accept: () => {
          this.mrn.nodoSeleccionado.tipoComision = 'CV'
          this.mrn.nodoSeleccionado.comisionAnticipada = 0
          this.mrn.updateNodo(this.mrn.nodoSeleccionado)
        },
      });
    }else{
      this.confirmationService.confirm({
        message:
            "Desea cambiar a este aliado a COMISION ANTICIPADA?",
        accept: () => {
          this.mrn.nodoSeleccionado.tipoComision = 'CA'
          this.mrn.nodoSeleccionado.comisionAnticipada = 5
          this.mrn.updateNodo(this.mrn.nodoSeleccionado)
        },
      });
    }
  }

  setClaveVenta() {
    let identificacion = this.mrn.form_nodo_usuario.value['identificacion_nodo'].toString()
    let clave = identificacion.slice(identificacion.length - 4)
    this.mrn.form_nodo_usuario.patchValue({
      clave_venta :clave
    })
  }

  verificarClave() {
    if(this.mrn.form_nodo_usuario.value['clave_venta'].toString().length > 4){
      this.mrn.mensajeInformacion('La clave de venta solo puede contener 4 digitos.','warn')
    }
  }

  selectQuery(event: any) {

    switch (event.value){
      case 1:
        this.mrn.getFacturasPendientesRed(this.api.nodoActual,this.mrn.getPastDate(0))
          this.fecha_inicial = this.mrn.getPastDate( 0)
        break
      case 2:
        this.mrn.getFacturasPendientesRed(this.api.nodoActual,this.mrn.getPastDate(3))
          this.fecha_inicial = this.mrn.getPastDate( 3)
        break
      case 3:
        this.mrn.getFacturasPendientesRed(this.api.nodoActual,this.mrn.getPastDate(8))
          this.fecha_inicial = this.mrn.getPastDate( 8)
        break
      case 4:
        this.mrn.getFacturasPendientesRed(this.api.nodoActual,this.mrn.getPastDate(15))
          this.fecha_inicial = this.mrn.getPastDate( 15)
        break
      case 5:
        this.mrn.getFacturasPendientesRed(this.api.nodoActual,this.mrn.getPastDate(60))
          this.fecha_inicial = this.mrn.getPastDate( 60)
        break
      case 6:
        this.mrn.getFacturasPendientesRed(this.api.nodoActual,'Todo')
        break

    }
  }


    getTotalByGanancias(ventas_by_fecha: any[]) {
      let total = 0
      for (let i of ventas_by_fecha){
        if(i.estado == 'Aprobado' && i.venta_desde == 'Ganancias') {
          total += parseInt(i.valor)
        }
      }
      return total
    }

  getTotalBySaldo(ventas_by_fecha: any[]) {
    let total = 0
    for (let i of ventas_by_fecha){
      if(i.estado == 'Aprobado' && i.venta_desde == 'Saldo') {
        total += parseInt(i.valor)
      }
    }
    return total
  }

  bloquear_usuario(usuario: any) {
    this.confirmationService.confirm({
      message:
          "Desea " + (usuario.is_active ? "desactivar" : "activar") + " a este usuario?",
      accept: () => {
        usuario.is_active = !usuario.is_active
        this.api.update('usuario', usuario.id, usuario)
            .subscribe(
                data => {
                  if (data) {
                    if(!data.is_active){
                      this.mrn.enviar_notificacion(this.api.nodoActual['id'],this.mrn.nodoSeleccionado.id ,
                          'BLQUS','El usuario: '+ usuario.first_name+' ha sido bloqueado.','success')
                    }else {
                      this.mrn.enviar_notificacion(this.api.nodoActual['id'],this.mrn.nodoSeleccionado.id ,
                          'NOBLQUS','El usuario: '+ usuario.first_name+' ha sido desbloqueado.','success')
                    }
                  }
                }
            )
      },
    });

  }

  verificarValor(value: number) {
    if(this.api.nodoActual['id']!= 1){
      if(value > this.mrn.misBoslsasDinero.saldo_disponible){
        this.mrn.mensajeInformacion('Usted no tiene saldo suficiente para realizar esta transaccion','warn')
        this.mrn.formTransaccion.reset()
      }
    }
  }

  validarFormaPago(event: any) {
    if(event.value == 'SSCR'){
      if(this.mrn.formTransaccion.value['valor'] > this.mrn.creditoByNodo.montoDisponible){
        this.mrn.mensajeInformacion('El aliado no cuenta con credito suficiente para esta transaccion.','warn')
        this.mrn.formTransaccion.reset()
      }
    }
  }

  getTotalCompraContadoMovComercio() {
    let total = 0;
    for (let i of this.mrn.movimientos_by_fecha) {
      if (i.nodo == this.mrn.nodoSeleccionado.id) {
        if(i.tipo_transaccion == 'SSC')total += i.valor
      }
    }
    return total;
  }
  getTotalCompraCreditoMovComercio() {
    let total = 0;
    for (let i of this.mrn.movimientos_by_fecha) {
      if (i.nodo == this.mrn.nodoSeleccionado.id) {
        if(i.tipo_transaccion == 'SSCR')total += i.valor
      }
    }
    return total;
  }
  getTotalReversionesMovDistribuidor() {
    let total = 0;
    for (let i of this.mrn.movimientos_by_fecha) {
      if (i.nodo == this.mrn.nodoSeleccionado.id) {
        if(i.tipo_transaccion == 'AJS')total += i.valor
      }
    }
    return total;
  }


  getTotalCompraContadoComercio() {
    let total = 0;
    if (this.mrn.dataFiltrada) {
      for (let i of this.mrn.dataFiltrada) {
        if (i.nodo.id == this.mrn.nodoSeleccionado.id) {
          if(i.tipo_transaccion == 'SSC')total += i.valor
        }
      }
    }
    return total;
  }
  getTotalCompraCreditoComercio() {
    let total = 0;
    if (this.mrn.dataFiltrada) {
      for (let i of this.mrn.dataFiltrada) {
        if (i.nodo.id == this.mrn.nodoSeleccionado.id) {
          if(i.tipo_transaccion == 'SSCR')total += i.valor
        }
      }
    }
    return total;
  }
  getTotalReversionesDistribuidor(data?) {
    let total = 0;
    if (this.mrn.dataFiltrada) {
      for (let i of this.mrn.dataFiltrada) {
        if (i.nodo.id == this.mrn.nodoSeleccionado.id) {
          if(i.tipo_transaccion == 'AJS')total += i.valor
        }
      }
    }
    return total;
  }

  getTotalComprasContado() {
    let total = 0
    if(this.mrn.dataFiltrada){
      for (let i of this.mrn.dataFiltrada){
        if(i.nodo.id == this.mrn.nodoSeleccionado.id){
          if(i.tipo_transaccion == 'SSC')total += i.valor
        }
      }
    }
    return total
  }
  getTotalComprasCredito() {
    let total = 0
    if(this.mrn.dataFiltrada){
      for (let i of this.mrn.dataFiltrada){
        if(i.nodo.id == this.mrn.nodoSeleccionado.id){
          if(i.tipo_transaccion == 'SSCR')total += i.valor
        }
      }
    }
    return total
  }

  getTotalTrasladoGanancias() {
    let total = 0
    if(this.mrn.dataFiltrada){
      for (let i of this.mrn.dataFiltrada){
        if(i.nodo.id == this.mrn.nodoSeleccionado.id){
          if(i.tipo_transaccion == 'CJG')total += i.valor
        }
      }
    }
    return total
  }
  getTotalReversionesPropias() {
    let total = 0
    if(this.mrn.dataFiltrada){
      for (let i of this.mrn.dataFiltrada){
        if(i.nodo.id == this.mrn.nodoSeleccionado.id){
          if(i.tipo_transaccion == 'AJS')total += i.valor
        }
      }
    }
    return total
  }

  getTotalVentaContado() {
    let total = 0
    if(this.mrn.dataFiltrada){
      for (let i of this.mrn.dataFiltrada){
        if(i.nodo.id != this.mrn.nodoSeleccionado.id){
          if(i.tipo_transaccion == 'SSC')total += i.valor
        }
      }
    }
    return total
  }
  getTotalVentaCredito() {
    let total = 0
    if(this.mrn.dataFiltrada){
      for (let i of this.mrn.dataFiltrada){
        if(i.nodo.id != this.mrn.nodoSeleccionado.id){
          if(i.tipo_transaccion == 'SSCR')total += i.valor
        }
      }
    }
    return total
  }
  getTotalVentaReversiones() {
    let total = 0
    if(this.mrn.dataFiltrada){
      for (let i of this.mrn.dataFiltrada){
        if(i.nodo.id != this.mrn.nodoSeleccionado.id){
          if(i.tipo_transaccion == 'AJS')total += i.valor
        }
      }
    }
    return total
  }
}
