import { Component, OnInit } from '@angular/core';
import {Mrn} from "../../providers/mrn";
import {ApiService} from "../../providers/api";
import {ConfirmationService} from "primeng/api";
import * as moment from "moment";
import {exit} from "process";
@Component({
  selector: 'app-consultas',
  templateUrl: './consultas.component.html',
  styleUrls: ['./consultas.component.css']
})
export class ConsultasComponent implements OnInit {
  exitosas  = 0
  verSoportePago = false;
  total_ganancias = 0
  total_ventas = 0
  total_ventas_ganancias = 0
  total_ventas_saldo = 0
  filtered_data = []

  constructor(public mrn: Mrn, public api:ApiService, private confirmationService: ConfirmationService) { }

  ngOnInit(): void {
    this.mrn.getMisComisiones(this.api.nodoActual)
  }

  formatDate(value: string) {
    return moment(value).format('YYYY-MM-DD');
  }
  getTotalVentas(ventas) {
    let total = 0
    this.exitosas = 0
    for (let i of ventas){
      if(i.estado == 'Aprobado') {
        total += i.valor;
      }
    }
    this.total_ventas = total
    return total
  }
  getTotalVentasByGanancias(ventas) {
    let total = 0
    this.exitosas = 0
    for (let i of ventas){
      if(i.estado == 'Aprobado' && i.venta_desde == 'Ganancias') {
        total += i.valor;
      }
    }
    this.total_ventas_ganancias= total
    return total
  }
  getTotalVentasBySaldo(ventas) {
    let total = 0
    this.exitosas = 0
    for (let i of ventas){
      if(i.estado == 'Aprobado' && i.venta_desde == 'Saldo') {
        total += i.valor;
      }
    }
    this.total_ventas_saldo = total
    return total
  }
  getTotalGanancias(ventas) {
    let total = 0
    for (let i of ventas){
      if(i.estado == 'Aprobado') {
        total += parseInt(i.ganancia)
      }
    }
    this.total_ganancias = total
    return total
  }
  calcular_max(value: string) {
    if(value){
      return moment(value).add(30,'days').format('YYYY-MM-DD')
    }

  }

  protected readonly exit = exit;

  onFilter(event, dt) {
    this.filtered_data = event.filteredValue
    if(this.filtered_data.length){
      this.getTotalGanancias(event.filteredValue)
      this.getTotalVentas(event.filteredValue)
      this.getTotalVentasByGanancias(event.filteredValue)
      this.getTotalVentasBySaldo(event.filteredValue)
    }
  }
}
