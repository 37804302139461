import { Component, OnInit } from '@angular/core';
import {Mrn} from "../../providers/mrn";
import {ApiService} from "../../providers/api";
import {ConfirmationService, MenuItem, TreeNode} from "primeng/api";
import * as moment from 'moment';

@Component({
  selector: 'app-usuarios',
  templateUrl: './usuarios.component.html',
})
export class UsuariosComponent implements OnInit {

  ciudad
  items: MenuItem[];
  asesorSeleccionado: any;

  constructor(public mrn: Mrn, public api: ApiService, private confirmationService: ConfirmationService) {
  }

  ngOnInit(): void {
    this.mrn.crearControles();
    this.mrn.getUsuarioByNodo(this.api.usuario['nodo']);
    this.mrn.getEquipos();
    this.mrn.getZonas()

  }

  guardarUsuario() {
    if (this.mrn.formUsuario.value['id']) {
      if (this.mrn.formUsuario.value['password2']) {
        this.confirmationService.confirm({
          message: 'Quiere cambair la contrasela al usuario ' + this.mrn.formUsuario.value['username'] + '?',
          accept: () => {
            this.mrn.formUsuario.patchValue({
              password: this.mrn.formUsuario.value['password2']
            });
            this.mrn.updatePwdUsuario(this.mrn.formUsuario.value)
          }
        });
      } else {
        this.mrn.updateUsuario(this.mrn.formUsuario.value)
      }
    } else {
      this.mrn.formUsuario.patchValue({
        password: this.mrn.formUsuario.value['password2'],
        nodo_id: this.api.nodoActual['id'],
        cargo: (this.api.nodoActual['tipo'] == 'Distribuidor') ? this.mrn.formUsuario.value['cargo'] : 'Auxiliar'
      });
      this.mrn.addUsuario()
    }
  }

  cambioEstado(usuario: any) {
    this.confirmationService.confirm({
      message: 'Desea ' + (usuario.is_active ? 'Bloquear' : 'Activar') + ' a  ' + usuario.first_name + ' ' + usuario.last_name + '?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        usuario.is_active = !usuario.is_active;
        this.mrn.updateUsuario(usuario)
      },
      reject: () => {
        //reject action
      }
    });
  }

  seleccionarZona($event,menu) {
    if(this.mrn.nodos_seleccionados.length && this.mrn.zonaSeleccionada){
      for(let nodo of this.mrn.nodos_seleccionados){
        nodo.zona_id = this.mrn.zonaSeleccionada.id
        this.mrn.updateNodoZona(nodo)
      }
    }else{
      menu.toggle($event)
      if(this.mrn.zonaSeleccionada.suma_nodos && this.mrn.zonaSeleccionada.zona_padre){
        this.items = [{
          label: 'Opciones '+this.mrn.zonaSeleccionada.nombre,
          items: [
            {
              separator: true
            },
            {
              label: 'Ver aliados',
              command: () => {
                this.mrn.get_aliados_by_zona(this.mrn.zonaSeleccionada)
              }
            },
            {
              separator: true
            },
            {
              label: 'Crear sub zona',
              command: () => {
                this.mrn.formZona.patchValue({
                  zona_padre: this.mrn.zonaSeleccionada.id,
                  nodo_id: this.api.nodoActual['id'],
                  activo:true
                })
                this.mrn.verFormZona = true
              }
            },
            {
              label: 'Editar zona',
              command: () => {
                this.mrn.llenarFormZona(this.mrn.zonaSeleccionada)
                this.mrn.verFormZona = true
              }
            },{
              label: !this.mrn.zona_mover?'Mover a...':'Mover aqui',
              command: () => {
                if(!this.mrn.zona_mover){
                  this.mrn.zona_mover = this.mrn.zonaSeleccionada
                }else {
                  this.mrn.zona_mover.zona_padre = this.mrn.zonaSeleccionada.id
                  this.mrn.llenarFormZona(this.mrn.zona_mover)
                  this.mrn.updateZonas()
                }
              }
            },
            {
              separator: true
            },
            {
              label: 'Cancelar',
              command: () => {
                this.mrn.zona_mover = ''
                this.mrn.zonaSeleccionada = ''
                this.mrn.aliado_mover = ''
              }
            },
          ]}];
      }
      else if(!this.mrn.zonaSeleccionada.suma_nodos && this.mrn.zonaSeleccionada.zona_padre){
        this.items = [{
          label: 'Opciones '+this.mrn.zonaSeleccionada.nombre,
          items: [
            {
              separator: true
            },
            {
              label: 'Crear sub zona',
              command: () => {
                this.mrn.formZona.patchValue({
                  zona_padre: this.mrn.zonaSeleccionada.id,
                  nodo_id: this.api.nodoActual['id'],
                  activo:true
                })
                this.mrn.verFormZona = true
              }
            },
            {
              label: 'Editar zona',
              command: () => {
                this.mrn.llenarFormZona(this.mrn.zonaSeleccionada)
                this.mrn.verFormZona = true
              }
            },{
              label: !this.mrn.zona_mover?'Mover a...':'Mover aqui',
              command: () => {
                if(!this.mrn.zona_mover){
                  this.mrn.zona_mover = this.mrn.zonaSeleccionada
                }else {
                  this.mrn.zona_mover.zona_padre = this.mrn.zonaSeleccionada.id
                  this.mrn.llenarFormZona(this.mrn.zona_mover)
                  this.mrn.updateZonas()
                }
              }
            },
            {
              separator: true
            },
            {
              label: 'Cancelar',
              command: () => {
                this.mrn.zona_mover = ''
                this.mrn.zonaSeleccionada = ''
                this.mrn.aliado_mover = ''
              }
            },
          ]}];
      }
      else if(this.mrn.zonaSeleccionada.suma_nodos && !this.mrn.zonaSeleccionada.zona_padre){
        this.items = [{
          label: 'Opciones '+this.mrn.zonaSeleccionada.nombre,
          items: [
            {
              separator: true
            },
            {
              label: 'Ver aliados',
              command: () => {
                this.mrn.get_aliados_by_zona(this.mrn.zonaSeleccionada)
              }
            },
            {
              separator: true
            },
            {
              label: 'Crear sub zona',
              command: () => {
                this.mrn.formZona.patchValue({
                  zona_padre: this.mrn.zonaSeleccionada.id,
                  nodo_id: this.api.nodoActual['id'],
                  activo:true
                })
                this.mrn.verFormZona = true
              }
            },
            {
              label: 'Editar zona',
              command: () => {
                this.mrn.llenarFormZona(this.mrn.zonaSeleccionada)
                this.mrn.verFormZona = true
              }
            },{
              label: !this.mrn.zona_mover?'Mover a...':'Mover aqui',
              command: () => {
                if(!this.mrn.zona_mover){
                  this.mrn.zona_mover = this.mrn.zonaSeleccionada
                }else {
                  this.mrn.zona_mover.zona_padre = this.mrn.zonaSeleccionada.id
                  this.mrn.llenarFormZona(this.mrn.zona_mover)
                  this.mrn.updateZonas()
                }
              }
            },
            {
              separator: true
            },
            {
              label: 'Cancelar',
              command: () => {
                this.mrn.zona_mover = ''
                this.mrn.zonaSeleccionada = ''
                this.mrn.aliado_mover = ''
              }
            },
          ]}];
      }
      else if(!this.mrn.zonaSeleccionada.suma_nodos && !this.mrn.zonaSeleccionada.zona_padre){
        this.items = [{
          label: 'Opciones '+this.mrn.zonaSeleccionada.nombre,
          items: [
            {
              separator: true
            },
            {
              label: 'Crear zona principal',
              disabled: this.mrn.zonaSeleccionada.zona_padre,
              command: () => {
                this.mrn.formZona.patchValue({
                  zona_padre: null,
                  nodo_id: this.api.nodoActual['id'],
                  activo:true
                })
                this.mrn.verFormZona = true
              }
            },
            {
              separator: true
            },
            {
              label: 'Crear sub zona',
              command: () => {
                this.mrn.formZona.patchValue({
                  zona_padre: this.mrn.zonaSeleccionada.id,
                  nodo_id: this.api.nodoActual['id'],
                  activo:true
                })
                this.mrn.verFormZona = true
              }
            },
            {
              label: 'Editar zona',
              command: () => {
                this.mrn.llenarFormZona(this.mrn.zonaSeleccionada)
                this.mrn.verFormZona = true
              }
            },{
              label: !this.mrn.zona_mover?'Mover a...':'Mover aqui',
              command: () => {
                if(!this.mrn.zona_mover){
                  this.mrn.zona_mover = this.mrn.zonaSeleccionada
                }else {
                  this.mrn.zona_mover.zona_padre = this.mrn.zonaSeleccionada.id
                  this.mrn.llenarFormZona(this.mrn.zona_mover)
                  this.mrn.updateZonas()
                }
              }
            },
            {
              separator: true
            },
            {
              label: 'Cancelar',
              command: () => {
                this.mrn.zona_mover = ''
                this.mrn.zonaSeleccionada = ''
                this.mrn.aliado_mover = ''
              }
            },
          ]}];
      }

    }
  }

  guardarZona() {
    if (this.mrn.formZona.value['id']) {
      this.mrn.updateZonas()
    } else {
      this.mrn.addZonas()
    }
  }

  guardarDetalleZona() {
    for (let item of this.mrn.BarriosSeleccionados) {
      this.mrn.formZonaBarrio.patchValue({
        zona_id: this.mrn.zonaSeleccionada.id,
        barrio_id: item.id,
        activo: true
      });
      this.mrn.addZonasBarrios()
    }
  }

  buscarCiudad(event: any) {
    if (event.query) {
      this.mrn.municipiosFiltrados = this.mrn.municipios.filter(item => item.nombre_mpio.includes(event.query.toUpperCase()))
    } else {
      this.mrn.municipiosFiltrados = this.mrn.municipios;
    }
  }

  getTotalAliados() {
    let total = 0
    for(let zona of this.mrn.zonaAsesores){
      total += parseInt(zona.zona.suma_nodos)
    }
    return total
  }
  getTotalAliadosByFecha() {
    let total = 0
    for(let zona of this.mrn.historial_asesores){
      total += parseInt(zona.zona.suma_nodos)
    }
    return total
  }
  consultarAsignacion() {
    let hoy = moment().format('YYYY-MM-DD')
    this.mrn.getAsignacionesByFechaByAsesor(hoy,hoy,this.asesorSeleccionado)
  }

  pruebaConsulta(zona:any) {
    this.api.get('facturas_by_zona/?zona_id='+zona.id)
        .subscribe(
            data=>{
              let lista = []
              for(let transaccion of data){
                if(!lista.length){
                  lista.push({
                    nodo:transaccion.nodo,
                    transacciones:[transaccion]
                  })
                }else {
                  if(lista.filter(item => item.nodo.id === transaccion.nodo.id).length){
                    let index = lista.indexOf(lista.filter(item => item.nodo.id === transaccion.nodo.id)[0])
                    lista[index]['transacciones'].push(transaccion)
                  }else {
                    lista.push({
                      nodo:transaccion.nodo,
                      transacciones:[transaccion]
                    })
                  }
                }
              }

              console.log(lista)
            }
        )
  }
}
