<p-tabView [(activeIndex)]="mrn.activeIndex1">
    <p-tabPanel header="Usuarios">
        <div class="p-grid">
            <div class="p-col-12 p-md-9">
                <div class="card">
                    <h5>Usuarios registrados</h5>
                    <span>{{api.nodoActual['tipo']}} {{api.nodoActual['razonSocial']}}</span>
                    <p-table [rowHover]="true" [responsive]="true" [value]="mrn.usuariosByNodo" #dtUsuarios
                             [globalFilterFields]="['first_name','username','telefono','identificacion','cargo']">
                        <ng-template pTemplate="caption">
                            <div class="caption_content">
                                <button pButton pRipple type="button" label="Nuevo usuario"
                                        (click)="mrn.verFormUsuarioPP = true;mrn.formUsuario.reset()"></button>
                                <span class="p-input-icon-left ml-auto">
                                    <i class="pi pi-search"></i>
                                    <input pInputText type="text" (input)="dtUsuarios.filterGlobal($event.target.value, 'contains')" placeholder="Buscar Usuario" />
                                </span>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="header">
                            <tr>
                                <th style="width:5%">Estado</th>
                                <th>Nombres</th>
                                <th style="width:13%">Usuario</th>
                                <th style="width:13%">Identificacion</th>
                                <th style="width:13%">Cargo</th>
                                <th style="width:13%"></th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-usuario>
                            <tr>
                                <td style="text-align: center">
                                    <i pTooltip="{{usuario.is_active?'activo':'inactivo'}}"
                                       class="pi pi-circle-on {{usuario.is_active?'activo':'inactivo'}}"></i>
                                </td>
                                <td>{{usuario.first_name}}</td>
                                <td>{{usuario.username}}</td>
                                <td style="text-align: center">{{usuario.identificacion}}</td>
                                <td style="text-align: center">{{usuario.cargo}}</td>
                                <td style="text-align: center">
                                    <i pTooltip="Ver permisos de acceso" style="margin-right: 10px"
                                       class="pi pi-unlock accion-tabla"
                                       (click)="mrn.usuarioSeleccionado = usuario;mrn.getPermisos()"></i>
                                    <i *ngIf="usuario.cargo!='Administrador'"
                                       pTooltip="{{usuario.is_active?'Bloquear usuario':'Activar usuario'}}"
                                       class="pi {{usuario.is_active?'pi-user-minus':'pi-user-plus'}}
                            accion-tabla" (click)="cambioEstado(usuario)"></i>
                                    <i pTooltip="Editar usuario" style="margin-right: 10px"
                                       class="pi pi-pencil accion-tabla"
                                       (click)="mrn.llenarformUsuario(usuario);mrn.verFormUsuario = true"></i>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </div>
            <div class="p-col-12 p-md-3">
                <div *ngIf="!mrn.usuarioSeleccionado" class="card">
                    <p style="text-align:justify">Para ver u otorgar permisos de acceso a las diferentes
                        funcionalidades,
                        por favor seleccione un usuario de la tabla de <span style="font-weight: bold">usuarios registrados</span>
                        haciendo click en el boton <i style="font-weight: bold" class="pi pi-unlock"></i> . </p>
                </div>
                <div *ngIf="mrn.usuarioSeleccionado" class="card">
                    <h5>Permisos de acceso</h5>
                    <span>{{mrn.usuarioSeleccionado.first_name}} {{mrn.usuarioSeleccionado.last_name}}
                        - {{mrn.usuarioSeleccionado.cargo}}</span>
                    <p-table [rowHover]="true" [responsive]="true" [value]="mrn.permisos">
                        <ng-template pTemplate="caption">
                            <div class="p-grid">
                                <div class="p-col-12  p-md-6">

                                </div>
                                <div style="text-align: right" class="p-col-12  p-md-6">
                                    <button pButton pRipple type="button" label="Otorgar permiso"
                                            (click)="mrn.getFuncionalidades();mrn.verFuncionalidades=true"></button>
                                </div>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="header">
                            <tr>
                                <th>Funcionalidad</th>
                                <th style="width: 4rem">Acceso</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-permiso>
                            <tr>
                                <td>{{permiso.funcionalidad.funcionalidad}}</td>
                                <td>
                                    <i pTooltip="Denegar acceso" class="pi pi-trash" style="color:darkred"
                                       (click)="mrn.deletePermiso(permiso)"></i>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </div>
        </div>
    </p-tabPanel>
    <p-tabPanel header="Zonas">
        <div class="p-grid">
            <div class="p-col-12 p-md-4">
                <div class="card">
                    <div style="margin: 15px 0 15px 0" *ngIf="mrn.zona_mover">Seleccione la zona a donde quiere mover:{{mrn.zona_mover.nombre}}</div>
                    <div style="margin: 15px 0 15px 0" *ngIf="mrn.nodos_seleccionados.length">Seleccione la zona destino.</div>
                    <p-menu #menu [popup]="true" [model]="items"></p-menu>
                    <p-tree [value]="mrn.arbol_zona" filterBy="nombre" [filter]="true" selectionMode="single"
                            [(selection)]="mrn.zonaSeleccionada" (onNodeSelect)="seleccionarZona($event,menu);">
                        <ng-template let-node  pTemplate="default" >
                            <span><b>{{node.nombre}}</b> {{node.suma_nodos?('('+node.suma_nodos+' aliados)'):''}} </span>
                        </ng-template>
                    </p-tree>

                </div>
            </div>
            <div class="p-col-12 p-md-8">
                <div class="card" *ngIf="mrn.zonaSeleccionada && mrn.aliados_by_zona.length">
                    <h5>Mis aliados en <span >{{mrn.zonaSeleccionada.nombre}}</span></h5>
                    <span style="color: gray">{{mrn.aliados_by_zona.length}} encontrados</span>
                    <p-table #dtali [rowHover]="true" [responsive]="true" [globalFilterFields]="['razonSocial']"
                             [value]="mrn.aliados_by_zona" [paginator]="true" [rows]="10" [(selection)]="mrn.nodos_seleccionados">
                        <ng-template pTemplate="caption">
                            <span class="p-input-icon-left ml-auto">
                                    <i class="pi pi-search"></i>
                                    <input pInputText type="text" (input)="dtali.filterGlobal($event.target.value, 'contains')" placeholder="Buscar aliado" />
                            </span>
                        </ng-template>
                        <ng-template pTemplate="header">
                            <tr>
                                <th style="width: 3rem">
                                    <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                                </th>
                                <th>Id</th>
                                <th>Nombre</th>
                                <th>Tipo</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-aliado>
                            <tr>
                                <td>
                                    <p-tableCheckbox [value]="aliado"></p-tableCheckbox>
                                </td>
                                <td>{{aliado.id}}</td>
                                <td>{{aliado.representanteLegal}}</td>
                                <td>{{aliado.tipo}}</td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="emptymessage">
                            <tr>
                                <td [attr.colspan]="2">
                                    Aun no hay Zonas.
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </div>
        </div>
    </p-tabPanel>
    <p-tabPanel header="Gestion de asesores" *ngIf="api.nodoActual['id'] == 1">
        <p-tabView>
            <p-tabPanel header="Asignacion de asesores">
                <div class="p-grid">
                    <div class="p-col-12 p-md-6">
                        <div class="card">
                            <h5>Usuarios registrados</h5>
                            <span>{{api.nodoActual['tipo']}} {{api.nodoActual['razonSocial']}}</span>
                            <p-table [rowHover]="true" [responsive]="true" [value]="mrn.asesoresByNodo" #dtAsesor
                                     [globalFilterFields]="['first_name','username','telefono','identificacion','cargo']">
                                <ng-template pTemplate="caption">
                                    <div class="caption_content">
                                <span class="p-input-icon-left ml-auto">
                                    <i class="pi pi-search"></i>
                                    <input pInputText type="text" (input)="dtAsesor.filterGlobal($event.target.value, 'contains')" placeholder="Buscar asesor" />
                                </span>
                                    </div>
                                </ng-template>
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th style="width:5%">Estado</th>
                                        <th>Nombres</th>
                                        <th style="width:13%">Usuario</th>
                                        <th style="width:13%">Identificacion</th>
                                        <th style="width:13%">Cargo</th>
                                        <th style="width:13%"></th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-usuario>
                                    <tr>
                                        <td style="text-align: center">
                                            <i pTooltip="{{usuario.is_active?'activo':'inactivo'}}"
                                               class="pi pi-circle-on {{usuario.is_active?'activo':'inactivo'}}"></i>
                                        </td>
                                        <td>{{usuario.first_name}}</td>
                                        <td>{{usuario.username}}</td>
                                        <td style="text-align: center">{{usuario.identificacion}}</td>
                                        <td style="text-align: center">{{usuario.cargo}}</td>
                                        <td style="text-align: center">
                                            <i pTooltip="Asignar zonas" style="margin-right: 10px"
                                               class="fas fa-map-marker-alt accion-tabla"
                                               (click)="asesorSeleccionado = usuario;mrn.verFormAsignacionAsesor = true"></i>
                                            <i pTooltip="Ver asignaciones" style="margin-right: 10px"
                                               class="fas fa-eye accion-tabla"
                                               (click)="mrn.zonaAsesores = [];asesorSeleccionado = usuario;consultarAsignacion()"></i>
                                        </td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </div>
                    </div>
                    <div class="p-col-12 p-md-6" *ngIf="asesorSeleccionado">
                        <div class="card">
                            <h5>Zonas asignadas a {{asesorSeleccionado.first_name}}</h5>
                            <p-table [rowHover]="true" [responsive]="true" [value]="mrn.zonaAsesores" #dtasesores
                                     [globalFilterFields]="['zona.nombre']">
                                <ng-template pTemplate="caption">
                                    <div class="p-grid">
                                        <div class="p-col-12 p-md-6">
                               <span class="p-input-icon-left ml-auto">
                                    <i class="pi pi-search"></i>
                                    <input pInputText type="text" (input)="dtasesores.filterGlobal($event.target.value, 'contains')" placeholder="Buscar" />
                                </span>
                                        </div>
                                    </div>
                                </ng-template>
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th style="width: 20%">Fecha</th>
                                        <th>Zona</th>
                                        <th style="width: 20%">Aliados registrados</th>
                                        <th></th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-zona_asesor>
                                    <tr>
                                        <td style="text-align: center">{{zona_asesor.created_at|date}}</td>
                                        <td>{{zona_asesor.zona.nombre}}</td>
                                        <td style="text-align: right">{{zona_asesor.zona.suma_nodos}}</td>
                                        <td><i (click)="pruebaConsulta(zona_asesor.zona)" class="fas fa-eye"></i></td>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="footer">
                                    <tr>
                                        <td style="text-align: right"  colspan="2">Total aliados</td>
                                        <td style="text-align: right">{{getTotalAliados()}}</td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </div>
                    </div>
                </div>
            </p-tabPanel>
            <p-tabPanel header="Historial de asignaciones">
                <div class="p-grid">
                    <div class="p-col-12 p-md-3">
                    </div>
                    <div class="p-col-12 p-md-6">
                        <div class="card">
                            <p-table [rowHover]="true" [responsive]="true" [value]="mrn.historial_asesores" #dtasesor
                                     [globalFilterFields]="['zona.nombre','usuario.first_name']">
                                <ng-template pTemplate="caption">
                                    <div class="p-grid">
                                        <div class="p-col-12 p-md-6">
                               <span class="p-input-icon-left ml-auto">
                                    <i class="pi pi-search"></i>
                                    <input pInputText type="text" (input)="dtasesor.filterGlobal($event.target.value, 'contains')" placeholder="Buscar" />
                                </span>
                                        </div>
                                        <div class="p-col-12 p-md-6">
                                            <div class="p-grid p-fluid p-formgrid">
                                                <div class="p-field p-col-12 p-md-6">
                                                    <label for="fia">Desde</label>
                                                    <input type="date" #fInicial pInputText id="fia" />
                                                </div>
                                                <div class="p-field p-col-12 p-md-6">
                                                    <label for="fiaf">Hasta</label>
                                                    <input type="date" #fFinal pInputText id="fiaf" />
                                                </div>
                                                <div class="p-col-12 p-md-6">
                                                    <button *ngIf="fInicial.value && fFinal.value" (click)="mrn.zonaAsesores = [];mrn.getAsignacionesByFecha(fInicial.value,fFinal.value)"
                                                            type="button" pButton pRipple styleClass="p-button-danger" icon="pi pi-search" label="Consultar"></button>
                                                </div>
                                                <div class="p-col-12 p-md-6">
                                                    <button
                                                            (click)="fInicial.value = ''; fFinal.value = '' ; mrn.historial_asesores = []"
                                                            type="button"  pButton pRipple styleClass="p-button-danger" icon="fas fa-broom" label="Limpiar" ></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </ng-template>
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th style="width: 20%">Fecha</th>
                                        <th>Asesor</th>
                                        <th>Zona</th>
                                        <th style="width: 20%">Aliados registrados</th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-zona_asesor>
                                    <tr>
                                        <td style="text-align: center">{{zona_asesor.created_at|date}}</td>
                                        <td>{{zona_asesor.usuario.first_name}}</td>
                                        <td>{{zona_asesor.zona.nombre}}</td>
                                        <td style="text-align: right">{{zona_asesor.zona.suma_nodos}}</td>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="footer">
                                    <tr>
                                        <td style="text-align: right"  colspan="3">Total aliados</td>
                                        <td style="text-align: right">{{getTotalAliadosByFecha()}}</td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </div>
                    </div>
                    <div class="p-col-12 p-md-3">
                    </div>
                </div>
            </p-tabPanel>
            <p-tabPanel header="Historial de consignaciones">
                <div class="p-grid">
                    <div class="p-col-12 p-md-3">
                    </div>
                    <div class="p-col-12 p-md-6">
                        <div class="card">
                            <p-table [rowHover]="true" [responsive]="true" [value]="mrn.zonaAsesores" #dtasesores
                                     [globalFilterFields]="['zona.nombre']">
                                <ng-template pTemplate="caption">
                                    <div class="p-grid">
                                        <div class="p-col-12 p-md-6">
                               <span class="p-input-icon-left ml-auto">
                                    <i class="pi pi-search"></i>
                                    <input pInputText type="text" (input)="dtasesores.filterGlobal($event.target.value, 'contains')" placeholder="Buscar" />
                                </span>
                                        </div>
                                        <div class="p-col-12 p-md-6">
                                            <div class="p-grid p-fluid p-formgrid">
                                                <div class="p-field p-col-12 p-md-6">
                                                    <label for="fia">Desde</label>
                                                    <input type="date" #fInicial pInputText id="fia" />
                                                </div>
                                                <div class="p-field p-col-12 p-md-6">
                                                    <label for="fiaf">Hasta</label>
                                                    <input type="date" #fFinal pInputText id="fiaf" />
                                                </div>
                                                <div class="p-col-12 p-md-6">
                                                    <button *ngIf="fInicial.value && fFinal.value" (click)="mrn.zonaAsesores = [];mrn.getAsignacionesByFechaByAsesor(fInicial.value,fFinal.value,asesorSeleccionado)"
                                                            type="button" pButton pRipple styleClass="p-button-danger" icon="pi pi-search" label="Consultar"></button>
                                                </div>
                                                <div class="p-col-12 p-md-6">
                                                    <button
                                                            (click)="fInicial.value = ''; fFinal.value = '' ; mrn.zonaAsesores = []"
                                                            type="button"  pButton pRipple styleClass="p-button-danger" icon="fas fa-broom" label="Limpiar" ></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </ng-template>
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th style="width: 20%">Fecha</th>
                                        <th>Zona</th>
                                        <th style="width: 20%">Aliados registrados</th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-zona_asesor>
                                    <tr>
                                        <td style="text-align: center">{{zona_asesor.created_at|date}}</td>
                                        <td>{{zona_asesor.zona.nombre}}</td>
                                        <td style="text-align: right">{{zona_asesor.zona.suma_nodos}}</td>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="footer">
                                    <tr>
                                        <td style="text-align: right"  colspan="2">Total aliados</td>
                                        <td style="text-align: right">{{getTotalAliados()}}</td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </div>
                    </div>
                    <div class="p-col-12 p-md-3">
                    </div>
                </div>
            </p-tabPanel>
        </p-tabView>
    </p-tabPanel>
   <!-- <p-tabPanel header="Equipos">
        <div class="p-grid">
            <div class="p-col-12 p-md-2">
                <form [formGroup]="mrn.formEquipo">
                    <div class="p-fluid p-formgrid p-grid">
                        <div class="p-field p-col-12 ">
                            <label for="marca">Marca</label>
                            <input id="marca" formControlName="marca" type="text" pInputText>
                        </div>
                    </div>
                    <div class="p-fluid p-formgrid p-grid">
                        <div class="p-field p-col-12 ">
                            <label for="serial">Serial</label>
                            <input id="serial" formControlName="serial" type="text" pInputText>
                        </div>
                    </div>
                </form>
                <div style="padding-top: 15px;text-align:right">
                    <button *ngIf="mrn.formEquipo.valid" pButton pRipple type="button" label="Guardar"
                            (click)="guardarEquipo()"></button>
                </div>
            </div>
            <div class="p-col-12 p-md-1">
                <p-divider layout="vertical"></p-divider>
            </div>
            <div class="p-col-12 p-md-9">
                <p-table [value]="mrn.equipos" [rowHover]="true" [paginator]="true" #dtEquipos
                         [responsive]="true" [rows]="10" [globalFilterFields]="['serial','marca']">
                    <ng-template pTemplate="caption">
                        <div class="caption_content_only_input">
                            <span class="p-input-icon-left ml-auto">
                                    <i class="pi pi-search"></i>
                                    <input pInputText type="text"
                                           (input)="dtEquipos.filterGlobal($event.target.value, 'contains')"
                                           placeholder="Buscar Equipo" />
                                </span>
                        </div>
                    </ng-template>
                    <ng-template pTemplate="header">
                        <tr>
                            <th style="width:12%">Activo</th>
                            <th style="width:12%">Estado</th>
                            <th style="width:7%">Serial</th>
                            <th>Marca</th>
                            <th style="width:10%"></th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-equipo>
                        <tr>
                            <td style="text-align:center">
                                <i pTooltip="Equipo {{equipo.activo?'Activo':'Inactivo'}}"
                                   (click)="inactivarEquipo(equipo)"></i>
                                {{equipo.activo ? 'Activo' : 'Inactivo'}}
                            </td>
                            <td style="text-align:center">
                                <i pTooltip="Equipo {{equipo.asignado?'Asignado':'Libre'}}"></i>
                                {{equipo.asignado ? 'Asignado' : 'Libre'}}
                            </td>
                            <td style="text-align:right">{{equipo.serial}}</td>
                            <td style="text-align:right">{{equipo.marca}}</td>
                            <td style="text-align:center">
                                <i pTooltip="{{equipo.activo?'Inactivar':'Activar'}} equipo"
                                   class="pi pi-{{equipo.activo?'times-circle':'check-circle'}} accion-tabla"
                                   (click)="inactivarEquipo(equipo)"></i>
                                <i pTooltip="Historial equipo" class="pi pi-list accion-tabla"
                                   (click)="mrn.getHistorialAsignacionEquipos(equipo);mrn.equipoSeleccionado = equipo"></i>
                            </td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="emptymessage">
                        <tr>
                            <td [attr.colspan]="3">
                                Aun no hay Equipos.
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
        </div>
    </p-tabPanel>
    <p-tabPanel [disabled]="!mrn.historialEquipo.length" header="Historial de asignacion de equipo">
        <div class="p-grid">
            <div class="p-col-12 p-md-12">
                <p-table [paginator]="true" [responsive]="true" [rows]="10" [value]="mrn.historialEquipo"
                         dataKey="nodo.razonSocial">
                    <ng-template pTemplate="caption">
                        <h3 *ngIf="mrn.equipoSeleccionado">{{mrn.equipoSeleccionado.marca}}</h3>
                        <h5 *ngIf="mrn.equipoSeleccionado">Serial: {{mrn.equipoSeleccionado.serial}}</h5>
                    </ng-template>
                    <ng-template pTemplate="header">
                        <tr>
                            <th style="width: 5%"></th>
                            <th>Fecha Asig.</th>
                            <th>Fecha Devol.</th>
                            <th>Aliado</th>
                            <th>Orden Asignacion</th>
                            <th>Orden Devolucion</th>
                            <th style="width: 5%"></th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-equipo let-expanded="expanded">
                        <tr [pSelectableRow]="equipo">
                            <td>
                                <i [pRowToggler]="equipo"
                                   class="{{expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'}} accion-tabla"></i>
                            </td>
                            <td style="text-align: center">{{equipo.fechaAsignacion|date}}</td>
                            <td style="text-align: center">{{equipo.fechaDevolucion ? (equipo.fechaDevolucion|date) : '&#45;&#45;'}}</td>
                            <td style="text-align: center">{{equipo.nodo.razonSocial}}</td>
                            <td style="text-align: center">{{equipo.ordenEntrega}}</td>
                            <td style="text-align: center">{{equipo.ordenDevolucion ? equipo.ordenDevolucion : '&#45;&#45;'}}</td>
                            <td style="text-align: center">
                                <i *ngIf="equipo.ordenDevolucion==''" pTooltip="Liberar equipo"
                                   (click)="mrn.llenarformEquipoNodo(equipo);mrn.verLiberarEquipo = true"
                                   class="pi pi-lock-open accion-tabla"></i>
                            </td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="rowexpansion" let-equipo>
                        <tr>
                            <td td colspan="7">
                                <table>
                                    <tr>
                                        <th>Observaciones de asignacion</th>
                                        <th>Observaciones de devolucion</th>
                                    </tr>
                                    <tr>
                                        <td style="text-align: justify">{{equipo.observacionAsignacion ? equipo.observacionAsignacion : '&#45;&#45;'}}</td>
                                        <td style="text-align: justify">{{equipo.observacionDevolucion ? equipo.observacionDevolucion : '&#45;&#45;'}}</td>
                                    </tr>
                                </table>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
        </div>
    </p-tabPanel>-->
</p-tabView>
<p-dialog header="Asignar zonas" [modal]="true" [(visible)]="mrn.verFormAsignacionAsesor" [style]="{width: '35vw'}">
    <div class="card">
        <p-tree [value]="mrn.arbol_zona" selectionMode="checkbox" filterBy="nombre" [filter]="true"
                [(selection)]="mrn.zonasSeleccionadas" filterPlaceholder="Buscar zona">
            <ng-template let-node  pTemplate="default">
                <span><b>{{node.nombre}}</b> {{node.suma_nodos?('('+node.suma_nodos+' aliados)'):''}} </span>
            </ng-template>
        </p-tree>
    </div>
    <p-divider></p-divider>
    <div *ngIf="mrn.zonasSeleccionadas.length">
        <span style="font-weight: bold">Se asignaran : {{mrn.zonasSeleccionadas.length}} zonas.</span>
        <ul>
            <li *ngFor="let zona of mrn.zonasSeleccionadas">{{zona.nombre}}</li>
        </ul>
    </div>
    <p-footer>
        <div style="padding-top: 15px;text-align:right">
            <button *ngIf="mrn.zonasSeleccionadas.length"
                    pButton pRipple type="button" label="Guardar" (click)="mrn.asignarZonaAsesor(asesorSeleccionado)"></button>
        </div>
    </p-footer>
</p-dialog>
<p-dialog header="Editar/Crear" [(visible)]="mrn.verFormUsuarioPP" [style]="{width: '35vw'}">
    <form [formGroup]="mrn.formUsuario">
        <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-12 p-md-12 ">
                <p-divider align="left">
                    <div class="p-d-inline-flex p-ai-center">
                        <i class="fas fa-house-user p-mr-2"></i>
                        <b>Informacion personal</b>
                    </div>
                </p-divider>
            </div>
            <div class="p-field p-col-12 p-md-6 ">
                <label for="identificacion">Número de Identificacion</label>
                <input formControlName="identificacion" #identificacion
                       (input)="mrn.verificar_user_data(identificacion.value,'')" id="identificacion"
                       type="number" pInputText>
                <small *ngIf="mrn.formUsuario.value['identificacion']" style="color: {{(mrn.identificacion_available=='Este número de identificacion ya existe!')?'darkred':'darkgreen'}}"
                       id="id-help">{{mrn.identificacion_available}}</small>
            </div>
            <div class="p-field p-col-12 p-md-6 ">
                <label for="first_name">Nombres</label>
                <input id="first_name" formControlName="first_name" type="text" pInputText>
            </div>
            <!--<div class="p-field p-col-12 p-md-6 ">
                <label for="last_name">Apellidos</label>
                <input id="last_name" formControlName="last_name" type="text" pInputText>
            </div>-->
            <div class="p-field p-col-12 p-md-6 ">
                <label for="tele">Número de telefono celular</label>
                <p-inputMask id="tele" mask="999-999-99-99" formControlName="telefono"
                             [unmask]="true"></p-inputMask>
            </div>
            <div class="p-field p-col-12 p-md-6 ">
                <label for="dir">Direccion</label>
                <input id="dir" type="text" formControlName="direccion" pInputText>
            </div>
            <div class="p-field p-col-12 p-md-12 ">
                <p-divider align="left">
                    <div class="p-d-inline-flex p-ai-center">
                        <i class="fas fa-user-cog p-mr-2"></i>
                        <b>Informacion de la cuenta de usuario</b>
                    </div>
                </p-divider>
            </div>
            <div class="p-field p-col-12 p-md-6 "
                 *ngIf="api.nodoActual['tipo']=='Distribuidor'">
                <label for="tipo">Tipo Usuario</label>
                <p-dropdown appendTo="body" id="tipo" [options]="mrn.cargos" formControlName="cargo" placeholder="Selecciona un tipo"
                            optionLabel="label" optionValue="value"></p-dropdown>
            </div>
            <div class="p-field p-col-12 p-md-6 ">
                <label for="username">Nombre de Usuario</label>
                <input id="username" #username (input)="mrn.verificar_user_data('',username.value)"
                       formControlName="username" type="text" pInputText>
                <small *ngIf="mrn.formUsuario.value['username']" style="color: {{(mrn.username_available=='Este nombre de usuario ya existe!')?'darkred':'darkgreen'}}"
                       id="username1-help">{{mrn.username_available}}</small>
            </div>
            <div class="p-field p-col-12 p-md-6">
                <label for="pwd">Contraseña</label>
                <span class="p-input-icon-right">
                    <i  *ngIf="!mrn.formUsuario.value['id']" class="fas fa-question-circle"
                       pTooltip="Recuerde que por defecto la contraseña inicial sera el numero de identificacion."></i>
                    <input id="pwd" type="text" formControlName="password2" pInputText>
                </span>
                <small *ngIf="mrn.formUsuario.value['id']" id="pwdsm">Digite una contraseña para cambiarla.</small>
            </div>
            <div class="p-field p-col-12 p-md-6 ">
                <label for="correo">Email</label>
                <input id="correo" type="email" ngModel email="true" formControlName="email" pInputText>
            </div>
        </div>
    </form>
    <p-footer>
        <div style="padding-top: 15px;text-align:right">
            <button *ngIf="mrn.formUsuario.valid"
                    pButton pRipple type="button" label="Guardar" (click)="guardarUsuario()"></button>
        </div>
    </p-footer>
</p-dialog>
<p-dialog header="Funcionalidades" [(visible)]="mrn.verFuncionalidades" [style]="{width: '30vw'}">
    <p-tree [value]="mrn.funcionalidades" selectionMode="single"
            [(selection)]="mrn.funcSeleccionada" [emptyMessage]="' Aun no hay funcionalidades'">
        <ng-template let-node pTemplate="default">
            <span>{{node.funcionalidad}}</span>
        </ng-template>
    </p-tree>
    <p-footer>
        <div style="padding-top: 15px;text-align:right">
            <button *ngIf="mrn.funcSeleccionada && mrn.funcSeleccionada.funcionPadre != null" pButton pRipple
                    type="button" label="Seleccionar Funcionalidad" (click)="mrn.addPermiso($event)"></button>
        </div>
    </p-footer>
</p-dialog>
<p-dialog header="Editar/Crear Zonas" [(visible)]="mrn.verFormZona" [style]="{width: '25vw'}">
    <form [formGroup]="mrn.formZona">
        <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-12 ">
                <label for="nombre">Nombre de la zona</label>
                <input formControlName="nombre" id="nombre" type="text" pInputText>
            </div>
        </div>
    </form>
    <p-footer>
        <div style="padding-top: 15px;text-align:right">
            <button *ngIf="mrn.formZona.valid" pButton pRipple type="button" label="Guardar"
                    (click)="guardarZona()"></button>
        </div>
    </p-footer>
</p-dialog>
<p-dialog header="Adicionar Barrios" [(visible)]="mrn.verFormDetalleZona" [style]="{width: '60vw'}">
    <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-12 p-md-4">
            <label for="nom_mpio">Municipios</label>
            <p-autoComplete id="nom_mpio" [(ngModel)]="ciudad" [suggestions]="mrn.municipiosFiltrados"
                            (onSelect)="mrn.getBarrios(ciudad)"
                            (completeMethod)="buscarCiudad($event)" field="nombre_mpio"
                            [minLength]="1"></p-autoComplete>
            <!--<p-dropdown id="municipios" [options]="mrn.municipios" (onChange)="mrn.getBarrios($event.value)"
                        placeholder="Selecciona un municipio" optionLabel="nombre_mpio" optionValue="codigo_municipio"></p-dropdown>-->
        </div>
        <div class="p-field p-col-12 ">
            <p-pickList [source]="mrn.barrios" [target]="mrn.BarriosSeleccionados" sourceHeader="Barrios o veredas"
                        targetHeader="Seleccionados" [dragdrop]="true"
                        [responsive]="true" [sourceStyle]="{'height':'30rem'}" [targetStyle]="{'height':'30rem'}"
                        filterBy="barrio_vereda"
                        sourceFilterPlaceholder="Buscar por nombre" targetFilterPlaceholder="Buscar por nombre">
                <ng-template let-detalle pTemplate="item">
                    <div class="product-item">
                        <div class="product-list-detail">
                            <h5 class="p-mb-2">{{detalle.barrio_vereda}}</h5>
                            <span class="product-category">{{detalle.comuna_corregimiento}}</span>
                        </div>
                    </div>
                </ng-template>
            </p-pickList>
        </div>
    </div>
    <p-footer>
        <div style="padding-top: 15px;text-align:right">
            <button *ngIf="mrn.BarriosSeleccionados.length" pButton pRipple type="button" label="Guardar"
                    (click)="guardarDetalleZona()"></button>
        </div>
    </p-footer>
</p-dialog>
<p-dialog header="Liberacion de equipos" [(visible)]="mrn.verLiberarEquipo" [style]="{width: '50vw'}">
    <div class="p-grid">
        <div class="p-col-12">
            <form [formGroup]="mrn.formEquipoNodo">
                <div class="p-fluid p-formgrid p-grid">
                    <div class="p-field p-col-12 p-md-6">
                        <label for="fechaAsignacion">Fecha de devolucion</label>
                        <input id="fechaAsignacion" formControlName="fechaDevolucion" type="date" pInputText>
                    </div>
                    <div class="p-field p-col-12 p-md-6">
                        <label for="ordenEntrega">Nº Orden de devolucion</label>
                        <input id="ordenEntrega" formControlName="ordenDevolucion" type="text" pInputText>
                    </div>
                    <div class="p-field p-col-12">
                        <label for="observacion">Observaciones</label>
                        <textarea id="observacion" formControlName="observacionDevolucion" type="text"
                                  pInputText></textarea>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <p-footer style="text-align:right">
        <button *ngIf="mrn.formEquipoNodo.valid"
                pButton pRipple type="button" label="Guardar" (click)="mrn.liberarEquipoNodo()"></button>
    </p-footer>
</p-dialog>

