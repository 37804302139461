<div class="p-grid">
    <div class="p-col-12">
        <p-tabView (onChange)="mrn.historial_solicitudes_saldo = [];this.mrn.dataFiltrada = []">
            <p-tabPanel>
                <ng-template pTemplate="header">
                    <span>Solicitudes de mis aliados</span>
                    <p-badge [style]="{'margin-left': '5px'}" [value]="mrn.solicitudesSaldo.length.toString()"></p-badge>
                </ng-template>
                <div class="card">
                    <p-table dataKey="id" [rowHover]="true" [value]="mrn.solicitudesSaldo">
                        <ng-template pTemplate="caption">
                            <!--<button pTooltip="Reversar saldo" label="Reversar saldo" pButton pRipple type="button" icon="fas fa-history"
                                    class="accion-tabla p-button-rounded p-button-info" (click)="mrn.verAjusteSaldo = true;mrn.getNodosDirectos()"></button>-->
                            <button pTooltip="Actualizar tabla" label="Actualizar tabla" pButton pRipple type="button"
                                    icon="fas fa-sync-alt" (click)="mrn.getSolicitudesSaldo()"
                                    class="accion-tabla p-button-rounded p-button-info"></button>
                        </ng-template>
                        <ng-template pTemplate="header">
                            <tr>
                                <th style="width:5%">Id</th>
                                <th style="width:13%">Fecha solicitud</th>
                                <th style="width:10%">Tipo aliado</th>
                                <th>Tiempo en espera</th>
                                <th>
                                    <p-columnFilter placeholder="Representante legal" type="text" field="nodo.representanteLegal" [showMenu]="false" ></p-columnFilter>
                                </th>
                                <th style="width:15%">
                                    <p-columnFilter field="tipo_transaccion" [showMenu]="false" matchMode="equals">
                                        <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                            <p-dropdown [ngModel]="value" [options]="mrn.formaPago" (onChange)="filter($event.value)" placeholder="Formas de pago" [showClear]="true">
                                                <ng-template let-option pTemplate="item">
                                                    <span>{{option.label}}</span>
                                                </ng-template>
                                            </p-dropdown>
                                        </ng-template>
                                    </p-columnFilter>
                                </th>
                                <th style="width:15%">
                                    <p-columnFilter field="tipoServicio" matchMode="equals" [showMenu]="false">
                                        <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                            <p-dropdown [ngModel]="value" [options]="mrn.tipoServicioTransaccion" (onChange)="filter($event.value)" placeholder="Comisión" [showClear]="true">
                                                <ng-template let-option pTemplate="item">
                                                    <span>{{option.label}}</span>
                                                </ng-template>
                                            </p-dropdown>
                                        </ng-template>
                                    </p-columnFilter>
                                </th>
                                <th style="width:8%">Valor solicitado</th>
                                <!--<th style="width:10%">
                                    <p-columnFilter field="estado" matchMode="equals" [showMenu]="false">
                                        <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                            <p-dropdown [ngModel]="value" [options]="mrn.estadosTransaccion" (onChange)="filter($event.value)" placeholder="Estado" [showClear]="true">
                                                <ng-template let-option pTemplate="item">
                                                    <span>{{option.label}}</span>
                                                </ng-template>
                                            </p-dropdown>
                                        </ng-template>
                                    </p-columnFilter>
                                </th>-->
                                <th style="width:5%"></th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-transaccion>
                            <tr>
                                <td>{{transaccion.id}}</td>
                                <td style="text-align:center">{{transaccion.created_at|date:"dd-MM-yyyy"}} {{transaccion.hour_at|date:'shortTime'}}</td>
                                <td style="text-align:center">{{transaccion.nodo.tipo}}</td>
                                <td style="text-align:center">{{mrn.tiempoTranscurrido(transaccion.hour_at)}}</td>
                                <td>{{transaccion.nodo.representanteLegal}}</td>
                                <td style="text-align: center">{{(transaccion.tipo_transaccion == 'SSC')?'Contado':'Credito'}}</td>
                                <td>{{(transaccion.tipoServicio=='CV')?'Comision venta':'Comision anticipada'}}</td>
                                <td style="text-align: right">
                                    <span style="float: right">$ {{transaccion.valor|number}}</span>
                                </td>
                                <td style="text-align: center">
                                    <i class="accion-tabla fas fa-cog" (click)="this.mrn.crearMenuSaldos(transaccion);menu.toggle($event)"></i>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </p-tabPanel>
            <p-tabPanel  *ngIf="api.nodoActual['id']!=1">
                <ng-template pTemplate="header">
                    <span>Mis solicitudes</span>
                    <p-badge [style]="{'margin-left': '5px'}" [value]="mrn.misSolicitudesSaldo.length.toString()"></p-badge>
                </ng-template>
                <div class="card">
                    <p-table dataKey="id" [rowHover]="true" [value]="mrn.misSolicitudesSaldo">
                        <ng-template pTemplate="caption">
                            <button pTooltip="Solicitud saldo"  pButton pRipple type="button" icon="fas fa-hand-holding-usd"
                                    class="accion-tabla p-button-rounded p-button-info" label="Solicitud saldo"
                                    (click)="mrn.formTransaccion.reset();mrn.verFormTransaccion = true"></button>
                            <button pTooltip="Actualizar tabla" pButton pRipple type="button"
                                    icon="fas fa-sync-alt" (click)="mrn.getMisSolicitudesSaldo()"
                                    class="accion-tabla p-button-rounded p-button-info" label="Actualizar tabla"></button>
                        </ng-template>
                        <ng-template pTemplate="header">
                            <tr>
                                <th style="width:5%">Id</th>
                                <th style="width:13%">Fecha solicitud</th>
                                <th>
                                    <p-columnFilter placeholder="Representante legal" type="text" field="nodo.representanteLegal" [showMenu]="false" ></p-columnFilter>
                                </th>
                                <th style="width:15%">
                                    <p-columnFilter field="tipo_transaccion" [showMenu]="false" matchMode="equals">
                                        <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                            <p-dropdown [ngModel]="value" [options]="mrn.formaPago" (onChange)="filter($event.value)" placeholder="Formas de pago" [showClear]="true">
                                                <ng-template let-option pTemplate="item">
                                                    <span>{{option.label}}</span>
                                                </ng-template>
                                            </p-dropdown>
                                        </ng-template>
                                    </p-columnFilter>
                                </th>
                                <th style="width:15%">
                                    <p-columnFilter field="tipoServicio" matchMode="equals" [showMenu]="false">
                                        <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                            <p-dropdown [ngModel]="value" [options]="mrn.tipoServicioTransaccion" (onChange)="filter($event.value)" placeholder="Comisión" [showClear]="true">
                                                <ng-template let-option pTemplate="item">
                                                    <span>{{option.label}}</span>
                                                </ng-template>
                                            </p-dropdown>
                                        </ng-template>
                                    </p-columnFilter>
                                </th>
                                <th style="width:8%">Valor solicitado</th>
                                <th style="width:10%">Estado de solicitud</th>
                                <th>Estado de pago</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-transaccion>
                            <tr>
                                <td>{{transaccion.id}}</td>
                                <td style="text-align:center">{{transaccion.created_at|date:"dd-MM-yyyy"}} {{transaccion.hour_at|date:'shortTime'}}</td>
                                <td>{{transaccion.nodo.representanteLegal}}</td>
                                <td style="text-align: center">{{(transaccion.tipo_transaccion == 'SSC')?'Contado':'Credito'}}</td>
                                <td>{{(transaccion.tipoServicio=='CV')?'Comision venta':'Comision anticipada'}}</td>
                                <td style="text-align: right">
                                    <span style="float: right">$ {{transaccion.valor|number}}</span>
                                </td>
                                <td style="text-align: center">
                                    <p-chip *ngIf="transaccion.estado=='Aprobado'" label="{{transaccion.estado}}" styleClass="p-mr-2 chip-aprobado"></p-chip>
                                    <p-chip *ngIf="transaccion.estado=='Rechazado'" label="{{transaccion.estado}}" styleClass="p-mr-2 chip-rechazado"></p-chip>
                                    <p-chip *ngIf="transaccion.estado=='Pendiente'" label="{{transaccion.estado}}" styleClass="p-mr-2 chip-pendiente"></p-chip>
                                </td>
                                <td style="text-align: center">
                                    <p-chip *ngIf="transaccion.estadoPago=='Pendiente'" label="{{transaccion.estadoPago}}" styleClass="p-mr-2 chip-pendiente"></p-chip>
                                    <p-chip *ngIf="transaccion.estadoPago=='Pago aceptado'" label="{{transaccion.estadoPago}}" styleClass="p-mr-2 chip-aprobado"></p-chip>
                                    <p-chip *ngIf="transaccion.estadoPago=='Pago en revision'" label="{{transaccion.estadoPago}}" styleClass="p-mr-2 chip-disponible"></p-chip>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </p-tabPanel>
            <p-tabPanel header="Historial de saldos">
                <div class="card">
                    <p-table #dt dataKey="id" [rowHover]="true" (onFilter)="getFilteredData($event)"
                             [value]="mrn.historial_solicitudes_saldo"
                             [globalFilterFields]="['nodo.representanteLegal','tipo_transaccion','tipoServicio','estado']"
                             [scrollable]="true" scrollHeight="400px">
                        <ng-template pTemplate="caption">
                            <div class="p-grid">
                                <div class="p-col-12 p-md-3">
                                    <div class="p-grid p-fluid p-formgrid">
                                        <div class=" p-field p-col-12 p-md-6" >
                                            <label for="fi">Desde</label>
                                            <input type="date" #fInicial pInputText id="fi">
                                        </div>
                                        <div class="p-field p-col-12 p-md-6">
                                            <label for="ff">Hasta</label>
                                            <input type="date" #fFinal pInputText id="ff">
                                        </div>
                                        <div class="p-col-12 p-md-6">
                                            <button *ngIf="fInicial.value && fFinal.value" (click)="mrn.getHistorialSolicitudesSaldo(fInicial.value,fFinal.value)"
                                                    type="button" pButton pRipple styleClass="p-button-danger"
                                                    icon="pi pi-search" label="Consultar" ></button>
                                        </div>
                                        <div *ngIf="fInicial.value && fFinal.value" class="p-col-12 p-md-6" >
                                            <button (click)="fInicial.value = '';fFinal.value = '';mrn.historial_solicitudes_saldo = [];this.mrn.dataFiltrada = []"
                                                    type="button" pButton pRipple
                                                    icon="pi pi-times" label="Limpiar" ></button>
                                        </div>
                                    </div>
                                </div>
                                <div class="p-col-12 p-md-5" style="text-align:right;">

                                </div>
                                <div class="p-col-12 p-md-4" style="text-align:right;">
                                    <div class="p-grid p-fluid p-formgrid">
                                        <div class=" p-field p-col-12 p-md-6" >
                                            <span class="p-input-icon-left ml-auto">
                                                <i class="pi pi-search"></i>
                                                <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')" placeholder="Representante legal" />
                                            </span>
                                        </div>
                                        <div class=" p-field p-col-12 p-md-6" >
                                            <p-dropdown [options]="mrn.formaPagoFilter" (onChange)="dt.filterGlobal($event.value, 'equals')" placeholder="Formas de pago">
                                                <ng-template let-option pTemplate="item">
                                                    <span>{{option.label}}</span>
                                                </ng-template>
                                            </p-dropdown>
                                        </div>
                                        <div class=" p-field p-col-12 p-md-6" >
                                            <p-dropdown [options]="mrn.tipoServicioTransaccion" (onChange)="dt.filterGlobal($event.value, 'equals')" placeholder="Comisión">
                                                <ng-template let-option pTemplate="item">
                                                    <span>{{option.label}}</span>
                                                </ng-template>
                                            </p-dropdown>
                                        </div>
                                        <div class=" p-field p-col-12 p-md-6" >
                                            <p-dropdown [options]="mrn.estadosTransaccion" (onChange)="dt.filterGlobal($event.value, 'equals')" placeholder="Estado">
                                                <ng-template let-option pTemplate="item">
                                                    <span>{{option.label}}</span>
                                                </ng-template>
                                            </p-dropdown>
                                        </div>
                                        <div class="p-col-12 p-md-12" *ngIf="mrn.historial_solicitudes_saldo.length">
                                            <button  type="button" pButton pRipple icon="pi pi-file-excel" (click)="exportExcel()"
                                                     class="p-button-success" label="Exportar a Excel" tooltipPosition="bottom"></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="header">
                            <tr>
                                <th>Tipo</th>
                                <th style="width:5%">Id</th>
                                <th>Fecha aprobacion</th>
                                <th>Representante legal</th>
                                <th>Formas de pago</th>
                                <th *ngIf="api.nodoActual['id']!=1">Saldo inicial</th>
                                <th>Valor</th>
                                <th *ngIf="api.nodoActual['id']!=1">Saldo final</th>
                                <th>Estado solicitud</th>
                                <th>Soporte</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-transaccion>
                            <tr>
                                <td style="text-align: left" *ngIf="transaccion.nodo.id == api.nodoActual['id']">
                                    <span *ngIf="transaccion.tipo_transaccion == 'SSC'" style="font-weight:bold;color:darkgreen">Compra de saldo</span>
                                    <span *ngIf="transaccion.tipo_transaccion == 'SSCR'" style="font-weight:bold;color:darkgreen">Compra de saldo</span>
                                    <span *ngIf="transaccion.tipo_transaccion == 'CJG'" style="font-weight:bold;color:darkgreen">Traslado de ganancias</span>
                                    <span *ngIf="transaccion.tipo_transaccion == 'AJS'" style="font-weight:bold;color:darkgreen">Reversión de saldo</span>
                                </td>
                                <td style="text-align: left" *ngIf="transaccion.nodo.id != api.nodoActual['id']">
                                    <span *ngIf="transaccion.tipo_transaccion == 'SSC'" style="font-weight:bold;color:darkred">Venta de saldo</span>
                                    <span *ngIf="transaccion.tipo_transaccion == 'SSCR'" style="font-weight:bold;color:darkred">Venta de saldo</span>
                                    <span *ngIf="transaccion.tipo_transaccion == 'CJG'" style="font-weight:bold;color:darkgreen">Traslado de ganancias</span>
                                    <span *ngIf="transaccion.tipo_transaccion == 'AJS'" style="font-weight:bold;color:darkgreen">Reversión de saldo</span>
                                </td>
                                <td style="width:5%">{{transaccion.id}}</td>
                                <td style="text-align:center">{{transaccion.fecha_aprobacion|date:"dd-MM-yyyy"}} {{transaccion.hour_at|date:'mediumTime'}}</td>
                                <td>{{transaccion.nodo.representanteLegal}}</td>
                                <td style="text-align: center">
                                    <span *ngIf="transaccion.tipo_transaccion == 'AJS'">n/a</span>
                                    <span *ngIf="transaccion.tipo_transaccion != 'AJS'">{{(transaccion.tipo_transaccion == 'SSC')?'Contado':'Credito'}}</span>
                                </td>
                                <td *ngIf="api.nodoActual['id']!=1" style="text-align: right">
                                    <span *ngIf="transaccion.nodo.id == api.nodoActual['id']" >$ {{transaccion.ultimoSaldo|number}}</span>
                                    <span *ngIf="transaccion.nodo.id != api.nodoActual['id']" >$ {{transaccion.ultimo_saldo_distribuidor|number}}</span>
                                </td>
                                <td style="text-align: right">
                                    <span style="float: right" *ngIf="transaccion.nodo.id == api.nodoActual['id']&& transaccion.tipo_transaccion != 'AJS'" style="font-weight:bold;color:darkgreen">$ {{transaccion.valor|number}}</span>
                                    <span style="float: right" *ngIf="transaccion.nodo.id != api.nodoActual['id']&& transaccion.tipo_transaccion != 'AJS'" style="font-weight:bold;color:darkred">$ {{transaccion.valor|number}}</span>
                                    <span style="float: right" *ngIf="transaccion.nodo.id == api.nodoActual['id']&& transaccion.tipo_transaccion == 'AJS'" style="font-weight:bold;color:darkred">$ {{transaccion.valor|number}}</span>
                                    <span style="float: right" *ngIf="transaccion.nodo.id != api.nodoActual['id']&& transaccion.tipo_transaccion == 'AJS'" style="font-weight:bold;color:darkgreen">$ {{transaccion.valor|number}}</span>
                                </td>
                                <td *ngIf="api.nodoActual['id']!=1" style="text-align: right">
                                    <span *ngIf="transaccion.nodo.id == api.nodoActual['id']" >$ {{transaccion.saldo_actual|number}}</span>
                                    <span *ngIf="transaccion.nodo.id != api.nodoActual['id']" >$ {{transaccion.saldo_actual_distribuidor|number}}</span>
                                </td>
                                <td style="text-align: center">
                                    <span>{{transaccion.estado}}</span>
                                </td>
                                <td>
                                    <span style="cursor: pointer" (click)="mrn.transaccionSeleccionada = transaccion;mrn.verSoporteTransaccion = true" *ngIf="transaccion.soporte ">Ver soporte</span>
                                </td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="summary">
                            <div *ngIf="api.nodoActual['id']==1">
                                <div class="p-fluid p-formgrid p-grid">
                                    <div class="p-field p-col-4">
                                        <div class="total_card_container">
                                            <div class="total_card">
                                                <span class="total_card_title">Venta saldo contado</span>
                                                <span class="total_card-value">${{getTotalVentaContado()|number}}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="p-field p-col-4">
                                        <div class="total_card_container">
                                            <div class="total_card">
                                                <span class="total_card_title">Venta saldo credito</span>
                                                <span class="total_card-value">${{getTotalVentaCredito()|number}}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="p-field p-col-4">
                                        <div class="total_card_container">
                                            <div class="total_card">
                                                <span class="total_card_title">Reversiones a aliados</span>
                                                <span class="total_card-value">${{getTotalVentaReversiones()|number}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div><!--visualizacion cuando es MRN -->
                            <div *ngIf="api.nodoActual['id']!=1">
                                <div class="p-fluid p-formgrid p-grid">
                                    <!--Compras-->
                                    <div class="p-field p-col-3">
                                        <div class="total_card_container">
                                            <div class="total_card">
                                                <span class="total_card_title">Compras saldo contado</span>
                                                <span class="total_card-value">${{getTotalComprasContado()|number}}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="p-field p-col-3">
                                        <div class="total_card_container">
                                            <div class="total_card">
                                                <span class="total_card_title">Compras saldo credito</span>
                                                <span class="total_card-value">${{getTotalComprasCredito()|number}}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="p-field p-col-3">
                                        <div class="total_card_container">
                                            <div class="total_card">
                                                <span class="total_card_title">Traslados de ganancias</span>
                                                <span class="total_card-value">${{getTotalTrasladoGanancias()|number}}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="p-field p-col-3">
                                        <div class="total_card_container">
                                            <div class="total_card">
                                                <span class="total_card_title">Reversiones a aliados</span>
                                                <span class="total_card-value">${{getTotalVentaReversiones()|number}}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <!--Ventas-->
                                    <div class="p-field p-col-3">
                                        <div class="total_card_container">
                                            <div class="total_card">
                                                <span class="total_card_title">Venta saldo contado</span>
                                                <span class="total_card-value">${{getTotalVentaContado()|number}}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="p-field p-col-3">
                                        <div class="total_card_container">
                                            <div class="total_card">
                                                <span class="total_card_title">Venta saldo credito</span>
                                                <span class="total_card-value">${{getTotalVentaCredito()|number}}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="p-field p-col-3">
                                        <div class="total_card_container">
                                            <div class="total_card">
                                                <span class="total_card_title">Reversiones de su distribuidor</span>
                                                <span class="total_card-value">${{getTotalReversionesPropias()|number}}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="p-field p-col-3" *ngIf="mrn.misBoslsasDinero">
                                        <div class="total_card_container">
                                            <div class="total_card">
                                                <span class="total_card_title">Saldo en plataforma</span>
                                                <span class="total_card-value">${{mrn.misBoslsasDinero.saldo_disponible|number}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div><!--visualizacion cuando es Un distribuidor -->
                        </ng-template>
                        <ng-template pTemplate="emptymessage" let-columns>
                            <tr>
                                <td [attr.colspan]="8">
                                    <p-messages severity="info">
                                        <ng-template pTemplate>
                                            <div class="ml-2">Seleccione la fecha inicial y la fecha final para generar una consulta.</div>
                                        </ng-template>
                                    </p-messages>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </p-tabPanel>
        </p-tabView>
    </div>
</div>
<p-menu #menu [popup]="true" [model]="mrn.menuSaldo"></p-menu>
<p-dialog header="Subir soporte de pago" closable="false" closeOnEscape="false" [(visible)]="mrn.verSubirArchivo" [modal]="true" [style]="{width: '30vw'}">
    <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-12 p-md-12">
           <!-- <h5 class="is-size-1">Subir soporte de pago</h5>-->
            <p-fileUpload [customUpload]="true" [uploadLabel]="'Cargar'" [chooseLabel]="'Seleccionar archivo'"
                          [cancelLabel]="'Cancelar'" (uploadHandler)="mrn.subirArchivo($event)"
                          maxFileSize="1000000"></p-fileUpload>
        </div>
    </div>
</p-dialog>
<p-dialog header="Soporte de pago" [(visible)]="mrn.verSoporteTransaccion" [modal]="true">
    <div style="text-align: center;padding: 25px" *ngIf="mrn.transaccionSeleccionada">
        <p-progressSpinner *ngIf="!imgLoaded"></p-progressSpinner>
        <img style="width: 50%" (load)="verificarCargaImg()" src="{{mrn.transaccionSeleccionada.soporte}}" alt="">
    </div>
</p-dialog>
<p-dialog header="Solicitud de saldo para terceros" [(visible)]="mrn.verFormTransaccionTercero" [modal]="true" [style]="{width: '20vw'}">
    <form [formGroup]="mrn.formTransaccion">
        <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-12">
                <h5>Mis aliados directos</h5>
                <p-autoComplete [style]="{'width':'100%'}" [ngModelOptions]="{standalone: true}"
                                [(ngModel)]="mrn.nodoSeleccionado"
                                [suggestions]="nodosFiltrados" (completeMethod)="filtrarNodos($event)"
                                field="representanteLegal" [dropdown]="true">
                    <ng-template let-nodo pTemplate="item">
                        <span>{{nodo.representanteLegal}}</span>
                        <span style="float: right">{{nodo.tipo}}</span>
                    </ng-template>
                </p-autoComplete>
            </div>
            <div class="p-field p-col-12">
                <label for="valor">Valor solicitado</label>
                <p-inputNumber id="valor"  inputId="integeronly"  formControlName="valor"></p-inputNumber>
            </div>
            <div class="p-field p-col-12 p-md-12">
                <label for="tipo_transacciontr">Forma de pago</label>
                <p-dropdown appendTo="body" id="tipo_transacciontr" [options]="mrn.formaPago" formControlName="tipo_transaccion" placeholder="Selecciona un forma de pago"
                            optionLabel="label" optionValue="value"></p-dropdown>
            </div>
            <div class="p-field p-col-12 p-md-12">
                <label for="medioSolicitud">Medio de solicitud</label>
                <p-dropdown appendTo="body" id="medioSolicitud" [options]="mrn.medio_de_solicitud" formControlName="medioSolicitud" placeholder="Selecciona un medio de solicitud"
                            optionLabel="label" optionValue="value"></p-dropdown>
            </div>
        </div>
    </form>
    <p-footer>
        <div style="text-align:right;">
            <button *ngIf="mrn.formTransaccion.valid" pButton pRipple type="button"
                    label="Solicitar saldo" (click)="solicitarSaldoTercero()"></button>
        </div>
    </p-footer>
</p-dialog>
<p-dialog header="Aprobación de solicitud" [(visible)]="mrn.verAprobarSolicitud" [modal]="true" [style]="{width: '55vw'}">
    <div style="text-align: center;padding: 10px" *ngIf="mrn.transaccionSeleccionada">
        <p-progressSpinner *ngIf="!imgLoaded"></p-progressSpinner>
        <div  style="padding: 25px" class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-8">
                <img style="width: 100%" (load)="verificarCargaImg()" src="{{mrn.transaccionSeleccionada.soporte}}" alt="">
            </div>
            <div class="p-field p-col-4">
                <label for="numsop">Escriba aqui el numero de aprobacion que aparece en el soporte de la transaccion.</label>
                <input pInputText id="numsop" [(ngModel)]="mrn.transaccionSeleccionada.numeroAprobacion">
            </div>
        </div>
    </div>
    <p-footer  *ngIf="mrn.transaccionSeleccionada">
        <div style="text-align:right;">
            <button *ngIf="mrn.transaccionSeleccionada.numeroAprobacion" pButton pRipple type="button"
                    label="Aprobar solicitud" [disabled]="mrn.processing"
                    (click)="mrn.dbl_click_prevent();mrn.autorizar_transaccion_contado(mrn.transaccionSeleccionada)"></button>
        </div>
    </p-footer>
</p-dialog>
<p-dialog header="Aprobación de solicitud credito" [(visible)]="mrn.verAprobarSolicitudCredito" [modal]="true" [style]="{width: '55vw'}">
    <div *ngIf="mrn.transaccionSeleccionada">
        <div *ngIf="mrn.transaccionSeleccionada.nodo.mora">
            <p-table  [rowHover]="true" [value]="mrn.facturasMora">
                <ng-template pTemplate="caption">
                    <div style="text-align: center;padding: 15px">
                        <h4>{{mrn.transaccionSeleccionada.nodo.representanteLegal}} tiene {{mrn.facturasMora.length}} factura<span *ngIf="mrn.facturasMora.length>1">s</span> en mora.</h4>
                    </div>
                </ng-template>
                <ng-template pTemplate="header">
                    <tr>
                        <th style="width:15%" >Id</th>
                        <th style="width:20%">Fecha de aprobación</th>
                        <th style="width:20%">Fecha de limite de pago</th>
                        <th style="width:20%">Valor solicitado</th>
                        <th style="width:20%">Dias de mora</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-transaccion>
                    <tr>
                        <td style="text-align: center" class="accion-tabla" (click)="mrn.transaccionSeleccionada = transaccion;mrn.verDetalletransaccion = true">{{transaccion.id}}</td>
                        <td>{{transaccion.fecha_aprobacion|date}}</td>
                        <td>{{transaccion.fecha_pago|date}}</td>
                        <td style="text-align: right">$ {{transaccion.valor|number}}</td>
                        <td style="text-align: center">{{transaccion.dias_mora}} dias</td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
        <p-footer *ngIf="!mrn.transaccionSeleccionada.nodo.mora">
            <div style="text-align:right;">
                <button pButton pRipple type="button"
                        label="Aprobar solicitud" (click)="mrn.autorizar_transaccion_credito(mrn.transaccionSeleccionada)"></button>
            </div>
        </p-footer>
    </div>
</p-dialog>
<p-dialog header="Rechazar solicitud" [(visible)]="mrn.verRechazoSolicitud" [modal]="true">
    <div style="text-align: center;padding: 10px" *ngIf="mrn.transaccionSeleccionada">
        <div  style="padding: 25px" class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-12">
                <label for="obs">Proque motivo se rechaza la solicitud?</label>
                <p-dropdown appendTo="body" id="obs" [options]="mrn.rechazo_opt" [(ngModel)]="mrn.transaccionSeleccionada.observacion" placeholder="Motivo del rechazo"
                            optionLabel="label" optionValue="value"></p-dropdown>
            </div>
        </div>
    </div>
    <p-footer  *ngIf="mrn.transaccionSeleccionada">
        <div style="text-align:right;">
            <button  pButton pRipple type="button"
                    label="Rechzar solicitud" (click)="rechazarSolicitud()"></button>
        </div>
    </p-footer>
</p-dialog>
<p-dialog header="Detalle de transacción" [style]="{width: '30vw'}" [(visible)]="mrn.verDetalletransaccion" [modal]="true">
    <div class="p-grid">
        <div class="p-col-12" style="padding-right:40px ">
            <ul>
                <div *ngIf="mrn.transaccionSeleccionada && mrn.verDetalletransaccion">
                    <li style="list-style: none;padding:6px;color: darkred;text-align: center" *ngIf="mrn.transaccionSeleccionada.dias_mora"><h5 style="font-weight: bold">¡Transacción con {{mrn.transaccionSeleccionada.dias_mora}} dias en mora!</h5></li>
                    <li style="list-style: none;padding:6px"><h5 style="font-weight: bold">Transaccion N° {{mrn.transaccionSeleccionada.id}}</h5></li>
                    <li style="list-style: none;padding:6px" *ngIf="mrn.transaccionSeleccionada.tipo_transaccion == 'SSC'"><span style="font-weight: bold">Numero de aprobacion :</span> <span style="float: right">{{mrn.transaccionSeleccionada.numeroAprobacion}}</span></li>
                    <li style="list-style: none;padding:6px"><span style="font-weight: bold">Valor solicitado :</span> <span style="float: right">$ {{mrn.transaccionSeleccionada.valor|number}}</span></li>
                    <li style="list-style: none;padding:6px" *ngIf="mrn.transaccionSeleccionada.nodo.comisionIncluida"><span style="font-weight: bold">Comision asignada :</span>
                        <span style="float: right">{{mrn.transaccionSeleccionada.nodo.comisionAnticipada}} %</span></li>
                    <li style="list-style: none;padding:6px" *ngIf="mrn.transaccionSeleccionada.nodo.comisionIncluida"><span style="font-weight: bold" >Valor aprobado :</span>
                        <span style="float: right">
                           $ {{((mrn.transaccionSeleccionada.nodo.comisionAnticipada/100)*mrn.transaccionSeleccionada.valor)+mrn.transaccionSeleccionada.valor|number}}</span>
                        <span *ngIf="!mrn.transaccionSeleccionada.nodo.comisionIncluida" style="float: right">{{mrn.transaccionSeleccionada.valor|number}}</span>
                    </li>
                    <li style="list-style: none;padding:6px"><span style="font-weight: bold">Solicitante :</span> <span style="float: right">{{mrn.transaccionSeleccionada.usuario.first_name}} {{mrn.transaccionSeleccionada.usuario.last_name}}</span></li>
                    <li style="list-style: none;padding:6px"><span style="font-weight: bold">Tipo de saldo :</span> <span style="float: right">{{mrn.transaccionSeleccionada.tipoServicio}}</span></li>
                    <li style="list-style: none;padding:6px"><span style="font-weight: bold">Forma de pago :</span> <span style="float: right">{{mrn.transaccionSeleccionada.tipo_transaccion}}</span></li>
                    <li style="list-style: none;padding:6px"><span style="font-weight: bold">Estado :</span> <span style="float: right">{{mrn.transaccionSeleccionada.estado}}</span></li>
                    <li style="list-style: none;padding:6px"><span style="font-weight: bold">Soporte :</span> <span style="float: right">{{mrn.transaccionSeleccionada.soporte?'Si':'No'}}</span></li>
                    <li style="list-style: none;padding:6px"><span style="font-weight: bold">Fecha de solicitud :</span> <span style="float: right">{{mrn.transaccionSeleccionada.created_at|date}}</span></li>
                    <li style="list-style: none;padding:6px"><span style="font-weight: bold">Fecha de aprobacion :</span> <span style="float: right">{{mrn.transaccionSeleccionada.fecha_aprobacion|date}}</span></li>
                    <li style="list-style: none;padding:6px"><span style="font-weight: bold">Fecha límite de pago :</span> <span style="float: right">{{mrn.transaccionSeleccionada.fecha_pago|date}}</span></li>
                    <li style="list-style: none;padding:6px"><span style="font-weight: bold">Estado de pago :</span> <span style="float: right">{{mrn.transaccionSeleccionada.estadoPago}}</span></li>
                    <li style="list-style: none;padding:6px"><span style="font-weight: bold">Dias de mora :</span> <span style="float: right">{{mrn.transaccionSeleccionada.dias_mora}}</span></li>
                </div>
            </ul>
        </div>
    </div>
</p-dialog>
