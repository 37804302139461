import { Injectable } from '@angular/core';

import { AngularFireDatabase, AngularFireList, AngularFireObject } from '@angular/fire/compat/database';
import {ApiService} from "./api";  // Firebase modules for Database, Data list and Single object
export interface Mensaje {
    $key: string;
    mensaje: string;
    url: string;
    usuario: Number
    nodo: Number;
}
@Injectable()
export class RTDatabaseService {
    MensajesRef: AngularFireList<any>;    // Reference to Student data list, its an Observable
    MensajeRef: AngularFireObject<any>;   // Reference to Student object, its an Observable too

    // Inject AngularFireDatabase Dependency in Constructor
    constructor(private db: AngularFireDatabase) { }

    // Create Student
    /*AddStudent(student: Student) {
        this.MensajesRef.push({
            firstName: student.firstName,
            lastName: student.lastName,
            email: student.email,
            mobileNumber: student.mobileNumber
        })
    }*/

    // Fetch Single Student Object
    /*GetStudent(id: string) {
        this.studentRef = this.db.object('students-list/' + id);
        return this.studentRef;
    }*/

    // Fetch Students List
    GetNotificationsList(nodo) {
        this.MensajesRef = this.db.list(nodo.toString());
        return this.MensajesRef;
    }

    // Update Student Object
    /*UpdateStudent(student: Student) {
        this.studentRef.update({
            firstName: student.firstName,
            lastName: student.lastName,
            email: student.email,
            mobileNumber: student.mobileNumber
        })
    }*/

    // Delete Student Object
    /*DeleteStudent(id: string) {
        this.studentRef = this.db.object('students-list/'+id);
        this.studentRef.remove();
    }*/

}
