import {RouterModule} from '@angular/router';
import {NgModule} from '@angular/core';
import {AppMainComponent} from './app.main.component';
import {AppLoginComponent} from './pages/app.login.component';
import {AuthGuard} from "./providers/auth.guard";
import {NonAuthGuard} from "./providers/non-auth.guard";
import {AliadosComponent} from "./pages/aliados/aliados.component";
import {UsuariosComponent} from "./pages/usuarios/usuarios.component";
import {ComisionesComponent} from "./pages/comisiones/comisiones.component";
import {MiComercioComponent} from "./pages/mi-comercio/mi-comercio.component";
import { ConfiguracionComponent } from './pages/configuracion/configuracion.component';
import {EquiposComponent} from "./pages/equipos/equipos.component";
import {SaldosComponent} from "./pages/saldos/saldos.component";
import {PagosComponent} from "./pages/pagos/pagos.component";
import {VentasComponent} from "./pages/ventas/ventas.component";
import {ConsultasComponent} from "./pages/consultas/consultas.component";


@NgModule({
    imports: [
        RouterModule.forRoot([
            {
                path: '', component: AppMainComponent, canActivate: [AuthGuard], canActivateChild: [AuthGuard],
                children: [
                    {path: 'aliados', component: AliadosComponent},
                    {path: 'usuarios', component: UsuariosComponent},
                    {path: 'comisiones', component: ComisionesComponent},
                    {path: 'micomercio', component: MiComercioComponent},
                    {path: 'configuracion', component: ConfiguracionComponent},
                    {path: 'equipos', component: EquiposComponent},
                    {path: 'saldos', component: SaldosComponent},
                    {path: 'pagos', component: PagosComponent},
                    {path: 'ventas', component: VentasComponent},
                    {path: 'consultas', component: ConsultasComponent},
                ]
            },
            {path: 'login', component: AppLoginComponent, canActivate: [NonAuthGuard]},
            {path: '**', redirectTo: '/notfound'},
        ], {scrollPositionRestoration: 'enabled'})
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
