import { Component, OnInit } from '@angular/core';
import {ApiService} from "../../providers/api";
import {Mrn} from "../../providers/mrn";
import {ConfirmationService} from "primeng/api";

@Component({
  selector: 'app-mi-comercio',
  templateUrl: './mi-comercio.component.html',
})
export class MiComercioComponent implements OnInit {

  constructor(public mrn: Mrn, public api:ApiService, private confirmationService: ConfirmationService) { }

  ngOnInit(): void {
    this.mrn.crearControles();
    this.mrn.getMisComisiones(this.api.nodoActual)
    this.mrn.nodoSeleccionado = this.api.nodoActual;
    this.mrn.getUsuarioByNodo()
    this.mrn.getNodoPadre()
    this.mrn.getUsuarioByNodo(this.api.nodoActual)
    this.mrn.getMisSolicitudesSaldo()
    this.mrn.get_direcciones_nodo(this.api.nodoActual)
  }
  ngOnDestroy(): void {
    this.mrn.nodoSeleccionado = '';
  }
  updateDireccion() {
    this.mrn.nodoSeleccionado.direccion = this.mrn.addDireccion(this.mrn.formNodo.value['direccion'],this.mrn.nodoSeleccionado.direccion);
    this.mrn.updateDireccionNodo()
  }
  solicitarSaldo() {
    this.mrn.formTransaccion.patchValue({
      nodo_id: this.api.nodoActual['id'],
      usuario_id: this.api.usuario['id'],
      tipoServicio: this.api.nodoActual['tipo'] == 'Distribuidor' ? this.mrn.formTransaccion.value['tipoServicio'] : this.api.nodoActual['tipoComision'],
      estado: 'Pendiente',
      estadoPago: (this.mrn.formTransaccion.value['tipo_transaccion'] == 'SSCR')?'Pendiente':'Pago en revision',
      medioSolicitud: 'Otro',
      ultimoSaldo: 0,
      dias_mora: 0,
      fecha_aprobacion: null,
      fecha_pago: null,
    });
    if (this.mrn.formTransaccion.value['tipo_transaccion'] == 'SSCR') {
      this.api.post_soap('verificar_numero_sol_cred_dia', this.mrn.formTransaccion.value)
          .subscribe(
              data => {
                this.confirmationService.confirm({
                  message: data,
                  rejectVisible: false,
                  accept: () => {
                    if (data != 'Ha superado el máximo de solicitudes de crédito diarias autorizadas por su distribuidor.') {
                      this.mrn.addTransaccion();
                    }
                  }
                });
              }
          )
    } else {
      this.mrn.addTransaccion();
    }
  }
  verSoporte(transaccion: any) {
    window.open(transaccion.soporte,'blank')
  }
  guardarUsuario() {
    if(this.mrn.formUsuario.value['id']){
      if(this.mrn.formUsuario.value['password2']){
        this.confirmationService.confirm({
          message: 'Quiere cambair la contrasela al usuario '+this.mrn.formUsuario.value['username']+'?',
          accept: () => {
            this.mrn.formUsuario.patchValue({
              password:this.mrn.formUsuario.value['password2']
            });
            this.mrn.updatePwdUsuario(this.mrn.formUsuario.value)
          }
        });
      }else {
        this.mrn.updateUsuario(this.mrn.formUsuario.value)
      }
    }else {
      this.mrn.formUsuario.patchValue({
        password:this.mrn.formUsuario.value['password2'],
        nodo_id:this.api.nodoActual['id'],
        cargo:(this.api.nodoActual['tipo']=='Distribuidor')?this.mrn.formUsuario.value['cargo']:'Auxiliar'
      });
      this.mrn.addUsuario()
    }
  }

  calcularTotal(event) {
    this.mrn.totalFacturasAPagar = 0
    for(let item of this.mrn.facturasSeleccionadas){
      this.mrn.totalFacturasAPagar += parseInt(item.saldo_pendiente_pago)
    }
  }

  propietarioFactura(transaccion: any) {
    if(this.mrn.facturasSeleccionadas.length){
      return transaccion.nodo.id != this.mrn.facturasSeleccionadas[0]['nodo_id'];
    }
  }

  pagoFacturas() {
    this.mrn.verFormPagosMulti = true;
    this.mrn.formPagos.patchValue({
      abono :this.mrn.totalFacturasAPagar
    })
  }

  seleccionarFactura(transaccion: any) {
    this.mrn.transaccionSeleccionada = ''
    this.mrn.transaccionSeleccionada = transaccion;
    this.mrn.ver_pagos_facturas = true
    this.mrn.get_abonos_factura(transaccion)
  }
}
