import { Component, OnInit } from '@angular/core';
import {Mrn} from "../../providers/mrn";
import {ApiService} from "../../providers/api";

@Component({
  selector: 'app-equipos',
  templateUrl: './equipos.component.html',
})
export class EquiposComponent implements OnInit {

  constructor(public mrn: Mrn, public api:ApiService) { }

  ngOnInit(): void {
    this.mrn.crearControles();
    this.mrn.getNodosHijo(this.api.nodoActual)
  }
  ngOnDestroy(): void {
    this.mrn.nodoSeleccionado = '';
  }
  seleccionarNodo() {
    this.mrn.getUsuarioByNodo(this.api.nodoActual)
  }

  guardarAsignacion() {
    if(this.mrn.formEquipoNodo.value['id']){
      this.mrn.updateEquipoNodo()
    }else {
      this.mrn.formEquipoNodo.patchValue({
        usuario_id:this.mrn.usuarioSeleccionado.id,
        equipo_id:this.mrn.equipoSeleccionado.id,
        fechaRetiro:null,
      })
      this.mrn.addEquipoNodo()
    }
  }
}
