<div class="layout-topbar">
    <div class="layout-topbar-wrapper">
        <div class="layout-topbar-left">
            <div class="layout-topbar-logo-wrapper">
                <a href="#" class="layout-topbar-logo">
                    <img src="../assets/layout/images/logo-mirage@2x.png" alt="mirage-layout"/>
                    <span class="app-name">MRN Colombia</span>
                </a>
            </div>
            <a href="#" class="sidebar-menu-button" (click)="appMain.onMenuButtonClick($event)">
                <i class="pi pi-bars"></i>
            </a>
            <a href="#" class="megamenu-mobile-button" (click)="appMain.onMegaMenuMobileButtonClick($event)">
                <i class="pi pi-align-right megamenu-icon"></i>
            </a>
            <a href="#" class="topbar-menu-mobile-button" (click)="appMain.onTopbarMobileMenuButtonClick($event)">
                <i class="pi pi-ellipsis-v"></i>
            </a>
            <div class="layout-megamenu-wrapper">
                <!--<a class="layout-megamenu-button" href="#" (click)="appMain.onMegaMenuButtonClick($event)">
                    <i class="pi pi-users"></i>
                    Proveedores
                </a>
                <ul style="width: 15%" class="layout-megamenu" [ngClass]="{'layout-megamenu-active fadeInDown': appMain.megaMenuActive}">
                    <li>
                        <a href="#">Saldos de proveedores <i class="pi pi-angle-down"></i></a>
                        <ul>
                            <li>
                                <img style="padding:5px;width: 20%" src="assets/logos_operadores/movil_servicios.png" alt="">
                                <span>
                                     <h5>{{mrn.saldo_movilservicios}}</h5>
                                    <span>Movilservicios</span>
                                </span>
                            </li>
                        </ul>
                        <ul>
                            <li>
                                <img style="padding:5px;width: 20%" src="assets/logos_operadores/movil_servicios.png" alt="">
                                <span>
                                     <h5>{{mrn.saldo_movilservicios}}</h5>
                                    <span>Practisistemas</span>
                                </span>
                            </li>
                        </ul>
                        <ul>
                            <li>
                                <img style="padding:5px;width: 20%" src="assets/logos_operadores/movil_servicios.png" alt="">
                                <span>
                                     <h5>{{mrn.saldo_movilservicios}}</h5>
                                    <span>Claro</span>
                                </span>
                            </li>
                        </ul>
                        <ul>
                            <li>
                                <img style="padding:5px;width: 20%" src="assets/logos_operadores/movil_servicios.png" alt="">
                                <span>
                                     <h5>{{mrn.saldo_movilservicios}}</h5>
                                    <span>Movistar</span>
                                </span>
                            </li>
                        </ul>
                    </li>
                </ul>-->
            </div>
        </div>
        <div class="layout-topbar-right fadeInDown">
            <ul class="layout-topbar-actions" *ngIf="mrn.misBoslsasDinero">
               <!--<li #calendar class="topbar-item"
                    [ngClass]="{'active-topmenuitem': appMain.activeTopbarItem === calendar}">
                    <a href="#" (click)="appMain.onTopbarItemClick($event,calendar)">
                        <i class="topbar-icon pi pi-calendar"></i>
                    </a>
                    <ul class="fadeInDown" (click)="appMain.topbarItemClick = true">
                        <li class="layout-submenu-header">
                            <h1>Calendario de actividades</h1>
                        </li>
                        <li class="calendar">
                           <p-calendar dateFormat="mm-dd-yy" [(ngModel)]="mrn.fechas_pago" [inline]="true" selectionMode="multiple">
                              &lt;!&ndash; <ng-template pTemplate="date" let-date let-rowIndex="rowIndex">
                                    <span pTooltip="{{verificarEvento(date.day)?'Pagar factura':''}}">{{date.day}}</span>
                                </ng-template>&ndash;&gt;
                            </p-calendar>
                        </li>
                    </ul>
                </li>--><!--calendar-->
                <li #refresh class="topbar-item profile-item"
                    [ngClass]="{'active-topmenuitem': appMain.activeTopbarItem === refresh}">
                    <a href="#" (click)="appMain.onTopbarItemClick($event,refresh)">
                             <span class="profile-image-wrapper" (click)="refreshStatus()" pTooltip="Refrescar valores">
                                 <i class="fas fa-sync-alt"></i>
                             </span>

                    </a>
                </li><!--refresher-->
                <!--<li #noti class="topbar-item profile-item"
                    [ngClass]="{'active-topmenuitem': appMain.activeTopbarItem === noti}">
                    <a href="#" (click)="appMain.onTopbarItemClick($event,refresh)">
                             <span class="profile-image-wrapper" (click)="mrn.enviar_notificacion(
                             api.nodoActual['id'],15 ,'SSCR',
                             'Prueba de notificacion del nodo: '+ api.nodoActual['id'],
                             'success'
                             )"pTooltip="Refrescar valores"><i class="fas fa-bell"></i></span>

                    </a>
                </li>&lt;!&ndash;prueba notificaciones&ndash;&gt;-->
                <p-divider layout="vertical"></p-divider>
                <li *ngIf="api.nodoActual['id'] != 1&& !api.nodoActual['mora']" #disponible class="topbar-item profile-item"
                    [ngClass]="{'active-topmenuitem': appMain.activeTopbarItem === disponible}">
                    <a href="#" (click)="appMain.onTopbarItemClick($event,disponible)">
                        <span class="profile-image-wrapper">
                                <i class="pi pi-dollar"></i>
                            </span>
                        <span class="profile-info-wrapper">
                                <h3>$ {{(mrn.misBoslsasDinero.saldo_disponible ? mrn.misBoslsasDinero.saldo_disponible : 0)|number}}</h3>
                                <span>Saldo disponible</span>
                        </span>
                    </a>
                    <ul class="profile-item-submenu fadeInDown">
                        <li class="layout-submenu-item" style="cursor: pointer"
                            (click)="mrn.formTransaccion.reset();mrn.verFormTransaccion = true">
                            <div class="menu-text">
                                <p>Solicitar Saldo</p>
                                <span>Solicita saldo a tu distribuidor.</span>
                            </div>
                        </li>
                    </ul>
                </li><!--saldos-->
                <li class="topbar-item profile-item" #utilidad *ngIf="api.nodoActual['id']!='1'"
                    [ngClass]="{'active-topmenuitem': appMain.activeTopbarItem === utilidad}">
                    <a href="#" (click)="appMain.onTopbarItemClick($event,utilidad)">
                        <span class="profile-image-wrapper">
                                <i class="pi pi-wallet"></i>
                            </span>
                        <span class="profile-info-wrapper">
                                <h3>$ {{(mrn.misBoslsasDinero.utilidad ? mrn.misBoslsasDinero.utilidad : 0)|number}}</h3>
                                <span>Mis ganancias</span>
                            </span>
                    </a>
                    <ul class="profile-item-submenu fadeInDown"  *ngIf="api.nodoActual['tipo']=='Distribuidor'&&mrn.misBoslsasDinero.utilidad">
                        <li class="layout-submenu-item" style="cursor: pointer" (click)="mrn.verCanjeGanancia = true;mrn.formTransaccion.reset()">
                            <div class="menu-text">
                                <p>Canjear mis ganancias</p>
                                <span>Pasa tu ganancias a saldo disponible.</span>
                            </div>
                        </li>
                    </ul>
                </li><!--ganancias-->
                <li *ngIf="api.nodoActual['id']==1"  #saldos class="topbar-item profile-item"
                    [ngClass]="{'active-topmenuitem': appMain.activeTopbarItem === saldos}">
                    <a  *ngFor="let item of mrn.proveedores" >
                             <span class="profile-image-wrapper">
                                 <img  src="{{item.logo}}" alt="">
                             </span>
                        <span class="profile-info-wrapper">
                                 <h3>{{item.saldo?item.saldo:0}}</h3>
                                 <span>{{item.nombre_prov}}</span>
                             </span>
                    </a>
                    <!--<ul class="profile-item-submenu fadeInDown">
                        <li class="layout-submenu-item" *ngFor="let item of mrn.proveedores">
                            <img style="width: 20%" src="{{item.logo}}" alt="">
                            <div class="menu-text">
                                <p>{{item.saldo?item.saldo:0}}</p>
                                <span>{{item.nombre_prov}}</span>
                            </div>
                        </li>
                    </ul>-->
                </li>
                <li *ngIf="api.nodoActual['id']!=1" #credito class="topbar-item profile-item"
                    [ngClass]="{'active-topmenuitem': appMain.activeTopbarItem === credito}">
                    <a href="#" (click)="appMain.onTopbarItemClick($event,credito)">
                             <span class="profile-image-wrapper">
                                 <i class="fas fa-handshake"></i>
                             </span>
                        <span class="profile-info-wrapper">
                                 <h3>Crédito</h3>
                                 <span>$ {{(mrn.Micredito ? mrn.Micredito.montoDisponible : 0)|number}}</span>
                             </span>
                    </a>
                    <ul *ngIf="mrn.Micredito" class="profile-item-submenu fadeInDown">
                        <li class="layout-submenu-item" *ngIf="mrn.Micredito.montoDisponible">
                            <p-knob [readonly]="true" [size]="250" [(ngModel)]="mrn.Micredito.montoDisponible"
                                    [min]="0" [max]="mrn.Micredito.montoAutorizado"
                                    valueColor="{{mrn.Micredito.montoDisponible < 100000?'#c4656d':'MediumTurquoise'}}"
                                    rangeColor="SlateGray"
                                    valueTemplate="${{mrn.Micredito.montoDisponible|number}}"></p-knob>
                        </li>
                        <li class="layout-submenu-item">
                            <div class="menu-text">
                                <p>$ {{(mrn.Micredito ? mrn.Micredito.montoAutorizado : 0)|number}}</p>
                                <span>Monto autorizado</span>
                            </div>
                        </li>
                        <li class="layout-submenu-item">
                            <div class="menu-text">
                                <p>$ {{(mrn.Micredito ? mrn.Micredito.montoUtilizado : 0)|number}}</p>
                                <span>Monto utilizado</span>
                            </div>
                        </li>
                        <li class="layout-submenu-item">
                            <div class="menu-text">
                                <p>$ {{(mrn.Micredito ? mrn.Micredito.montoDisponible : 0)|number}}</p>
                                <span>Monto disponible</span>
                            </div>
                        </li>
                    </ul>
                </li><!--credito-->
                <p-divider layout="vertical"></p-divider>
                <!--<li #notificaciones class="topbar-item profile-item"
                    [ngClass]="{'active-topmenuitem': appMain.activeTopbarItem === notificaciones}">
                    <a href="#" (click)="appMain.onTopbarItemClick($event,notificaciones)">
                             <span class="profile-image-wrapper">
                                 <i class="fas fa-bell"></i>
                             </span>

                    </a>
                    <ul *ngIf="mrn.notifications.length" class="profile-item-submenu fadeInDown">
                        <li class="layout-submenu-item" *ngFor="let item of mrn.notifications">
                            <p-messages severity="{{item.severity}}">
                                <ng-template pTemplate>
                                    <i style="font-size:1.5em;margin-right:7px " class="fas fa-info-circle"></i>
                                    <div class="ml-2">{{item.mensaje}}</div>
                                </ng-template>
                            </p-messages>
                        </li>
                    </ul>
                </li>notificaciones-->
                <li #fecha class="topbar-item profile-item"
                    [ngClass]="{'active-topmenuitem': appMain.activeTopbarItem === fecha}">
                    <a href="#" (click)="appMain.onTopbarItemClick($event,fecha);cambiarMensaje()">
                        <span class="profile-image-wrapper">
                                <i class="{{day_detect[2]}}"></i>
                            </span>
                        <span class="profile-info-wrapper">
                                <h3>{{day_detect[1]|date:'short'}}</h3>
                                <span>{{day_detect[0]}} </span>
                        </span>
                    </a>
                    <ul class="profile-item-submenu fadeInDown" style="text-align: center">
                        <li class="layout-submenu-item" style="cursor: pointer;padding: 15px">
                            <p style="font-size: 1.5em;color: gray">{{this.frase}}</p>
                        </li>
                        <li class="layout-submenu-item" style="cursor: pointer;justify-content: right;">
                            <span style="color: gray;font-weight: bold ">"Henry Ford"</span>
                        </li>
                        <li class="layout-submenu-item" style="cursor: pointer;justify-content: center;">
                            <span style="font-size: 1.3em;color: gray;font-weight: bold ">Equipo Mrn Colombia</span>
                        </li>
                    </ul>
                </li><!--fecha-->
                <li #profile class="topbar-item profile-item"
                    [ngClass]="{'active-topmenuitem': appMain.activeTopbarItem === profile}">
                    <a href="#" (click)="appMain.onTopbarItemClick($event,profile)">
                        <span class="profile-info-wrapper">
                                 <h3>{{api.nodoActual['razonSocial']}}</h3>
                                 <span>{{api.nodoActual['tipo']}}</span>

                             </span>
                    </a>
                    <ul class="profile-item-submenu fadeInDown">
                        <li class="profile-submenu-header">
                            <div class="performance">
                                <span></span>
                            </div>
                            <div class="profile">
                                <h1>{{api.usuario['first_name']}}</h1>
                                <span>{{api.usuario['cargo']}}</span>
                            </div>
                        </li>
                        <li class="layout-submenu-item"
                            (click)="mrn.llenarformUsuario(api.usuario);mrn.verFormUsuario = true">
                            <div class="menu-text">
                                <p>Información de usuario</p>
                            </div>
                            <i class="pi pi-angle-right"></i>
                        </li>
                        <li class="layout-submenu-item"
                            (click)="mrn.llenarFormNodo(api.nodoActual);mrn.verFormEditNodo = true;">
                            <div class="menu-text">
                                <p>Información de empresa</p>
                            </div>
                            <i class="pi pi-angle-right"></i>
                        </li>
                        <li class="layout-submenu-footer">
                            <button class="signout-button" (click)="api.logOut()">Salir</button>
                        </li>
                    </ul>
                </li><!--connected_user-->
                <!--<li #search class="search-item topbar-item" [ngClass]="{'active-topmenuitem': appMain.activeTopbarItem === search}">
                    <a href="#" class="topbar-search-mobile-button" (click)="appMain.onTopbarItemClick($event,search)">
                        <i class="topbar-icon pi pi-search"></i>
                    </a>
                    <ul class="search-item-submenu fadeInDown" (click)="appMain.topbarItemClick = true">
                        <li>
                            <span class="md-inputfield search-input-wrapper">
                                <input pInputText placeholder="Search..."/>
                                <i class="pi pi-search"></i>
                            </span>
                        </li>
                    </ul>
                </li>-->
                <!--<li #message class="topbar-item" [ngClass]="{'active-topmenuitem': appMain.activeTopbarItem === message}">
                    <a href="#" (click)="appMain.onTopbarItemClick($event,message)">
                        <i class="topbar-icon pi pi-inbox"></i>
                    </a>
                    <ul class="fadeInDown">
                        <li class="layout-submenu-header">
                            <h1>Messages</h1>
                            <span>Today, you have new 4 messages</span>
                        </li>
                        <li class="layout-submenu-item">
                            <img src="assets/layout/images/topbar/avatar-cayla.png" alt="mirage-layout" width="35" />
                            <div class="menu-text">
                                <p>Override the digital divide</p>
                                <span>Cayla Brister</span>
                            </div>
                            <i class="pi pi-angle-right"></i>
                        </li>
                        <li class="layout-submenu-item">
                            <img src="assets/layout/images/topbar/avatar-gabie.png" alt="mirage-layout" width="35" />
                            <div class="menu-text">
                                <p>Nanotechnology immersion</p>
                                <span>Gabie Sheber</span>
                            </div>
                            <i class="pi pi-angle-right"></i>
                        </li>
                        <li class="layout-submenu-item">
                            <img src="assets/layout/images/topbar/avatar-gaspar.png" alt="mirage-layout" width="35" />
                            <div class="menu-text">
                                <p>User generated content</p>
                                <span>Gaspar Antunes</span>
                            </div>
                            <i class="pi pi-angle-right"></i>
                        </li>
                        <li class="layout-submenu-item">
                            <img src="assets/layout/images/topbar/avatar-tatiana.png" alt="mirage-layout" width="35" />
                            <div class="menu-text">
                                <p>The holistic world view</p>
                                <span>Tatiana Gagelman</span>
                            </div>
                            <i class="pi pi-angle-right"></i>
                        </li>
                    </ul>
                </li>-->
                <!--<li #gift class="topbar-item" [ngClass]="{'active-topmenuitem': appMain.activeTopbarItem === gift}">
                    <a href="#" (click)="appMain.onTopbarItemClick($event,gift)">
                        <i class="topbar-icon pi pi-envelope"></i>
                    </a>
                    <ul class="fadeInDown">
                        <li class="layout-submenu-header">
                            <h1>Deals</h1>
                        </li>

                        <li class="deals">
                            <ul>
                                <li>
                                    <img src="assets/layout/images/topbar/deal-icon-sapphire.png" alt="mirage-layout" width="35" />
                                    <div class="menu-text">
                                        <p>Sapphire</p>
                                        <span>Angular</span>
                                    </div>
                                    <i class="pi pi-angle-right"></i>
                                </li>
                                <li>
                                    <img src="assets/layout/images/topbar/deal-icon-roma.png" alt="mirage-layout" width="35" />
                                    <div class="menu-text">
                                        <p>Roma</p>
                                        <span>Minimalism</span>
                                    </div>
                                    <i class="pi pi-angle-right"></i>
                                </li>
                                <li>
                                    <img src="assets/layout/images/topbar/deal-icon-babylon.png" alt="mirage-layout" width="35" />
                                    <div class="menu-text">
                                        <p>Babylon</p>
                                        <span>Powerful</span>
                                    </div>
                                    <i class="pi pi-angle-right"></i>
                                </li>
                            </ul>
                            <ul>
                                <li>
                                    <img src="assets/layout/images/topbar/deal-icon-harmony.png" alt="mirage-layout" width="35" />
                                    <div class="menu-text">
                                        <p>Harmony</p>
                                        <span>USWDS</span>
                                    </div>
                                    <i class="pi pi-angle-right"></i>
                                </li>
                                <li>
                                    <img src="assets/layout/images/topbar/deal-icon-prestige.png" alt="mirage-layout" width="35" />
                                    <div class="menu-text">
                                        <p>Prestige</p>
                                        <span>Elegancy</span>
                                    </div>
                                    <i class="pi pi-angle-right"></i>
                                </li>
                                <li>
                                    <img src="assets/layout/images/topbar/deal-icon-ultima.png" alt="mirage-layout" width="35" />
                                    <div class="menu-text">
                                        <p>Ultima</p>
                                        <span>Material</span>
                                    </div>
                                    <i class="pi pi-angle-right"></i>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </li>-->
                <!-- <li #profile class="topbar-item profile-item"
                     [ngClass]="{'active-topmenuitem': appMain.activeTopbarItem === profile}">
                     <a href="#" (click)="appMain.onTopbarItemClick($event,profile)">
                             <span class="profile-image-wrapper">
                                 <i class="{{api.nodoActual['icon']}}"></i>
                             </span>
                         <span class="profile-info-wrapper">
                                 <h3>{{api.nodoActual['razonSocial']}}</h3>
                                 <span>{{api.nodoActual['tipo']}}</span>
                             </span>
                     </a>
                     <ul class="profile-item-submenu fadeInDown">
                         <li class="profile-submenu-header">
                             <div class="performance">
                                 <span></span>
                             </div>
                             <div class="profile">
                                 <h1>{{api.usuario['first_name']}} {{api.usuario['last_name']}}</h1>
                                 <span>{{api.usuario['cargo']}}</span>
                             </div>
                         </li>
                         <li class="layout-submenu-item">
                             <i class="pi pi-user icon icon-1"></i>
                             <div class="menu-text">
                                 <p>Perfil</p>
                             </div>
                             <i class="pi pi-angle-right"></i>
                         </li>
                         <li class="layout-submenu-item">
                             <i class="pi pi-cog icon icon-2"></i>
                             <div class="menu-text">
                                 <p>Configuracion</p>
                             </div>
                             <i class="pi pi-angle-right"></i>
                         </li>
                         &lt;!&ndash;<li class="layout-submenu-item">
                             <i class="pi pi-sitemap icon icon-3"></i>
                             <div class="menu-text">
                                 <p>Red de Distribucion</p>
                             </div>
                             <i class="pi pi-angle-right"></i>
                         </li>&ndash;&gt;
                         <li class="layout-submenu-footer">
                             <button class="signout-button" (click)="api.logOut()">Salir</button>
                             &lt;!&ndash;<button class="buy-mirage-button">Buy Mirage</button>&ndash;&gt;
                         </li>
                     </ul>
                 </li>
                 <li>
                     <a href="#" class="layout-rightpanel-button" (click)="appMain.onRightPanelButtonClick($event)">
                         <i class="pi pi-arrow-left"></i>
                     </a>
                 </li>-->
            </ul>
            <!--<ul class="profile-mobile-wrapper">
                <li #mobileProfile class="topbar-item profile-item"
                    [ngClass]="{'active-topmenuitem': appMain.activeTopbarItem === mobileProfile}">
                    <a href="#" (click)="appMain.onTopbarItemClick($event,mobileProfile)">
                            <span class="profile-image-wrapper">
                                <img src="assets/layout/images/topbar/avatar-eklund.png" alt="mirage-layout"/>
                            </span>
                        <span class="profile-info-wrapper">
                                <h3>Olivia Eklund</h3>
                                <span>Design</span>
                            </span>
                    </a>
                    <ul class="fadeInDown">
                        <li class="profile-submenu-header">
                            <div class="performance">
                                <span>Weekly Performance</span>
                                <img src="assets/layout/images/topbar/asset-bars.svg" alt="mirage-layout"/>
                            </div>
                            <div class="profile">
                                <img src="assets/layout/images/topbar/avatar-eklund.png" alt="mirage-layout"
                                     width="45"/>
                                <h1>Olivia Eklund</h1>
                                <span>Design</span>
                            </div>
                        </li>
                        <li>
                            <i class="pi pi-list icon icon-1"></i>
                            <div class="menu-text">
                                <p>Tasks</p>
                                <span>3 open issues</span>
                            </div>
                            <i class="pi pi-angle-right"></i>
                        </li>
                        <li>
                            <i class="pi pi-shopping-cart icon icon-2"></i>
                            <div class="menu-text">
                                <p>Payments</p>
                                <span>24 new</span>
                            </div>
                            <i class="pi pi-angle-right"></i>
                        </li>
                        <li>
                            <i class="pi pi-users icon icon-3"></i>
                            <div class="menu-text">
                                <p>Clients</p>
                                <span>+80%</span>
                            </div>
                            <i class="pi pi-angle-right"></i>
                        </li>
                        <li class="layout-submenu-footer">
                            <button class="signout-button">Sign Out</button>
                            <button class="buy-mirage-button">Buy Mirage</button>
                        </li>
                    </ul>
                </li>
            </ul>-->
        </div>
    </div>
</div>
<p-dialog header="Editar aliado" [(visible)]="mrn.verFormEditNodo" [style]="{width: '40vw'}">
    <div class="card">
        <h5>Aliado Comercial</h5>
        <form [formGroup]="mrn.formNodo">
            <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-12 p-md-12 ">
                    <p-divider align="left">
                        <div class="p-d-inline-flex p-ai-center">
                            <i class="fas fa-building p-mr-2"></i>
                            <b>Información de empresa</b>
                        </div>
                    </p-divider>
                </div>
                <div class="p-field p-col-12 p-md-6">
                    <label for="tipoe">Tipo Aliado</label>
                    <input readonly formControlName="tipo" id="tipoe" type="text" pInputText>
                </div>
                <div class="p-field p-col-12 p-md-6">
                    <label for="tipo_personae">Tipo persona</label>
                    <input readonly formControlName="tipo_persona" id="tipo_personae" type="text" pInputText>
                </div>
                <div class="p-field p-col-12 p-md-6">
                    <label for="razonSociale">Razon Social</label>
                    <input formControlName="razonSocial" id="razonSociale" type="text" pInputText>
                </div>
                <div class="p-field p-col-12 p-md-6">
                    <label for="identificacion_nodoe">Nit ó Cedula</label>
                    <input formControlName="identificacion_nodo" #identificacion
                           (input)="mrn.verificar_user_data(identificacion.value,'')" id="identificacion_nodoe"
                           type="number" pInputText>
                    <small style="color: {{(mrn.identificacion_available=='Este número de identificacion ya existe!')?'darkred':'darkgreen'}}" id="id-helpe">{{mrn.identificacion_available}}</small>
                </div>
                <!--<div class="p-field p-col-12 p-md-6">
                    <label for="ciudad">Departamento / Municipio</label>
                    <p-dropdown id="ciudad" [options]="mrn.municipiosFiltrados" [(ngModel)]="mrn.ciudad"
                                [ngModelOptions]="{standalone: true}"
                                optionLabel="nombre_mpio" optionValue="codigo_municipio"
                                [filter]="true" filterBy="nombre_mpio" [showClear]="true"
                                placeholder="Seleccione un municipio" (onChange)="mrn.getBarrios(mrn.ciudad)">
                    </p-dropdown>

                </div>
                <div class="p-field p-col-12 p-md-6" *ngIf="mrn.barrios.length">
                    <label for="barrioe">Barrio ó Vereda</label>
                    <p-dropdown id="barrioe" [options]="mrn.barrios" formControlName="barrio_id" optionLabel="barrio_vereda" optionValue="id"
                                [filter]="true" filterBy="barrio_vereda" [showClear]="true"
                                placeholder="Seleccione un Barrio">
                    </p-dropdown>
                </div>-->
               <!-- <div class="p-field p-col-12 p-md-6">
                    <label for="direccione">Direccion</label>
                    <input id="direccione" type="text" formControlName="direccion" pInputText>
                </div>-->
                <div class="p-field p-col-12 p-md-12 ">
                    <p-divider align="left">
                        <div class="p-d-inline-flex p-ai-center">
                            <i class="fas fa-user p-mr-2"></i>
                            <b>Informacion del representante legal</b>
                        </div>
                    </p-divider>
                </div>
                <div class="p-field p-col-12 p-md-6">
                    <label for="representanteLegale">Representante legal</label>
                    <input formControlName="representanteLegal" id="representanteLegale" type="text" pInputText>
                </div>
                <div class="p-field p-col-12 p-md-6">
                    <label for="telefonoe">Número de telefono celular</label>
                    <p-inputMask id="telefonoe" mask="999-999-99-99" formControlName="telefono"
                                 [unmask]="true"></p-inputMask>
                </div>
                <div class="p-field p-col-12 p-md-6">
                    <label for="emaile">Email</label>
                    <input id="emaile" type="email" formControlName="email" pInputText>
                </div>
                <!--<div class="p-field p-col-12 p-md-6" *ngIf="api.nodoActual['tipo'] == 'Comercio'">
                    <label for="clave_ven">Clave de ventas</label>
                    <input id="clave_ven" type="text" formControlName="clave_venta" pInputText>
                </div>-->
            </div>
        </form>
    </div>
    <p-footer>
        <div style="text-align:right;">
            <button *ngIf="mrn.formNodo.valid" pButton pRipple type="button"
                    label="Editar Aliado" (click)="mrn.updateNodo(mrn.formNodo.value)"></button>
        </div>
    </p-footer>
</p-dialog>
<p-dialog header="Editar datos de usuario" [(visible)]="mrn.verFormUsuario" [style]="{width: '35vw'}">
    <form [formGroup]="mrn.formUsuario">
        <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-6 ">
                <label for="tipo">Tipo Usuario</label>
                <p-dropdown id="tipo" [options]="mrn.cargos" formControlName="cargo" placeholder="Selecciona un tipo"
                            optionLabel="label" optionValue="value"></p-dropdown>
            </div>
            <div class="p-field p-col-6 " *ngIf="mrn.formUsuario.value['id']">
                <label for="iden">Identificacion</label>
                <input formControlName="identificacion" readonly id="iden" type="number" pInputText>
            </div>
            <div class="p-field p-col-6 " *ngIf="!mrn.formUsuario.value['id']">
                <label for="identificacion">Identificacion</label>
                <input formControlName="identificacion" id="identificacion" type="number" pInputText>
            </div>
            <div class="p-field p-col-6 ">
                <label for="first_name">Nombres</label>
                <input id="first_name" formControlName="first_name" type="text" pInputText>
            </div>
            <div class="p-field p-col-6 " *ngIf="mrn.formUsuario.value['id']">
                <label for="user">Nombre de Usuario</label>
                <input id="user" formControlName="username" readonly type="text" pInputText>
            </div>
            <div class="p-field p-col-6 " *ngIf="!mrn.formUsuario.value['id']">
                <label for="username">Nombre de Usuario</label>
                <input id="username" formControlName="username" type="text" pInputText>
            </div>
            <div class="p-field p-col-6 ">
                <label for="tele">Telefono</label>
                <input id="tele" formControlName="telefono" type="number" pInputText>
            </div>
            <div class="p-field p-col-6 ">
                <label for="correo">Email</label>
                <input id="correo" type="email" formControlName="email" pInputText>
            </div>
            <div class="p-field p-col-6">
                <label for="pwd">Contraseña</label>
                <input id="pwd" type="text" formControlName="password2" pInputText>
                <small id="pwdsm">Digite una contraseña para cambiarla.</small>
            </div>
            <div class="p-field p-col-12 ">
                <label for="dir">Direccion</label>
                <input id="dir" type="text" formControlName="direccion" pInputText>
            </div>
        </div>
    </form>
    <p-footer>
        <div style="padding-top: 15px;text-align:right">
            <button *ngIf="mrn.formUsuario.valid"
                    pButton pRipple type="button" label="Guardar" (click)="guardarUsuario()"></button>
        </div>
    </p-footer>
</p-dialog>
<p-dialog header="Solicitud de saldo" [(visible)]="mrn.verFormTransaccion" [modal]="true" [style]="{width: '30vw'}">
    <form [formGroup]="mrn.formTransaccion">
        <div class="p-fluid p-formgrid p-grid">
            <div  class="p-field p-col-12 p-md-12">
                <p-divider align="left">
                    <div class="inline-flex align-items-center">
                        <i class="fas fa-dollar-sign mr-2 accion-tabla"></i>
                        <b>1. Valor a solicitar</b>
                    </div>
                </p-divider>
            </div>
            <div *ngIf="api.nodoActual['tipo']=='Comercio'" class="p-field p-col-12 p-md-4">
                <button pButton pRipple type="button" (click)="mrn.formTransaccion.get('valor').setValue(20000);" label="$ 20.000"
                        class="{{valorVenta ==10000?'p-button-info':'p-button-outlined'}} p-button-rounded"></button>
            </div>
            <div *ngIf="api.nodoActual['tipo']=='Comercio'" class="p-field p-col-12 p-md-4">
                <button pButton pRipple type="button" (click)="mrn.formTransaccion.get('valor').setValue(30000);" label="$ 30.000"
                        class="{{valorVenta ==30000?'p-button-info':'p-button-outlined'}} p-button-rounded"></button>
            </div>
            <div *ngIf="api.nodoActual['tipo']=='Comercio'" class="p-field p-col-12 p-md-4">
                <button pButton pRipple type="button" (click)="mrn.formTransaccion.get('valor').setValue(40000);" label="$ 40.000"
                        class="{{valorVenta ==40000?'p-button-info':'p-button-outlined'}} p-button-rounded"></button>
            </div>
            <div *ngIf="api.nodoActual['tipo']=='Comercio'" class="p-field p-col-12 p-md-4">
                <button pButton pRipple type="button" (click)="mrn.formTransaccion.get('valor').setValue(50000);" label="$ 50.000"
                        class="{{valorVenta ==50000?'p-button-info':'p-button-outlined'}} p-button-rounded"></button>
            </div>
            <div *ngIf="api.nodoActual['tipo']=='Comercio'" class="p-field p-col-12 p-md-4">
                <button pButton pRipple type="button" (click)="mrn.formTransaccion.get('valor').setValue(60000);" label="$ 60.000"
                        class="{{valorVenta ==60000?'p-button-info':'p-button-outlined'}} p-button-rounded"></button>
            </div>
            <div *ngIf="api.nodoActual['tipo']=='Comercio'" class="p-field p-col-12 p-md-4">
                <button pButton pRipple type="button" (click)="mrn.formTransaccion.get('valor').setValue(70000);" label="$ 70.000"
                        class="{{valorVenta ==70000?'p-button-info':'p-button-outlined'}} p-button-rounded"></button>
            </div>
            <div *ngIf="api.nodoActual['tipo']=='Comercio'" class="p-field p-col-12 p-md-4">
                <button pButton pRipple type="button" (click)="mrn.formTransaccion.get('valor').setValue(80000);" label="$ 80.000"
                        class="{{valorVenta ==80000?'p-button-info':'p-button-outlined'}} p-button-rounded" ></button>
            </div>
            <div *ngIf="api.nodoActual['tipo']=='Comercio'" class="p-field p-col-12 p-md-4">
                <button pButton pRipple type="button" (click)="mrn.formTransaccion.get('valor').setValue(100000);" label="$ 100.000"
                        class="{{valorVenta ==100000?'p-button-info':'p-button-outlined'}} p-button-rounded"></button>
            </div>
            <div class="p-field p-col-12">
                <label for="valora">{{api.nodoActual['tipo']=='Comercio'?'Otro valor':'Valor a solicitar'}}</label>
                <p-inputNumber id="valora" inputId="integeronly"
                               formControlName="valor"></p-inputNumber>
                <div *ngIf="mrn.formTransaccion.controls['valor'].invalid && (mrn.formTransaccion.controls['valor'].dirty || mrn.formTransaccion.controls['valor'].touched)">
                    <p slot="end" style="font-size: 0.9em;color: gray" *ngIf="mrn.formTransaccion.controls['valor'].errors.min">
                        Valor minimo de solicitud de saldo $20.000
                    </p>
                </div>
            </div>
            <div  class="p-field p-col-12 p-md-12">
                <p-divider align="left">
                    <div class="inline-flex align-items-center">
                        <i class="fas fa-calculator mr-2 accion-tabla"></i>
                        <b>2. Cálculo de utilidad</b>
                    </div>
                </p-divider>
            </div>
            <div class="p-field p-col-12 p-md-12" style="padding-bottom: 20px">
                <table class="tabla_calculadora">
                    <tr *ngIf="mrn.misBoslsasDinero">
                        <td>Saldo disponible</td>
                        <th style="text-align:right">$ {{(mrn.misBoslsasDinero.saldo_disponible ? mrn.misBoslsasDinero.saldo_disponible : 0)|number}}</th>
                    </tr>
                    <tr>
                        <td>Valor solicitado</td>
                        <th style="text-align:right">$ {{valorVenta|number}}</th>
                    </tr>
                    <tr *ngIf="api.nodoActual['tipoComision']=='CA'">
                        <td>Mi utilidad</td>
                        <th style="text-align:right">$ {{((api.nodoActual['comisionAnticipada']/100)*valorVenta)|number}}</th>
                    </tr>

                </table>
            </div>
            <div  class="p-field p-col-12 p-md-12">
                <p-divider align="left">
                    <div class="inline-flex align-items-center">
                        <i class="fas fa-hand-holding-usd mr-2 accion-tabla"></i>
                        <b>3. Forma de pago</b>
                    </div>
                </p-divider>
            </div>
            <div class="p-field p-col-12 p-md-12">
                <label for="tipo_transaccion">Forma de pago</label>
                <p-dropdown appendTo="body" id="tipo_transaccion" [options]="mrn.formaPago"
                            formControlName="tipo_transaccion" placeholder="Selecciona un forma de pago"
                            optionLabel="label" optionValue="value"></p-dropdown>
            </div>
        </div>
    </form>
    <p-footer>
        <div style="text-align:right;">
            <button *ngIf="mrn.formTransaccion.valid && mrn.formTransaccion.value['tipo_transaccion']" [disabled]="mrn.processing" pButton pRipple type="button"
                    label="Solicitar saldo" (click)="mrn.dbl_click_prevent();solicitarSaldo()"></button>
        </div>
    </p-footer>
</p-dialog>
<p-dialog header="Canjear ganancias" [(visible)]="mrn.verCanjeGanancia" [modal]="true" [style]="{width: '20vw'}">
    <form [formGroup]="mrn.formTransaccion" *ngIf="mrn.misBoslsasDinero">
        <div style="text-align:center;padding: 5px">
            <span>Ganancia disponible</span>
            <h3>$ {{(mrn.misBoslsasDinero ? mrn.misBoslsasDinero.utilidad : 0)|number}}</h3>
        </div>
        <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-12">
                <label for="valorcj">Valor a canjear</label>
                <p-inputNumber id="valorcj" inputId="integeronly"
                               [max]="mrn.misBoslsasDinero.utilidad"
                               [min]="0"
                               formControlName="valor"></p-inputNumber>
            </div>
        </div>
    </form>
    <p-footer>
        <div style="text-align:right;">
            <button pButton pRipple type="button" [disabled]="mrn.processing"
                    label="Canjear Ganancias" (click)="mrn.dbl_click_prevent(); canjearGanancia()"></button>
        </div>
    </p-footer>
</p-dialog>
<p-dialog header="Subir soporte de pago" closable="false" closeOnEscape="false" [(visible)]="mrn.verSubirArchivo" [modal]="true" [style]="{width: '30vw'}">
    <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-12 p-md-12">
            <!-- <h5 class="is-size-1">Subir soporte de pago</h5>-->
            <p-fileUpload [customUpload]="true" [uploadLabel]="'Cargar'" [chooseLabel]="'Seleccionar archivo'"
                          [cancelLabel]="'Cancelar'" (uploadHandler)="mrn.subirArchivo($event)"
                          maxFileSize="1000000"></p-fileUpload>
        </div>
    </div>
</p-dialog>
<!--<p-dialog header="Soporte de pago" [(visible)]="mrn.verSoporte" [modal]="true">
    <div style="text-align: center;padding: 25px" *ngIf="mrn.transaccionSeleccionada">
        <p-progressSpinner *ngIf="!imgLoaded"></p-progressSpinner>
        <img (load)="verificarCargaImg()" src="{{mrn.transaccionSeleccionada.soporte}}" alt="">
    </div>
</p-dialog>-->

