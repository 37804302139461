<div class="layout-wrapper" (click)="onLayoutClick()"
     [ngClass]="{'layout-horizontal': app.horizontalMenu,
                'layout-wrapper-static': staticMenuActive,
                'layout-rightpanel-active': rightPanelActive,
                'layout-topbar-mobile-active': topbarMobileMenuActive,
                'layout-megamenu-mobile-active': megaMenuMobileActive,
                'layout-sidebar-mobile-active': menuMobileActive,
                'p-input-filled': app.inputStyle === 'filled',
                'p-ripple-disabled': !app.ripple}" [class]="app.menuColor">

    <app-menu></app-menu>

    <div class="layout-main">
        <p-confirmDialog acceptLabel="Aceptar" header="MRN Colombia" ></p-confirmDialog>

        <p-dialog header="MRN Colombia" [modal]="true"  [(visible)]="mrn.loading" [closable]="false">
            <div style="padding: 25px;text-align: center" class="p-grid" [style]="{width: '20vw'}">
                <div class="p-col-12">
                    <div class="lds-grid"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                </div>
                <div style="padding: 25px;" class="p-col-12">
                    Un momento por favor...
                </div>
            </div>
        </p-dialog>

        <p-toast></p-toast>

        <app-topbar></app-topbar>

        <app-rightpanel></app-rightpanel>

        <!--<p-progressBar *ngIf="mrn.loading" [style]="{'height': '6px'}" mode="indeterminate"></p-progressBar>-->
       <!-- <app-breadcrumb></app-breadcrumb>-->

        <div class="layout-content">
            <router-outlet></router-outlet>
        </div>

        <!--<app-footer></app-footer>-->

        <!--<app-config></app-config>-->

        <div class="layout-main-mask"></div>
    </div>
</div>
