import { Component, OnInit } from '@angular/core';
import {Mrn} from "../../providers/mrn";
import {ApiService} from "../../providers/api";
import {ConfirmationService} from "primeng/api";
import {error} from "protractor";

@Component({
  selector: 'app-ventas',
  templateUrl: './ventas.component.html',
})
export class VentasComponent implements OnInit {
  valorVenta;
  email;
  prueba = false;
  celular;
  convenios = []
  convenio_seleccionado: any;
  recibo: any;
  convenios_filtrados: any[];
  factura_pagada: any;
  ver_resumen_transaccion: boolean = false;
  ver_resumen_convenio: boolean = false;
  telefono_convenio = ''
  valor_factura = ''
  venta_ganancias:boolean;
  clave_ventas: any;
  constructor(public mrn: Mrn, public api: ApiService, private confirmationService: ConfirmationService) {
  }

  ngOnInit(): void {
    this.mrn.crearControles();
    this.mrn.messageSell = [];
    this.mrn.empresaSeleccionada = '';
    this.mrn.categoriaSeleccionada = '';
    this.prueba_conexion_practi()
  }
  seleccionarEmpresa(empresa: any) {
    this.mrn.empresaSeleccionada = empresa['info'].proveedorEmpresa.empresa;
    this.mrn.proveedorSeleccionado = empresa['info'].proveedorEmpresa.proveedor;
    this.mrn.togleVentas(0,false)
    this.mrn.togleVentas(1,true)
    this.mrn.getProductosProveedorAlternativa(empresa['info'].proveedorEmpresa.proveedor.id,this.mrn.empresaSeleccionada.id)
    if(this.mrn.empresaSeleccionada.catServicio.nombre == 'Recaudos'){
        this.mrn.verInfoAdicionalFacturas = true
    }
  }

  cambiarValor(valor: any) {
    this.mrn.productoCodificadoSeleccionado = ''
    if(valor != this.mrn.formVentasRecargas.value['valor']){
      this.valorVenta = valor
      this.mrn.formVentasRecargas.patchValue({
        valor:valor
      })
    }else {
      this.valorVenta = 0
      this.mrn.formVentasRecargas.patchValue({
        valor:0
      })
    }
  }

  seleccionarPaquete() {
    if(this.mrn.formVentasRecargas.value['telefono'].length>=10){
      this.valorVenta = 0
      this.mrn.formVentasRecargas.patchValue({
        valor:this.mrn.productoCodificadoSeleccionado.producto.valor_producto
      })
    }else {
      this.confirmationService.confirm({
        message: 'Para vender un paquete o una recarga debe escribir primero un número de teléfono valido.',
        rejectVisible:false,
        accept: () => {this.mrn.productoCodificadoSeleccionado=''}
      });
    }
  }

  seleccionarPin() {
    if(this.mrn.formVentasPines.value['telefono'].length==10){
      this.valorVenta = 0
      this.mrn.formVentasPines.patchValue({
        valor:this.mrn.productoCodificadoSeleccionado.producto.valor_producto
      })
    }else {
      this.confirmationService.confirm({
        message: 'Para vender un Pin debe escribir primero un número de teléfono valido.',
        rejectVisible:false,
        accept: () => {this.mrn.productoCodificadoSeleccionado=''}
      });
    }
  }

  valorPersonalizado() {
    this.valorVenta = 0
    this.mrn.productoCodificadoSeleccionado = '';
  }

  seleccionarCategoria(categoria) {
    this.mrn.categoriaSeleccionada = categoria;
    this.mrn.togleVentas(0,true);
    this.mrn.togleVentas(1,false)
    this.mrn.empresaSeleccionada = ''
    this.mrn.formVentasRecargas.reset()
    this.mrn.formVentasApuestas.reset()
    this.mrn.formVentasCertificados.reset()
    this.mrn.formVentasSoat.reset()
  }

  convertirMayusculas(value: string) {
    this.mrn.formVentasSoat.patchValue({
      documento:value.toUpperCase()
    })
  }

  seleccionarMunicipio(ciudad) {
      let codigo_municipio = this.mrn.municipiosFiltrados.filter(item=>item.codigo_municipio == ciudad)[0]['codigo_municipio']
      let codigo_departamento = this.mrn.municipiosFiltrados.filter(item=>item.codigo_municipio == ciudad)[0]['codigo_departamento']
      this.mrn.formVentasSoat.patchValue({
        municipio:this.mrn.formVentasSoat.value['municipio']?this.mrn.formVentasSoat.value['municipio']:codigo_municipio,
        departamento:this.mrn.formVentasSoat.value['departamento']?this.mrn.formVentasSoat.value['departamento']:codigo_departamento
      })

    }

  prueba_conexion_practi() {
      this.mrn.loading = false
      let parametros = {
          "idcomercio": this.mrn.USRPRACTI,
          "claveventa": this.mrn.PWDPRACTI,
          "tipoConsulta":"convenios_consulta",
          "data" : {"tipo": "0","key": ""},
          "idTrx":"1",
          "end_point":"preConsulta"
        }
        this.convenios = []
        this.api.post_soap('consulta_convenios_practi',parametros)
            .subscribe(
                datos =>{
                  if(datos != null){
                      let object = JSON.parse(datos['data']['convenios'])
                      for (const property in object) {
                          this.convenios.push(object[property])
                      }
                  }
                  this.mrn.loading = false
                },
                error =>{
                    console.log('algo salio mal',error)
                }
            )
  }

  consultar_referencia(referencia){

      if(referencia){
          this.mrn.loading = true
          let parametros = {}
          if (referencia.length < 12){
              parametros = {
                  "idcomercio": this.mrn.USRPRACTI,
                  "claveventa": this.mrn.PWDPRACTI,
                  "tipoConsulta":"consultaValorConvRef",
                  "data":{
                      "idConv":this.convenio_seleccionado?this.convenio_seleccionado.id:0,
                      "extConvenio":referencia
                  },
                  "idTrx":"1",
                  "end_point":"preConsulta"
              }
          }
          else {
              this.convenio_seleccionado = undefined
              parametros = {
                  "idcomercio": this.mrn.USRPRACTI,
                  "claveventa": this.mrn.PWDPRACTI,
                  "tipoConsulta":"verifyBillEan",
                  "data":{
                      "eanbill":referencia
                  },
                  "idTrx":"1",
                  "end_point":"preConsulta"
              }
          }
          this.api.post_soap('consulta_referencia_practi',parametros)
              .subscribe(
                  datos =>{
                      let respuesta = JSON.parse(datos)
                      if(respuesta['codigo']!='00'){
                          this.confirmationService.confirm({
                              message: respuesta['data']['data']['reply'],
                              rejectVisible:false,
                              accept: () => {}
                          });
                      }else {
                          this.recibo = respuesta['data']['data']
                          this.valor_factura = this.recibo.valorPago
                          if(this.recibo.estado == 5){
                              alert(this.recibo.nombre)
                              this.convenio_seleccionado = '';
                              this.recibo=''
                          }
                          else{
                              this.ver_resumen_convenio = true
                          }
                      }
                      this.mrn.loading = false

                  }
              )
      }
      else {
          this.confirmationService.confirm({
              message:' Digite una referencia valida',
              rejectVisible:false,});
      }
  }

  pagar_factura(valor,telefono,venta_ganancias){
      this.mrn.loading = true
      let tipo_convenio = ''
      if(this.convenio_seleccionado){
          tipo_convenio =  this.convenio_seleccionado.tipo == '0'?'667':'721'
      }
      else {
          tipo_convenio =  this.recibo.tipo == '0'?'667':'721'
      }
      let parametros = {
          "idcomercio": this.mrn.USRPRACTI,
          "claveventa": this.mrn.PWDPRACTI,
          "celular":telefono,
          "operador":"fc",
          "valor":valor,
          "jsonAdicional":{"idPre":this.recibo.idPre},
          "idtrans":"1",
          "end_point":"pracRec",
          "nodo":this.api.nodoActual['id'],
          "usuario_mrn":this.api.usuario['id'],
          "producto_venta":tipo_convenio,
          "medioVenta":"web",
          "venta_ganancias":venta_ganancias,
          "tipo_datos":'postpago',
          "referencia":this.recibo.referencia,
          "tipo_red":'wifi',
          "app_ver":'n/a',
          "clave_venta_mrn":this.clave_ventas
      }
      this.api.post_soap('pago_factura_practi',parametros)
          .subscribe(
              datos =>{
                  let respuesta = JSON.parse(datos)
                  if(respuesta.codigo == '00'){
                      this.factura_pagada = respuesta.data
                      this.mrn.trans_resultado_venta = respuesta.data
                      this.mrn.verResultadoVenta = true
                  }else{
                      this.confirmationService.confirm({
                          message:respuesta.mensaje,
                          rejectVisible:false,});
                  }
                  this.mrn.empresaSeleccionada = '';
                  this.mrn.categoriaSeleccionada = '';
                  this.mrn.loading = false
                  this.ver_resumen_convenio = false
                  this.telefono_convenio='';
                  this.valor_factura='';
                  this.clave_ventas = ''
                  this.mrn.getMisBolsasDinero()
                  this.mrn.togleVentas(0,true)
              }
          )
    }

  filtrarConvenio(event) {
        let query = event.query;
        this.convenios_filtrados = this.convenios.filter(item=>item.nombre.toLowerCase().includes(query.toLowerCase()))
    }



  seleccionar_convenio(event) {
    this.convenio_seleccionado = event
  }

 limpiarConsulta() {
    this.mrn.formVentasCertificados.reset()
 }

 ver_confirmacion(ven_gan: any) {
     this.venta_ganancias = ven_gan;
     this.mrn.ver_resumen_SNR = true
}

aceptar_pago() {
      this.convenio_seleccionado='';
      this.factura_pagada = '';
      this.ver_resumen_transaccion = false
  }

    getvalorPago(valor_factura?: any) {
        return valor_factura?valor_factura:this.recibo.valorPago
    }

    verificarClave() {
        if(this.clave_ventas.toString().length > 4){
            this.mrn.mensajeInformacion('La clave de venta solo puede contener 4 digitos.','warn')
            this.clave_ventas = ''
        }
    }
}
