import { Component, OnInit } from '@angular/core';
import {ApiService} from "../../providers/api";
import {Mrn} from "../../providers/mrn";
import {ConfirmationService} from "primeng/api";
import * as FileSaver from 'file-saver';
import * as moment from 'moment';
@Component({
  selector: 'app-configuracion',
  templateUrl: './configuracion.component.html',
})
export class ConfiguracionComponent implements OnInit {
  comisionActual: any;
  tiempo_al_aire: any[];
  dataset_ventas_seleccionado = []
  stackedOptions : any
  hoy:any
  totales_transacciones = [];
  dias_consulta = 5;
  credito_editar;
  listaReporteVersiones = [];
  constructor(public mrn: Mrn, public api:ApiService,private confirmationService: ConfirmationService) {
    this.tiempo_al_aire = [{label: 'Si', value: true}, {label: 'No', value: false}];
  }

  ngOnInit(): void {
    this.mrn.crearControles();
    this.mrn.getProveedores();
    this.mrn.getEmpresasNoFilter()
    this.mrn.getCatServicio();
    this.mrn.getEmpresas();
    this.mrn.getComisionesBaseByProveedor();
    this.stackedOptions = {
      tooltips: {
        mode: 'index',
        intersect: false
      },
      responsive: true,
      scales: {
        xAxes: [{
          stacked: true,
        }],
        yAxes: [{
          stacked: true
        }]
      }
    };
    this.hoy = moment().format('YYYY-MM-DD')
  }
  ngOnDestroy(): void {
    this.mrn.catEmpresaSeleccionada = ''
    this.mrn.verProductosEmpresa = false
  }

  setMaximaComision(comision){
    if(comision>this.mrn.catEmpresaSeleccionada.comisiones){
      this.confirmationService.confirm({
        message: 'la maxima comision para '+this.mrn.formProducto.controls['nombre']+' es de: '+ this.mrn.catEmpresaSeleccionada.comisiones+' %',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.mrn.formProducto.controls['comision'].reset()
          this.mrn.formProducto.patchValue({
            comision: this.mrn.catEmpresaSeleccionada.comisiones
          })
        },
        reject: () => {
          //reject action
        }
      });
    }
  }

  guardarProducto() {
    if(this.mrn.formProducto.value['id']){
      this.mrn.updateCategoria()
    }else {
      this.mrn.formProducto.patchValue({
        activo:true,
        tipo_producto:this.mrn.empresaSeleccionada.catServicio.id,
        empresa_id:this.mrn.empresaSeleccionada.id,
        descuento_promosional:this.mrn.formProducto.value['descuento_promosional']?this.mrn.formProducto.value['descuento_promosional']:0
      });
      this.mrn.addProducto();
    }
  }

  nuevoProducto() {
    this.mrn.verFormProducto = true;
    this.mrn.productoSeleccionado = '';
    this.mrn.formProducto.patchValue({
      comision:this.mrn.catEmpresaSeleccionada.comisiones,
    })
  }

  seleccionarProducto(producto: any) {
    this.mrn.productoSeleccionado = producto;
    this.mrn.llenarFormProducto(this.mrn.productoSeleccionado);
    this.mrn.verFormProducto = true
  }

  nuevo_producto() {
    this.mrn.formProducto.reset();
    this.mrn.productos=[];
    this.mrn.verFormProducto = true;

  }

  seleccionarEmpresa(value) {
    this.mrn.empresaSeleccionada = value;
    this.mrn.getProductos()
  }

  guardarServCategoria() {
    if(this.mrn.formServicioCategoria.value['id']){
      this.mrn.updateServCategoria();
    }else {
      this.mrn.addServCategoria();
    }
  }

  guardarEmpresa() {
    if(this.mrn.formEmpresa.value['id']){
      this.mrn.updateEmpresa();
    }else {
      this.mrn.formEmpresa.patchValue({
        activo:true
      })
      this.mrn.addEmpresa();
    }
  }

  GuardarCodificacion() {
    for(let item of this.mrn.productosCodificar){
      if(item.codigo&&!this.mrn.productosByProveedor.filter(prod => prod.producto.id == item.id).length){
        this.mrn.formCodificarProducto.patchValue({
          codigo_producto : item.codigo,
          producto_id:item.id,
          proveedor_id:this.mrn.proveedorSeleccionado.id,
          comisionBase:this.comisionActual,
          activo:true
        })
        this.mrn.addCodificarProducto();
      }
    }
  }

  guardarProveedor() {
    if(this.mrn.formProveedor.value['id']){
      this.mrn.updateProveedor();
    }else {
      this.mrn.formProveedor.patchValue({
        activo:true
      })
      this.mrn.addProveedor();
    }
  }

  seleccionarProveedor(value) {
    this.mrn.proveedorSeleccionado = value;
    this.mrn.getComisionesBaseByProveedor(value.id);
    this.mrn.comisionBase = '';
    this.mrn.productosByProveedor=[];
  }

  seleccionarEmpresaCodificacion(value) {
    this.mrn.empresaSeleccionada = this.mrn.empresasAll.filter(item=>item.id==value)[0]
    this.mrn.getComisionBase(this.mrn.empresaSeleccionada.id,this.mrn.proveedorSeleccionado.id);
    this.mrn.getProductosProveedor(this.mrn.proveedorSeleccionado.id,value)
    this.mrn.getProductosCodificar(value)
  }

  seleccionarProdCodif() {
    if(this.mrn.productosByProveedor.filter(item=>item.producto.id == this.mrn.productoSeleccionado.id).length){
      this.mrn.productoSeleccionado = '';
      this.confirmationService.confirm({
        message: 'Este producto ya esta codificado, por favor seleccione otro producto de la lista.',
        accept: () => {
          //Actual logic to perform a confirmation
        }
      });
    }
  }

  verificarCodigo(value) {
    if(this.mrn.productosByProveedor.filter(item=>item.codigo_producto == value).length){
      this.confirmationService.confirm({
        message: 'Este codigo ya esta siendo usado por otro producto.',
        rejectVisible:false,
        accept: () => {
          this.mrn.formCodificarProducto.patchValue({
            codigo_producto:''
          })
        }
      });
    }
  }

  codificar() {
    for(let item of this.mrn.productosCodificar){
      let prod = this.mrn.productosByProveedor.filter(producto=>producto.producto.id == item.id).length?
          this.mrn.productosByProveedor.filter(producto=>producto.producto.id == item.id)[0]:'';
      if(prod){
        this.mrn.productosCodificar.filter(producto=>producto.id == prod.producto.id)[0]['codigo'] = prod.codigo_producto
        this.mrn.productosCodificar.filter(producto=>producto.id == prod.producto.id)[0]['codificado'] = true
      }
    }
    this.comisionActual = this.mrn.productosByProveedor.length?this.mrn.productosByProveedor[0]['comisionBase']:'';
    this.mrn.formProveedor.reset();
    this.mrn.verFormCodificarProducto = true
  }

  guardarComisionBase() {
    if(this.mrn.formProveedorEmpresa.value['id']){
      this.mrn.formProveedorEmpresa.patchValue({
        comisionBase:this.mrn.formProveedorEmpresa.value['comisionBase']?this.mrn.formProveedorEmpresa.value['comisionBase']:0,
        comisionBase_valor:this.mrn.formProveedorEmpresa.value['comisionBase_valor']?this.mrn.formProveedorEmpresa.value['comisionBase_valor']:0
      })
      this.mrn.updateEmpresaProveedor();
    }else {
      if(this.mrn.comisionesBase.filter(item=>(
          item.proveedor.id == this.mrn.proveedorSeleccionado.id &&
          item.empresa.id == this.mrn.formProveedorEmpresa.value['empresa_id']
      )).length){
        this.confirmationService.confirm({
          message: 'Esta empresa ya esta registrada a este proveedor.',
          icon: 'pi pi-exclamation-triangle', accept: () => {}, reject: () => {}
        });
      }else{
        this.api.get('proveedor_emrpesa/')
            .subscribe(
                data => {
                  if(data.length){
                    let comision =data.filter(item=>
                        item.empresa.id == this.mrn.formProveedorEmpresa.value['empresa_id']);
                    this.mrn.formProveedorEmpresa.patchValue({
                      proveedor_id:this.mrn.proveedorSeleccionado.id,
                      activo:comision.length?!comision[0].activo:false,
                      comisionBase:this.mrn.formProveedorEmpresa.value['comisionBase']?this.mrn.formProveedorEmpresa.value['comisionBase']:0,
                      comisionBase_valor:this.mrn.formProveedorEmpresa.value['comisionBase_valor']?this.mrn.formProveedorEmpresa.value['comisionBase_valor']:0
                    })
                  }else {
                    this.mrn.formProveedorEmpresa.patchValue({
                      proveedor_id:this.mrn.proveedorSeleccionado.id,
                      activo:false,
                      comisionBase:this.mrn.formProveedorEmpresa.value['comisionBase']?this.mrn.formProveedorEmpresa.value['comisionBase']:0,
                      comisionBase_valor:this.mrn.formProveedorEmpresa.value['comisionBase_valor']?this.mrn.formProveedorEmpresa.value['comisionBase_valor']:0
                    })
                  }
                  this.mrn.addEmpresaProveedor();
                }
            )
      }
    }
  }

  calculateEmpresasTotal(name) {
    let total = 0;

    if (this.mrn.empresas) {
      for (let empresa of this.mrn.empresas) {
        if (empresa.catServicio.nombre === name) {
          total++;
        }
      }
    }

    return total;
  }

  cambioTab() {
    this.mrn.empresaSeleccionada = ''
    this.mrn.productos = [];
    this.mrn.getEmpresas()

  }

  verDetalleVentas(event: any) {
    this.dataset_ventas_seleccionado = []
    if(event.element._index == 0){
      this.dataset_ventas_seleccionado = this.mrn.ventas_exitosas
    }else {
      this.dataset_ventas_seleccionado = this.mrn.ventas_fallidas
    }
  }

  crear_documento_export(data_set){
    let excel = []
    for(let item of data_set){
      let f = moment(item.hour_at)
      let fecha = f.date() + '-'+f.month()+'-'+f.year()+' '+f.hour()+':'+f.minutes()
      let obj = {
        'transaccion': item.id,
        'canal de venta':item.medioVenta,
        'representante legal':item.representante_legal,
        'saldo inicial':item.ultimoSaldo,
        'valor':item.valor,
        'saldo slado final':item.saldo_actual,
        'numero destino':item.numeroDestino,
        'empresa':item.nombre_empresa,
        'fecha y hora':fecha,
        "proveedor":item.proveedor,
        'producto':item.nom_producto,
        'codigo aprobracion':item.codigo_transaccion_externa,
        'resultado':item.resultado,
      }
      excel.push(obj)
    }
    return excel
  }

  exportExcel(dataset) {
    import("xlsx").then(xlsx => {
      const worksheet = xlsx.utils.json_to_sheet(this.crear_documento_export(dataset));
      const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
      this.saveAsExcelFile(excelBuffer, "ventas");
    });
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_reporte_' + new Date().getTime() + EXCEL_EXTENSION);
  }

  getGnanancia() {
    let json = JSON.parse(this.mrn.transaccionSeleccionada.ganancia_red)
    let ganancia = json.filter(item => item.nodo == this.mrn.transaccionSeleccionada.nodo.id)[0]
    return [ganancia.valor,ganancia.comision]
  }
  seguimientoComercios(fInicial: any, fFinal: any) {
    this.mrn.loading = true
    let obj = {
      fecha: fInicial,
      fecha2:fFinal
    }
    this.api.post_soap('totalesbyfecha',obj)
        .subscribe(
            data=>{
              this.totales_transacciones =JSON.parse(data)
              this.mrn.loading = false
            }
        )
  }
  seguimientoDistribuidores(fInicial: any, fFinal: any) {
    this.mrn.loading = true
    let obj = {
      fecha: fInicial,
      fecha2:fFinal
    }
    this.api.post_soap('totalesbyfechadistribuidor',obj)
        .subscribe(
            data=>{
              this.totales_transacciones =JSON.parse(data)
              this.mrn.loading = false
            }
        )
  }

  reporteVersiones(fInicial: any, fFinal: any) {
    this.mrn.loading = true
    let obj = {
      fecha: fInicial,
      fecha2:fFinal
    }
    this.api.post_soap('app_version',obj)
        .subscribe(
            data=>{
              console.log(data);
              this.listaReporteVersiones =data
              this.mrn.loading = false
            }
        )
  }
  seleccionarNodo(nodo_id) {
    this.mrn.loading  =true
    this.api.get('creditos/?nodo=' + nodo_id)
        .subscribe(
            data => {
              if (data.length) {
                this.credito_editar = data[0];
                this.mrn.llenarFormCreditos(this.credito_editar)
              } else {
                this.credito_editar = '';
              }
              this.mrn.loading  =false
            });
  }
  guardarCreditos() {
    this.api.update('creditos', this.mrn.formCreditos.value['id'], this.mrn.formCreditos.value)
        .subscribe(
            data => {
              if(data){
                this.confirmationService.confirm({
                  message: 'El credito se edito correctamente',
                  rejectVisible:false,
                  accept: () => {
                    this.mrn.formCreditos.reset()
                    this.credito_editar = '';
                  }
                });
              }
            });
  }
}