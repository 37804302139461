<div class="p-grid">
    <div class="p-col-12 p-md-3">
        <p-card header="Zona de pagos" subheader="MRN Colombia" styleClass="p-card-shadow" *ngIf="mrn.distribuidor">
            <p style="text-align: justify;">Usted ha llegado a esta zona ya que posee una o mas factura que presentan dias de mora en el pago. Por
                favor pongase al dia en sus obligaciones para seguir disfrutando de los servicios.</p>
            <p style="text-align: justify;">Puede ponerse en contacto con su distribuidor {{mrn.distribuidor.razonSocial}}, a travez de los medios destinados para este fin.
            Celular : {{mrn.distribuidor.telefono}}, Correo electronico : {{mrn.distribuidor.email}}.</p>
            <ng-template pTemplate="footer">
                <p-button label="Contactar" icon="fab fa-whatsapp" (click)=" this.mrn.sendWpMessage(mrn.distribuidor.telefono);"></p-button>
            </ng-template>
        </p-card>
    </div>
    <div class="p-col-12 p-md-9" >
        <p-table #dtfacpen [rowHover]="true" [responsive]="true" [value]="mrn.facturasMora"
                 [globalFilterFields]="['id','valor','dias_mora','nodo.representanteLegal']"
                 [(selection)]="mrn.facturasSeleccionadas"
                 responsiveLayout="scroll" (onRowSelect)="calcularTotal($event)"
                 (onRowUnselect)="calcularTotal($event)">
            <ng-template pTemplate="caption">
                <div class="p-d-flex">
                    <button pTooltip="Actualizar tabla" label="Actualizar tabla" pButton pRipple
                            type="button"
                            icon="fas fa-sync-alt"
                            (click)="mrn.getFacturasMora(api.nodoActual)"
                            class="accion-tabla p-button-rounded p-button-secondary"></button>
                    <span class="p-input-icon-left p-ml-auto">
                                                        <i class="pi pi-search"></i>
                                                        <input pInputText type="text"
                                                               pTooltip="Puede buscar una factura a travez de los siguientes parametros:
                                                               Nombre del aliado,Id,valor,dias de mora." class="fas fa-question-circle"
                                                               (input)="dtfacpen.filterGlobal($event.target.value, 'contains')"
                                                               placeholder="Buscar factura"/>
                                                    </span>
                </div>
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th style="width: 3rem">
                    </th>
                    <th style="width:10%">Id</th>
                    <th style="width:20%">Aliado</th>
                    <th style="width:20%">Fecha de aprobación</th>
                    <th style="width:20%">Fecha de limite de pago</th>
                    <th style="width:20%">Valor solicitado</th>
                    <th style="width:20%">Saldo para pago</th>
                    <th style="width:20%">Dias de mora</th>
                    <th style="width:20%">Estado de pago</th>
                    <th style="width:10%"></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-transaccion>
                <tr>
                    <td>
                        <p-tableCheckbox *ngIf="transaccion.estadoPago !='Pago en revision'"
                                         [value]="{'id':transaccion.id,'valor':transaccion.valor,'nodo_id':transaccion.nodo.id,
                                                'repr_legal':transaccion.nodo.representanteLegal,'nodo':transaccion.nodo
                                                ,'saldo_pendiente_pago':transaccion.saldo_pendiente_pago}"
                                         [disabled]="propietarioFactura(transaccion)"></p-tableCheckbox>
                    </td>
                    <td style="text-align: center" class="accion-tabla"
                        (click)="mrn.transaccionSeleccionada = transaccion;mrn.verDetalletransaccion = true">{{transaccion.id}}</td>
                    <td>{{transaccion.nodo.representanteLegal}}</td>
                    <td>{{transaccion.fecha_aprobacion|date}}</td>
                    <td>{{transaccion.fecha_pago|date}}</td>
                    <td style="text-align: right">$ {{transaccion.valor|number}}</td>
                    <td style="text-align: right">$ {{transaccion.saldo_pendiente_pago|number}}</td>
                    <td style="text-align: center">{{transaccion.dias_mora}} dias</td>
                    <td style="text-align: center">{{transaccion.estadoPago}}</td>
                    <td style="text-align: center">
                        <i pTooltip="Ver pagos" *ngIf="transaccion.saldo_pendiente_pago<transaccion.valor" (click)="seleccionarFactura(transaccion)"
                           class="accion-tabla fas fa-cash-register"></i>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="summary">
                <div class="p-grid">
                    <div *ngIf="mrn.facturasSeleccionadas.length" class="p-col-6 p-md-12"
                         style="text-align:center">
                                        <span style="font-size:1.5em">Facturas de {{mrn.facturasSeleccionadas[0]['repr_legal']}}
                                            : </span>
                        <p-chips [(ngModel)]="mrn.facturasSeleccionadas"
                                 (onRemove)="calcularTotal($event)">
                            <ng-template let-item pTemplate="item">
                                {{item.id}} | ${{item.saldo_pendiente_pago|number}}
                            </ng-template>
                        </p-chips>
                    </div>
                    <div *ngIf="mrn.facturasSeleccionadas.length" class="p-col-6 p-md-12"
                         style="text-align:center">
                        <button pButton pRipple type="button" class="p-button-rounded p-button-info"
                                label="Pagar ${{mrn.totalFacturasAPagar|number}}"
                                (click)="pagoFacturas()"></button>
                    </div>
                </div>
            </ng-template>
        </p-table>
    </div>
</div>
<!--<p-dialog header="Abonar a factura" [(visible)]="mrn.verFormPagos" [modal]="true" [style]="{width: '50vw'}">
    <div class="card" *ngIf="mrn.transaccionSeleccionada">
        <div style="padding-bottom:25px">
            <div class="p-grid">
                <div class="p-col-12 p-md-4">
                    <table class="tabla_calculadora">
                        <tr>
                            <td>Total a pagar</td>
                            <th style="text-align:right">$ {{mrn.transaccionSeleccionada.valor|number}}</th>
                        </tr>
                        <tr>
                            <td>Total abonado</td>
                            <th style="text-align:right">$ {{this.mrn.totalAbonos|number}}</th>
                        </tr>
                        <tr>
                            <td>Saldo</td>
                            <th style="text-align:right">$ {{mrn.transaccionSeleccionada.valor-this.mrn.totalAbonos|number}}</th>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
        <form [formGroup]="mrn.formPagos" *ngIf="this.mrn.totalAbonos < mrn.transaccionSeleccionada.valor">
            <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-12 p-md-6">
                    <div class="p-fluid p-formgrid p-grid">
                        <div class="p-field p-col-12 p-md-6">
                            <label for="abono">Abono a la factura</label>
                            <p-inputNumber formControlName="abono" id="abono" mode="currency" currency="COP"
                                           [minFractionDigits]="0"></p-inputNumber>
                        </div>
                        <div class="p-field p-col-12 p-md-6">
                            <label for="numero_recibo">Comprobante de recaudo</label>
                            <input #numero (input)="mrn.verificar_soporte_pago(numero.value)"
                                   formControlName="numero_recibo" id="numero_recibo" type="text" pInputText>
                            <small >{{mrn.recibo_ok}}</small>
                        </div>
                        <div class="p-field p-col-12 p-md-12">
                            <label for="entidad">Entidad recaudo</label>
                            <p-dropdown appendTo="body" id="entidad" [options]="mrn.entidad_recaudo" formControlName="entidad"
                                        placeholder="Selecciona una entidad" optionLabel="label"
                                        optionValue="value"></p-dropdown>
                        </div>
                        <div class="p-field p-col-12 p-md-12">
                            <div style="text-align:right;">
                                <button *ngIf="mrn.formPagos.valid" pButton pRipple type="button"
                                        label="Enviar pago" (click)="guardarPago()"></button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="p-field p-col-12 p-md-6 subir_soporte">
                    <div *ngIf="mrn.formPagos.value['soporte']">
                        <img src="{{mrn.formPagos.value['soporte']}}" style="width: 100%" alt="">
                    </div>
                    <div *ngIf="!mrn.formPagos.value['soporte']">
                        <i class="fas fa-upload" style="font-size: 5em;cursor: pointer" pTooltip="Subir comprobante de pago."
                           (click)="mrn.ver_subir_soporte_pago_fac_mora = true"></i>
                    </div>
                    <p *ngIf="!mrn.formPagos.value['soporte']" style="padding: 15px 0 15px 0">Subir Comprobante de pago.</p>
                </div>
            </div>
        </form>
    </div>
    <div class="card">
        <h5>Pagos Realizados</h5>
        <p-table [rowHover]="true" [responsive]="true" [value]="mrn.abonos">
            <ng-template pTemplate="header">
                <tr>
                    <th>Comprobante</th>
                    <th>Entidad reacudo</th>
                    <th>Numero de comprobante</th>
                    <th>Valor de abono</th>
                    <th>Fecha</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-pago>
                <tr>
                    <td style="text-align: center">
                        <i pTooltip="Ver comprobante de pago." (click)="mrn.pagoSeleccionado = pago;mrn.verSoportePagoFacM=true"
                           *ngIf="pago.soporte" class="fas fa-file-alt" style="cursor: pointer"></i>
                    </td>
                    <td>{{pago.entidad}}</td>
                    <td>{{pago.numero_recibo}}</td>
                    <td>${{pago.abono|number}}</td>
                    <td>{{pago.created_at|date}}</td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</p-dialog>-->
<p-dialog header="Subir soporte de pago" [(visible)]="mrn.ver_subir_soporte_pago_fac_mora" [style]="{width: '30vw'}">
    <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-12 p-md-12">
            <p-fileUpload [customUpload]="true" [uploadLabel]="'Cargar'" [chooseLabel]="'Seleccionar archivo'"
                          [cancelLabel]="'Cancelar'" (uploadHandler)="mrn.subir_soporte_PFM($event,'1')"
                          maxFileSize="1000000"></p-fileUpload>
        </div>
    </div>
</p-dialog>
<p-dialog header="Soporte de pago" [(visible)]="mrn.verSoportePagoFacM" [modal]="true">
    <div style="text-align: center;padding: 25px" *ngIf="mrn.pagoSeleccionado">
        <img style="width: 50%" src="{{mrn.pagoSeleccionado.soporte}}" alt="">
    </div>
</p-dialog>
<p-dialog [modal]="true" header="Detalle de pagos a factura" [(visible)]="mrn.ver_pagos_facturas" [modal]="true"
          [style]="{width: '50vw'}">
    <div class="card" *ngIf="mrn.transaccionSeleccionada">
        <div style="padding-bottom:15px">
            <div class="p-grid">
                <div class="p-col-12 p-md-6">
                    <table class="tabla_calculadora">
                        <tr>
                            <td>Numero Transaccion</td>
                            <th style="text-align:right">{{mrn.transaccionSeleccionada.id}}</th>
                        </tr>
                        <tr>
                            <td>Total a pagar</td>
                            <th style="text-align:right">$ {{mrn.transaccionSeleccionada.valor|number}}</th>
                        </tr>
                        <tr>
                            <td>Total abonado</td>
                            <th style="text-align:right">
                                $ {{mrn.transaccionSeleccionada.valor - mrn.transaccionSeleccionada.saldo_pendiente_pago|number}}</th>
                        </tr>
                        <tr>
                            <td>Saldo</td>
                            <th style="text-align:right">
                                $ {{mrn.transaccionSeleccionada.saldo_pendiente_pago|number}}</th>
                        </tr>
                    </table>
                </div>
                <div class="p-col-12 p-md-6">
                    <div style="text-align: center;padding: 25px" *ngIf="mrn.pagoSeleccionado">
                        <img style="width: 100%" src="{{mrn.pagoSeleccionado.soporte}}" alt="">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="card">
        <h5>Pagos Realizados</h5>
        <p-table [rowHover]="true" [responsive]="true" [value]="mrn.abonos">
            <ng-template pTemplate="header">
                <tr>
                    <th>Comprobante</th>
                    <th>Entidad reacudo</th>
                    <th>Numero de comprobante</th>
                    <th>Valor de abono</th>
                    <th>Fecha</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-pago>
                <tr>
                    <td style="text-align: center">
                        <i pTooltip="Ver comprobante de pago."
                           (click)="mrn.pagoSeleccionado = '';mrn.pagoSeleccionado = pago"
                           *ngIf="pago.soporte" class="fas fa-file-alt" style="cursor: pointer"></i>
                    </td>
                    <td>{{pago.entidad}}</td>
                    <td>{{pago.numero_recibo}}</td>
                    <td>${{pago.abono|number}}</td>
                    <td>{{pago.created_at|date}}</td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</p-dialog>
<p-dialog [modal]="true" header="Abonar a factura" [(visible)]="mrn.verFormPagosMulti" [modal]="true"
          [style]="{width: '50vw'}">
    <div class="card">
        <h5>Facturas a pagar</h5>
        <div class="card">
            <form [formGroup]="mrn.formPagos">
                <div class="p-fluid p-formgrid p-grid">
                    <div class="p-field p-col-12 p-md-6">
                        <div class="p-fluid p-formgrid p-grid">
                            <div class="p-field p-col-12 p-md-6">
                                <label for="abono">Abono a la factura</label>
                                <p-inputNumber formControlName="abono" id="abono" mode="currency" currency="COP"
                                               [minFractionDigits]="0"></p-inputNumber>
                            </div>
                            <div class="p-field p-col-12 p-md-6">
                                <label for="numero_recibo">Comprobante de recaudo</label>
                                <input #numero (input)="mrn.verificar_soporte_pago(numero.value)"
                                       formControlName="numero_recibo" id="numero_recibo" type="text" pInputText>
                                <small>{{mrn.recibo_ok}}</small>
                            </div>
                            <div class="p-field p-col-12 p-md-12">
                                <label for="entidad">Entidad recaudo</label>
                                <p-dropdown appendTo="body" id="entidad" [options]="mrn.entidad_recaudo"
                                            formControlName="entidad"
                                            placeholder="Selecciona una entidad" optionLabel="label"
                                            optionValue="value"></p-dropdown>
                            </div>
                        </div>
                    </div>
                    <div class="p-field p-col-12 p-md-6 subir_soporte">
                        <div *ngIf="mrn.formPagos.value['soporte']">
                            <img src="{{mrn.formPagos.value['soporte']}}" style="width: 100%" alt="">
                        </div>
                        <div *ngIf="!mrn.formPagos.value['soporte']">
                            <i class="fas fa-upload" style="font-size: 5em;cursor: pointer"
                               pTooltip="Subir comprobante de pago."
                               (click)="mrn.ver_subir_soporte_pago_fac_mora = true"></i>
                        </div>
                        <p *ngIf="!mrn.formPagos.value['soporte']" style="padding: 15px 0 15px 0">Subir Comprobante de
                            pago.</p>
                    </div>
                </div>
            </form>
        </div>
        <p-table [rowHover]="true" [responsive]="true" [value]="mrn.facturasSeleccionadas"
                 [globalFilterFields]="['id','valor','dias_mora']"
                 responsiveLayout="scroll">
            <ng-template pTemplate="header">
                <tr>
                    <th style="width:10%">Id</th>
                    <th style="width:20%">Valor solicitado</th>
                    <th style="width:20%">Valor saldo</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-transaccion>
                <tr>
                    <td>{{transaccion.id}}</td>
                    <td style="text-align: right">$ {{transaccion.valor|number}}</td>
                    <td style="text-align: right">$ {{transaccion.saldo_pendiente_pago|number}}</td>
                </tr>
            </ng-template>
            <ng-template pTemplate="summary">
                <div class="p-col-6 p-md-12" style="text-align:center">
                    <button pButton pRipple type="button" class="p-button-rounded p-button-info"
                            label="Pagar facturas" [disabled]="!mrn.formPagos.valid"
                            (click)="mrn.pagarFacturasPendientesRedRevision(this.mrn.facturasSeleccionadas)"></button>
                </div>
            </ng-template>
        </p-table>
    </div>
</p-dialog>
<p-dialog [modal]="true" header="Resultado de la transaccion de pago" (onHide)="mrn.facturasSeleccionadas=[]" [(visible)]="mrn.verResumenPagos" [style]="{width: '30vw'}">
    <div class="card">
        <h5>Pagos Realizados</h5>
        <p-table [rowHover]="true" [responsive]="true" [value]="mrn.resumen_pagos.reverse()"
                 responsiveLayout="scroll">
            <ng-template pTemplate="header">
                <tr>
                    <th style="width:10%">Id</th>
                    <th>Valor Solicitado</th>
                    <th>Valor saldo</th>
                    <th>Estado</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-transaccion>
                <tr>
                    <td style="text-align: center">{{transaccion.id}}</td>
                    <td style="text-align: center">{{transaccion.valor|number}}</td>
                    <td style="text-align: right">$ {{transaccion.saldo_pendiente_pago|number}}</td>
                    <td style="text-align: center">{{transaccion.estadoPago == 'Pendiente'?'Abonado':'Pagado'}}</td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</p-dialog>
