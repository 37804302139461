import { Component, OnInit } from '@angular/core';
import {Mrn} from "../../providers/mrn";
import {ApiService} from "../../providers/api";
import {ConfirmationService} from "primeng/api";

@Component({
  selector: 'app-comisiones',
  templateUrl: './comisiones.component.html',
})
export class ComisionesComponent implements OnInit {

  comisionActual :any;
  nodosFiltrados: any[];
  comisionDistribuidor ;
  constructor(public mrn: Mrn, public api:ApiService,private confirmationService: ConfirmationService) {
  }

  ngOnInit(): void {
    //this.mrn.misComisiones = [];
    this.mrn.getEmpresasNoFilter();
    this.mrn.crearControles();
    this.mrn.getNodosDirectos();
    //this.mrn.getMisComisiones(this.mrn.nodoSeleccionado);

  }

  ngOnDestroy(): void {
    this.mrn.nodoSeleccionado = '';
    this.comisionActual = ''
    this.mrn.catEmpresaSeleccionada = ''
    this.mrn.misComisiones = [];
    this.mrn.comisiones = []
    this.mrn.verProductosEmpresa = false
  }

  seleccionarProducto(event) {
    this.comisionActual=this.mrn.misComisiones.filter(item=>item.empresa.id==event)['0']
    if(!this.mrn.comisiones.filter(item=>item.empresa.id == this.comisionActual.empresa.id).length){
      this.mrn.formComision.patchValue({
        comision: this.comisionActual.comision
      })
    }else {
      this.confirmationService.confirm({
        message: this.mrn.nodoSeleccionado.razonSocial+' ya tiene a '+this.comisionActual.empresa.nombre+' dentro de sus comisiones.',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
        },
      });
    }
  }

  setMaximaComision(comision){
    if(comision>this.comisionActual.comision){
      this.confirmationService.confirm({
        message: 'la maxima comision para '+this.comisionActual.producto.nombre+' es de: '+ this.comisionActual.comision+' %',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.mrn.formComision.controls['comision'].reset()
          this.mrn.formComision.patchValue({
            comision: this.comisionActual.comision
          })
        },
        reject: () => {
          //reject action
        }
      });
    }
  }

  seleccionarNodo() {
    this.mrn.getComisiones(this.mrn.nodoSeleccionado);
    this.mrn.formComision.reset();
  }


  filtrarNodos(event) {
    //in a real application, make a request to a remote url with the query and return filtered results, for demo001 we filter at client side
    let filtrados : any[] = [];
    let query = event.query;

    for(let i = 0; i < this.mrn.nodosDirectos.length; i++) {
      let nodo =  this.mrn.nodosDirectos[i];
      if (nodo.representanteLegal.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtrados.push(nodo);
      }
    }

    this.nodosFiltrados = filtrados;
  }

  desactivarComision(comision: any) {
    comision.activo = !comision.activo;
    this.mrn.llenarFormComision(comision);
    this.mrn.updateComision()
  }

 /* seleccionarCategorias(value) {
    this.mrn.getMisComisionesByCategoria(this.api.nodoActual,value);
  }*/

  guardarComision(){
    if(this.mrn.formComision.value['id']){
      this.mrn.updateComision();
    }else {
      this.mrn.formComision.patchValue({
        nodo_id: this.api.nodoActual['id'],
        activo: true,
      })
      this.mrn.addComisiones()
    }
  }

  verificarValorComision(value) {
    if (parseFloat(value) > this.comisionDistribuidor.micomision) {
      this.mrn.formComision.patchValue({
        micomision: this.comisionDistribuidor.micomision,
      });
    }
  }

  editarComisionAliado(comision: any) {
    this.comisionDistribuidor = this.mrn.misComisiones.filter(item => item.proveedorEmpresa.id == comision.proveedorEmpresa.id)[0]
    this.mrn.comisionAliadoSeleccionada = comision;
    this.mrn.llenarFormComision(comision);
    this.mrn.verFormComisionAliado=true
  }
}
