<div class="p-grid">
  <div class="p-col-12 p-md-12">
    <p-accordion>
      <p-accordionTab [(selected)]="paneles[0]">
        <ng-template pTemplate="header">
          <i class="fas fa-users-cog accion-tabla"></i>Gestión de mis aliados
        </ng-template>
        <p-tabView>
          <p-tabPanel styleClass="tabview-custom">
            <ng-template pTemplate="header">
              <span>Gestión de saldos</span>
              <p-badge [style]="{'margin-left': '5px'}" [value]="mrn.solicitudesSaldo.length.toString()"></p-badge>
            </ng-template>
            <app-saldos></app-saldos>
          </p-tabPanel>
          <p-tabPanel>
            <ng-template pTemplate="header">
              <span>Gestión de pagos</span>
              <p-badge
                      [style]="{'margin-left': '5px'}"
                [value]="mrn.pagos_entrantes.length.toString()"
              ></p-badge>
            </ng-template>
            <div class="card">
              <p-table
                dataKey="id"
                #dtPagos
                [rowHover]="true"
                [value]="mrn.pagos_entrantes"
                [globalFilterFields]="[
                  'transaccion.id',
                  'transaccion.nodo.representanteLegal',
                  'entidad',
                  'numero_recibo',
                  'usuario.username'
                ]"
              >
                <ng-template pTemplate="caption">
                  <div class="p-d-flex">
                    <button
                      pTooltip="Actualizar tabla"
                      label="Actualizar tabla"
                      pButton
                      pRipple
                      type="button"
                      icon="fas fa-sync-alt"
                      (click)="mrn.get_pagos_entrantes()"
                      class="accion-tabla p-button-rounded p-button-secondary"
                    ></button>
                    <span class="p-input-icon-left p-ml-auto">
                      <input
                        pTooltip="Usted puede buscar por transaccion,entidad,numero de comprobante."
                        pInputText
                        type="text"
                        (input)="
                          dtPagos.filterGlobal($event.target.value, 'contains')
                        "
                        placeholder="Buscar pago"
                      />
                    </span>
                  </div>
                </ng-template>
                <ng-template pTemplate="header">
                  <tr>
                    <th style="width: 5%">Id de pago</th>
                    <th>Id Transaccion</th>
                    <th>Aliado</th>
                    <th>Valor solicitado</th>
                    <th>Pagado por</th>
                    <th>Fecha pago</th>
                    <th>Abono</th>
                    <th>Entidad</th>
                    <th>Numero de recibo</th>
                    <th>Soporte</th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-pago>
                  <tr>
                    <td>{{ pago.id }}</td>
                    <td>{{ pago.transaccion.id }}</td>
                    <td>{{ pago.transaccion.nodo.representanteLegal }}</td>
                    <td>$ {{ pago.transaccion.valor | number }}</td>
                    <td
                      pTooltip="{{ pago.usuario ? pago.usuario.username : '' }}"
                    >
                      {{ pago.usuario ? pago.usuario.first_name : "" }}
                    </td>
                    <td>{{ pago.hour_at | date : "short" }}</td>
                    <td>$ {{ pago.abono | number }}</td>
                    <td>{{ pago.entidad }}</td>
                    <td>{{ pago.numero_recibo }}</td>
                    <td style="text-align: center">
                      <i pTooltip="Ver soporte" style="cursor: pointer" (click)="
                          mrn.pagoSeleccionado = '';mrn.pagoSeleccionado = pago;mrn.verSoportePagoEntrante = true"
                         *ngIf="pago.soporte"class="fas fa-image"></i>
                      <i pTooltip="Rechazar pago" style="cursor: pointer" (click)="
                          mrn.pagoSeleccionado = '';mrn.pagoSeleccionado = pago;mrn.verRechazarPagoEntrante = true"
                         *ngIf="!pago.soporte"class="fas fa-times-circle"></i>
                    </td>
                  </tr>
                </ng-template>
                <ng-template pTemplate="summary">
                  <div class="flex align-items-center justify-content-between">
                    Se encontraron {{ mrn.pagos_entrantes.length }} pagos.
                  </div>
                </ng-template>
                <ng-template pTemplate="emptymessage" let-columns>
                  <tr>
                    <td [attr.colspan]="9">
                      <div class="card" style="background: rgba(147, 226, 255, 0.67)" >
                        <p>
                          <i
                            style="font-size: 1.8em"
                            class="fas fa-info-circle accion-tabla"
                          >
                          </i
                          >No hay pagos pendientes por autorizar.
                        </p>
                      </div>
                    </td>
                  </tr>
                </ng-template>
              </p-table>
            </div>
          </p-tabPanel>
          <p-tabPanel style="justify-content: flex-end">
            <ng-template pTemplate="header">
              <span>Mis aliados</span>
            </ng-template>
            <div class="card" style="text-align: center;padding: 15px">
              <div *ngIf="api.nodoActual['id'] == 1">
                <p-table
                        #dtALiados
                        [rowHover]="true"
                        [value]="mrn.nodosDirectosAll"
                        [paginator]="true"
                        [rows]="15"
                        [showCurrentPageReport]="true"
                        currentPageReportTemplate="Viendo {first} hasta {last} de {totalRecords} aliados"
                        [rowsPerPageOptions]="[15, 25, 50]"
                        [globalFilterFields]="[
                  'nodo.representanteLegal',
                  'nodo.tipo',
                  'nodo.mora'
                ]"
                >
                  <ng-template pTemplate="caption">
                    <div class="p-d-flex">
                      <button
                              pTooltip="Nuevo aliado"
                              pButton
                              pRipple
                              type="button"
                              icon="fas fa-plus"
                              class="accion-tabla p-button-rounded p-button-info"
                              label="Nuevo aliado"
                              (click)="
                        mrn.formNodo.reset();
                        mrn.formUsuario.reset();
                        mrn.verFormNodo = true " ></button>
                      <span class="p-input-icon-left p-ml-auto">
                      <input style="width: 250px;margin-right: 15px" placeholder="Buscar por Identificacion" id="value" (focus)="repre.value = '';mrn.nodosDirectosAll = []"
                             type="text" pInputText #value (input)="mrn.getNodosTodosDirectosById(value.value,'')">
                      <input style="width: 250px"  placeholder="Buscar por Repr. Legal" id="repre" (focus)="value.value = '';mrn.nodosDirectosAll = []"
                             type="text" pInputText #repre (input)="mrn.getNodosTodosDirectosById('',repre.value)">
                        <!--<i class="pi pi-search"></i>
                        <input pInputText type="text" (input)=" dtALiados.filterGlobal( $event.target.value, 'contains' ) " placeholder="Buscar Aliado" />-->
                    </span>
                    </div>
                  </ng-template>
                  <ng-template pTemplate="header">
                    <tr>
                      <th pSortableColumn="nodo.mora" style="width: 7%">
                        Estado
                        <p-sortIcon field="nodo.mora"></p-sortIcon>
                      </th>
                      <th style="width: 5%">ID</th>
                      <th style="width: 7%">Comision</th>
                      <th pSortableColumn="nodo.representanteLegal">
                        Representante legal
                        <p-sortIcon field="nodo.representanteLegal"></p-sortIcon>
                      </th>
                      <th style="width: 10%">Nit/Cedula</th>
                      <th style="width: 10%">Tipo aliado</th>
                      <th pSortableColumn="saldo_disponible" style="width: 10%">
                        Saldo actual
                        <p-sortIcon field="saldo_disponible"></p-sortIcon>
                      </th>
                      <th pSortableColumn="utilidad" style="width: 10%">
                        Utilidad
                        <p-sortIcon field="utilidad"></p-sortIcon>
                      </th>
                      <th style="width: 7%"></th>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-nodo>
                    <tr>
                      <td style="text-align: center">
                        <i
                                pTooltip="{{ !nodo.nodo.mora ? 'Al dia' : 'En mora' }}"
                                class="{{ nodo.nodo.mora ? 'fas fa-hand-holding-usd ':'fas fa-hand-holding-usd'}}
                        accion-tabla {{ nodo.nodo.mora
                            ? 'animate__animated animate__bounceIn animate__infinite' : '' }}"
                                style="color: {{ !nodo.nodo.mora ? 'green' : 'darkred' }}"
                                (click)="seleccionarNodo(nodo.nodo)" ></i>

                        <i pTooltip="{{ nodo.nodo.activo ? 'Activo' : 'Bloqueado' }}"
                           class="{{ nodo.nodo.activo ? 'fas fa-check-circle' :'fas fa-ban' }}
                        accion-tabla {{ !nodo.nodo.activo? 'animate__animated animate__bounceIn animate__infinite' : '' }}"
                           style="color: {{ nodo.nodo.activo ? 'green' : 'darkred' }}"
                           (click)="seleccionarNodo(nodo.nodo)" ></i>

                        <i pTooltip="{{ nodo.nodo.imprime ? 'Trabaja con Smart POS' : '' }}"
                           class="{{ nodo.nodo.imprime ? 'fas fa-print' :'' }}"></i>
                      </td>
                      <td style="text-align: center">{{ nodo.nodo.id }}</td>
                      <td
                              style="text-align: left"
                              pTooltip="{{
                        nodo.nodo.tipoComision == 'CA'
                          ? 'Comision anticipada'
                          : 'Comision por venta'
                      }}"
                      >
                        {{ nodo.nodo.tipoComision }}
                      </td>
                      <td>{{ nodo.nodo.representanteLegal }}</td>
                      <td>{{ nodo.nodo.identificacion_nodo }}</td>
                      <td>{{ nodo.nodo.tipo }}</td>
                      <td style="text-align: right">
                        $ {{ nodo.saldo_disponible | number }}
                      </td>
                      <td style="text-align: right">
                        $ {{ nodo.utilidad | number }}
                      </td>
                      <td style="text-align: center">
                        <i
                                pTooltip="Ver informacion"
                                class="accion-tabla fas fa-list"
                                (click)="this.seleccionarNodo(nodo.nodo)"
                        ></i>
                        <i
                                pTooltip="Asignar saldo"
                                class="accion-tabla fas fa-phone-volume"
                                (click)="solicitar_saldo(nodo.nodo)"
                        ></i>
                        <i
                                pTooltip="Mas opciones"
                                class="fas fa-chevron-circle-down"
                                (click)="crearMenuALiado($event, menualiados, nodo)"
                        ></i>
                      </td>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="emptymessage">
                    <tr>
                      <td [attr.colspan]="9">
                        <div class="card">
                          <div class="card-content" style="text-align: center; color: grey" >
                            <i style="font-size: 5em" class="far fa-laugh-beam"></i>
                            <h2>
                              Buscar aliado por nombre de representante legal o número de identificacíon.
                            </h2>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </ng-template>
                </p-table>
              </div>
              <div *ngIf="api.nodoActual['id'] != 1">
                <p-table
                        #dtALiados
                        [rowHover]="true"
                        [responsive]="true"
                        [value]="mrn.nodosDirectosAll"
                        [paginator]="true"
                        [rows]="15"
                        [showCurrentPageReport]="true"
                        currentPageReportTemplate="Viendo {first} hasta {last} de {totalRecords} aliados"
                        [rowsPerPageOptions]="[15, 25, 50]"
                        [globalFilterFields]="[
                  'nodo.representanteLegal',
                  'nodo.tipo',
                  'nodo.mora'
                ]"
                >
                  <ng-template pTemplate="caption">
                    <div class="p-d-flex">
                      <button
                              pTooltip="Nuevo aliado"
                              pButton
                              pRipple
                              type="button"
                              icon="fas fa-plus"
                              class="accion-tabla p-button-rounded p-button-info"
                              label="Nuevo aliado"
                              (click)="
                        mrn.formNodo.reset();
                        mrn.formUsuario.reset();
                        mrn.verFormNodo = true " ></button>
                      <button
                              pTooltip="Actualizar listado"
                              label="Actualizar listado"
                              pButton
                              pRipple
                              type="button"
                              icon="fas fa-sync-alt"
                              class="accion-tabla p-button-rounded p-button-info"
                              (click)="mrn.getNodosTodosDirectos()" ></button>
                      <span class="p-input-icon-left p-ml-auto">
                      <i class="pi pi-search"></i>
                      <input pInputText type="text" (input)=" dtALiados.filterGlobal( $event.target.value, 'contains' ) " placeholder="Buscar Aliado" />
                    </span>
                    </div>
                  </ng-template>
                  <ng-template pTemplate="header">
                    <tr>
                      <th pSortableColumn="nodo.mora" style="width: 7%">
                        Estado
                        <p-sortIcon field="nodo.mora"></p-sortIcon>
                      </th>
                      <th style="width: 5%">ID</th>
                      <th style="width: 7%">Comision</th>
                      <th pSortableColumn="nodo.representanteLegal">
                        Representante legal
                        <p-sortIcon field="nodo.representanteLegal"></p-sortIcon>
                      </th>
                      <th style="width: 10%">Nit/Cedula</th>
                      <th style="width: 10%">Tipo aliado</th>
                      <th pSortableColumn="saldo_disponible" style="width: 10%">
                        Saldo actual
                        <p-sortIcon field="saldo_disponible"></p-sortIcon>
                      </th>
                      <th pSortableColumn="utilidad" style="width: 10%">
                        Utilidad
                        <p-sortIcon field="utilidad"></p-sortIcon>
                      </th>
                      <th style="width: 7%"></th>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-nodo>
                    <tr>
                      <td style="text-align: center">
                        <i
                                pTooltip="{{ !nodo.nodo.mora ? 'Al dia' : 'En mora' }}"
                                class="{{ nodo.nodo.mora ? 'fas fa-hand-holding-usd ':'fas fa-hand-holding-usd'}}
                        accion-tabla {{ nodo.nodo.mora
                            ? 'animate__animated animate__bounceIn animate__infinite' : '' }}"
                                style="color: {{ !nodo.nodo.mora ? 'green' : 'darkred' }}"
                                (click)="seleccionarNodo(nodo.nodo)" ></i>

                        <i pTooltip="{{ nodo.nodo.activo ? 'Activo' : 'Bloqueado' }}"
                           class="{{ nodo.nodo.activo ? 'fas fa-check-circle' :'fas fa-ban' }}
                        accion-tabla {{ !nodo.nodo.activo? 'animate__animated animate__bounceIn animate__infinite' : '' }}"
                           style="color: {{ nodo.nodo.activo ? 'green' : 'darkred' }}"
                           (click)="seleccionarNodo(nodo.nodo)" ></i>

                        <i pTooltip="{{ nodo.nodo.imprime ? 'Trabaja con Smart POS' : '' }}"
                           class="{{ nodo.nodo.imprime ? 'fas fa-print' :'' }}"></i>
                      </td>
                      <td style="text-align: center">{{ nodo.nodo.id }}</td>
                      <td
                              style="text-align: left"
                              pTooltip="{{
                        nodo.nodo.tipoComision == 'CA'
                          ? 'Comision anticipada'
                          : 'Comision por venta'
                      }}"
                      >
                        {{ nodo.nodo.tipoComision }}
                      </td>
                      <td>{{ nodo.nodo.representanteLegal }}</td>
                      <td>{{ nodo.nodo.identificacion_nodo }}</td>
                      <td>{{ nodo.nodo.tipo }}</td>
                      <td style="text-align: right">
                        $ {{ nodo.saldo_disponible | number }}
                      </td>
                      <td style="text-align: right">
                        $ {{ nodo.utilidad | number }}
                      </td>
                      <td style="text-align: center">
                        <i
                                pTooltip="Ver informacion"
                                class="accion-tabla fas fa-list"
                                (click)="this.seleccionarNodo(nodo.nodo)"
                        ></i>
                        <i
                                pTooltip="Asignar saldo"
                                class="accion-tabla fas fa-phone-volume"
                                (click)="solicitar_saldo(nodo.nodo)"
                        ></i>
                        <i
                                pTooltip="Mas opciones"
                                class="fas fa-chevron-circle-down"
                                (click)="crearMenuALiado($event, menualiados, nodo)"
                        ></i>
                      </td>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="emptymessage">
                    <tr>
                      <td [attr.colspan]="2">Aún no hay aliados registrados</td>
                    </tr>
                  </ng-template>
                </p-table>
              </div>

            </div>
          </p-tabPanel>
          <p-tabPanel>
            <ng-template pTemplate="header">
              <span>Gestión de comisiones</span>
            </ng-template>
            <div class="card">
              <p-table
                [rowHover]="true"
                #dtComision
                [globalFilterFields]="['proveedorEmpresa.empresa.nom_empresa']"
                [value]="lista_categorias"
                [paginator]="true"
                [rows]="10"
              >
                <ng-template pTemplate="caption">
                  <div class="caption_content">
                    <p-dropdown placeholder="Seleccione una categoria" [options]="mrn.categorias" (onChange)="filtarComisiones($event.value)" optionLabel="nombre"></p-dropdown>
                    <span class="p-input-icon-left ml-auto">
                      <i class="pi pi-search"></i>
                      <input
                        pInputText
                        type="text"
                        (input)="
                          dtComision.filterGlobal(
                            $event.target.value,
                            'contains'
                          )
                        "
                        placeholder="Buscar"
                      />
                    </span>
                  </div>
                </ng-template>
                <ng-template pTemplate="header">
                  <tr>
                    <th style="width: 20%">Empresa</th>
                    <th style="width: 20%">Proveedor</th>
                    <th style="width: 10%">Mi Comision</th>
                    <th style="width: 15%">Comisión Distribuidor</th>
                    <th style="width: 15%">Comisión Comercio</th>
                    <th style="width: 20%">Actualizacion</th>
                    <th style="width: 10%"></th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-comision let-rowIndex="rowIndex">
                  <tr>
                    <td *ngIf="api.nodoActual['id'] == 1">
                    {{ comision.proveedorEmpresa.proveedor.nombre_prov }}
                    </td>
                    <td>{{ comision.proveedorEmpresa.empresa.nom_empresa }}</td>
                    <td *ngIf="api.nodoActual['id'] != 1">
                    N/A
                    </td>
                    <td style="text-align: center">
                      {{ comision.micomision }}
                      {{ comision.micomision < 100 ? "%" : "pesos" }}
                    </td>
                    <td style="text-align: center">
                      {{ comision.comisionDistribuidor }}
                      {{ comision.comisionDistribuidor < 100 ? "%" : "pesos" }}
                    </td>
                    <td style="text-align: center">
                      {{ comision.comisionComercio }}
                      {{ comision.comisionComercio < 100 ? "%" : "pesos" }}
                    </td>
                    <td style="text-align: center">
                      {{ comision.updated_at | date }}
                    </td>
                    <td style="text-align: center">
                      <i
                        pTooltip="Ver Productos"
                        class="pi pi-eye accion-tabla"
                        (click)="
                          mrn.comisionSeleccionada = comision;
                          mrn.getProductosProveedor(
                            comision.proveedorEmpresa.proveedor.id,
                            comision.proveedorEmpresa.empresa.id
                          );
                          mrn.verProductosEmpresa = true
                        "
                      ></i>
                      <i
                        pTooltip="Editar Comision"
                        class="fas fa-pencil-alt accion-tabla"
                        (click)="
                          mrn.comisionSeleccionada = comision;
                          mrn.llenarFormComision(comision);
                          mrn.verFormComision = true
                        "
                      ></i>
                    </td>
                  </tr>
                </ng-template>

                <ng-template pTemplate="emptymessage">
                  <tr>
                    <td [attr.colspan]="8">No hay comisiones para esta categoria.</td>
                  </tr>
                </ng-template>
              </p-table>
            </div>
          </p-tabPanel>
          <p-tabPanel>
            <ng-template pTemplate="header">
              <span>Gestión de cartera</span>
            </ng-template>
            <div *ngIf="api.nodoActual['id'] == 1">
              <p-table
                      #dtfacpen
                      [rowHover]="true"
                      [value]="mrn.facturasPendientesRed"
                      [globalFilterFields]="[
                'id',
                'valor',
                'dias_mora',
                'nodo.representanteLegal'
              ]"
                      [(selection)]="mrn.facturasSeleccionadas"
                      responsiveLayout="scroll"
                      (onRowSelect)="calcularTotal($event)"
                      (onRowUnselect)="calcularTotal($event)"
              >
                <ng-template pTemplate="caption">
                  <div class="p-d-flex">
                  <span class="p-input-icon-left p-ml-auto">
                      <input style="width: 250px;margin-right: 15px" placeholder="Buscar por Identificacion" id="value1" (focus)="repre.value = '';mrn.facturasPendientesRed = []"
                             type="text" pInputText #ident (input)="mrn.getFacturasPendientesRedV2(ident.value,'')">
                      <input style="width: 250px"  placeholder="Buscar por Repr. Legal" id="repre1" (focus)="ident.value = '';mrn.facturasPendientesRed = []"
                             type="text" pInputText #repre (input)="mrn.getFacturasPendientesRedV2('',repre.value)">
                    </span>
                  </div>
                </ng-template>
                <ng-template pTemplate="header">
                  <tr>
                    <th style="width: 3rem"></th>
                    <th style="width: 10%">Id</th>
                    <th>Aliado</th>
                    <th>Aprobado</th>
                    <th>Limite de pago</th>
                    <th>Valor solicitado</th>
                    <th>Saldo para pago</th>
                    <th>Dias de mora</th>
                    <th>Estado de pago</th>
                    <th></th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-transaccion>
                  <tr>
                    <td>
                      <p-tableCheckbox
                              *ngIf="transaccion.estadoPago != 'Pago en revision'"
                              [value]="{
                        id: transaccion.id,
                        valor: transaccion.valor,
                        nodo_id: transaccion.nodo.id,
                        repr_legal: transaccion.nodo.representanteLegal,
                        nodo: transaccion.nodo,
                        saldo_pendiente_pago: transaccion.saldo_pendiente_pago,
                        fecha_aprobacion: transaccion.fecha_aprobacion,
                      }"
                              [disabled]="propietarioFactura(transaccion)"
                      ></p-tableCheckbox>
                    </td>
                    <td
                            style="text-align: right"
                            class="accion-tabla"
                            (click)="
                      mrn.transaccionSeleccionada = transaccion;
                      mrn.verDetalletransaccion = true
                    "
                    >
                      {{ transaccion.id }}
                    </td>
                    <td>{{ transaccion.nodo.representanteLegal }}</td>
                    <td style="text-align: right">
                      {{ transaccion.fecha_aprobacion | date : "dd/MM/yyyy" }}
                    </td>
                    <td style="text-align: right">
                      {{ transaccion.fecha_pago | date : "dd/MM/yyyy" }}
                    </td>
                    <td style="text-align: right">
                      $ {{ transaccion.valor | number }}
                    </td>
                    <td style="text-align: right">
                      $ {{ transaccion.saldo_pendiente_pago | number }}
                    </td>
                    <td style="text-align: center">
                      {{ transaccion.dias_mora }} dias
                    </td>
                    <td style="text-align: center">
                      {{ transaccion.estadoPago }}
                    </td>
                    <td style="text-align: center">
                      <i
                              pTooltip="Ver pagos"
                              *ngIf="transaccion.saldo_pendiente_pago"
                              (click)="seleccionarFactura(transaccion)"
                              class="accion-tabla fas fa-cash-register"
                      ></i>
                      <i
                              *ngIf="
                        transaccion.estadoPago == 'Pago en revision' &&
                        transaccion.nodo.id != api.nodoActual['id']
                      "
                              pTooltip="Aprobar pago"
                              (click)="mrn.aprobar_pago_factura(transaccion)"
                              class="accion-tabla fas fa-check-circle"
                      ></i>
                    </td>
                  </tr>
                </ng-template>
                <ng-template pTemplate="summary">
                  <div class="p-grid">
                    <div
                            *ngIf="mrn.facturasSeleccionadas.length"
                            class="p-col-6 p-md-12"
                            style="text-align: center"
                    >
                    <span style="font-size: 1.5em"
                    >Facturas de
                      {{ mrn.facturasSeleccionadas[0]["repr_legal"] }} :
                    </span>
                      <p-chips
                              [(ngModel)]="mrn.facturasSeleccionadas"
                              (onRemove)="calcularTotal($event)"
                      >
                        <ng-template let-item pTemplate="item">
                          {{ item.id }} | ${{
                          item.saldo_pendiente_pago | number
                          }}
                        </ng-template>
                      </p-chips>
                    </div>
                    <div
                            *ngIf="mrn.facturasSeleccionadas.length"
                            class="p-col-6 p-md-12"
                            style="text-align: center"
                    >
                      <button
                              pButton
                              pRipple
                              type="button"
                              class="p-button-rounded p-button-info"
                              label="Pagar ${{ mrn.totalFacturasAPagar | number }}"
                              (click)="pagoFacturas()"
                      ></button>
                    </div>
                  </div>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                  <tr>
                    <td [attr.colspan]="9">
                      <div class="card">
                        <div class="card-content" style="text-align: center; color: grey" >
                          <i style="font-size: 5em" class="far fa-laugh-beam"></i>
                          <h2>
                            Buscar facturas por nombre de representante legal o número de identificacíon.
                          </h2>
                        </div>
                      </div>
                    </td>
                  </tr>
                </ng-template>
              </p-table>
            </div>
            <div *ngIf="api.nodoActual['id'] != 1">
              <p-table
                      #dtfacpen
                      [rowHover]="true"
                      [responsive]="true"
                      [value]="mrn.facturasPendientesRed"
                      [globalFilterFields]="[
                'id',
                'valor',
                'dias_mora',
                'nodo.representanteLegal'
              ]"
                      [(selection)]="mrn.facturasSeleccionadas"
                      responsiveLayout="scroll"
                      (onRowSelect)="calcularTotal($event)"
                      (onRowUnselect)="calcularTotal($event)"
              >
                <ng-template pTemplate="caption">
                  <div class="p-grid">
                    <div class="p-col-12 p-md-3">
                      <div class="p-grid p-fluid p-formgrid" >
                        <div class="p-col-12 p-md-4">
                          <p-dropdown
                                  style="margin-right: 15px"
                                  appendTo="body"
                                  id="filterBag"
                                  [options]="[{'label':'Hoy','value':1},{'label':'3 dias','value':2},{'label':'8 dias','value':3},{'label':'15 dias','value':4},{'label':'60 dias','value':5},{'label':'Todos','value':6}]"
                                  placeholder="Seleccione un rango"
                                  (onChange)="selectQuery($event)" optionLabel="label" optionValue="value" ></p-dropdown>
                        </div>
                        <div style="padding: 5px;text-align: center" class="p-col-12 p-md-8" *ngIf="fecha_inicial">
                          <span style="color: #3b4252">Desde: {{fecha_inicial|date : "dd/MM/yyyy"}} - Hasta: {{hoy|date : "dd/MM/yyyy"}}</span>
                        </div>
                        <div class="p-col-12 p-md-6">
                          <button
                                  pTooltip="Actualizar tabla" label="Actualizar tabla"
                                  pButton pRipple type="button" icon="fas fa-sync-alt"
                                  (click)="mrn.getFacturasPendientesRed(api.nodoActual,'Todo')"
                                  class="accion-tabla p-button-rounded p-button-secondary" ></button>
                        </div>
                        <div class="p-col-12 p-md-6">
                          <button
                                  type="button"
                                  pButton pRipple icon="pi pi-file-excel"
                                  (click)="exportExcelCarteta()" label="Exportar a Excel"
                                  tooltipPosition="bottom" class="accion-tabla p-button-rounded p-button-success"
                          ></button>
                        </div>
                      </div>
                    </div>
                    <div class="p-col-12 p-md-4">

                    </div>
                    <div class="p-col-12 p-md-5" style="text-align: right">
                      <span class="p-input-icon-left p-ml-auto">
                    <i class="pi pi-search"></i>
                    <input
                            pInputText
                            type="text"
                            pTooltip="Puede buscar una factura a travez de los siguientes parametros:
                                                               Nombre del aliado,Id,valor,dias de mora."
                            class="fas fa-question-circle"
                            (input)="
                        dtfacpen.filterGlobal($event.target.value, 'contains')
                      "
                            placeholder="Buscar factura"
                    />
                  </span>
                    </div>
                  </div>
                </ng-template>
                <ng-template pTemplate="header">
                  <tr>
                    <th style="width: 3rem"></th>
                    <th style="width: 10%">Id</th>
                    <th>Aliado</th>
                    <th >Aprobado</th>
                    <th >Limite de pago</th>
                    <th >Valor solicitado</th>
                    <th >Saldo para pago</th>
                    <th >Dias de mora</th>
                    <th>Estado de pago</th>
                    <th ></th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-transaccion>
                  <tr>
                    <td>
                      <p-tableCheckbox
                              *ngIf="transaccion.estadoPago != 'Pago en revision'"
                              [value]="{
                        id: transaccion.id,
                        valor: transaccion.valor,
                        nodo_id: transaccion.nodo.id,
                        repr_legal: transaccion.nodo.representanteLegal,
                        nodo: transaccion.nodo,
                        saldo_pendiente_pago: transaccion.saldo_pendiente_pago,
                        fecha_aprobacion: transaccion.fecha_aprobacion,
                      }"
                              [disabled]="propietarioFactura(transaccion)"
                      ></p-tableCheckbox>
                    </td>
                    <td
                            style="text-align: right"
                            class="accion-tabla"
                            (click)="
                      mrn.transaccionSeleccionada = transaccion;
                      mrn.verDetalletransaccion = true
                    "
                    >
                      {{ transaccion.id }}
                    </td>
                    <td>{{ transaccion.nodo.representanteLegal }}</td>
                    <td style="text-align: right">
                      {{ transaccion.fecha_aprobacion | date : "dd/MM/yyyy" }}
                    </td>
                    <td style="text-align: right">
                      {{ transaccion.fecha_pago | date : "dd/MM/yyyy" }}
                    </td>
                    <td style="text-align: right">
                      $ {{ transaccion.valor | number }}
                    </td>
                    <td style="text-align: right">
                      $ {{ transaccion.saldo_pendiente_pago | number }}
                    </td>
                    <td style="text-align: center">
                      {{ transaccion.dias_mora }} dias
                    </td>
                    <td style="text-align: center">
                      {{ transaccion.estadoPago }}
                    </td>
                    <td style="text-align: center">
                      <i
                              pTooltip="Ver pagos"
                              *ngIf="transaccion.saldo_pendiente_pago"
                              (click)="seleccionarFactura(transaccion)"
                              class="accion-tabla fas fa-cash-register"
                      ></i>
                      <i
                              *ngIf="
                        transaccion.estadoPago == 'Pago en revision' &&
                        transaccion.nodo.id != api.nodoActual['id']
                      "
                              pTooltip="Aprobar pago"
                              (click)="mrn.aprobar_pago_factura(transaccion)"
                              class="accion-tabla fas fa-check-circle"
                      ></i>
                    </td>
                  </tr>
                </ng-template>
                <ng-template pTemplate="summary">
                  <div class="p-grid">
                    <div
                            *ngIf="mrn.facturasSeleccionadas.length"
                            class="p-col-6 p-md-12"
                            style="text-align: center"
                    >
                    <span style="font-size: 1.5em"
                    >Facturas de
                      {{ mrn.facturasSeleccionadas[0]["repr_legal"] }} :
                    </span>
                      <p-chips
                              [(ngModel)]="mrn.facturasSeleccionadas"
                              (onRemove)="calcularTotal($event)"
                      >
                        <ng-template let-item pTemplate="item">
                          {{ item.id }} | ${{
                          item.saldo_pendiente_pago | number
                          }}
                        </ng-template>
                      </p-chips>
                    </div>
                    <div
                            *ngIf="mrn.facturasSeleccionadas.length"
                            class="p-col-6 p-md-12"
                            style="text-align: center"
                    >
                      <button
                              pButton
                              pRipple
                              type="button"
                              class="p-button-rounded p-button-info"
                              label="Pagar ${{ mrn.totalFacturasAPagar | number }}"
                              (click)="pagoFacturas()"
                      ></button>
                    </div>
                  </div>
                </ng-template>
              </p-table>
            </div>
          </p-tabPanel>
          <p-tabPanel>
            <ng-template pTemplate="header">
              <span>Historial de pagos</span>
            </ng-template>
            <p-table
              dataKey="id"
              #dtPagosH
              [rowHover]="true"
              [value]="mrn.pagos_by_fecha_red"
              [globalFilterFields]="[
                'transaccion.id',
                'transaccion.nodo.representanteLegal',
                'entidad',
                'numero_recibo',
                'usuario.username'
              ]"
            >
              <ng-template pTemplate="caption">
                <div class="p-grid">
                  <div class="p-col-12 p-md-3">
                    <div class="p-grid p-fluid p-formgrid">
                      <div class="p-field p-col-12 p-md-6">
                        <label for="fip">Desde</label>
                        <input type="date" #fInicial pInputText id="fip1" />
                      </div>
                      <div class="p-field p-col-12 p-md-6">
                        <label for="fip">Hasta</label>
                        <input type="date" #fFinal pInputText id="fip22" />
                      </div>
                      <div class="p-field p-col-12 p-md-12">
                        <label for="net_query">Tipo consulta</label>
                        <p-dropdown
                          appendTo="body"
                          id="net_query"
                          [options]="net_query_opt"
                          placeholder="Selecciona un tipo"
                          [(ngModel)]="net_query_value"
                          optionLabel="label"
                          optionValue="value"
                        ></p-dropdown>
                      </div>
                      <div class="p-col-12 p-md-6">
                        <button
                          *ngIf="
                            fInicial.value && fFinal.value && net_query_value
                          "
                          (click)="
                            mrn.get_pagos_by_fecha_red(
                              fInicial.value,
                              fFinal.value,
                              net_query_value
                            )
                          "
                          type="button"
                          pButton
                          pRipple
                          styleClass="p-button-danger"
                          icon="pi pi-search"
                          label="Consultar"
                        ></button>
                      </div>
                      <div class="p-col-12 p-md-6">
                        <button
                          (click)="
                            fInicial.value = '';
                            fFinal.value = '';
                            mrn.pagos_by_fecha_red = [];
                            net_query_value = ''
                          "
                          type="button"
                          pButton
                          pRipple
                          styleClass="p-button-danger"
                          icon="fas fa-broom"
                          label="Limpiar"
                        ></button>
                      </div>
                        <div class="p-col-12 p-md-12" style="margin-top: 10px" *ngIf="mrn.pagos_by_fecha_red.length">
                            <button
                                    type="button"
                                    pButton pRipple icon="pi pi-file-excel"
                                    (click)="exportExcelPagos()" label="Exportar a Excel"
                                    tooltipPosition="bottom" class="accion-tabla p-button-rounded p-button-success"
                            ></button>
                        </div>

                    </div>
                  </div>
                  <div class="p-col-12 p-md-9" style="text-align: right">
                    <input
                      pTooltip="Usted puede buscar por transaccion,entidad,numero de comprobante."
                      pInputText
                      type="text"
                      (input)="
                        dtPagosH.filterGlobal($event.target.value, 'contains')
                      "
                      placeholder="Buscar pago"
                    />
                  </div>
                </div>
              </ng-template>
              <ng-template pTemplate="header">
                <tr>
                  <th style="width: 5%">Id de pago</th>
                  <th>Id Transaccion</th>
                  <th>Aliado</th>
                  <th>Valor solicitado</th>
                  <th>Pagado por</th>
                  <th>Fecha pago</th>
                  <th>Abono</th>
                  <th>Entidad</th>
                  <th>Numero de recibo</th>
                  <th>Estado</th>
                  <th style="width: 5%">Soporte</th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-pago>
                <tr>
                  <td>{{ pago.id }}</td>
                  <td>{{ pago.transaccion.id }}</td>
                  <td>{{ pago.transaccion.nodo.representanteLegal }}</td>
                  <td>$ {{ pago.transaccion.valor | number }}</td>
                  <td
                    pTooltip="{{ pago.usuario ? pago.usuario.username : '' }}"
                  >
                    {{ pago.usuario ? pago.usuario.first_name : "" }}
                  </td>
                  <td>{{ pago.hour_at | date : "short" }}</td>
                  <td>$ {{ pago.abono | number }}</td>
                  <td>{{ pago.entidad }}</td>
                  <td>{{ pago.numero_recibo }}</td>
                  <td pTooltip="{{ pago.motivo_rechazo }}">
                    {{ pago.estadoPago }}
                  </td>
                  <td style="text-align: center; width: 5%">
                    <i
                      pTooltip="Ver soporte"
                      style="cursor: pointer"
                      (click)="
                        mrn.pagoSeleccionado = '';
                        mrn.pagoSeleccionado = pago;
                        mrn.verSoporte = true
                      "
                      *ngIf="pago.soporte"
                      class="fas fa-image"
                    ></i>
                  </td>
                </tr>
              </ng-template>
              <ng-template pTemplate="summary">
                <div class="flex align-items-center justify-content-between">
                  Se encontraron {{ mrn.pagos_by_fecha.length }} pagos.
                </div>
              </ng-template>
              <ng-template pTemplate="emptymessage" let-columns>
                <tr>
                  <td [attr.colspan]="11">
                    <div
                      class="card"
                      style="background: rgba(147, 226, 255, 0.67)"
                    >
                      <p>
                        <i
                          style="font-size: 1.8em"
                          class="fas fa-info-circle accion-tabla"
                        >
                        </i
                        >Para realizar una consulta por favor seleccione una
                        Fecha.
                      </p>
                    </div>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </p-tabPanel>
          <p-tabPanel>
            <ng-template pTemplate="header">
              <span>Reporte de ventas</span>
            </ng-template>
            <div class="card">
              <div class="p-grid">
                <div style="margin-top: 20px" class="p-col-12 p-md-4">
                  <div class="p-grid">
                    <div class="p-col-12">
                      <div class="p-grid p-fluid p-formgrid">
                        <div class="p-field p-col-12">
                          <label for="fis">Desde</label>
                          <input
                            type="date"
                            #fInicial
                            pInputText
                            value="{{ hoy }}"
                            id="fis"
                          />
                        </div>
                        <div class="p-field p-col-12">
                          <label for="ffs">Hasta</label>
                          <input
                            type="date"
                            #fFinal
                            pInputText
                            value="{{ hoy }}"
                            id="ffs"
                          />
                        </div>
                        <div class="p-col-6">
                          <button
                            (click)="
                              mrn.ventas_exitosas = [];
                              mrn.ventas_fallidas = [];
                              mrn.ventas_web = 0;
                              mrn.ventas_movil = 0;
                              (mrn.grafico_medio_ventas == '');
                              mrn.valor_ventas_exitosas = 0;
                              mrn.valor_ventas_fallidas = 0;
                              dataset_ventas_seleccionado = [];
                              mrn.get_reporte_ventas_red(
                                fInicial.value,
                                fFinal.value
                              )
                            "
                            type="button"
                            pButton
                            pRipple
                            styleClass="p-button-danger"
                            label="Consultar"
                          ></button>
                        </div>
                        <div class="p-col-6">
                          <button
                            *ngIf="fInicial.value && fFinal.value"
                            (click)="
                              mrn.ventas_web = 0;
                              mrn.ventas_movil = 0;
                              (mrn.grafico_medio_ventas == '');
                              fInicial.value = '';
                              fFinal.value = '';
                              mrn.ventas_exitosas = [];
                              mrn.ventas_fallidas = [];
                              mrn.valor_ventas_exitosas = 0;
                              mrn.valor_ventas_fallidas = 0;
                              dataset_ventas_seleccionado = []
                            "
                            type="button"
                            pButton
                            pRipple
                            styleClass="p-button-danger"
                            label="Limpiar"
                          ></button>
                        </div>
                      </div>
                    </div>
                    <div *ngIf="mrn.ventas_exitosas.length" class="p-col-12">
                      <ul>
                        <li class="lista_credito">Resultado de la consulta</li>
                        <li class="lista_credito">
                          <span style="float: right">
                            <div
                              style="
                                display: flex;
                                flex-flow: column wrap;
                                align-items: end;
                              "
                            >
                              <span
                                style="
                                  color: #4c566a;
                                  font-size: 2em;
                                  font-weight: bold;
                                "
                                >${{ mrn.valor_ventas_exitosas | number }}</span
                              >
                              <span style="color: gray"
                                >{{ mrn.ventas_exitosas.length }} Transacciones
                                exitosas
                              </span>
                            </div>
                          </span>
                        </li>
                        <p-divider></p-divider>
                        <li class="lista_credito">
                          <span style="float: right">
                            <div
                              style="
                                display: flex;
                                flex-flow: column wrap;
                                align-items: end;
                              "
                            >
                              <span
                                style="
                                  color: #4c566a;
                                  font-size: 2em;
                                  font-weight: bold;
                                "
                                >${{ mrn.valor_ventas_fallidas | number }}</span
                              >
                              <span style="color: gray"
                                >{{ mrn.ventas_fallidas.length }} Transacciones
                                fallidas</span
                              >
                            </div>
                          </span>
                        </li>
                        <p-divider></p-divider>
                      </ul>
                    </div>
                  </div>
                </div>
                <!--<div *ngIf="mrn.ventas_exitosas.length" class="p-col-12 p-md-4">
                  <p-chart
                    type="pie"
                    [data]="mrn.grafico_ventas_globales"
                    (onDataSelect)="verDetalleVentas($event)"
                    [style]="{ width: '80px', height: '80px' }"
                  ></p-chart>
                </div>
                <div *ngIf="mrn.ventas_exitosas.length" class="p-col-12 p-md-4">
                  <p-chart
                    type="bar"
                    [options]="stackedOptions"
                    [data]="mrn.grafico_medio_ventas"
                    [style]="{ width: '100%', height: '150px' }"
                  ></p-chart>
                </div>-->
                <div class="p-col-12 p-md-12">
                  <p-tabView>
                    <p-tabPanel header="Ventas Exitosas">
                      <p-table #ventasRed2 [rowHover]="true" #dtvengen [scrollable]="true" scrollHeight="400px" [globalFilterFields]="[
                      'representante_legal', 'numeroDestino', 'nombre_empresa', 'referencia_pago' ]" [value]="mrn.ventas_exitosas" >
                        <ng-template pTemplate="caption">
                          <div class="p-grid">
                            <div class="p-col-12 p-md-6">
                              <button
                                      type="button"
                                      pButton
                                      pRipple
                                      icon="pi pi-file-excel"
                                      (click)="exportExcel(mrn.ventas_exitosas)"
                                      class="p-button-success mr-2"
                                      label="Exportar a Excel"
                                      tooltipPosition="bottom"
                              ></button>
                            </div>
                            <div class="p-col-12 p-md-6" style="text-align: right">
                          <span class="p-input-icon-left ml-auto">
                            <i class="pi pi-search"></i>
                            <input
                                    style="width: 500px"
                                    pInputText
                                    type="text"
                                    (input)="
                                ventasRed2.filterGlobal(
                                  $event.target.value,
                                  'contains'
                                )
                              "
                                    placeholder="Buscar por: Representante legal,numero destino,empresa"
                            />
                          </span>
                            </div>
                          </div>
                        </ng-template>
                        <ng-template pTemplate="header">
                          <tr>
                            <th style="width: 10%">Id</th>
                            <th>Representante legal</th>
                            <th >Destino/Referencia</th>
                            <th>Empresa</th>
                            <th>Fecha solicitud</th>
                            <th>Valor</th>
                            <th>Resulatdo de la transaccion</th>
                          </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-transaccion>
                          <tr>
                            <td style="width: 10%">
                              {{ transaccion.id }}
                              <i
                                      class="fas fa-search accion-tabla_izq"
                                      (click)="
                              mrn.transaccionSeleccionada = transaccion;
                              mrn.verDetalletransaccionByAliado = true
                            "
                              ></i>
                            </td>
                            <td>{{ transaccion.representante_legal }}</td>
                            <td>{{ transaccion.referencia_pago !='n/a'?transaccion.referencia_pago:transaccion.numeroDestino }}</td>
                            <td>{{ transaccion.nombre_empresa }}</td>
                            <td style="text-align: center">
                              {{ transaccion.created_at | date : "dd-MM-yyyy" }}
                              {{ transaccion.hour_at | date : "shortTime" }}
                            </td>
                            <td style="text-align: right">
                              $ {{ transaccion.valor | number }}
                            </td>
                            <td class="wrap">{{ transaccion.resultado }}</td>
                          </tr>
                        </ng-template>
                        <ng-template pTemplate="summary">
                          <div
                                  class="flex align-items-center justify-content-between"
                          >
                            Se encontraron
                            {{ dataset_ventas_seleccionado.length }} transacciones.
                          </div>
                        </ng-template>
                        <ng-template pTemplate="emptymessage" let-columns>
                          <tr>
                            <td [attr.colspan]="6">
                              <div class="card info_card">
                                <i
                                        style="font-size: 1.8em"
                                        class="fas fa-info-circle accion-tabla"
                                ></i>
                                <p>
                                  Para realizar una consulta por favor seleccione
                                  una Fecha inicial y un Fecha final.
                                </p>
                              </div>
                            </td>
                          </tr>
                        </ng-template>
                      </p-table>
                    </p-tabPanel>
                    <p-tabPanel header="Ventas Fallidas">
                      <p-table #ventasRed [rowHover]="true" #dtvengen [scrollable]="true" scrollHeight="400px" [globalFilterFields]="[
                      'representante_legal', 'numeroDestino', 'nombre_empresa', 'referencia_pago' ]"
                              [value]="this.mrn.ventas_fallidas" >
                        <ng-template pTemplate="caption">
                          <div class="p-grid">
                            <div class="p-col-12 p-md-6">
                              <button
                                      type="button"
                                      pButton
                                      pRipple
                                      icon="pi pi-file-excel"
                                      (click)="exportExcel(mrn.ventas_fallidas)"
                                      class="p-button-success mr-2"
                                      label="Exportar a Excel"
                                      tooltipPosition="bottom"
                              ></button>
                            </div>
                            <div class="p-col-12 p-md-6" style="text-align: right">
                          <span class="p-input-icon-left ml-auto">
                            <i class="pi pi-search"></i>
                            <input
                                    style="width: 500px"
                                    pInputText
                                    type="text"
                                    (input)="
                                ventasRed.filterGlobal(
                                  $event.target.value,
                                  'contains'
                                )
                              "
                                    placeholder="Buscar por: Representante legal,numero destino,empresa"
                            />
                          </span>
                            </div>
                          </div>
                        </ng-template>
                        <ng-template pTemplate="header">
                          <tr>
                            <th style="width: 10%">Id</th>
                            <th>Representante legal</th>
                            <th >Destino/Referencia</th>
                            <th>Empresa</th>
                            <th>Fecha solicitud</th>
                            <th>Valor</th>
                            <th>Resulatdo de la transaccion</th>
                          </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-transaccion>
                          <tr>
                            <td style="width: 10%">
                              {{ transaccion.id }}
                              <i
                                      class="fas fa-search accion-tabla_izq"
                                      (click)="
                              mrn.transaccionSeleccionada = transaccion;
                              mrn.verDetalletransaccionByAliado = true
                            "
                              ></i>
                            </td>
                            <td>{{ transaccion.representante_legal }}</td>
                            <td>{{ transaccion.referencia_pago !='n/a'?transaccion.referencia_pago:transaccion.numeroDestino }}</td>
                            <td>{{ transaccion.nombre_empresa }}</td>
                            <td style="text-align: center">
                              {{ transaccion.created_at | date : "dd-MM-yyyy" }}
                              {{ transaccion.hour_at | date : "shortTime" }}
                            </td>
                            <td style="text-align: right">
                              $ {{ transaccion.valor | number }}
                            </td>
                            <td class="wrap">{{ transaccion.resultado }}</td>
                          </tr>
                        </ng-template>
                        <ng-template pTemplate="summary">
                          <div
                                  class="flex align-items-center justify-content-between"
                          >
                            Se encontraron
                            {{ dataset_ventas_seleccionado.length }} transacciones.
                          </div>
                        </ng-template>
                        <ng-template pTemplate="emptymessage" let-columns>
                          <tr>
                            <td [attr.colspan]="6">
                              <div class="card info_card">
                                <i
                                        style="font-size: 1.8em"
                                        class="fas fa-info-circle accion-tabla"
                                ></i>
                                <p>
                                  Para realizar una consulta por favor seleccione
                                  una Fecha inicial y un Fecha final.
                                </p>
                              </div>
                            </td>
                          </tr>
                        </ng-template>
                      </p-table>
                    </p-tabPanel>
                  </p-tabView>
                </div>
              </div>
            </div>
          </p-tabPanel>
        </p-tabView>
      </p-accordionTab>
      <p-accordionTab *ngIf="mrn.nodoSeleccionado" [(selected)]="paneles[1]">
        <ng-template pTemplate="header">
          <i class="fas fa-building accion-tabla"></i>Informacion de
          {{ mrn.nodoSeleccionado.representanteLegal }}
        </ng-template>
        <p-tabView>
          <p-tabPanel
                  style="justify-content: flex-end"
                  *ngIf="mrn.nodoSeleccionado"
                  header="Informacion General"
          >
            <div class="card">
              <div class="card-header">
                <h5>
                  <i
                          class="{{ mrn.nodoSeleccionado.icon }}"
                          style="margin-right: 10px; font-size: 1.1em"
                  ></i
                  >{{ mrn.nodoSeleccionado.razonSocial }}
                </h5>
                <button
                        *ngIf="mrn.nodoSeleccionado"
                        pButton
                        pRipple
                        type="button"
                        label="Editar Aliado"
                        (click)="
                    mrn.llenarFormNodo(this.mrn.nodoSeleccionado);
                    mrn.verFormEditNodoTercero = true
                  "
                ></button>
              </div>
              <div class="p-grid">
                <div class="p-col-12 p-md-5">
                  <ul>
                    <div *ngIf="mrn.usuarioByNodo">
                      <li style="list-style: none; padding: 6px">
                        <h5 style="font-weight: bold">
                          Información del Administrador
                        </h5>
                      </li>
                      <li style="list-style: none; padding: 6px">
                        <span style="font-weight: bold">Nombres :</span>
                        <span style="float: right">{{
                          mrn.usuarioByNodo.first_name
                          }}</span>
                      </li>
                      <li style="list-style: none; padding: 6px">
                        <span style="font-weight: bold">Email :</span>
                        <span style="float: right">{{
                          mrn.usuarioByNodo.email
                          }}</span>
                      </li>
                    </div>
                    <p-divider></p-divider>
                    <li style="list-style: none; padding: 6px">
                      <h5 style="font-weight: bold">
                        Informacion de {{ mrn.nodoSeleccionado.razonSocial }}
                      </h5>
                    </li>
                    <li style="list-style: none; padding: 6px">
                      <span style="font-weight: bold">Razon Social :</span>
                      <span style="float: right">{{
                        mrn.nodoSeleccionado.razonSocial
                        }}</span>
                    </li>
                    <li style="list-style: none; padding: 6px">
                      <span style="font-weight: bold"
                      >fecha de vinculación :</span
                      >
                      <span style="float: right">{{
                        mrn.nodoSeleccionado.fecha_vinculacion | date
                        }}</span>
                    </li>
                    <li style="list-style: none; padding: 6px">
                      <span style="font-weight: bold">Telefono :</span>
                      <span style="float: right">{{
                        mrn.nodoSeleccionado.telefono
                        }}</span>
                    </li>
                    <li style="list-style: none; padding: 6px">
                      <span style="font-weight: bold">Tipo Aliado :</span>
                      <span style="float: right">{{
                        mrn.nodoSeleccionado.tipo
                        }}</span>
                    </li>
                    <li style="list-style: none; padding: 6px">
                      <span style="font-weight: bold">Email :</span>
                      <span style="float: right">{{
                        mrn.nodoSeleccionado.email
                        }}</span>
                    </li>
                    <li
                            style="list-style: none; padding: 6px"
                            *ngIf="mrn.nodoSeleccionado.zona"
                    >
                      <span style="font-weight: bold">Zona :</span>
                      <span style="float: right">{{
                        mrn.nodoSeleccionado.zona.nombre
                        }}</span>
                    </li>
                    <li style="list-style: none; padding: 6px">
                      <span style="font-weight: bold"
                      >Solicitudes de credito diarias :</span
                      >
                      <span style="float: right">{{
                        mrn.nodoSeleccionado.solicitudes_de_credito_dia
                        }}</span>
                    </li>
                    <li style="list-style: none; padding: 6px">
                      <span style="font-weight: bold"
                      >Aprobacion de credito:</span
                      >
                      <span style="float: right">{{
                        mrn.nodoSeleccionado.aprobacion_credito_automatico
                                ? "Automatica"
                                : "Manual"
                        }}</span>
                    </li>
                    <li
                            *ngIf="mrn.nodoSeleccionado.tipo != 'Distribuidor'"
                            style="list-style: none; padding: 6px"
                    >
                      <span style="font-weight: bold">Tipo de comision :</span>
                      <span style="float: right">{{
                        mrn.nodoSeleccionado.tipoComision == "CA"
                                ? "Comision anticipada"
                                : "Comision por venta"
                        }} <i  pTooltip="Cambiar Tipo de comision"
                              style="margin-left: 5px;cursor: pointer" class="fas fa-exchange-alt" (click)="cambiarComision(mrn.nodoSeleccionado.tipoComision)"></i></span>
                    </li>
                    <li
                            *ngIf="mrn.nodoSeleccionado.comisionAnticipada"
                            style="list-style: none; padding: 6px"
                    >
                      <span style="font-weight: bold"
                      >Porcentaje de comision :</span
                      >
                      <span style="float: right"
                      >{{ mrn.nodoSeleccionado.comisionAnticipada }} %</span
                      >
                    </li>
                    <li
                            *ngIf="mrn.nodoSeleccionado.tipo == 'Comercio'"
                            style="list-style: none; padding: 6px">
                      <span style="font-weight: bold">Tiene una smart POS? :</span>
                      <span style="float: right">{{ mrn.nodoSeleccionado.imprime?'Si':'No' }}</span>
                    </li>
                  </ul>
                </div>
                <div class="p-col-12 p-md-7">
                  <ul>
                    <li style="list-style: none; padding: 6px">
                      <h5 style="font-weight: bold">
                        Direcciones Registradas de
                        {{ mrn.nodoSeleccionado.tipo }}
                      </h5>
                    </li>
                    <li
                            *ngFor="
                        let direccion of mrn.direcciones_nodo;
                        let i = index
                      "
                            style="list-style: none; padding: 6px"
                    >
                      <span style="font-weight: bold"
                      >Direccion {{ i == 0 ? " actual" : "" }}
                        {{ i + 1 }} :</span
                      >
                      <span style="float: right"
                      >{{ direccion.direccion }}
                        <b *ngIf="direccion.municipio"
                        >({{ direccion.municipio.nombre_mpio }})</b
                        ></span
                      >
                    </li>
                    <li
                            style="
                        list-style: none;
                        padding: 6px;
                        color: gray;
                        font-weight: bold;
                        cursor: pointer;
                      "
                            (click)="mrn.verFormDireccion = true"
                    >
                      <span>
                        Agregar diereccion
                        <i
                                style="float: right; font-weight: bold"
                                class="pi pi-plus"
                        ></i
                        ></span>
                    </li>
                    <p-divider></p-divider>
                  </ul>
                  <ul>
                    <li style="list-style: none; padding: 6px">
                      <h5 style="font-weight: bold">
                        Referencia comerciales registradas de
                        {{ mrn.nodoSeleccionado.tipo }}
                      </h5>
                    </li>
                    <ul
                            *ngFor="
                        let referencia of mrn.stringToJson(
                          mrn.nodoSeleccionado.ref_comercial
                        );
                        let i = index
                      "
                    >
                      <p-divider></p-divider>
                      <li style="list-style: none; padding: 6px">
                        <span style="font-weight: bold">Empresa:</span>
                        <span style="float: right">{{
                          referencia.nom_empresa
                          }}</span>
                      </li>
                      <li style="list-style: none; padding: 6px">
                        <span style="font-weight: bold"
                        >Representante legal:</span
                        >
                        <span style="float: right">{{
                          referencia.repr_legal
                          }}</span>
                      </li>
                      <li style="list-style: none; padding: 6px">
                        <span style="font-weight: bold">Telefono:</span>
                        <span style="float: right">{{
                          referencia.telefono
                          }}</span>
                      </li>
                      <li style="list-style: none; padding: 6px">
                        <span style="font-weight: bold"
                        >Correo electronico:</span
                        >
                        <span style="float: right">{{
                          referencia.correo
                          }}</span>
                      </li>
                    </ul>
                    <li
                            style="
                        list-style: none;
                        padding: 6px;
                        color: gray;
                        font-weight: bold;
                        cursor: pointer;
                      "
                            (click)="mrn.verFormReferencia = true"
                    >
                      <span>
                        Agregar referencia comercial
                        <i
                                style="float: right; font-weight: bold"
                                class="pi pi-plus"
                        ></i
                        ></span>
                    </li>
                    <p-divider></p-divider>
                  </ul>
                </div>
              </div>
            </div>
          </p-tabPanel>
          <p-tabPanel
                  *ngIf="mrn.nodoSeleccionado && mrn.nodoSeleccionado.tipoComision == 'CV'" header="Comisiones">
            <app-comisiones></app-comisiones>
          </p-tabPanel>
          <p-tabPanel *ngIf="mrn.nodoSeleccionado" header="Cupos de credito">
            <div *ngIf="mrn.nodoSeleccionado" class="card">
              <div style="text-align: center; padding-bottom: 30px">
                <button
                        pButton
                        pRipple
                        type="button"
                        label="Asignar monto de credito"
                        (click)="
                    mrn.llenarFormCreditos(mrn.creditoByNodo);
                    this.mrn.verFormCredito = true
                  "
                ></button>
              </div>
              <div class="p-grid">
                <div
                        class="p-field p-col-12 p-md-6"
                        *ngIf="mrn.creditoByNodo"
                        style="text-align: center"
                >
                  <p-knob
                          [readonly]="true"
                          [size]="250"
                          [(ngModel)]="mrn.creditoByNodo.montoDisponible"
                          [min]="0"
                          [max]="mrn.creditoByNodo.montoAutorizado"
                          valueColor="{{
                      mrn.creditoByNodo.montoDisponible < 500000
                        ? '#c4656d'
                        : 'MediumTurquoise'
                    }}"
                          rangeColor="SlateGray"
                          valueTemplate="${{
                      mrn.creditoByNodo.montoDisponible | number
                    }}"
                  ></p-knob>
                </div>
                <div class="p-field p-col-12 p-md-6">
                  <ul *ngIf="mrn.creditoByNodo">
                    <li class="lista_credito">
                      <h5 style="font-weight: bold">Información de crédito</h5>
                    </li>
                    <li class="lista_credito">
                      <span style="font-weight: bold"
                      >Cupo total asignado :</span
                      >
                      <span style="float: right">
                        <p-chip
                                label="${{
                            (mrn.creditoByNodo
                              ? mrn.creditoByNodo.montoAutorizado
                              : 0
                            ) | number
                          }}"
                                styleClass="p-mr-2"
                        ></p-chip>
                      </span>
                    </li>
                    <li class="lista_credito">
                      <span style="font-weight: bold">Cupo utilizado :</span>
                      <span style="float: right">
                        <p-chip
                                label="${{
                            (mrn.creditoByNodo
                              ? mrn.creditoByNodo.montoUtilizado
                              : 0
                            ) | number
                          }}"
                                styleClass="p-mr-2 chip-disponible"
                        ></p-chip>
                      </span>
                    </li>
                    <li class="lista_credito">
                      <span style="font-weight: bold">Cupo disponible :</span>
                      <span style="float: right">
                        <p-chip
                                label="${{
                            (mrn.creditoByNodo
                              ? mrn.creditoByNodo.montoDisponible
                              : 0
                            ) | number
                          }}"
                                styleClass="p-mr-2 {{
                            mrn.creditoByNodo.montoDisponible < 500000
                              ? 'chip-saldo-bajo'
                              : 'chip-utilizado'
                          }}"
                        ></p-chip>
                      </span>
                    </li>
                    <p-divider></p-divider>
                    <li class="lista_credito">
                      <span style="font-weight: bold">Plazo de pago :</span>
                      <span style="float: right"
                      >{{ mrn.creditoByNodo.plazoMaximo }} dias</span
                      >
                    </li>
                    <li class="lista_credito">
                      <span style="font-weight: bold"
                      >Ultima actualizacion :</span
                      >
                      <span style="float: right">{{
                        mrn.creditoByNodo.updated_at | date
                        }}</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </p-tabPanel>
          <p-tabPanel *ngIf="mrn.nodoSeleccionado" header="Usuarios">
            <div class="card">
              <h5>Usuarios registrados</h5>
              <div style="margin-top: 20px">
                <p-table
                        [rowHover]="true"
                        [responsive]="true"
                        [value]="mrn.usuariosByNodo"
                        #dtUsuarios
                        [globalFilterFields]="[
                    'first_name',
                    'username',
                    'telefono',
                    'identificacion',
                    'cargo'
                  ]"
                >
                  <ng-template pTemplate="caption">
                    <div class="caption_content_only_input">
                      <span class="p-input-icon-left ml-auto">
                        <i class="pi pi-search"></i>
                        <input
                                pInputText
                                type="text"
                                (input)="
                            dtUsuarios.filterGlobal(
                              $event.target.value,
                              'contains'
                            )
                          "
                                placeholder="Buscar Usuario"
                        />
                      </span>
                    </div>
                  </ng-template>
                  <ng-template pTemplate="header">
                    <tr>
                      <th style="width: 10%">Estado</th>
                      <th>Nombres</th>
                      <th style="width: 15%">Usuario</th>
                      <th style="width: 15%">Teléfono</th>
                      <th style="width: 15%">Identificacion</th>
                      <th style="width: 15%">Cargo</th>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-usuario>
                    <tr>
                      <td style="text-align: center">
                        <i (click)="bloquear_usuario(usuario)" style="cursor: pointer"
                           pTooltip="{{ usuario.is_active ? 'Desactivar usuario' : 'Activar usuario' }}"
                           class="pi pi-circle-on {{ usuario.is_active ? 'activo' : 'inactivo' }}" ></i>
                      </td>
                      <td>{{ usuario.first_name }}</td>
                      <td pEditableColumn>
                        <p-cellEditor>
                          <ng-template pTemplate="input">
                            <div class="p-inputgroup">
                              <button type="button" pButton pRipple icon="pi pi-times" (click)="mrn.getUsuarioByNodo()" styleClass="p-button-warn"></button>
                              <input pInputText type="text" [(ngModel)]="usuario.username">
                              <button type="button" pButton pRipple icon="pi pi-save" (click)="mrn.updateUsuario(usuario)" styleClass="p-button-warn"></button>
                            </div>
                          </ng-template>
                          <ng-template pTemplate="output">
                            {{ usuario.username }} <i class="fas fa-pencil-alt" style="margin-left: 5px" pTooltip="Editar nombre de usuario"></i>
                          </ng-template>
                        </p-cellEditor>
                      </td>
                      <td style="text-align: center">{{ usuario.telefono }}</td>
                      <td style="text-align: center">
                        {{ usuario.identificacion }}
                      </td>
                      <td>{{ usuario.cargo }}</td>
                    </tr>
                  </ng-template>
                </p-table>
              </div>
            </div>
          </p-tabPanel>
          <p-tabPanel *ngIf="mrn.nodoSeleccionado" header="Reportes">
            <p-tabView>
              <p-tabPanel *ngIf="mrn.nodoSeleccionado && mrn.nodoSeleccionado.tipo == 'Comercio'" header="Ventas">
                <div class="card">
                  <p-table
                          #dtventas
                          [rowHover]="true"
                          [responsive]="true"
                          #dtComision
                          [globalFilterFields]="['numeroDestino']"
                          [value]="mrn.ventas_by_fecha"
                          [scrollable]="true"
                          styleClass="p-datatable-sm"
                          scrollHeight="400px"
                  >
                    <ng-template pTemplate="caption">
                      <div class="p-grid">
                        <div class="p-col-12 p-md-3">
                          <div class="p-grid p-fluid p-formgrid">
                            <div class="p-field p-col-12 p-md-6">
                              <label for="fix">Fecha inicial</label>
                              <input
                                      type="date"
                                      #fInicial
                                      pInputText
                                      id="fix"
                                      min="{{ fInicial.value }}"
                              />
                            </div>
                            <div class="p-field p-col-12 p-md-6">
                              <label for="ffx">Fecha final</label>
                              <input
                                      type="date"
                                      #fFinal
                                      pInputText
                                      id="ffx"
                                      min="{{ fInicial.value }}"
                                      max="{{ calcular_max(fInicial.value) }}"
                              />
                            </div>
                            <div class="p-col-12 p-md-6">
                              <button [disabled]="!fInicial.value"
                                      (click)=" mrn.get_ventas_by_fecha(fInicial.value,fFinal.value,mrn.nodoSeleccionado
                              ) " type="button" pButton pRipple styleClass="p-button-danger" label="Consultar"
                              ></button>
                            </div>
                            <div class="p-col-12 p-md-6">
                              <button (click)=" fInicial.value = ''; fFinal.value = ''; mrn.ventas_by_fecha = [] "
                                      type="button" pButton pRipple styleClass="p-button-danger" label="Limpiar"
                              ></button>
                            </div>
                          </div>
                        </div>
                        <div class="p-col-12 p-md-9" style="text-align: right">
                            <span class="p-input-icon-left ml-auto">
                                <i class="pi pi-search"></i>
                                <input
                                        pInputText
                                        type="text"
                                        (input)="
                                    dtventas.filterGlobal(
                                      $event.target.value,
                                      'contains'
                                    )
                                  "
                                        placeholder="Buscar venta por Destino"
                                />
                              </span>
                        </div>
                      </div>
                    </ng-template>
                    <ng-template pTemplate="header">
                      <tr>
                        <th>Id</th>
                        <th>Empresa</th>
                        <th>Fecha solicitud</th>
                        <th>Valor</th>
                        <th>Ganancia</th>
                        <th>Destino</th>
                        <th style="width: 20%">Resulatdo de la transaccion</th>
                        <th>Venta desde</th>
                      </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-transaccion>
                      <tr>
                        <td style="text-align: center">
                          {{ transaccion.id }}
                          <i
                                  class="fas fa-search accion-tabla_izq"
                                  (click)="
                          mrn.transaccionSeleccionada = transaccion;
                          mrn.verDetalletransaccionByAliado = true
                        "
                          ></i>
                        </td>
                        <td>
                          {{
                          transaccion.producto_venta.producto.empresa.nom_empresa
                          }}
                        </td>
                        <td style="text-align: center">
                          {{ transaccion.created_at | date : "dd-MM-yyyy" }}
                          {{ transaccion.hour_at | date : "shortTime" }}
                        </td>
                        <td style="text-align: right">
                          $ {{ transaccion.valor | number }}
                        </td>
                        <td style="text-align: right">$ {{transaccion.ganancia?transaccion.ganancia:0|number}}</td>
                        <td style="text-align: center">
                          {{ transaccion.numeroDestino }}
                        </td>
                        <td style="width: 20%" class="wrap">
                          {{ transaccion.resultado }}
                        </td>
                        <td style="text-align: center">{{ transaccion.venta_desde }}</td>
                      </tr>
                    </ng-template>
                    <ng-template pTemplate="summary">
                      <div class="p-grid p-fluid p-formgrid" *ngIf="mrn.ventas_by_fecha.length">
                        <div class="p-field p-col-12 p-md-12">
                          <div class="p-grid p-fluid p-formgrid">
                            <div class="p-field p-col-12 p-md-3">
                              <div  class="total_card_container">
                                <div class="total_card" >
                                  <span class="total_card_title">Ventas desde Ganancias</span>
                                  <span class="total_card-value" >${{ getTotalByGanancias(mrn.ventas_by_fecha) | number }}</span>
                                </div>
                              </div>
                            </div>
                            <div class="p-field p-col-12 p-md-3">
                              <div class="total_card_container">
                                <div class="total_card">
                                  <span class="total_card_title">Ventas desde Saldo</span>
                                  <span class="total_card-value">${{ getTotalBySaldo(mrn.ventas_by_fecha) | number }}</span>
                                </div>
                              </div>
                            </div>
                            <div class="p-field p-col-12 p-md-3">
                              <div class="total_card_container">
                                <div  class="total_card" >
                                  <span class="total_card_title">Total Ventas</span>
                                  <span class="total_card-value" >${{ getTotalVentas(mrn.ventas_by_fecha) | number }}</span>
                                </div>
                              </div>
                            </div>
                            <div class="p-field p-col-12 p-md-3">
                              <div class="total_card_container">
                                <div class="total_card">
                                  <span class="total_card_title">Total Ganancias</span>
                                  <span class="total_card-value" >${{ getTotalGanancias(mrn.ventas_by_fecha) | number }}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="p-field p-col-12 p-md-9"></div><!--espacio en blanco-->
                      </div>
                    </ng-template>
                    <ng-template pTemplate="emptymessage" let-columns>
                      <tr >
                        <td [attr.colspan]="8">
                          <p-messages severity="info">
                            <ng-template pTemplate>
                              <div  class="ml-2">
                                Seleccione la fecha inicial y la fecha final para
                                generar una consulta.
                              </div>
                            </ng-template>
                          </p-messages>
                        </td>
                      </tr>
                    </ng-template>
                  </p-table>
                </div>
              </p-tabPanel>
              <p-tabPanel header="Historial de saldos">
                <div class="card">
                  <p-table
                          #dt
                          dataKey="id"
                          [rowHover]="true"
                          [responsive]="true"
                          (onFilter)="getFilteredData($event)"
                          [value]="mrn.historial_solicitudes_saldo"
                          [globalFilterFields]="[
                  'nodo.representanteLegal',
                  'tipo_transaccion',
                  'tipoServicio',
                  'estado'
                ]"
                          [scrollable]="true"
                          scrollHeight="400px"
                  >
                    <ng-template pTemplate="caption">
                      <div class="p-grid">
                        <div class="p-col-12 p-md-4">
                          <div class="p-grid p-fluid p-formgrid">
                            <div class="p-field p-col-12 p-md-6">
                              <label for="fi">Desde</label>
                              <input type="date" #fInicial pInputText id="fi" />
                            </div>
                            <div class="p-field p-col-12 p-md-6">
                              <label for="ff">Hasta</label>
                              <input type="date" #fFinal pInputText id="ff" />
                            </div>
                            <div class="p-col-12 p-md-6">
                              <button *ngIf="fInicial.value&&fFinal.value"
                                      (click)="
                              mrn.getHistorialSolicitudesSaldoByNodo(
                                fInicial.value,
                                fFinal.value,
                                this.mrn.nodoSeleccionado.id ) "
                                      type="button" pButton pRipple
                                      styleClass="p-button-danger" icon="pi pi-search" label="Consultar"  ></button>
                            </div>
                            <div
                                    *ngIf="fInicial.value && fFinal.value"
                                    class="p-col-12 p-md-6"
                            >
                              <button
                                      (click)="
                              fInicial.value = '';
                              fFinal.value = '';
                              mrn.historial_solicitudes_saldo = [];
                              this.mrn.dataFiltrada = []
                            "
                                      type="button"
                                      pButton
                                      pRipple
                                      icon="pi pi-times"
                                      label="Limpiar"
                              ></button>
                            </div>
                          </div>
                        </div>
                        <div
                                class="p-col-12 p-md-4"
                                style="text-align: right"
                        ></div>
                        <div class="p-col-12 p-md-4" style="text-align: right">
                          <div class="p-grid p-fluid p-formgrid">
                            <div class="p-field p-col-12 p-md-6">
                              <span class="p-input-icon-left ml-auto">
                            <i class="pi pi-search"></i>
                            <input
                                    pInputText
                                    type="text"
                                    (input)="
                                dt.filterGlobal($event.target.value, 'contains')
                              "
                                    placeholder="Representante legal"
                            />
                          </span>
                            </div>
                            <div class="p-field p-col-12 p-md-6">
                              <p-dropdown
                                      [options]="mrn.formaPagoFilter"
                                      (onChange)="dt.filterGlobal($event.value, 'equals')"
                                      placeholder="Formas de pago"
                              >
                                <ng-template let-option pTemplate="item">
                                  <span>{{ option.label }}</span>
                                </ng-template>
                              </p-dropdown>
                            </div>
                            <div class="p-field p-col-12 p-md-6">
                              <p-dropdown
                                      [options]="mrn.tipoServicioTransaccion"
                                      (onChange)="dt.filterGlobal($event.value, 'equals')"
                                      placeholder="Comisión"
                              >
                                <ng-template let-option pTemplate="item">
                                  <span>{{ option.label }}</span>
                                </ng-template>
                              </p-dropdown>
                            </div>
                            <div class="p-field p-col-12 p-md-6">
                              <p-dropdown
                                      [options]="mrn.estadosTransaccion"
                                      (onChange)="dt.filterGlobal($event.value, 'equals')"
                                      placeholder="Estado"
                              >
                                <ng-template let-option pTemplate="item">
                                  <span>{{ option.label }}</span>
                                </ng-template>
                              </p-dropdown>
                            </div>
                            <div
                                    class="p-col-12 p-md-12"
                                    *ngIf="mrn.historial_solicitudes_saldo.length"
                            >
                              <button
                                      type="button"
                                      pButton
                                      pRipple
                                      icon="pi pi-file-excel"
                                      (click)="exportExcel_saldos()"
                                      class="p-button-success"
                                      label="Exportar a Excel"
                                      tooltipPosition="bottom"
                              ></button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </ng-template>
                    <ng-template pTemplate="header">
                      <tr>
                        <th>Tipo</th>
                        <th style="width: 5%">Id</th>
                        <th>Fecha aprobacion</th>
                        <th>Representante legal</th>
                        <th>Formas de pago</th>
                        <th
                                style="width: 10%"
                                *ngIf="mrn.nodoSeleccionado['id'] != 1"
                        >
                          Saldo inicial
                        </th>
                        <th style="width: 10%">Valor</th>
                        <th
                                style="width: 10%"
                                *ngIf="mrn.nodoSeleccionado['id'] != 1"
                        >
                          Saldo final
                        </th>
                        <th>Estado solicitud</th>
                        <th>Soporte</th>
                      </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-transaccion>
                      <tr>
                        <td style="text-align: left" *ngIf="transaccion.nodo.id ==mrn.nodoSeleccionado.id">
                          <span *ngIf="transaccion.tipo_transaccion == 'SSC'" style="font-weight:bold;color:darkgreen">Compra de saldo</span>
                          <span *ngIf="transaccion.tipo_transaccion == 'SSCR'" style="font-weight:bold;color:darkgreen">Compra de saldo</span>
                          <span *ngIf="transaccion.tipo_transaccion == 'CJG'" style="font-weight:bold;color:darkgreen">Traslado de ganancias</span>
                          <span *ngIf="transaccion.tipo_transaccion == 'AJS'" style="font-weight:bold;color:darkgreen">Reversión de saldo</span>
                        </td>
                        <td style="text-align: left" *ngIf="transaccion.nodo.id != mrn.nodoSeleccionado.id">
                          <span *ngIf="transaccion.tipo_transaccion == 'SSC'" style="font-weight:bold;color:darkred">Venta de saldo</span>
                          <span *ngIf="transaccion.tipo_transaccion == 'SSCR'" style="font-weight:bold;color:darkred">Venta de saldo</span>
                          <span *ngIf="transaccion.tipo_transaccion == 'CJG'" style="font-weight:bold;color:darkgreen">Traslado de ganancias</span>
                          <span *ngIf="transaccion.tipo_transaccion == 'AJS'" style="font-weight:bold;color:darkgreen">Reversión de saldo</span>
                        </td>
                        <td style="width: 5%; text-align: center">
                          {{ transaccion.id }}
                        </td>
                        <td style="text-align: center">
                          {{ transaccion.fecha_aprobacion?transaccion.fecha_aprobacion:transaccion.created_at | date : "dd-MM-yyyy" }}
                          {{ transaccion.hour_at | date : "mediumTime" }}
                        </td>
                        <td>{{ transaccion.nodo.representanteLegal }}</td>
                        <td style="text-align: center">
                      <span *ngIf="transaccion.tipo_transaccion == 'AJS'"
                      >n/a</span>
                          <span *ngIf="transaccion.tipo_transaccion != 'AJS'">{{
                            transaccion.tipo_transaccion == "SSC"
                                    ? "Contado"
                                    : "Credito"
                            }}</span>
                        </td>
                        <td
                                *ngIf="mrn.nodoSeleccionado.id != 1"
                                style="text-align: right; width: 10%">
                      <span
                              *ngIf="transaccion.nodo.id == mrn.nodoSeleccionado.id"
                      >$ {{ transaccion.ultimoSaldo | number }}</span
                      >
                          <span
                                  *ngIf="transaccion.nodo.id != mrn.nodoSeleccionado.id"
                          >$
                            {{
                            transaccion.ultimo_saldo_distribuidor | number
                            }}</span
                          >
                        </td>
                        <td style="text-align: right;width: 10%">
                      <span
                              style="text-align: right; width: 10%"
                              *ngIf="
                          transaccion.nodo.id == mrn.nodoSeleccionado.id &&
                          transaccion.tipo_transaccion != 'AJS'
                        "
                              style="font-weight: bold; color: darkgreen"
                      >$ {{ transaccion.valor | number }}</span
                      >
                          <span
                                  style="text-align: right; width: 10%"
                                  *ngIf="
                          transaccion.nodo.id != mrn.nodoSeleccionado.id &&
                          transaccion.tipo_transaccion != 'AJS'
                        "
                                  style="font-weight: bold; color: darkred"
                          >$ {{ transaccion.valor | number }}</span
                          >
                          <span
                                  style="text-align: right; width: 10%"
                                  *ngIf="
                          transaccion.nodo.id == mrn.nodoSeleccionado.id &&
                          transaccion.tipo_transaccion == 'AJS'
                        "
                                  style="font-weight: bold; color: darkred"
                          >$ {{ transaccion.valor | number }}</span
                          >
                          <span
                                  style="text-align: right; width: 10%"
                                  *ngIf="
                          transaccion.nodo.id != mrn.nodoSeleccionado.id &&
                          transaccion.tipo_transaccion == 'AJS'
                        "
                                  style="font-weight: bold; color: darkgreen"
                          >$ {{ transaccion.valor | number }}</span
                          >
                        </td>
                        <td
                                *ngIf="mrn.nodoSeleccionado.id != 1"
                                style="text-align: right; width: 10%"
                        >
                      <span
                              *ngIf="transaccion.nodo.id == mrn.nodoSeleccionado.id"
                      >$ {{ transaccion.saldo_actual | number }}</span
                      >
                          <span
                                  *ngIf="transaccion.nodo.id != mrn.nodoSeleccionado.id"
                          >$
                            {{
                            transaccion.saldo_actual_distribuidor | number
                            }}</span
                          >
                        </td>
                        <td style="text-align: center">
                          <span>{{ transaccion.estado }}</span>
                        </td>
                        <td>
                          <span style="cursor: pointer" (click)="mrn.transaccionSeleccionada = transaccion;mrn.verSoporteTransaccion = true" *ngIf="transaccion.soporte ">Ver soporte</span>
                        </td>
                      </tr>
                    </ng-template>
                    <ng-template pTemplate="summary">
                      <div *ngIf="mrn.nodoSeleccionado.tipo == 'Comercio' ">
                        <div class="p-fluid p-formgrid p-grid">
                          <div class="p-field p-col-3">
                            <div class="total_card_container">
                              <div class="total_card">
                                <span class="total_card_title">Compra saldo contado</span>
                                <span class="total_card-value">${{getTotalCompraContadoComercio()|number}}</span>
                              </div>
                            </div>
                          </div>
                          <div class="p-field p-col-3">
                            <div class="total_card_container">
                              <div class="total_card">
                                <span class="total_card_title">Compra saldo credito</span>
                                <span class="total_card-value">${{getTotalCompraCreditoComercio()|number}}</span>
                              </div>
                            </div>
                          </div>
                          <div class="p-field p-col-3">
                            <div class="total_card_container">
                              <div class="total_card">
                                <span class="total_card_title">Reversiones de su distribuidor</span>
                                <span class="total_card-value">${{getTotalReversionesDistribuidor()|number}}</span>
                              </div>
                            </div>
                          </div>
                          <div class="p-field p-col-3"></div>
                        </div>
                      </div><!--visualizacion cuando es un Comercio -->
                      <div *ngIf="mrn.nodoSeleccionado.tipo == 'Distribuidor'">
                        <div class="p-fluid p-formgrid p-grid">
                          <!--Compras-->
                          <div class="p-field p-col-3">
                            <div class="total_card_container">
                              <div class="total_card">
                                <span class="total_card_title">Compras saldo contado</span>
                                <span class="total_card-value">${{getTotalComprasContado()|number}}</span>
                              </div>
                            </div>
                          </div>
                          <div class="p-field p-col-3">
                            <div class="total_card_container">
                              <div class="total_card">
                                <span class="total_card_title">Compras saldo credito</span>
                                <span class="total_card-value">${{getTotalComprasCredito()|number}}</span>
                              </div>
                            </div>
                          </div>
                          <div class="p-field p-col-3">
                            <div class="total_card_container">
                              <div class="total_card">
                                <span class="total_card_title">Traslados de ganancias</span>
                                <span class="total_card-value">${{getTotalTrasladoGanancias()|number}}</span>
                              </div>
                            </div>
                          </div>
                          <div class="p-field p-col-3">
                            <div class="total_card_container">
                              <div class="total_card">
                                <span class="total_card_title">Reversiones a aliados</span>
                                <span class="total_card-value">${{getTotalVentaReversiones()|number}}</span>
                              </div>
                            </div>
                          </div>
                          <!--Ventas-->
                          <div class="p-field p-col-3">
                            <div class="total_card_container">
                              <div class="total_card">
                                <span class="total_card_title">Venta saldo contado</span>
                                <span class="total_card-value">${{getTotalVentaContado()|number}}</span>
                              </div>
                            </div>
                          </div>
                          <div class="p-field p-col-3">
                            <div class="total_card_container">
                              <div class="total_card">
                                <span class="total_card_title">Venta saldo credito</span>
                                <span class="total_card-value">${{getTotalVentaCredito()|number}}</span>
                              </div>
                            </div>
                          </div>
                          <div class="p-field p-col-3">
                            <div class="total_card_container">
                              <div class="total_card">
                                <span class="total_card_title">Reversiones de su distribuidor</span>
                                <span class="total_card-value">${{getTotalReversionesPropias()|number}}</span>
                              </div>
                            </div>
                          </div>
                          <div class="p-field p-col-3">
                          </div>
                        </div>
                      </div><!--visualizacion cuando es un Distribuidor -->
                    </ng-template>
                    <ng-template pTemplate="emptymessage" let-columns>
                      <tr>
                        <td [attr.colspan]="10">
                          <p-messages severity="info">
                            <ng-template pTemplate>
                              <div class="ml-2">
                                Seleccione la fecha inicial y la fecha final para
                                generar una consulta.
                              </div>
                            </ng-template>
                          </p-messages>
                        </td>
                      </tr>
                    </ng-template>
                  </p-table>
                </div>
              </p-tabPanel>
              <p-tabPanel *ngIf="mrn.nodoSeleccionado" header="Estado de cartera">
                <div class="card">
                  <p-tabView>
                    <p-tabPanel header="Facturas pendientes">
                      <p-table
                              #dtfacpen
                              [rowHover]="true"
                              [responsive]="true"
                              [value]="mrn.facturasPendientes"
                              [globalFilterFields]="['id', 'valor', 'dias_mora']"
                      >
                        <ng-template pTemplate="caption">
                          <div class="p-d-flex">
                            <button
                                    pTooltip="Actualizar tabla"
                                    label="Actualizar tabla"
                                    pButton
                                    pRipple
                                    type="button"
                                    icon="fas fa-sync-alt"
                                    (click)="
                            mrn.getFacturasMora(mrn.nodoSeleccionado, false)
                          "
                                    class="accion-tabla p-button-rounded p-button-info"
                            ></button>
                            <span class="p-input-icon-left p-ml-auto">
                          <i class="pi pi-search"></i>
                          <input
                                  pInputText
                                  type="text"
                                  (input)="
                              dtfacpen.filterGlobal(
                                $event.target.value,
                                'contains'
                              )
                            "
                                  placeholder="Buscar factura"
                          />
                        </span>
                          </div>
                        </ng-template>
                        <ng-template pTemplate="header">
                          <tr>
                            <th style="width: 10%">Id</th>
                            <th style="width: 20%">Fecha de aprobación</th>
                            <th style="width: 20%">Fecha de limite de pago</th>
                            <th style="width: 20%">Valor solicitado</th>
                            <th style="width: 20%">Dias de mora</th>
                            <th style="width: 20%">Estado de pago</th>
                            <th style="width: 10%"></th>
                          </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-transaccion>
                          <tr>
                            <td
                                    style="text-align: center"
                                    class="accion-tabla"
                                    (click)="
                            mrn.transaccionSeleccionada = transaccion;
                            mrn.verDetalletransaccion = true
                          "
                            >
                              {{ transaccion.id }}
                            </td>
                            <td>{{ transaccion.fecha_aprobacion | date }}</td>
                            <td>{{ transaccion.fecha_pago | date }}</td>
                            <td style="text-align: right">
                              $ {{ transaccion.valor | number }}
                            </td>
                            <td style="text-align: center">
                              {{ transaccion.dias_mora }} dias
                            </td>
                            <td style="text-align: center">
                              {{ transaccion.estadoPago }}
                            </td>
                            <td style="text-align: center">
                              <i
                                      pTooltip="Ver pagos"
                                      (click)="seleccionarFactura(transaccion)"
                                      class="accion-tabla fas fa-cash-register"
                              ></i>
                              <i
                                      *ngIf="transaccion.estadoPago == 'Pago en revision'"
                                      pTooltip="Aprobar pago"
                                      (click)="mrn.aprobar_pago_factura(transaccion)"
                                      class="accion-tabla fas fa-check-circle"
                              ></i>
                            </td>
                          </tr>
                        </ng-template>
                      </p-table>
                    </p-tabPanel>
                    <p-tabPanel header="Facturas en mora">
                      <div *ngIf="mrn.facturasMora.length" style="margin-top: 20px">
                        <h5>Facturas en mora</h5>
                        <p-table
                                #dtfacpen
                                [rowHover]="true"
                                [responsive]="true"
                                [value]="mrn.facturasMora"
                                [globalFilterFields]="['id', 'valor', 'dias_mora']"
                        >
                          <ng-template pTemplate="caption">
                            <div class="p-d-flex">
                          <span class="p-input-icon-left p-ml-auto">
                            <i class="pi pi-search"></i>
                            <input
                                    pInputText
                                    type="text"
                                    (input)="
                                dtfacpen.filterGlobal(
                                  $event.target.value,
                                  'contains'
                                )
                              "
                                    placeholder="Buscar factura"
                            />
                          </span>
                            </div>
                          </ng-template>
                          <ng-template pTemplate="header">
                            <tr>
                              <th style="width: 10%">Id</th>
                              <th style="width: 20%">Fecha de aprobación</th>
                              <th style="width: 20%">Fecha de limite de pago</th>
                              <th style="width: 20%">Valor solicitado</th>
                              <th style="width: 20%">Dias de mora</th>
                              <th style="width: 20%">Estado de pago</th>
                              <th style="width: 10%"></th>
                            </tr>
                          </ng-template>
                          <ng-template pTemplate="body" let-transaccion>
                            <tr>
                              <td
                                      style="text-align: center"
                                      class="accion-tabla"
                                      (click)="
                              mrn.transaccionSeleccionada = transaccion;
                              mrn.verDetalletransaccion = true
                            "
                              >
                                {{ transaccion.id }}
                              </td>
                              <td>{{ transaccion.fecha_aprobacion | date }}</td>
                              <td>{{ transaccion.fecha_pago | date }}</td>
                              <td style="text-align: right">
                                $ {{ transaccion.valor | number }}
                              </td>
                              <td style="text-align: center">
                                {{ transaccion.dias_mora }} dias
                              </td>
                              <td style="text-align: center">
                                {{ transaccion.estadoPago }}
                              </td>
                              <td style="text-align: center">
                                <i
                                        pTooltip="Ver pagos"
                                        (click)="seleccionarFactura(transaccion)"
                                        class="accion-tabla fas fa-cash-register"
                                ></i>
                                <i
                                        *ngIf="
                                transaccion.estadoPago == 'Pago en revision'
                              "
                                        pTooltip="Aprobar pago"
                                        (click)="mrn.aprobar_pago_factura(transaccion)"
                                        class="accion-tabla fas fa-check-circle"
                                ></i>
                              </td>
                            </tr>
                          </ng-template>
                        </p-table>
                      </div>
                      <div
                              *ngIf="!mrn.facturasMora.length"
                              style="margin-top: 20px"
                      >
                        <div class="card">
                          <div class="card-content" style="text-align: center; color: grey" >
                            <i style="font-size: 5em" class="far fa-laugh-beam"></i>
                            <h2 *ngIf="mrn.nodoSeleccionado">
                              {{ mrn.nodoSeleccionado.razonSocial }} esta al dia!
                            </h2>
                          </div>
                        </div>
                      </div>
                    </p-tabPanel>
                  </p-tabView>
                </div>
              </p-tabPanel>
              <p-tabPanel *ngIf="mrn.nodoSeleccionado" header="Pagos de saldos">
                <div class="card">
                  <p-table
                          dataKey="id"
                          #dtPagos
                          [rowHover]="true"
                          [responsive]="true"
                          [value]="mrn.pagos_by_fecha"
                          [globalFilterFields]="[
                  'transaccion.id',
                  'entidad',
                  'numero_recibo'
                ]"
                  >
                    <ng-template pTemplate="caption">
                      <div class="p-grid">
                        <div class="p-col-12 p-md-3">
                          <div class="p-grid p-fluid p-formgrid">
                            <div class="p-field p-col-12 p-md-6">
                              <label for="fip">Desde</label>
                              <input type="date" #fInicial pInputText id="fip" />
                            </div>
                            <div class="p-field p-col-12 p-md-6">
                              <label for="fip">Hasta</label>
                              <input type="date" #fFinal pInputText id="fip2" />
                            </div>
                            <div class="p-col-12 p-md-6">
                              <button
                                      (click)="
                              mrn.get_pagos_by_fecha_comercio_seleccionado(
                                fInicial.value,
                                fFinal.value
                              )
                            "
                                      type="button"
                                      pButton
                                      pRipple
                                      styleClass="p-button-danger"
                                      icon="pi pi-search"
                                      label="Consultar"
                              ></button>
                            </div>
                            <div class="p-col-12 p-md-6">
                              <button
                                      (click)="
                              fInicial.value = '';
                              fFinal.value = '';
                              mrn.pagos_by_fecha = []
                            "
                                      type="button"
                                      pButton
                                      pRipple
                                      styleClass="p-button-danger"
                                      icon="fas fa-broom"
                                      label="Limpiar"
                              ></button>
                            </div>
                          </div>
                        </div>
                        <div class="p-col-12 p-md-9" style="text-align: right">
                          <input
                                  pTooltip="Usted puede buscar por transaccion,entidad,numero de comprobante."
                                  pInputText
                                  type="text"
                                  (input)="
                          dtPagos.filterGlobal($event.target.value, 'contains')
                        "
                                  placeholder="Buscar pago"
                          />
                        </div>
                      </div>
                    </ng-template>
                    <ng-template pTemplate="header">
                      <tr>
                        <th style="width: 5%">Id</th>
                        <th>Transaccion</th>
                        <th>Valor solicitado</th>
                        <th>Fecha aprobación</th>
                        <th>Fecha pago</th>
                        <th>Abono</th>
                        <th>Entidad</th>
                        <th>Numero de recibo</th>
                        <th>Soporte</th>
                      </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-pago>
                      <tr>
                        <td>{{ pago.id }}</td>
                        <td>{{ pago.transaccion.id }}</td>
                        <td>$ {{ pago.transaccion.valor | number }}</td>
                        <td>{{ pago.transaccion.fecha_aprobacion }}</td>
                        <td>{{ pago.created_at }}</td>
                        <td>$ {{ pago.abono | number }}</td>
                        <td>{{ pago.entidad }}</td>
                        <td>{{ pago.numero_recibo }}</td>
                        <td style="text-align: center">
                          <i
                                  pTooltip="Ver soporte"
                                  style="cursor: pointer"
                                  (click)="
                          mrn.pagoSeleccionado = '';
                          mrn.pagoSeleccionado = pago;
                          mrn.verSoporte = true
                        "
                                  *ngIf="pago.soporte"
                                  class="fas fa-image"
                          ></i>
                        </td>
                      </tr>
                    </ng-template>
                    <ng-template pTemplate="summary">
                      <div class="flex align-items-center justify-content-between">
                        Se encontraron {{ mrn.pagos_by_fecha.length }} pagos.
                      </div>
                    </ng-template>
                    <ng-template pTemplate="emptymessage" let-columns>
                      <tr>
                        <td [attr.colspan]="9">
                          <div
                                  class="card"
                                  style="background: rgba(147, 226, 255, 0.67)"
                          >
                            <p>
                              <i
                                      style="font-size: 1.8em"
                                      class="fas fa-info-circle accion-tabla"
                              >
                              </i
                              >Para realizar una consulta por favor seleccione una
                              Fecha.
                            </p>
                          </div>
                        </td>
                      </tr>
                    </ng-template>
                  </p-table>
                </div>
              </p-tabPanel>
              <p-tabPanel *ngIf="mrn.nodoSeleccionado && mrn.nodoSeleccionado.tipo == 'Comercio'"  header="Movimientos">
                <div class="card">
                  <p-table
                          dataKey="id"
                          #dtMovimientos
                          [rowHover]="true"
                          [responsive]="true"
                          [value]="mrn.movimientos_by_fecha"
                          [scrollable]="true"
                          styleClass="p-datatable-sm"
                          scrollHeight="400px"
                          [globalFilterFields]="[ 'transaccion.id','entidad','numero_recibo']">
                    <ng-template pTemplate="caption">
                      <div class="p-grid">
                        <div class="p-col-12 p-md-3">
                          <div class="p-grid p-fluid p-formgrid">
                            <div class="p-field p-col-12 p-md-6">
                              <label for="fip">Desde</label>
                              <input type="date" #fInicial pInputText id="fip" />
                            </div>
                            <div class="p-field p-col-12 p-md-6">
                              <label for="fip">Hasta</label>
                              <input type="date" #fFinal pInputText id="fip2" />
                            </div>
                            <div class="p-col-12 p-md-6">
                              <button
                                      (click)=" mrn.movimientos_by_fecha_comercio_seleccionado( fInicial.value, fFinal.value )"
                                      type="button" pButton pRipple styleClass="p-button-danger"
                                      label="Consultar" ></button>
                            </div>
                            <div class="p-col-12 p-md-6">
                              <button
                                      (click)="
                              fInicial.value = '';
                              fFinal.value = '';
                              mrn.pagos_by_fecha = []
                            "
                                      type="button"
                                      pButton
                                      pRipple
                                      styleClass="p-button-danger"
                                      label="Limpiar"
                              ></button>
                            </div>
                            <div class="p-col-12 p-md-12" *ngIf="mrn.movimientos_by_fecha.length">
                              <button
                                      type="button"
                                      pButton pRipple icon="pi pi-file-excel" style="margin-top: 10px"
                                      (click)="exportExcelMovimientos()" label="Exportar a Excel"
                                      tooltipPosition="bottom" class="accion-tabla p-button-rounded p-button-success"
                              ></button>
                            </div>
                          </div>
                        </div>
                        <div class="p-col-12 p-md-9">

                        </div>
                      </div>
                    </ng-template>
                    <ng-template pTemplate="header">
                      <tr>
                        <th>Tipo</th>
                        <th>Transaccion</th>
                        <th>Desde</th>
                        <th>Fecha aprobación</th>
                        <th>Saldo inicial</th>
                        <th>Valor solicitado</th>
                        <th>Saldo final</th>
                        <th>Estado</th>
                      </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-movimiento>
                      <tr>
                        <td *ngIf="movimiento.nodo == mrn.nodoSeleccionado.id">
                          <span style="color: darkred">{{ movimiento.tipo_transaccion ==='VEN'?'Venta':''}}</span>
                          <span style="color: darkgreen">{{ (movimiento.tipo_transaccion ==='SSC')?'Compra de saldo contado':''}}</span>
                          <span style="color: darkgreen">{{ (movimiento.tipo_transaccion ==='SSCR')?'Compra de saldo crédito':''}}</span>
                          <span style="color: darkred">{{ movimiento.tipo_transaccion ==='AJS'?'Reversion de saldo':''}}</span>
                          <span style="color: darkgreen">{{ movimiento.tipo_transaccion ==='CJG'?'Traslado de ganancias':''}}</span>
                        </td>
                        <td *ngIf="movimiento.nodo != mrn.nodoSeleccionado.id">
                          <span style="color: darkred">{{ movimiento.tipo_transaccion ==='VEN'?'Venta':''}}</span>
                          <span style="color: darkred">{{ (movimiento.tipo_transaccion ==='SSC')?'Venta de saldo contado':''}}</span>
                          <span style="color: darkred">{{ (movimiento.tipo_transaccion ==='SSCR')?'Venta de saldo crédito':''}}</span>
                          <span style="color: darkgreen">{{ movimiento.tipo_transaccion ==='AJS'?'Reversion de saldo':''}}</span>
                          <span style="color: darkgreen">{{ movimiento.tipo_transaccion ==='CJG'?'Traslado de ganancias':''}}</span>
                        </td>
                        <td>{{ movimiento.id }}</td>
                        <td>{{ movimiento.venta_desde? movimiento.venta_desde:'n/a'}}</td>
                        <td>{{ movimiento.created_at }}</td>
                        <td>${{ movimiento.ultimoSaldo|number }}</td>
                        <td>${{ movimiento.valor|number  }}</td>
                        <td>${{ movimiento.saldo_actual|number  }}</td>
                        <td>{{ movimiento.estado }}</td>
                      </tr>
                    </ng-template>
                    <ng-template pTemplate="summary">
                      <div class="p-fluid p-formgrid p-grid" *ngIf="mrn.movimientos_by_fecha.length">
                        <div class="p-field p-col-3">
                          <div class="total_card_container">
                            <div class="total_card">
                              <span class="total_card_title">Ventas totales</span>
                              <span class="total_card-value">${{getTotalVentas(mrn.movimientos_by_fecha)|number}}</span>
                            </div>
                          </div>
                        </div>
                        <div class="p-field p-col-3">
                          <div class="total_card_container">
                            <div class="total_card">
                              <span class="total_card_title">Ventas desde saldo</span>
                              <span class="total_card-value">${{getTotalVentasDesdeSaldo(mrn.movimientos_by_fecha)|number}}</span>
                            </div>
                          </div>
                        </div>
                        <div class="p-field p-col-3">
                          <div class="total_card_container">
                            <div class="total_card">
                              <span class="total_card_title">Ventas desde ganancias</span>
                              <span class="total_card-value">${{getTotalVentasDesdeGanancias(mrn.movimientos_by_fecha)|number}}</span>
                            </div>
                          </div>
                        </div>
                        <div class="p-field p-col-3">
                          <div class="total_card_container">
                            <div class="total_card">
                              <span class="total_card_title">Compra saldo contado</span>
                              <span class="total_card-value">${{getTotalCompraContadoMovComercio()|number}}</span>
                            </div>
                          </div>
                        </div>
                        <div class="p-field p-col-3">
                          <div class="total_card_container">
                            <div class="total_card">
                              <span class="total_card_title">Compra saldo credito</span>
                              <span class="total_card-value">${{getTotalCompraCreditoMovComercio()|number}}</span>
                            </div>
                          </div>
                        </div>
                        <div class="p-field p-col-3">
                          <div class="total_card_container">
                            <div class="total_card">
                              <span class="total_card_title">Reversiones de su distribuidor</span>
                              <span class="total_card-value">${{getTotalReversionesMovDistribuidor()|number}}</span>
                            </div>
                          </div>
                        </div>
                        <div class="p-field p-col-3"></div>
                      </div>
                    </ng-template>
                    <ng-template pTemplate="emptymessage" let-columns>
                      <tr>
                        <td [attr.colspan]="8">
                          <div
                                  class="card"
                                  style="background: rgba(147, 226, 255, 0.67)"
                          >
                            <p>
                              <i
                                      style="font-size: 1.8em"
                                      class="fas fa-info-circle accion-tabla"
                              >
                              </i
                              >Para realizar una consulta por favor seleccione una
                              fecha de inicio y una fecha de fin.
                            </p>
                          </div>
                        </td>
                      </tr>
                    </ng-template>
                  </p-table>
                </div>
              </p-tabPanel>
            </p-tabView>
          </p-tabPanel>
        </p-tabView>
      </p-accordionTab><!--Aliado seleccionado-->
    </p-accordion>
  </div>
</div>
<p-menu #menualiados [popup]="true" [model]="mrn.menuAliados"></p-menu>
<!--<p-dialog [modal]="true" header="Codigo QR" [(visible)]="mrn.verQR">
  <ngx-qrcode
    [elementType]="mrn.elementType"
    [value]="mrn.valueQR"
    [errorCorrectionLevel]="mrn.errorCorrectionLevel"
  ></ngx-qrcode>
</p-dialog>-->
<p-dialog
  [modal]="true"
  header="Detalle de pagos a factura"
  [(visible)]="mrn.ver_pagos_facturas"
  [modal]="true"
  [style]="{ width: '50vw' }"
>
  <div class="card" *ngIf="mrn.transaccionSeleccionada">
    <div style="padding-bottom: 15px">
      <div class="p-grid">
        <div class="p-col-12 p-md-6">
          <table class="tabla_calculadora">
            <tr>
              <td>Numero Transaccion</td>
              <th style="text-align: right">
                {{ mrn.transaccionSeleccionada.id }}
              </th>
            </tr>
            <tr>
              <td>Total a pagar</td>
              <th style="text-align: right">
                $ {{ mrn.transaccionSeleccionada.valor | number }}
              </th>
            </tr>
            <tr>
              <td>Total abonado</td>
              <th style="text-align: right">
                $
                {{
                  mrn.transaccionSeleccionada.valor -
                    mrn.transaccionSeleccionada.saldo_pendiente_pago | number
                }}
              </th>
            </tr>
            <tr>
              <td>Saldo</td>
              <th style="text-align: right">
                $
                {{ mrn.transaccionSeleccionada.saldo_pendiente_pago | number }}
              </th>
            </tr>
          </table>
        </div>
        <div class="p-col-12 p-md-6">
          <div
            style="text-align: center; padding: 25px"
            *ngIf="mrn.pagoSeleccionado"
          >
            <img
              style="width: 100%"
              src="{{ mrn.pagoSeleccionado.soporte }}"
              alt=""
            />
            <span style="cursor: pointer" (click)="ampliar_imagen = true"
              >Ampliar imagen</span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="card">
    <h5>Pagos Realizados</h5>
    <p-table [rowHover]="true" [value]="mrn.abonos">
      <ng-template pTemplate="header">
        <tr>
          <th>Comprobante</th>
          <th>Entidad reacudo</th>
          <th>Numero de comprobante</th>
          <th>Valor de abono</th>
          <th>Estado</th>
          <th>Fecha</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-pago>
        <tr>
          <td style="text-align: center">
            <i
              pTooltip="Ver comprobante de pago."
              (click)="mrn.pagoSeleccionado = ''; mrn.pagoSeleccionado = pago"
              *ngIf="pago.soporte"
              class="fas fa-file-alt"
              style="cursor: pointer"
            ></i>
          </td>
          <td>{{ pago.entidad }}</td>
          <td>{{ pago.numero_recibo }}</td>
          <td>${{ pago.abono | number }}</td>
          <td>{{ pago.estadoPago }}</td>
          <td>{{ pago.created_at | date }}</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</p-dialog>
<p-dialog
  [modal]="true"
  [(visible)]="ampliar_imagen"
  [modal]="true"
  [style]="{ width: '70vw' }"
>
  <div style="text-align: center; padding: 25px" *ngIf="mrn.pagoSeleccionado">
    <img style="width: 100%" src="{{ mrn.pagoSeleccionado.soporte }}" alt="" />
    <span (click)="ampliar_imagen = true">Ampliar imagen</span>
  </div>
</p-dialog>
<p-dialog
  [modal]="true"
  header="Abonar a factura"
  [(visible)]="mrn.verFormPagosMulti" [modal]="true" [style]="{ width: '50vw' }" >
  <div class="card">
    <h5>Facturas a pagar</h5>
    <div class="card">
      <form [formGroup]="mrn.formPagos">
        <div class="p-fluid p-formgrid p-grid">
          <div class="p-field p-col-12 p-md-6">
            <div class="p-fluid p-formgrid p-grid">
              <div class="p-field p-col-12 p-md-6">
                <label for="abono">Abono a la factura</label>
                <p-inputNumber
                  formControlName="abono"
                  id="abono"
                  mode="currency"
                  currency="COP"
                  [minFractionDigits]="0"
                ></p-inputNumber>
              </div>
              <div class="p-field p-col-12 p-md-6">
                <label for="numero_recibo">Comprobante de recaudo</label>
                <input
                  #numero
                  (input)="mrn.verificar_soporte_pago(numero.value)"
                  formControlName="numero_recibo"
                  id="numero_recibo"
                  type="text"
                  pInputText
                />
                <small>{{ mrn.recibo_ok }}</small>
              </div>
              <div class="p-field p-col-12 p-md-12">
                <label for="entidad">Entidad recaudo</label>
                <p-dropdown
                  appendTo="body"
                  id="entidad"
                  [options]="mrn.entidad_recaudo"
                  formControlName="entidad"
                  placeholder="Selecciona una entidad"
                  optionLabel="label"
                  optionValue="value"
                ></p-dropdown>
              </div>
            </div>
          </div>
          <div
            *ngIf="mrn.formPagos.value['numero_recibo']" class="p-field p-col-12 p-md-6 subir_soporte" >
            <div *ngIf="mrn.formPagos.value['soporte']">
              <img
                src="{{ mrn.formPagos.value['soporte'] }}"
                style="width: 100%"
                alt=""
              />
            </div>
            <div *ngIf="!mrn.formPagos.value['soporte'] && soporte_requerido">
              <i
                class="fas fa-upload"
                style="font-size: 5em; cursor: pointer"
                pTooltip="Subir comprobante de pago."
                (click)="mrn.ver_subir_soporte_pago_fac_mora = true"
              ></i>
            </div>
            <p
              *ngIf="!mrn.formPagos.value['soporte'] && soporte_requerido"
              style="padding: 15px 0 15px 0"
            >
              Subir Comprobante de pago.
            </p>
            <div class="field-checkbox">
              <p-checkbox
                [ngModelOptions]="{ standalone: true }"
                [binary]="true"
                [(ngModel)]="soporte_requerido"
                id="binary"
              ></p-checkbox>
              <label for="binary" style="margin-left: 10px; font-size: 1.5em">{{
                soporte_requerido ? "Soporte reqerido" : "Soporte no requerido"
              }}</label>
            </div>
          </div>
        </div>
      </form>
    </div>
    <p-table
      [rowHover]="true"
      [value]="mrn.facturasSeleccionadas"
      [globalFilterFields]="['id', 'valor', 'dias_mora']"
      responsiveLayout="scroll"
    >
      <ng-template pTemplate="header">
        <tr>
          <th style="width: 10%">Id</th>
          <th style="width: 20%">Valor solicitado</th>
          <th style="width: 20%">Valor saldo</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-transaccion>
        <tr>
          <td>{{ transaccion.id }}</td>
          <td style="text-align: right">$ {{ transaccion.valor | number }}</td>
          <td style="text-align: right">
            $ {{ transaccion.saldo_pendiente_pago | number }}
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="summary">
        <div class="p-col-6 p-md-12" style="text-align: center">
          <button
            pButton
            pRipple
            type="button"
            class="p-button-rounded p-button-info"
            label="Pagar facturas"
            [disabled]="!mrn.formPagos.valid"
            (click)="
              mrn.pagarFacturasPendientesRed(this.mrn.facturasSeleccionadas)
            "
          ></button>
        </div>
      </ng-template>
    </p-table>
  </div>
</p-dialog>
<p-dialog
  [modal]="true"
  header="Resultado de la transaccion de pago"
  (onHide)="mrn.facturasSeleccionadas = []"
  [(visible)]="mrn.verResumenPagos"
  [style]="{ width: '30vw' }"
>
  <div class="card">
    <h5>Pagos Realizados</h5>
    <p-table
      [rowHover]="true"
      [value]="mrn.resumen_pagos.reverse()"
      responsiveLayout="scroll"
    >
      <ng-template pTemplate="header">
        <tr>
          <th style="width: 10%">Id</th>
          <th>Valor Solicitado</th>
          <th>Valor saldo</th>
          <th>Estado</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-transaccion>
        <tr>
          <td style="text-align: center">{{ transaccion.id }}</td>
          <td style="text-align: center">{{ transaccion.valor | number }}</td>
          <td style="text-align: right">
            $ {{ transaccion.saldo_pendiente_pago | number }}
          </td>
          <td style="text-align: center">
            {{ transaccion.estadoPago == "Pendiente" ? "Abonado" : "Pagado" }}
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</p-dialog>
<p-dialog
  [modal]="true"
  header="Crear aliado"
  [(visible)]="mrn.verFormNodo"
  [style]="{ width: '70vw' }"
>
  <div class="p-fluid p-formgrid p-grid">
    <div class="p-field p-col-6">
      <div class="card">
        <h5>Aliado Comercial</h5>
        <form [formGroup]="mrn.form_nodo_usuario">
          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-12 p-md-12">
              <p-divider align="left">
                <div class="p-d-inline-flex p-ai-center">
                  <i class="fas fa-building p-mr-2"></i>
                  <b>Información de empresa</b>
                </div>
              </p-divider>
            </div>
            <div class="p-field p-col-12 p-md-6">
              <label for="tipo">Tipo Aliado</label>
              <p-dropdown
                id="tipo"
                [options]="mrn.tipoAliado"
                formControlName="tipo"
                placeholder="Selecciona un tipo"
                optionLabel="label"
                optionValue="value"
              ></p-dropdown>
            </div>
            <div class="p-field p-col-12 p-md-6">
              <label for="tipo_persona">Tipo persona</label>
              <p-dropdown
                appendTo="body"
                id="tipo_persona"
                [options]="mrn.tipoPersona"
                placeholder="Selecciona un tipo"
                formControlName="tipo_persona"
                optionLabel="label"
                optionValue="value"
              ></p-dropdown>
            </div>
            <div class="p-field p-col-12 p-md-6">
              <label for="razonSocial">Razon Social</label>
              <input
                formControlName="razonSocial"
                id="razonSocial"
                type="text"
                pInputText
              />
            </div>
            <div class="p-field p-col-12 p-md-6">
              <label for="identificacion_nodo">Nit ó Cedula</label>
              <input
                formControlName="identificacion_nodo"
                #identificacion
                (input)="mrn.verificar_user_data(identificacion.value, '');//setClaveVenta()"
                id="identificacion_nodo"
                type="number"
                pInputText
              />
              <small
                [style]="{'color': (mrn.identificacion_available =='Este número de identificacion ya existe!'? 'darkred': 'darkgreen')}"
                id="id-help"
                >{{ mrn.identificacion_available }}</small
              >
            </div>
            <div class="p-field p-col-12 p-md-6">
              <label for="ciudad">Departamento / Municipio</label>
              <p-dropdown
                id="ciudad"
                [options]="mrn.municipiosFiltrados"
                formControlName="municipio_id"
                optionLabel="nombre_mpio"
                optionValue="id"
                [filter]="true"
                filterBy="nombre_mpio"
                [showClear]="true"
                placeholder="Seleccione un municipio"
              >
              </p-dropdown>
            </div>
            <div class="p-field p-col-12 p-md-6">
              <label for="ciudad">Zonas</label>
              <div class="p-inputgroup">
                <input readonly type="text" pInputText [value]="mrn.selected_zone?mrn.selected_zone.nombre:''">
                <button pTooltip="Ver zonas disponibles" type="button" (click)="mrn.zone_list = true" pButton pRipple icon="fas fa-search"></button>
              </div>
            </div>
            <div class="p-field p-col-12 p-md-6">
              <label for="direccion">Direccion</label>
              <input
                id="direccion"
                type="text"
                formControlName="direccion"
                pInputText
              />
            </div>
            <div class="p-field p-col-12 p-md-12">
              <p-divider align="left">
                <div class="p-d-inline-flex p-ai-center">
                  <i class="fas fa-user p-mr-2"></i>
                  <b>Informacion del representante legal</b>
                </div>
              </p-divider>
            </div>
            <div class="p-field p-col-12 p-md-6">
              <label for="representanteLegal">Representante legal</label>
              <input
                formControlName="representanteLegal"
                id="representanteLegal"
                type="text"
                pInputText
              />
            </div>
            <div class="p-field p-col-12 p-md-6">
              <label for="telefono">Número de telefono celular</label>
              <input
                id="telefono"
                type="number"
                formControlName="telefono"
                pInputText
              />
            </div>
            <div class="p-field p-col-12 p-md-6">
              <label for="email">Email</label>
              <input
                id="email"
                type="email"
                formControlName="email"
                pInputText
              />
            </div>
            <div class="p-field p-col-12 p-md-6">
              <label for="fecha_vinculacion">Fecha de vinculación</label>
              <input
                id="fecha_vinculacion"
                type="date"
                formControlName="fecha_vinculacion"
                pInputText
              />
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="p-field p-col-12 p-md-6">
      <div class="card">
        <h5>Usuario Administrador</h5>
        <form [formGroup]="mrn.form_nodo_usuario">
          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-12 p-md-12">
              <p-divider align="left">
                <div class="p-d-inline-flex p-ai-center">
                  <i class="fas fa-user-cog p-mr-2"></i>
                  <b>Informacion de la cuenta de usuario</b>
                </div>
              </p-divider>
            </div>
            <div class="p-field p-col-12 p-md-6">
              <label for="username">Nombre de Usuario</label>
              <input
                id="username"
                #username
                (input)="mrn.verificar_user_data('', username.value);convertir_minusculas()"
                formControlName="username"
                type="text"
                pInputText
              />
              <small
                [style]="{'color': (mrn.username_available == 'Este nombre de usuario ya existe!'? 'darkred': 'darkgreen')}"
                id="username1-help"
                >{{ mrn.username_available }}</small
              >
            </div>
            <!-- <div class="p-field p-col-12 p-md-6 ">
                            <label for="password">Contraseña</label>
                            <span class="p-input-icon-right">
                                <i class="fas fa-question-circle"
                                   pTooltip="Recuerde que por defecto la contraseña inicial sera el numero de identificacion."></i>
                                <input id="password" readonly formControlName="password"
                                       [value]="mrn.formNodo.value['identificacion_nodo']"
                                       type="text" pInputText>
                            </span>
                        </div>-->
          </div>
        </form>
        <form [formGroup]="mrn.form_nodo_usuario">
          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-12 p-md-12">
              <p-divider align="left">
                <div class="p-d-inline-flex p-ai-center">
                  <i class="fas fa-cogs p-mr-2"></i>
                  <b>Configuración de aliado</b>
                </div>
              </p-divider>
            </div>
            <div class="p-field p-col-12 p-md-12">
              <table class="tabla">
                <tr>
                  <th style="padding: 15px">Parametro</th>
                  <th style="padding: 15px; width: 15%">Valor</th>
                </tr>
                <tr *ngIf="mrn.form_nodo_usuario.value['tipo'] == 'Comercio'">
                  <td>Como obtiene sus comisiones?</td>
                  <td>
                    <p-dropdown
                      appendTo="body"
                      [options]="mrn.tipoServicioTransaccion"
                      formControlName="tipoComision"
                      placeholder="Selecciona una forma"
                      optionLabel="label"
                      optionValue="value"
                    ></p-dropdown>
                  </td>
                </tr>
                <!--<tr *ngIf="mrn.form_nodo_usuario.value['tipo'] == 'prueba'">
                  <td>Clave de venta (4 digitos)</td>
                  <td style="text-align: right">
                    <span class="p-input-icon-right">
                      <i class="fas fa-question-circle"
                              pTooltip="Clave de 4 digitos que se solicitara al vender cualquier producto." ></i>
                      <input formControlName="clave_venta" (input)="verificarClave()" type="number" pInputText />
                    </span>
                  </td>
                </tr>--><!--pendiente activar clave de ventas-->
                <tr>
                  <td>El credito de este aliado se aprobará de manera</td>
                  <td>
                    <p-dropdown
                      appendTo="body"
                      [options]="[
                        { label: 'Manual', value: false },
                        { label: 'Automatico', value: true }
                      ]"
                      formControlName="aprobacion_credito_automatico"
                      placeholder="Selecciona un tipo"
                      optionLabel="label"
                      optionValue="value"
                    ></p-dropdown>
                  </td>
                </tr>

                <tr
                  *ngIf="
                    mrn.form_nodo_usuario.value['tipoComision'] == 'CA' &&
                    mrn.form_nodo_usuario.value['tipo'] == 'Comercio'
                  "
                >
                  <td>Porcentaje de comisión incluida</td>
                  <td style="text-align: right">
                    <span class="p-input-icon-right">
                      <i
                        class="fas fa-question-circle"
                        pTooltip="Comision que se otorga cuando se realiza la solicitud de saldo."
                      ></i>
                      <input
                        formControlName="comisionAnticipada"
                        type="number"
                        pInputText
                      />
                    </span>
                  </td>
                </tr>
                <tr
                  *ngIf="mrn.form_nodo_usuario.value['tipo'] == 'Distribuidor'"
                >
                  <td>Porcentaje de comisión incluida</td>
                  <td style="text-align: right">
                    <span class="p-input-icon-right">
                      <i
                        class="fas fa-question-circle"
                        pTooltip="Comision que se otorga cuando se realiza la solicitud de saldo."
                      ></i>
                      <input
                        formControlName="comisionAnticipada"
                        type="number"
                        pInputText
                      />
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>Cupo de crédito asignado</td>
                  <td style="text-align: right">
                    <span class="p-input-icon-right">
                      <i
                        class="fas fa-question-circle"
                        pTooltip="Cupo total que se asignara a este aliado"
                      ></i>
                      <input
                        formControlName="montoAutorizado"
                        type="number"
                        pInputText
                      />
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>Dias de plazo para pago</td>
                  <td style="text-align: right">
                    <span class="p-input-icon-right">
                      <i
                        class="fas fa-question-circle"
                        pTooltip="Dias de plazo para pago de las solicitudes de credito."
                      ></i>
                      <input
                        formControlName="plazoMaximo"
                        type="number"
                        pInputText
                      />
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>Cantidad de solicitudes de crédito al día</td>
                  <td>
                    <input
                      type="text"
                      formControlName="solicitudes_de_credito_dia"
                      pInputText
                    />
                  </td>
                </tr>
                <tr *ngIf="mrn.form_nodo_usuario.value['tipo'] == 'Comercio'">
                  <td>Trabaja con una Smart POS ?</td>
                  <td>
                    <p-dropdown
                            appendTo="body"
                            [options]="opciones_soporte"
                            formControlName="imprime"
                            placeholder="Selecciona una opcion"
                            optionLabel="label"
                            optionValue="value"
                    ></p-dropdown>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <p-footer>
    <div
      style="text-align: right"
      *ngIf="mrn.form_nodo_usuario.value['solicitudes_de_credito_dia']">
      <button
        *ngIf="mrn.form_nodo_usuario.valid"
        pButton
        pRipple
        type="button"
        label="Guardar Aliado"
        (click)="guardarNodoUsuario()"
      ></button>
    </div>
  </p-footer>
</p-dialog>
<p-dialog
  [modal]="true"
  header="Editar aliado"
  [(visible)]="mrn.verFormEditNodoTercero"
  [style]="{ width: '40vw' }"
>
  <div class="card">
    <h5>Aliado Comercial</h5>
    <form [formGroup]="mrn.formNodo">
      <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-12 p-md-12">
          <p-divider align="left">
            <div class="p-d-inline-flex p-ai-center">
              <i class="fas fa-building p-mr-2"></i>
              <b>Información de empresa</b>
            </div>
          </p-divider>
        </div>
        <div class="p-field p-col-12 p-md-6">
          <label for="tipoe">Tipo Aliado</label>
          <p-dropdown
            id="tipoe"
            [options]="mrn.tipoAliado"
            formControlName="tipo"
            placeholder="Selecciona un tipo"
            optionLabel="label"
            optionValue="value"
          ></p-dropdown>
        </div>
        <div class="p-field p-col-12 p-md-6">
          <label for="tipo_personae">Tipo persona</label>
          <p-dropdown
            appendTo="body"
            id="tipo_personae"
            [options]="mrn.tipoPersona"
            placeholder="Selecciona un tipo"
            formControlName="tipo_persona"
            optionLabel="label"
            optionValue="value"
          ></p-dropdown>
        </div>
        <div class="p-field p-col-12 p-md-6">
          <label for="razonSocial">Razon Social</label>
          <input
            formControlName="razonSocial"
            id="razonSociale"
            type="text"
            pInputText
          />
        </div>
        <div class="p-field p-col-12 p-md-6">
          <label for="identificacion_nodoe">Nit ó Cedula</label>
          <input
            formControlName="identificacion_nodo"
            #identificacion
            (input)="mrn.verificar_user_data(identificacion.value, '')"
            id="identificacion_nodoe"
            type="number"
            pInputText
          />
          <small
            [style]="{'color':(mrn.identificacion_available =='Este número de identificacion ya existe!'? 'darkred': 'darkgreen')}"
            id="id-helpe"
            >{{ mrn.identificacion_available }}</small
          >
        </div>
        <div class="p-field p-col-12 p-md-6">
          <label for="ciudad">Zonas</label>
          <p-dropdown
            id="zona1"
            [options]="mrn.zonas"
            optionLabel="nombre"
            optionValue="id"
            formControlName="zona_id"
            placeholder="Seleccione una zona"
          >
          </p-dropdown>
        </div>
        <!--<div class="p-field p-col-12 p-md-6">
                    <label for="direccione">Direccion</label>
                    <input id="direccione" type="text" formControlName="direccion" pInputText>
                </div>-->
        <div class="p-field p-col-12 p-md-6">
          <label for="vinculacion">Fecha de vinculacion</label>
          <input
            id="vinculacion"
            type="date"
            formControlName="fecha_vinculacion"
            pInputText
          />
        </div>
        <div class="p-field p-col-12 p-md-12">
          <p-divider align="left">
            <div class="p-d-inline-flex p-ai-center">
              <i class="fas fa-user p-mr-2"></i>
              <b>Informacion del representante legal</b>
            </div>
          </p-divider>
        </div>
        <div class="p-field p-col-12 p-md-6">
          <label for="representanteLegale">Representante legal</label>
          <input
            formControlName="representanteLegal"
            id="representanteLegale"
            type="text"
            pInputText
          />
        </div>
        <div class="p-field p-col-12 p-md-6">
          <label for="telefonoe">Número de telefono celular</label>
          <p-inputMask
            id="telefonoe"
            mask="999-999-99-99"
            formControlName="telefono"
            [unmask]="true"
          ></p-inputMask>
        </div>
        <div class="p-field p-col-12 p-md-6">
          <label for="emaile">Email</label>
          <input id="emaile" type="email" formControlName="email" pInputText />
        </div>
        <div class="p-field p-col-12 p-md-12">
          <p-divider align="left">
            <div class="p-d-inline-flex p-ai-center">
              <i class="fas fa-cogs p-mr-2"></i>
              <b>Configuración</b>
            </div>
          </p-divider>
        </div>
        <div class="p-field p-col-12 p-md-12">
          <table class="tabla">
            <tr>
              <th style="padding: 15px">Parametro</th>
              <th style="padding: 15px; width: 15%">Valor</th>
            </tr>
            <tr>
              <td>
                El credito de este aliado se aprobará de manera:
              </td>
              <td>
                  <select pInputText formControlName="aprobacion_credito_automatico">
                      <option value="{{false}}">Manual</option>
                      <option value="{{true}}">Automatico</option>
                  </select>
              </td>
            </tr>

            <tr *ngIf="mrn.formNodo.value['tipoComision'] == 'CA'">
              <td>Porcentaje de comisión incluida</td>
              <td style="text-align: right">
                <span class="p-input-icon-right">
                  <i
                    class="fas fa-question-circle"
                    pTooltip="SI el aliado no obtiene la comision anticipada dejar este valor en 0"
                  ></i>
                  <input
                    formControlName="comisionAnticipada"
                    type="number"
                    pInputText
                  />
                </span>
              </td>
            </tr>
            <tr>
              <td>cantidad de solicitudes de crédito al día</td>
              <td>
                <input
                  type="text"
                  formControlName="solicitudes_de_credito_dia"
                  [value]="mrn.formUsuario.value['direccion']"
                  pInputText
                />
              </td>
            </tr>
            <tr>
              <td>Trabaja con una Smart POS ?</td>
              <td>
                <p-dropdown
                        appendTo="body"
                        [options]="opciones_soporte"
                        formControlName="imprime"
                        placeholder="Selecciona una opcion"
                        optionLabel="label"
                        optionValue="value"
                ></p-dropdown>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </form>
  </div>
  <p-footer>
    <div style="text-align: right">
      <button
        *ngIf="mrn.formNodo.valid"
        pButton
        pRipple
        type="button"
        label="Editar Aliado"
        (click)="mrn.updateNodo(mrn.formNodo.value)"
      ></button>
    </div>
  </p-footer>
</p-dialog>
<p-dialog
  [modal]="true"
  header="Agregar Diereccion"
  [(visible)]="mrn.verFormDireccion"
  [style]="{ width: '30vw' }"
>
  <div class="p-fluid p-formgrid p-grid">
    <div class="card">
      <form [formGroup]="mrn.formDireccion">
        <div class="p-fluid p-formgrid p-grid">
          <div class="p-field p-col-12">
            <label for="dire">Nueva Direccion</label>
            <input
              id="dire"
              type="text"
              formControlName="direccion"
              pInputText
            />
          </div>
          <div class="p-field p-col-12 p-md-12">
            <label for="city">Departamento / Municipio</label>
            <p-dropdown
              id="city"
              [options]="mrn.municipiosFiltrados"
              formControlName="municipio_id"
              optionLabel="nombre_mpio"
              optionValue="id"
              appendTo="body"
              [filter]="true"
              filterBy="nombre_mpio"
              [showClear]="true"
              placeholder="Seleccione un municipio"
            >
            </p-dropdown>
          </div>
        </div>
      </form>
    </div>
  </div>
  <p-footer>
    <div style="padding-top: 15px; text-align: right">
      <button
        [disabled]="!mrn.formDireccion.valid"
        pButton
        pRipple
        type="button"
        label="Guardar"
        (click)="mrn.guardar_direccion(mrn.nodoSeleccionado)"
      ></button>
    </div>
  </p-footer>
</p-dialog>
<p-dialog
  [modal]="true"
  header="Agregar Referencia"
  [(visible)]="mrn.verFormReferencia"
  [style]="{ width: '20vw' }"
>
  <div class="p-fluid p-formgrid p-grid">
    <div class="card">
      <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-12">
          <label for="empresa">Nombre de la Empresa</label>
          <input
            id="empresa"
            type="text"
            [(ngModel)]="mrn.REF_COMERCIAL.nom_empresa"
            pInputText
          />
        </div>
        <div class="p-field p-col-12">
          <label for="legal">Representante legal</label>
          <input
            id="legal"
            type="text"
            [(ngModel)]="mrn.REF_COMERCIAL.repr_legal"
            pInputText
          />
        </div>
        <div class="p-field p-col-12">
          <label for="tel">Telefono representante legal</label>
          <input
            id="tel"
            type="text"
            [(ngModel)]="mrn.REF_COMERCIAL.telefono"
            pInputText
          />
        </div>
        <div class="p-field p-col-12">
          <label for="em">Correo representante legal</label>
          <input
            id="em"
            type="email"
            [(ngModel)]="mrn.REF_COMERCIAL.correo"
            pInputText
          />
        </div>
      </div>
    </div>
  </div>
  <p-footer>
    <div style="padding-top: 15px; text-align: right">
      <button
        pButton
        pRipple
        type="button"
        label="Guardar"
        (click)="updateRefComercial()"
      ></button>
    </div>
  </p-footer>
</p-dialog>
<p-dialog
  [modal]="true"
  header="Asignacion de equipos"
  [(visible)]="mrn.verAsignacionDeEquipo"
  [style]="{ width: '50vw' }"
>
  <div class="p-grid">
    <div class="p-col-12 p-md-6">
      <form [formGroup]="mrn.formEquipoNodo">
        <div class="p-fluid p-formgrid p-grid">
          <div class="p-field p-col-12 p-md-6">
            <label for="fechaAsignacion">Fecha de asignacion</label>
            <input
              id="fechaAsignacion"
              formControlName="fechaAsignacion"
              type="date"
              pInputText
            />
          </div>
          <div class="p-field p-col-12 p-md-6">
            <label for="ordenEntrega">Nº Orden de entrega</label>
            <input
              id="ordenEntrega"
              formControlName="ordenEntrega"
              type="text"
              pInputText
            />
          </div>
          <div class="p-field p-col-12">
            <label for="observacion">Observaciones</label>
            <textarea
              id="observacion"
              formControlName="observacionAsignacion"
              type="text"
              pInputText
            ></textarea>
          </div>
        </div>
      </form>
    </div>
    <div class="p-col-12 p-md-6">
      <p-table
        [rowHover]="true"
        [rowHover]="true"
        [paginator]="true"
        [rows]="10"
        [value]="mrn.equiposLibres"
        selectionMode="single"
        [(selection)]="mrn.equipoSeleccionado"
      >
        <ng-template pTemplate="caption">
          <p>De la siguiente lista seleccione el equipo para asignar.</p>
        </ng-template>
        <ng-template pTemplate="header">
          <tr>
            <th>Serial</th>
            <th>Marca</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-equipo>
          <tr [pSelectableRow]="equipo">
            <td>{{ equipo.marca }}</td>
            <td>{{ equipo.serial }}</td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
  <p-footer style="text-align: right">
    <button
      *ngIf="mrn.equipoSeleccionado && mrn.formEquipoNodo.valid"
      pButton
      pRipple
      type="button"
      label="Guardar"
      (click)="guardarAsignacion()"
    ></button>
  </p-footer>
</p-dialog>
<p-dialog
  [modal]="true"
  header="Asignacion de cupo"
  [(visible)]="mrn.verFormCredito"
  [style]="{ width: '20vw' }"
>
  <form [formGroup]="mrn.formCreditos">
    <div class="p-fluid p-formgrid p-grid">
      <div class="p-field p-col-12 p-md-6">
        <label for="montoAutorizado">Monto total</label>
        <p-inputNumber
          prefix="$ "
          id="montoAutorizado"
          formControlName="montoAutorizado"
          type="number"
        ></p-inputNumber>
      </div>
      <div class="p-field p-col-12 p-md-6">
        <label for="plazoMaximo">Dias de plazo para pago</label>
        <input
          pInputText
          id="plazoMaximo"
          formControlName="plazoMaximo"
          type="number"
        />
      </div>
    </div>
  </form>
  <p-footer style="text-align: right">
    <button
      *ngIf="mrn.formCreditos.valid"
      pButton
      pRipple
      type="button"
      label="Guardar"
      (click)="guardarCreditos()"
    ></button>
  </p-footer>
</p-dialog>
<p-dialog [modal]="true" header="Asignacion de saldo para terceros"  [(visible)]="mrn.verFormTransaccionTercero" [style]="{ width: '20vw' }">
  <span style="padding:20px" *ngIf="api.nodoActual['id'] != 1">
      <div style="display: flex;flex-flow: column wrap;align-items: start">
      <span *ngIf="mrn.misBoslsasDinero" style="color: #4c566a;font-size: 2em;font-weight: bold">${{mrn.misBoslsasDinero.saldo_disponible|number}}</span>
        <span style="color:gray">Mi saldo disponible </span>
      </div>
  </span>
  <span style="padding:20px">
      <div style="display: flex;flex-flow: column wrap;align-items: start">
      <span *ngIf="mrn.nodoSeleccionado" style="color: #4c566a;font-size: 2em;font-weight: bold">{{mrn.nodoSeleccionado.representanteLegal}}</span>
        <span style="color:gray">Aliado</span>
      </div>
  </span>
  <span style="padding:20px">
      <div style="display: flex;flex-flow: column wrap;align-items: start">
      <span *ngIf="mrn.creditoByNodo" style="color: #4c566a;font-size: 2em;font-weight: bold">${{mrn.creditoByNodo.montoDisponible|number}}</span>
        <span style="color:gray">Cupo de credito disponible del aliado</span>
      </div>
  </span>
  <form [formGroup]="mrn.formTransaccion">
    <div class="p-fluid p-formgrid p-grid">
      <div class="p-field p-col-12">
        <label for="valor">Valor solicitado</label>
        <p-inputNumber
          id="valor"
          inputId="integeronly"
          formControlName="valor"
          #valor
          (onBlur)="verificarValor(valor.value)"
        ></p-inputNumber>
      </div>
      <div class="p-field p-col-12 p-md-12">
        <label for="tipo_transacciontr">Forma de pago</label>
        <p-dropdown
          appendTo="body" id="tipo_transacciontr" [options]="mrn.formaPago" formControlName="tipo_transaccion"
          placeholder="Selecciona un forma de pago" (onChange)="validarFormaPago($event)" optionLabel="label" optionValue="value" ></p-dropdown>
      </div>
      <div class="p-field p-col-12 p-md-12">
        <label for="medioSolicitud">Medio de solicitud</label>
        <p-dropdown
          appendTo="body" id="medioSolicitud" [options]="mrn.medio_de_solicitud"
          formControlName="medioSolicitud" placeholder="Selecciona un medio de solicitud"
          optionLabel="label" optionValue="value" ></p-dropdown>
      </div>
      <div class="p-field p-col-12 p-md-12" *ngIf="mrn.formTransaccion.value['tipo_transaccion'] == 'SSC'">
        <label for="medioSolicitud">Desea adjuntar un soporte para esta transaccion?</label>
        <p-selectButton [options]="opciones_soporte" (onChange)="select_option_support($event)" optionLabel="label" optionValue="value"></p-selectButton>
      </div>
      <div class="p-field p-col-12 p-md-12" *ngIf="mrn.soporte_requerido && mrn.formTransaccion.value['tipo_transaccion'] == 'SSC'">
        <label for="numsop">Numero de aprobacion</label>
        <input formControlName="numeroAprobacion" id="numsop" type="text" pInputText />
      </div>
      <div class="p-field p-col-12 p-md-12" *ngIf="mrn.formTransaccion.value['soporte']">
        <img src="{{ mrn.formTransaccion.value['soporte'] }}" style="width: 100%" alt="" />
      </div>
      <div class="p-field p-col-12 p-md-12"  *ngIf="mrn.soporte_requerido && mrn.formTransaccion.value['tipo_transaccion'] == 'SSC'" >
        <button pButton pRipple type="button" label="Subir soporte"
                (click)="mrn.verSubirSoporteTransTercero = true"></button>
      </div>
    </div>
  </form>
  <p-footer>
    <div style="text-align: right">
      <button *ngIf="mrn.formTransaccion.valid  && mrn.formTransaccion.value['medioSolicitud'] && mrn.formTransaccion.value['tipo_transaccion']"
        pButton pRipple type="button" label="Solicitar saldo" [disabled]="mrn.processing"
        (click)="mrn.dbl_click_prevent();solicitarSaldoTercero()"
      ></button>
    </div>
  </p-footer>
</p-dialog>
<p-dialog
  [modal]="true"
  header="Subir soporte de pago"
  [(visible)]="mrn.ver_subir_soporte_pago_fac_mora"
  [style]="{ width: '30vw' }"
>
  <div class="p-fluid p-formgrid p-grid">
    <div class="p-field p-col-12 p-md-12">
      <p-fileUpload
        [customUpload]="true"
        [uploadLabel]="'Cargar'"
        [chooseLabel]="'Seleccionar archivo'"
        [cancelLabel]="'Cancelar'"
        (uploadHandler)="
          mrn.subir_soporte_PFM($event, mrn.formPagos.value['numero_recibo'])
        "
        maxFileSize="1000000"
      ></p-fileUpload>
    </div>
  </div>
</p-dialog>
<p-dialog
  [modal]="true"
  header="Soporte de pago"
  [(visible)]="mrn.verSoportePagoFacM"
  [modal]="true"
  [style]="{ width: '60%' }"
>
  <div style="text-align: center; padding: 25px" *ngIf="mrn.pagoSeleccionado">
    <img style="width: 80%" src="{{ mrn.pagoSeleccionado.soporte }}" alt="" />
  </div>
</p-dialog>
<p-dialog
  [modal]="true"
  header="Productos registrados"
  [(visible)]="mrn.verProductosEmpresa"
  [style]="{ width: '50vw' }"
>
  <div class="card">
    <h5>
      Usted tiene {{ mrn.productosByProveedor.length }} productos de
      {{
        mrn.comisionSeleccionada
          ? mrn.comisionSeleccionada.proveedorEmpresa.empresa.nom_empresa
          : ""
      }}
      que puede comercializar.
    </h5>
    <p-table
      [value]="mrn.productosByProveedor"
      [paginator]="true"
      [rowHover]="true"
      [rows]="10"
    >
      <ng-template pTemplate="header">
        <tr>
          <th style="width: 11%">Codigo Proveedor</th>
          <th>Producto</th>
          <th style="width: 15%">Valor</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-producto>
        <tr>
          <td style="text-align: right">{{ producto.codigo_producto }}</td>
          <td>{{ producto.producto.nom_producto }}</td>
          <td style="text-align: right">
            $ {{ producto.producto.valor_producto | number }}
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td [attr.colspan]="3">Aun no hay Productos.</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</p-dialog>
<p-dialog
  header="Editar Mis Comisiones"
  [(visible)]="mrn.verFormComision"
  [style]="{ width: '25vw' }"
>
  <div *ngIf="mrn.comisionSeleccionada" class="card">
    <h5>
      Comision para
      {{ mrn.comisionSeleccionada.proveedorEmpresa.empresa.nom_empresa }}
    </h5>
    <form [formGroup]="mrn.formComision">
      <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-12">
          <label for="micomision">Mi Comision</label>
          <input
            readonly
            formControlName="micomision"
            id="micomision"
            type="number"
            pInputText
          />
        </div>
        <div class="p-field p-col-12">
          <label for="comisionDistribuidor">Comision para distribuidor</label>
          <input
                  pTooltip="Esta comision debe ser mayor a cero (0)"
            #valor
            (input)="verificarValorComisionDistr(valor.value)"
            formControlName="comisionDistribuidor"
            id="comisionDistribuidor"
            type="number"
            pInputText
          />
        </div>
        <div class="p-field p-col-12">
          <label for="comisionComercio">Comision para comercio</label>
          <input
                  pTooltip="Esta comision debe ser mayor a cero (0)"
            #valor2
            (input)="verificarValorComisionCom(valor2.value)"
            formControlName="comisionComercio"
            id="comisionComercio"
            type="number"
            pInputText
          />
        </div>
      </div>
    </form>
  </div>
  <p-footer>
    <button
      *ngIf="mrn.formComision.valid"
      pButton
      pRipple
      type="button"
      label="Guardar"
      (click)="actualizarMiComision(mrn.formComision.value)"
    ></button>
  </p-footer>
</p-dialog>
<p-dialog
  header="Ajuste de saldo"
  [style]="{ width: '20vw' }"
  [(visible)]="mrn.verAjusteSaldo"
  [modal]="true"
>
  <div style="padding-top: 10px">
    <div *ngIf="mrn.bolsasDinero">
      <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-12 p-md-12" style="padding-bottom: 20px">
          <table class="tabla_calculadora">
            <tr>
              <td>Aliado</td>
              <th style="text-align: right">
                {{ mrn.bolsasDinero.nodo.razonSocial }}
              </th>
            </tr>
            <tr>
              <td>Tipo comision</td>
              <th style="text-align: right">
                {{
                  mrn.bolsasDinero.nodo.tipoComision == "CA"
                    ? "Comision anticipada"
                    : "Comision por venta"
                }}
              </th>
            </tr>
            <tr>
              <td>Saldo disponible</td>
              <th style="text-align: right">
                $
                {{
                  (mrn.bolsasDinero.saldo_disponible
                    ? mrn.bolsasDinero.saldo_disponible
                    : 0
                  ) | number
                }}
              </th>
            </tr>
            <tr>
              <td>Utilidad</td>
              <th style="text-align: right">
                $
                {{
                  (mrn.bolsasDinero.utilidad ? mrn.bolsasDinero.utilidad : 0)
                    | number
                }}
              </th>
            </tr>
          </table>
        </div>
        <div class="p-field p-col-12">
          <label for="val">Valor a ajustar</label>
          <p-inputNumber
            id="val"
            inputId="integeronly"
            [(ngModel)]="mrn.valorAjuste"
          ></p-inputNumber>
        </div>
        <div class="p-field p-col-12 p-md-12" style="padding-bottom: 20px">
          <table
            class="tabla_calculadora"
            *ngIf="mrn.bolsasDinero.nodo.tipoComision == 'CA'"
          >
            <tr>
              <td>Valor a ajustar</td>
              <th style="text-align: right">
                $
                {{
                  mrn.valorAjuste +
                    mrn.valorAjuste *
                      (mrn.bolsasDinero.nodo.comisionAnticipada / 100) | number
                }}
              </th>
            </tr>
          </table>
        </div>
        <div class="p-field p-col-12">
          <label for="mot">Motivo del ajuste</label>
          <textarea
            pInputText
            id="mot"
            rows="10"
            [(ngModel)]="mrn.motivo_ajuste"
          ></textarea>
        </div>
      </div>
    </div>
  </div>
  <p-footer>
    <div style="text-align: right">
      <button
        *ngIf="mrn.motivo_ajuste"
        [disabled]="mrn.processing"
        pButton pRipple type="button" label="Ajustar saldo"
        (click)="mrn.dbl_click_prevent();mrn.ajustarSaldo(mrn.valorAjuste, mrn.motivo_ajuste)"
      ></button>
    </div>
  </p-footer>
</p-dialog>
<p-dialog
  [modal]="true"
  header="Soporte de pago pendiente"
  [(visible)]="mrn.verSoportePagoEntrante"
  [modal]="true"
  [style]="{ width: '60%' }"
>
  <div style="text-align: center; padding: 10px" *ngIf="mrn.pagoSeleccionado">
    <img style="width: 80%" src="{{ mrn.pagoSeleccionado.soporte }}" alt="" />
  </div>
  <p-footer *ngIf="mrn.pagoSeleccionado">
    <div style="text-align: right">
      <!--mrn.rechazar_pago_entrante('Hola')-->
      <button
        *ngIf="mrn.ver_opciones == false"
        pButton
        pRipple
        type="button"
        class="p-button-info"
        label="Rechazar pago"
        (click)="mrn.ver_opciones = true"
      ></button>
      <p-dropdown
        *ngIf="mrn.ver_opciones"
        appendTo="body"
        id="motivos"
        [options]="motivos"
        placeholder="Selecciona un motivo"
        (onChange)="asignarEvento($event)"
        optionLabel="label"
        optionValue="value"
      ></p-dropdown>
      <button
        *ngIf="mrn.motivo_seleccionado"
        pButton
        pRipple
        type="button"
        class="p-button-info"
        style="margin-left: 10px"
        label="Confirmar rechazo"
        (click)="mrn.rechazar_pago_entrante(mrn.motivo_seleccionado)"
      ></button>

      <button
        *ngIf="mrn.ver_opciones == false"
        pButton
        pRipple
        type="button"
        label="Aprobar pago"
        (click)="mrn.aceptar_pago_entrante()"
      ></button>
    </div>
  </p-footer>
</p-dialog>
<p-dialog
        [modal]="true"
        header="Rechazar pago" [(visible)]="mrn.verRechazarPagoEntrante"
        [modal]="true">
  <div *ngIf="mrn.pagoSeleccionado">
    <p-dropdown
            appendTo="body"
            id="motivos1"
            [options]="motivos"
            placeholder="Selecciona un motivo"
            (onChange)="asignarEvento($event)"
            optionLabel="label"
            optionValue="value"
    ></p-dropdown>
    <button
            *ngIf="mrn.motivo_seleccionado"
            pButton
            pRipple
            type="button"
            class="p-button-info"
            style="margin-left: 10px"
            label="Confirmar rechazo"
            (click)="mrn.rechazar_pago_entrante(mrn.motivo_seleccionado)"
    ></button>
  </div>
</p-dialog>
<p-dialog
  [modal]="true"
  header="Soporte de pago"
  [(visible)]="mrn.verSoporte"
  [modal]="true"
  [style]="{ width: '60%' }"
>
  <div style="text-align: center; padding: 10px" *ngIf="mrn.pagoSeleccionado">
    <img style="width: 80%" src="{{ mrn.pagoSeleccionado.soporte }}" alt="" />
  </div>
</p-dialog>
<p-dialog
  [modal]="true"
  header="Detalle de transacción"
  [style]="{ width: '30vw' }"
  [(visible)]="mrn.verDetalletransaccionByAliado"
  [modal]="true"
>
  <div class="p-grid">
    <div class="p-col-12" style="padding-right: 40px">
      <table class="tabla_detalle" *ngIf="mrn.transaccionSeleccionada">
        <tr>
          <th>Transaccion N°</th>
          <td>{{ mrn.transaccionSeleccionada.id }}</td>
        </tr>
        <tr>
          <th>Nombre de usuario :</th>
          <td>{{ mrn.transaccionSeleccionada.usuario.username }}</td>
        </tr>
        <tr>
           <th>Usuario :</th>
           <td>{{ mrn.transaccionSeleccionada.usuario.first_name }} {{ mrn.transaccionSeleccionada.usuario.last_name }}</td>
        </tr>
        <tr>
           <th>Identificacion de usuario :</th>
           <td>{{ mrn.transaccionSeleccionada.usuario.identificacion }}</td>
        </tr>
          <tr>
          <th>Ultimo saldo :</th>
          <td>${{ mrn.transaccionSeleccionada.ultimoSaldo | number }}</td>
        </tr>
        <tr>
          <th>Valor :</th>
          <td>$ {{ mrn.transaccionSeleccionada.valor | number }}</td>
        </tr>
        <tr>
          <th>Saldo actual :</th>
          <td>${{ mrn.transaccionSeleccionada.saldo_actual | number }}</td>
        </tr>
        <tr *ngIf="mrn.transaccionSeleccionada.ganancia_red">
          <th>Ganancia {{ getGnanancia()[1] | number }}% :</th>
          <td>${{ getGnanancia()[0] | number }}</td>
        </tr>
        <tr>
          <th>Fecha y hora :</th>
          <td>
            {{ mrn.transaccionSeleccionada.created_at | date : "dd-MM-yyyy" }}
            {{ mrn.transaccionSeleccionada.hour_at | date : "shortTime" }}
          </td>
        </tr>
        <tr>
          <th>Destino :</th>
          <td>{{ mrn.transaccionSeleccionada.numeroDestino }}</td>
        </tr>
        <tr>
          <th>Canal de Venta :</th>
          <td>{{ mrn.transaccionSeleccionada.medioVenta }}</td>
        </tr>
        <tr>
          <th>Empresa :</th>
          <td>{{ mrn.transaccionSeleccionada.nombre_empresa }}</td>
        </tr>
        <tr>
          <th>Estado :</th>
          <td>{{ mrn.transaccionSeleccionada.estado }}</td>
        </tr>
        <tr>
          <th>Codigo aprobacion :</th>
          <td>{{ mrn.transaccionSeleccionada.codigo_transaccion_externa }}</td>
        </tr>
        <tr>
          <th>Codigo Producto :</th>
          <td>{{ mrn.transaccionSeleccionada.codigo_producto }}</td>
        </tr>
        <tr>
          <th>Producto :</th>
          <td>{{ mrn.transaccionSeleccionada.nom_producto }}</td>
        </tr>
        <tr>
          <th>Convenio :</th>
          <td>{{ mrn.transaccionSeleccionada.convenio_pago }}</td>
        </tr>
        <tr>
          <th>Referencia de pago :</th>
          <td>{{ mrn.transaccionSeleccionada.referencia_pago }}</td>
        </tr>
        <tr>
          <th>Código de respuesta :</th>
          <td>{{ mrn.transaccionSeleccionada.codigo_resultado }}</td>
        </tr>
        <tr>
          <th>Tipo de red :</th>
          <td>{{ mrn.transaccionSeleccionada.tipo_red }}</td>
        </tr>
        <tr>
          <th>Tipo de datos :</th>
          <td>{{ mrn.transaccionSeleccionada.tipo_datos }}</td>
        </tr>
        <tr>
          <th>Version App :</th>
          <td>{{ mrn.transaccionSeleccionada.app_ver }}</td>
        </tr>
        <tr>
          <th>Tiempo de respuesta:</th>
          <td>{{ mrn.transaccionSeleccionada.tiempo_respuesta }}</td>
        </tr>
        <tr>
          <th>Resultado de la transaccion :</th>
        </tr>
        <tr>
          <td colspan="2" style="text-align: justify">
            {{ mrn.transaccionSeleccionada.resultado }}
          </td>
        </tr>
      </table>
    </div>
  </div>
</p-dialog>
<p-dialog
  [modal]="true"
  header="Detalle de transacción"
  [style]="{ width: '30vw' }"
  [(visible)]="mrn.verDetalletransaccion"
  [modal]="true"
>
  <div class="p-grid">
    <div class="p-col-12" style="padding-right: 40px">
      <ul>
        <div *ngIf="mrn.transaccionSeleccionada && mrn.verDetalletransaccion">
          <li
            style="
              list-style: none;
              padding: 6px;
              color: darkred;
              text-align: center;
            "
            *ngIf="mrn.transaccionSeleccionada.dias_mora"
          >
            <h5 style="font-weight: bold">
              ¡Transacción con {{ mrn.transaccionSeleccionada.dias_mora }} dias
              en mora!
            </h5>
          </li>
          <li style="list-style: none; padding: 6px">
            <h5 style="font-weight: bold">
              Transaccion N° {{ mrn.transaccionSeleccionada.id }}
            </h5>
          </li>
          <li
            style="list-style: none; padding: 6px"
            *ngIf="mrn.transaccionSeleccionada.tipo_transaccion == 'SSC'"
          >
            <span style="font-weight: bold">Numero de aprobacion :</span>
            <span style="float: right">{{
              mrn.transaccionSeleccionada.numeroAprobacion
            }}</span>
          </li>
          <li style="list-style: none; padding: 6px">
            <span style="font-weight: bold">Valor :</span>
            <span style="float: right"
              >$ {{ mrn.transaccionSeleccionada.valor | number }}</span
            >
          </li>
          <li
            style="list-style: none; padding: 6px"
            *ngIf="mrn.transaccionSeleccionada.nodo.comisionIncluida"
          >
            <span style="font-weight: bold">Comision asignada :</span>
            <span style="float: right"
              >{{ mrn.transaccionSeleccionada.nodo.comisionAnticipada }} %</span
            >
          </li>
          <li
            style="list-style: none; padding: 6px"
            *ngIf="mrn.transaccionSeleccionada.nodo.comisionIncluida"
          >
            <span style="font-weight: bold">Valor aprobado :</span>
            <span style="float: right">
              $
              {{
                (mrn.transaccionSeleccionada.nodo.comisionAnticipada / 100) *
                  mrn.transaccionSeleccionada.valor +
                  mrn.transaccionSeleccionada.valor | number
              }}</span
            >
            <span
              *ngIf="!mrn.transaccionSeleccionada.nodo.comisionIncluida"
              style="float: right"
              >{{ mrn.transaccionSeleccionada.valor | number }}</span
            >
          </li>
          <li style="list-style: none; padding: 6px">
            <span style="font-weight: bold">Solicitante :</span>
            <span style="float: right"
              >{{ mrn.transaccionSeleccionada.usuario.first_name }}
              {{ mrn.transaccionSeleccionada.usuario.last_name }}</span
            >
          </li>
          <li style="list-style: none; padding: 6px">
            <span style="font-weight: bold">Tipo de saldo :</span>
            <span style="float: right">{{
              mrn.transaccionSeleccionada.tipoServicio
            }}</span>
          </li>
          <li style="list-style: none; padding: 6px">
            <span style="font-weight: bold">Estado :</span>
            <span style="float: right">{{
              mrn.transaccionSeleccionada.estado
            }}</span>
          </li>
          <li style="list-style: none; padding: 6px">
            <span style="font-weight: bold">Tipo de transaccion :</span>
            <span style="float: right">{{
              mrn.transaccionSeleccionada.tipo_transaccion
            }}</span>
          </li>
          <li style="list-style: none; padding: 6px">
            <span style="font-weight: bold">Fecha de solicitud :</span>
            <span style="float: right"
              >{{
                mrn.transaccionSeleccionada.created_at | date : "dd-MM-yyyy"
              }}
              {{
                mrn.transaccionSeleccionada.hour_at | date : "shortTime"
              }}</span
            >
          </li>
          <div *ngIf="mrn.transaccionSeleccionada.tipo_transaccion != 'VEN'">
            <li style="list-style: none; padding: 6px">
              <span style="font-weight: bold">Soporte :</span>
              <span style="float: right">{{
                mrn.transaccionSeleccionada.soporte ? "Si" : "No"
              }}</span>
            </li>
            <li style="list-style: none; padding: 6px">
              <span style="font-weight: bold">Fecha de aprobacion :</span>
              <span style="float: right">{{
                mrn.transaccionSeleccionada.fecha_aprobacion | date
              }}</span>
            </li>
            <li style="list-style: none; padding: 6px">
              <span style="font-weight: bold">Fecha límite de pago :</span>
              <span style="float: right">{{
                mrn.transaccionSeleccionada.fecha_pago | date
              }}</span>
            </li>
            <li style="list-style: none; padding: 6px">
              <span style="font-weight: bold">Estado de pago :</span>
              <span style="float: right">{{
                mrn.transaccionSeleccionada.estadoPago
              }}</span>
            </li>
            <li style="list-style: none; padding: 6px">
              <span style="font-weight: bold">Dias de mora :</span>
              <span style="float: right">{{
                mrn.transaccionSeleccionada.dias_mora
              }}</span>
            </li>
          </div>
          <li style="list-style: none; padding: 6px">
            <span style="font-weight: bold">Resultado de la transaccion :</span>
            <span
              style="float: right; text-align: justify; padding-top: 10px"
              >{{ mrn.transaccionSeleccionada.resultado }}</span
            >
          </li>
        </div>
      </ul>
    </div>
  </div>
</p-dialog>
<p-dialog
        [modal]="true" header="Zonas disponibles" [(visible)]="mrn.zone_list" [modal]="true" >

  <p-tree [value]="mrn.arbol_zona" selectionMode="single" filterBy="nombre" [filter]="true"
          (onNodeSelect)="select_zone($event.node)" filterPlaceholder="Buscar zona" [(selection)]="mrn.selected_zone">
    <ng-template let-node  pTemplate="default">
      <span><b>{{node.nombre}}</b> {{node.suma_nodos?('('+node.suma_nodos+' aliados)'):''}} </span>
    </ng-template>
  </p-tree>
</p-dialog>
<p-dialog header="Soporte de pago" [(visible)]="mrn.verSoporteTransaccion" [modal]="true">
  <div style="text-align: center;padding: 25px" *ngIf="mrn.transaccionSeleccionada">
    <p-progressSpinner *ngIf="!imgLoaded"></p-progressSpinner>
    <img style="width: 50%" (load)="verificarCargaImg()" src="{{mrn.transaccionSeleccionada.soporte}}" alt="">
  </div>
</p-dialog>
<p-dialog header="Subir soporte de pago" closable="false" closeOnEscape="false" [(visible)]="mrn.verSubirSoporteTransTercero" [modal]="true" [style]="{width: '30vw'}">
  <div class="p-fluid p-formgrid p-grid">
    <div class="p-field p-col-12 p-md-12">
      <p-fileUpload [customUpload]="true" [uploadLabel]="'Cargar'" [chooseLabel]="'Seleccionar archivo'"
                     (uploadHandler)="mrn.subir_soporte_PFST($event,this.mrn.formTransaccion.value['numeroAprobacion'])"
                    maxFileSize="1000000"></p-fileUpload>
    </div>
  </div>
</p-dialog> <!--subir soporte de solicitud de contado para terceros-->
