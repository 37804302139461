import {Component, OnInit} from '@angular/core';
import {Mrn} from "../../providers/mrn";
import {ApiService} from "../../providers/api";
import {ConfirmationService} from "primeng/api";
import * as FileSaver from 'file-saver';
import * as moment from "moment/moment";
import {parseInt} from "lodash";

@Component({
    selector: 'app-saldos',
    templateUrl: './saldos.component.html',
})
export class SaldosComponent implements OnInit {
    nodosFiltrados: any[];
    imgLoaded = false;
    valorAjuste = 0
    motivo_ajuste: any;



    constructor(public mrn: Mrn, public api: ApiService, private confirmationService: ConfirmationService) {

    }

    ngOnInit(): void {
        this.mrn.crearControles();
        this.mrn.getSolicitudesSaldo()
        this.mrn.getMisSolicitudesSaldo()
        //this.mrn.getNodosTodosDirectos()
    }


    solicitarSaldo() {
        let obj = {
            nodo: this.api.nodoActual['id'],
            usuario: this.api.usuario['id'],
            tipoServicio: this.mrn.formTransaccion.value['tipoServicio'],
            tipo_transaccion: this.mrn.formTransaccion.value['tipo_transaccion'],
            medioSolicitud: 'Web',
            valor: this.mrn.formTransaccion.value['valor'],
            saldo_pendiente_pago: this.mrn.formTransaccion.value['valor']
        }

        this.api.post_soap('solicitar_saldo', obj).subscribe(
            data => {
                let respuesta = JSON.parse(data)
                if (respuesta.data) {
                    this.mrn.transaccionSeleccionada = respuesta.data
                    this.mrn.getMisSolicitudesSaldo();
                    this.mrn.verSubirArchivo = this.mrn.formTransaccion.value['tipo_transaccion'] == 'SSC' ? true : false;
                    this.mrn.verFormTransaccion = false;
                    this.mrn.formTransaccion.reset();
                }
                this.confirmationService.confirm({
                    message: respuesta.mensaje[0],
                    rejectVisible: false,
                    accept: () => {
                    }
                });
                if (respuesta.mensaje[1]) {
                    this.confirmationService.confirm({
                        message: respuesta.mensaje[1],
                        rejectVisible: false,
                        accept: () => {
                        }
                    });
                }
            })
    }

    solicitarSaldoTercero() {
        this.mrn.formTransaccion.patchValue({
            nodo_id: this.mrn.nodoSeleccionado.id,
            usuario_id: this.api.usuario['id'],
            tipo: 'Solicitud de saldo',
            tipoServicio: this.mrn.nodoSeleccionado.comisionAnticipada ? 'CA' : 'CV',
            estado: 'Pendiente',
            saldo_pendiente_pago:this.mrn.formTransaccion.value['valor']

        });
        this.mrn.addTransaccionTercero();
    }

    filtrarNodos(event) {
        //in a real application, make a request to a remote url with the query and return filtered results, for demo001 we filter at client side
        let filtrados: any[] = [];
        let query = event.query;

        for (let i = 0; i < this.mrn.nodosDirectosAll.length; i++) {
            let nodo = this.mrn.nodosDirectosAll[i]['nodo'];
            if (nodo.representanteLegal.toLowerCase().indexOf(query.toLowerCase()) == 0) {
                filtrados.push(nodo);
            }
        }

        this.nodosFiltrados = filtrados;
    }

    rechazarSolicitud() {
        this.mrn.transaccionSeleccionada.estado = 'Rechazado'
        this.mrn.llenarFormTransaccion(this.mrn.transaccionSeleccionada)
        this.mrn.updateTransaccion(true);
    }

    verSoporte(transaccion: any) {
        window.open(transaccion.soporte, 'blank')
    }

    verificarCargaImg() {
        this.imgLoaded = true;
    }

    /*prueba() {
        let obj = new Object();
        obj['usuario_id'] = this.api.usuario['id']
        this.api.post_soap('enviar_mensaje', obj)
            .subscribe(
                data => {
                    console.log(data)

                }
            )
    }

    seleccionarNodo() {
       this.mrn.getBolsasDinero(this.mrn.nodoSeleccionado)
    }*/

    crear_documento_export(data_set){
        let excel = []
        for(let item of data_set){
            let f = moment(item.hour_at)
            let fecha = f.date() + '-'+(f.month()+1)+'-'+f.year()+' '+f.hour()+':'+f.minutes()
            let tipo = ''

            if(item.nodo.id == this.api.nodoActual['id']){
                if(item.tipo_transaccion == 'SSC')tipo = 'Compra de saldo contado'
                if(item.tipo_transaccion == 'SSCR')tipo = 'Compra de saldo crédito'
                if(item.tipo_transaccion == 'AJS')tipo = 'Reversión de saldo'
                if(item.tipo_transaccion == 'CJG')tipo = 'Traslado de ganancias'
            }else{
                if(item.tipo_transaccion == 'SSC')tipo = 'Venta de saldo contado'
                if(item.tipo_transaccion == 'SSCR')tipo = 'Venta de saldo crédito'
                if(item.tipo_transaccion == 'AJS')tipo = 'Reversión de saldo'
                if(item.tipo_transaccion == 'CJG')tipo = 'Traslado de ganancias'
            }

            let obj = {
                'transaccion': item.id,
                'tipo': tipo,
                'fecha de aprobacion':fecha,
                'representante legal':item.nodo.representanteLegal,
                'Numero de identificacion': item.nodo.identificacion_nodo,
                'Saldo inicial':item.ultimo_saldo_distribuidor,
                'Valor solicitado':item.valor,
                'Saldo final':item.saldo_actual_distribuidor,
                'estado de transaccion':item.estado,
                'estado pago':item.estadoPago,
            }
            excel.push(obj)
        }
        return excel
    }

    exportExcel() {
        import("xlsx").then(xlsx => {
            const worksheet = xlsx.utils.json_to_sheet(this.crear_documento_export(this.mrn.historial_solicitudes_saldo));
            const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
            const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
            this.saveAsExcelFile(excelBuffer, "solicitudes_slado_");
        });
    }

    saveAsExcelFile(buffer: any, fileName: string): void {
        let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        let EXCEL_EXTENSION = '.xlsx';
        const data: Blob = new Blob([buffer], {
            type: EXCEL_TYPE
        });
        FileSaver.saveAs(data, fileName + '_reporte_' + new Date().getTime() + EXCEL_EXTENSION);
    }

    getTotalVentasSaldo() {
        let total = 0
        if(this.mrn.dataFiltrada) {
            for (let i of this.mrn.dataFiltrada) {
                if (i.nodo.id != this.api.nodoActual['id']) {
                    total += i.valor
                }
            }
        }
        return total
    }

    getTotalComprasSaldo() {
        let total = 0
        if(this.mrn.dataFiltrada){
            for (let i of this.mrn.dataFiltrada){
                if(i.nodo.id == this.api.nodoActual['id']){
                    total += i.valor
                }
            }
        }
        return total
    }

    getFilteredData(event: any) {
        this.mrn.dataFiltrada = event.filteredValue
    }

    getTotalComprasContado() {
        let total = 0
        if(this.mrn.dataFiltrada){
            for (let i of this.mrn.dataFiltrada){
                if(i.nodo.id == this.api.nodoActual['id']){
                    if(i.tipo_transaccion == 'SSC')total += i.valor
                }
            }
        }
        return total
    }
    getTotalComprasCredito() {
        let total = 0
        if(this.mrn.dataFiltrada){
            for (let i of this.mrn.dataFiltrada){
                if(i.nodo.id == this.api.nodoActual['id']){
                    if(i.tipo_transaccion == 'SSCR')total += i.valor
                }
            }
        }
        return total
    }

    getTotalTrasladoGanancias() {
        let total = 0
        if(this.mrn.dataFiltrada){
            for (let i of this.mrn.dataFiltrada){
                if(i.nodo.id == this.api.nodoActual['id']){
                    if(i.tipo_transaccion == 'CJG')total += i.valor
                }
            }
        }
        return total
    }
    getTotalReversionesPropias() {
        let total = 0
        if(this.mrn.dataFiltrada){
            for (let i of this.mrn.dataFiltrada){
                if(i.nodo.id == this.api.nodoActual['id']){
                    if(i.tipo_transaccion == 'AJS')total += i.valor
                }
            }
        }
        return total
    }

    getTotalVentaContado() {
        let total = 0
        if(this.mrn.dataFiltrada){
            for (let i of this.mrn.dataFiltrada){
                if(i.nodo.id != this.api.nodoActual['id']){
                    if(i.tipo_transaccion == 'SSC')total += i.valor
                }
            }
        }
        return total
    }
    getTotalVentaCredito() {
        let total = 0
        if(this.mrn.dataFiltrada){
            for (let i of this.mrn.dataFiltrada){
                if(i.nodo.id != this.api.nodoActual['id']){
                    if(i.tipo_transaccion == 'SSCR')total += i.valor
                }
            }
        }
        return total
    }
    getTotalVentaReversiones() {
        let total = 0
        if(this.mrn.dataFiltrada){
            for (let i of this.mrn.dataFiltrada){
                if(i.nodo.id != this.api.nodoActual['id']){
                    if(i.tipo_transaccion == 'AJS')total += i.valor
                }
            }
        }
        return total
    }
}
