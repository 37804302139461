import {Injectable} from '@angular/core';
import {AngularFireStorage} from '@angular/fire/compat/storage';

@Injectable()
export class FirebaseStorage{
  constructor(
    private storage: AngularFireStorage
  ) { }

  //Tarea para subir archivo
  public tareaCloudStorage(ruta:string,nombreArchivo: string, datos: any,type:any) {
    //{contentType:'application/pdf'}
    return this.storage.upload(ruta+'/'+nombreArchivo, datos)
  }

  //Referencia del archivo
  public referenciaCloudStorage(nombreArchivo: string) {
    return this.storage.ref(nombreArchivo);
  }
  public referenciaByURLCloudStorage(url: string) {
    return this.storage.refFromURL(url);
  }
}
