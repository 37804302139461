<p-tabView (onChange)="cambioTab()">
    <p-tabPanel header="Empresas y productos">
        <div class="p-grid">
            <div class="p-col-12 p-md-4">
                <div class="card">
                    <p-table [value]="mrn.empresas" sortField="catServicio.nombre" sortMode="single" rowGroupMode="subheader"
                             groupRowsBy="catServicio.nombre" #dtEmpresa [globalFilterFields]="['catServicio.nombre','nom_empresa']"
                             [rowHover]="true" [responsive]="true" [scrollable]="true" scrollHeight="400px" rowGroupMode="subheader"
                             responsiveLayout="scroll">
                        <ng-template pTemplate="caption">
                            <div class="flex">
                                <span class="p-input-icon-left ml-auto">
                                    <i class="pi pi-search"></i>
                                    <input pInputText type="text" (input)="dtEmpresa.filterGlobal($event.target.value, 'contains')" placeholder="Buscar empresa" />
                                </span>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="header">
                            <tr>
                                <th>Categoiria</th>
                                <th>Nombre</th>
                                <th style="width:20%"></th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="groupheader" let-empresa>
                            <tr pRowGroupHeader>
                                <td colspan="3">
                                    <span class="font-bold ml-2">{{empresa.catServicio.nombre}}</span>
                                </td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="groupfooter" let-empresa>
                            <tr class="p-rowgroup-footer">
                                <td style="min-width: 80%">
                                    <div style="text-align: right; width: 100%">Número de empresas registradas</div>
                                </td>
                                <td style="width: 20%">{{calculateEmpresasTotal(empresa.catServicio.nombre)}}</td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-empresa let-rowIndex="rowIndex">
                            <tr>
                                <td>{{empresa.catServicio.nombre}}</td>
                                <td>{{empresa.nom_empresa}}</td>
                                <td style="text-align:center">
                                    <i pTooltip="Editar empresa"
                                       (click)="mrn.llenarFormEmpresa(empresa);mrn.verFormEmpresa = true"
                                       class="pi pi-pencil accion-tabla"></i>
                                    <i pTooltip="Ver productos"
                                       (click)="seleccionarEmpresa(empresa)"
                                       class="pi pi-list accion-tabla"></i>
                                </td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="emptymessage" let-columns>
                            <tr>
                                <td [attr.colspan]="3">
                                    <div class="card info_card">
                                        <i style="font-size:1.8em" class="fas fa-info-circle accion-tabla"></i>
                                        <span>Seleccione un tipo de servicio para ver sus empresas.</span>
                                    </div>
                                </td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="summary">
                            <div class="p-grid">
                                <div style="text-align:right;" class="p-col-12">
                                    <button pButton pRipple type="button" label="Nueva Empresa" icon="pi pi-plus"
                                            (click)="mrn.formEmpresa.reset();mrn.verFormEmpresa = true"></button>
                                </div>
                            </div>
                        </ng-template>
                    </p-table>
                </div>
            </div>
            <div class="p-col-12 p-md-8">
                <div class="card" *ngIf="mrn.empresaSeleccionada">
                    <h5>Productos para :
                        {{mrn.empresaSeleccionada.nom_empresa}}</h5>
                    <div style="width: 100%;text-align: center;padding: 15px 0 25px 0">
                        <img style="width: 15%;cursor: pointer" src="{{mrn.empresaSeleccionada.imagen}}" alt="">
                    </div>
                    <p-table [value]="mrn.productos" [globalFilterFields]="['nom_producto','valor_producto']"
                             [rowHover]="true" [paginator]="true" [responsive]="true" [rows]="10" #dtProducto>
                        <ng-template pTemplate="caption">
                            <div class="caption_content">
                                <button pButton pRipple type="button" label="Nuevo Producto" icon="pi pi-plus"
                                        (click)="nuevo_producto()"></button>
                                <span class="p-input-icon-left ml-auto">
                                    <i class="pi pi-search"></i>
                                    <input pInputText type="text" (input)="dtProducto.filterGlobal($event.target.value, 'contains')" placeholder="Buscar Producto" />
                                </span>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="header">
                            <tr>
                                <th>Nombre</th>
                                <th style="width:12%">Valor</th>
                                <th style="width:20%">Descuento promocional</th>
                                <th style="width:12%">Actualizacion</th>
                                <th style="width:10%"></th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-producto>
                            <tr pTooltip="{{!producto.activo?'Producto inactivo':'Producto activo'}}" [style]="{'background-color':!producto.activo?'#d088a5':''}">
                                <td>{{producto.nom_producto}}</td>
                                <td style="text-align:right">${{producto.valor_producto|number}}</td>
                                <td style="text-align:right">${{producto.descuento_promosional|number}}</td>
                                <td style="text-align:center">{{producto.updated_at|date}}</td>
                                <td style="text-align:center">
                                    <i pTooltip="Editar Producto" (click)="mrn.llenarFormProducto(producto);
                                    mrn.verFormProducto=true" class="pi pi-pencil accion-tabla"></i>
                                </td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="emptymessage" let-columns>
                            <tr>
                                <td [attr.colspan]="5">
                                    <div class="card info_card">
                                        <i style="font-size:1.8em" class="fas fa-info-circle accion-tabla"></i>
                                        <span>Seleccione una empresa para ver sus productos, si ya selecciono una y aun no hay productos puede crear uno nuevo.</span>
                                    </div>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </div>
        </div>
    </p-tabPanel>
    <p-tabPanel header="Proveedores y codigos">
        <div class="p-grid">
            <div class="p-col-12 p-md-5">
                <div class="card">
                    <h5>Proveedores</h5>
                   <!-- <p-table [columns]="mrn.comisionesBaseCols" [value]="mrn.comisionesBase" scrollHeight="400px"  [scrollable]="true" selectionMode="single">
                        <ng-template pTemplate="header" let-columns>
                            <tr>
                                <th>Empresa</th>
                                <th style="width: 25%" *ngFor="let col of columns">
                                    {{col.proveedor}}
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-rowData let-columns="columns">
                            <tr>
                                <td>{{rowData.empresa}}</td>
                                <td style="text-align: right" *ngFor="let col of columns">
                                    {{rowData[col.proveedor]?rowData[col.proveedor]+' %':0}}
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>-->
                   <p-table [value]="mrn.proveedores"
                             [rowHover]="true" [paginator]="true" [responsive]="true" [rows]="10">
                        <ng-template pTemplate="caption">
                            <div class="p-grid">
                                <div style="text-align:left;"  class="p-col-12 p-md-6">
                                </div>
                                <div  style="text-align:right;" class="p-col-12 p-md-6">
                                    <button pButton pRipple type="button" label="Nuevo Proveedor" icon="pi pi-plus"
                                            (click)="mrn.verFormProveedor = true"></button>
                                </div>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="header">
                            <tr>
                                <th >Proveedor</th>
                                <th style="width:20%">Telefono</th>
                                <th>E-mail</th>
                                <th style="width:20%"></th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-proveedor>
                            <tr>
                                <td>{{proveedor.nombre_prov}}</td>
                                <td style="text-align:center">{{proveedor.telefono_prov}}</td>
                                <td style="text-align:center">{{proveedor.email_prov}}</td>
                                <td style="text-align:center">
                                    <i pTooltip="Editar Proveedor" (click)="mrn.llenarFormProveedor(proveedor);
                                    mrn.verFormProveedor=true" class="pi pi-pencil accion-tabla"></i>
                                    <i pTooltip="Productos Codificados" (click)="seleccionarProveedor(proveedor)"
                                       class="pi pi-eye accion-tabla"></i>
                                    <i pTooltip="Lista de Comisiones"
                                       (click)="mrn.getComisionesBaseByProveedor(proveedor.id);
                                       mrn.verComisionesBase=true;mrn.proveedorSeleccionado = proveedor"
                                       class="pi pi-list accion-tabla"></i>
                                </td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="emptymessage">
                            <tr>
                                <td [attr.colspan]="4">
                                    Aun no hay proveedores registrados.
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </div>
            <div class="p-col-12 p-md-6">
                <div class="card" *ngIf="mrn.proveedorSeleccionado">
                    <h5 *ngIf="mrn.proveedorSeleccionado">Productos codificados para :
                        {{mrn.proveedorSeleccionado.nombre_prov}}</h5>
                    <p-table [value]="mrn.productosByProveedor"
                             [rowHover]="true" [paginator]="true" [responsive]="true" [rows]="10">
                        <ng-template pTemplate="caption">
                            <div class="p-grid">
                                <div style="text-align:left;"  class="p-col-12 p-md-5">
                                    <div class="p-inputgroup">
                                        <p-dropdown [style]="{width: '100%'}" [options]="mrn.comisionesBase"
                                                    placeholder="Selecciona una Empresa"
                                                    optionLabel="empresa.nom_empresa" optionValue="empresa.id"
                                                    (onChange)="seleccionarEmpresaCodificacion($event.value)"
                                        ></p-dropdown>
                                        <span *ngIf="mrn.empresaSeleccionada" class="p-inputgroup-addon">
                                            Comision Base :
                                            {{mrn.comisionBase?mrn.comisionBase.comisionBase:0}}%
                                        </span>
                                    </div>
                                </div>
                                <div  style="text-align:right;" class="p-col-12 p-md-7">
                                    <button *ngIf="mrn.productosByProveedor.length" pButton pRipple type="button" label="Consultar productos" icon="fas fa-compress-alt"
                                            (click)="mrn.producto_ms(mrn.empresaSeleccionada.nom_empresa)" style="margin-right: 10px"></button>
                                    <button *ngIf="mrn.empresaSeleccionada" pButton pRipple type="button"
                                            label="Codificar producto" icon="pi pi-plus"
                                            (click)="codificar()"></button>
                                </div>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="header">
                            <tr>
                                <th style="width:15%">Codigo</th>
                                <th >Producto</th>
                                <th style="width:10%"></th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-proveedor>
                            <tr>
                                <td style="text-align:center">{{proveedor.codigo_producto}}</td>
                                <td>{{proveedor.producto.nom_producto}}</td>
                                <td style="text-align:center">
                                    <i pTooltip="Editar Producto"
                                       (click)="mrn.productoCodificadoSeleccionado = proveedor;
                                       mrn.llenarFormCodificarProducto(proveedor);
                                       mrn.verFormProdCodificados=true"
                                       class="pi pi-pencil accion-tabla"></i>
                                </td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="emptymessage">
                            <tr>
                                <td [attr.colspan]="4">
                                    Aun no hay Productos codficados.
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </div>
        </div>
    </p-tabPanel>
    <p-tabPanel header="Reporte de ventas">
        <div class="card">
            <div class="p-grid">
                <div style="margin-top:20px" class="p-col-12 p-md-4">
                    <div class="p-grid">
                        <div class="p-col-12">
                            <div class="p-grid p-fluid p-formgrid">
                                <div class=" p-field p-col-12 " >
                                    <label for="fis">Desde</label>
                                    <input type="date" #fInicial  pInputText value="{{hoy}}" id="fis">
                                </div>
                                <div class="p-field p-col-12">
                                    <label for="ffs">Hasta</label>
                                    <input type="date" #fFinal pInputText value="{{hoy}}" id="ffs">
                                </div>
                                <div class="p-col-6 ">
                                    <button (click)="mrn.ventas_exitosas=[];mrn.ventas_fallidas=[];mrn.ventas_web=0;
                                    mrn.ventas_movil=0;mrn.grafico_medio_ventas =='';
                                               mrn.valor_ventas_exitosas=0;mrn.valor_ventas_fallidas=0;dataset_ventas_seleccionado=[];
                                               mrn.get_reporte_ventas_global(fInicial.value,fFinal.value)"
                                            type="button" pButton pRipple styleClass="p-button-danger"
                                             label="Consultar"></button>
                                </div>
                                <div class="p-col-6 ">
                                    <button *ngIf="fInicial.value && fFinal.value"
                                            (click)="mrn.ventas_web=0;mrn.ventas_movil=0;mrn.grafico_medio_ventas =='';
                                            fInicial.value ='';fFinal.value='';mrn.ventas_exitosas=[];mrn.ventas_fallidas=[];
                                               mrn.valor_ventas_exitosas=0;mrn.valor_ventas_fallidas=0;dataset_ventas_seleccionado=[] "
                                            type="button" pButton pRipple styleClass="p-button-danger"
                                            label="Limpiar"></button>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="mrn.ventas_exitosas.length" class="p-col-12">
                            <ul>
                                <li class="lista_credito">
                                    Resultado de la consulta
                                </li>
                                <li class="lista_credito">
                                <span style="float: right">
                                    <div style="display: flex;flex-flow: column wrap;align-items: end">
                                        <span style="color: #4c566a;font-size: 2em;font-weight: bold">${{mrn.valor_ventas_exitosas|number}}</span>
                                        <span style="color:gray">{{mrn.ventas_exitosas.length}} Transacciones exitosas </span>
                                    </div>
                                </span>
                                </li>
                                <p-divider></p-divider>
                                <li class="lista_credito">
                                <span style="float: right">
                                    <div style="display: flex;flex-flow: column wrap;align-items: end">
                                        <span style="color: #4c566a;font-size: 2em;font-weight: bold">${{mrn.valor_ventas_fallidas|number}}</span>
                                        <span style="color:gray">{{mrn.ventas_fallidas.length}} Transacciones fallidas</span>
                                    </div>
                                </span>
                                </li>
                                <p-divider></p-divider>
                            </ul>
                        </div>
                    </div>
                </div>
                <!--<div *ngIf="mrn.ventas_exitosas.length" class="p-col-12 p-md-4">
                    <p-chart type="pie" [data]="mrn.grafico_ventas_globales" (onDataSelect)="verDetalleVentas($event)" [style]="{'width': '80px','height': '80px'}"></p-chart>
                </div>-->
               <!-- <div *ngIf="mrn.ventas_exitosas.length" class="p-col-12 p-md-4">
                    <p-chart type="bar" [options]="stackedOptions" [data]="mrn.grafico_medio_ventas" [style]="{'width': '100%','height': '150px'}"></p-chart>
                </div>-->
                <div class="p-col-12 p-md-12">
                    <p-tabView (onChange)="cambioTab()">
                        <p-tabPanel header="Ventas Exitosas">
                            <p-table #ventasRed1 [rowHover]="true" [responsive]="true" #dtvengen [scrollable]="true" scrollHeight="400px"
                                     [globalFilterFields]="['representante_legal','numeroDestino','nombre_empresa','resultado']"
                                     [value]="this.mrn.ventas_exitosas">
                                <ng-template pTemplate="caption">
                                    <div class="p-grid">
                                        <div class="p-col-12 p-md-6" >
                                            <button type="button" pButton pRipple icon="pi pi-file-excel" (click)="exportExcel(this.mrn.ventas_exitosas)"
                                                    class="p-button-success mr-2" label="Exportar a Excel" tooltipPosition="bottom"></button>
                                        </div>
                                        <div class="p-col-12 p-md-6" style="text-align: right">
                                    <span class="p-input-icon-left ml-auto">
                                      <i class="pi pi-search"></i>
                                      <input style="width: 500px" pInputText type="text"
                                             (input)="ventasRed1.filterGlobal($event.target.value, 'contains')"
                                             placeholder="Buscar por: Representante legal,numero destino,empresa" />
                                    </span>
                                        </div>
                                    </div>
                                </ng-template>
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th style="width:10%">Id</th>
                                        <th>Representante legal</th>
                                        <th  style="width:10%">Destino</th>
                                        <th>Empresa</th>
                                        <th>Fecha solicitud</th>
                                        <th>Valor</th>
                                        <th>Resulatdo de la transaccion</th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-transaccion>
                                    <tr>
                                        <td style="width:10%">{{transaccion.id}} <i class="fas fa-search accion-tabla_izq"
                                                                  (click)="mrn.transaccionSeleccionada = transaccion;mrn.verDetalletransaccionByAliado = true"></i></td>
                                        <td>{{transaccion.representante_legal}}</td>
                                        <td>{{transaccion.numeroDestino}}</td>
                                        <td>{{transaccion.nombre_empresa}}</td>
                                        <td style="text-align:center">{{transaccion.created_at|date:"dd-MM-yyyy"}} {{transaccion.hour_at|date:'shortTime'}}</td>
                                        <td style="text-align: right">$ {{transaccion.valor|number}}</td>
                                        <td class="wrap">{{transaccion.resultado}}</td>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="summary">
                                    <div class="flex align-items-center justify-content-between">
                                        Se encontraron {{dataset_ventas_seleccionado.length}} transacciones.
                                    </div>
                                </ng-template>
                                <ng-template pTemplate="emptymessage" let-columns>
                                    <tr>
                                        <td [attr.colspan]="7">
                                            <div class="card info_card">
                                                <i style="font-size:1.8em" class="fas fa-info-circle accion-tabla"></i>
                                                <p>Para realizar una consulta por favor seleccione una Fecha inicial y
                                                    un Fecha final.</p>
                                            </div>
                                        </td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </p-tabPanel>
                        <p-tabPanel header="Ventas Fallidas">
                            <p-table #ventasRed [rowHover]="true" [responsive]="true" #dtvengen [scrollable]="true" scrollHeight="400px"
                                     [globalFilterFields]="['representante_legal','numeroDestino','nombre_empresa','resultado']"
                                     [value]="this.mrn.ventas_fallidas">
                                <ng-template pTemplate="caption">
                                    <div class="p-grid">
                                        <div class="p-col-12 p-md-6" >
                                            <button type="button" pButton pRipple icon="pi pi-file-excel" (click)="exportExcel(this.mrn.ventas_fallidas)"
                                                    class="p-button-success mr-2" label="Exportar a Excel" tooltipPosition="bottom"></button>
                                        </div>
                                        <div class="p-col-12 p-md-6" style="text-align: right">
                                    <span class="p-input-icon-left ml-auto">
                                      <i class="pi pi-search"></i>
                                      <input style="width: 500px" pInputText type="text"
                                             (input)="ventasRed.filterGlobal($event.target.value, 'contains')"
                                             placeholder="Buscar por: Representante legal,numero destino,empresa" />
                                    </span>
                                        </div>
                                    </div>
                                </ng-template>
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th style="width:10%">Id</th>
                                        <th>Representante legal</th>
                                        <th  style="width:10%">Destino</th>
                                        <th>Empresa</th>
                                        <th>Fecha solicitud</th>
                                        <th>Valor</th>
                                        <th>Resulatdo de la transaccion</th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-transaccion>
                                    <tr>
                                        <td style="width:10%">{{transaccion.id}} <i class="fas fa-search accion-tabla_izq"
                                                                  (click)="mrn.transaccionSeleccionada = transaccion;mrn.verDetalletransaccionByAliado = true"></i></td>
                                        <td>{{transaccion.representante_legal}}</td>
                                        <td>{{transaccion.numeroDestino}}</td>
                                        <td>{{transaccion.nombre_empresa}}</td>
                                        <td style="text-align:center">{{transaccion.created_at|date:"dd-MM-yyyy"}} {{transaccion.hour_at|date:'shortTime'}}</td>
                                        <td style="text-align: right">$ {{transaccion.valor|number}}</td>
                                        <td class="wrap">{{transaccion.resultado}}</td>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="summary">
                                    <div class="flex align-items-center justify-content-between">
                                        Se encontraron {{dataset_ventas_seleccionado.length}} transacciones.
                                    </div>
                                </ng-template>
                                <ng-template pTemplate="emptymessage" let-columns>
                                    <tr>
                                        <td [attr.colspan]="7">
                                            <div class="card info_card">
                                                <i style="font-size:1.8em" class="fas fa-info-circle accion-tabla"></i>
                                                <p>Para realizar una consulta por favor seleccione una Fecha inicial y
                                                    un Fecha final.</p>
                                            </div>
                                        </td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </p-tabPanel>
                    </p-tabView>

                </div>
            </div>
        </div>
    </p-tabPanel>
    <p-tabPanel header="Reporte de totales">
        <p-tabView (onChange)="cambioTab()">
            <p-tabPanel header="Seguimiento a comercios">
                <div class="card">
                    <p-table [rowHover]="true" [responsive]="true" #dttotalesc [scrollable]="true" scrollHeight="600px"
                             [globalFilterFields]="['nombre_cliente']"
                             [value]="totales_transacciones">
                        <ng-template pTemplate="caption">
                            <div class="p-grid">
                                <div class="p-col-12 p-md-4" >
                                    <div class="p-grid">
                                        <div class="p-col-12">
                                            <div class="p-grid p-fluid p-formgrid">
                                                <div class=" p-field p-col-12 " >
                                                    <label for="fist">Desde</label>
                                                    <input [max]="hoy" type="date" #fInicial  pInputText value="{{hoy}}" id="fist">
                                                </div>
                                                <div class=" p-field p-col-12 " >
                                                    <label for="fifn">Hasta</label>
                                                    <input [min]="fInicial.value"  [max]="mrn.getPostDayByDate(fInicial.value)" type="date" #fFinal  pInputText value="{{hoy}}" id="fifn">
                                                </div>
                                                <div class="p-col-6 ">
                                                    <button (click)=" seguimientoComercios(fInicial.value,fFinal.value)"
                                                            type="button" pButton pRipple styleClass="p-button-danger"
                                                            label="Consultar"></button>
                                                </div>
                                                <div class="p-col-6 ">
                                                    <button *ngIf="fInicial.value && fFinal.value"
                                                            (click)="mrn.ventas_web=0;mrn.ventas_movil=0;mrn.grafico_medio_ventas =='';
                                            fInicial.value ='';fFinal.value='';mrn.ventas_exitosas=[];mrn.ventas_fallidas=[];
                                               mrn.valor_ventas_exitosas=0;mrn.valor_ventas_fallidas=0;dataset_ventas_seleccionado=[] "
                                                            type="button" pButton pRipple styleClass="p-button-danger"
                                                            label="Limpiar"></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="p-col-12 p-md-8" style="text-align: right">
                                    <span class="p-input-icon-left ml-auto">
                                      <i class="pi pi-search"></i>
                                   <input style="width: 500px" pInputText type="text"
                                          (input)="dttotalesc.filterGlobal($event.target.value, 'contains')"
                                          placeholder="Buscar por: Representante legal" />
                                    </span>
                                </div>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="header">
                            <tr>
                                <th pSortableColumn="nodo" style="width: 7%">
                                    Cliente Id
                                    <p-sortIcon field="nodo"></p-sortIcon>
                                </th>
                                <th>Representante legal</th>
                                <th style="width: 7%">Saldo inicial</th>
                                <th style="width: 7%">T. solicitud a credito</th>
                                <th style="width: 7%">T. solicitud a contado</th>
                                <th style="width: 7%">T. reversiones</th>
                                <th style="width: 7%">T. Ventas desde ganacias</th>
                                <th style="width: 7%">T. Ventas desde saldo</th>
                                <th style="width: 7%">Saldo final</th>
                                <th pSortableColumn="diferencia" style="width: 7%">
                                    Diferencia
                                    <p-sortIcon field="diferencia"></p-sortIcon>
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-transaccion>
                            <tr>
                                <td style="width: 7%">{{transaccion.nodo}}</td>
                                <td>{{transaccion.nombre_cliente}}</td>
                                <td style="width: 7%">${{transaccion.saldo_inicial|number}}</td>
                                <td style="width: 7%">${{transaccion.total_sscr|number}}</td>
                                <td style="width: 7%">${{transaccion.total_ssc|number}}</td>
                                <td style="width: 7%">${{transaccion.total_reversiones|number}}</td>
                                <td style="width: 7%">${{transaccion.total_ventas_ganancias|number}}</td>
                                <td style="width: 7%">${{transaccion.total_ventas_saldo|number}}</td>
                                <td style="width: 7%">${{transaccion.saldo_final|number}}</td>
                                <td style="width: 7%;background: {{transaccion.diferencia != 0?'rgba(255,117,117,0.23)':''}}">${{transaccion.diferencia|number}}</td>

                            </tr>
                        </ng-template>
                        <ng-template pTemplate="summary">
                            <div class="flex align-items-center justify-content-between">
                                Se encontraron {{totales_transacciones.length}} transacciones.
                            </div>
                        </ng-template>
                        <ng-template pTemplate="emptymessage" let-columns>
                            <tr>
                                <td [attr.colspan]="6">
                                    <div class="card info_card">
                                        <i style="font-size:1.8em" class="fas fa-info-circle accion-tabla"></i>
                                        <p>Para realizar una consulta por favor seleccione una Fecha inicial y
                                            un Fecha final.</p>
                                    </div>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </p-tabPanel>
            <p-tabPanel header="Seguimiento a distribuidores">
                <div class="card">
                    <p-table [rowHover]="true" [responsive]="true" #dttotalesd [scrollable]="true" scrollHeight="600px"
                             [globalFilterFields]="['nombre_cliente']"
                             [value]="totales_transacciones">
                        <ng-template pTemplate="caption">
                            <div class="p-grid">
                                <div class="p-col-12 p-md-4" >
                                    <div class="p-grid p-fluid p-formgrid">
                                        <div class=" p-field p-col-12 " >
                                            <label for="fidi">Desde</label>
                                            <input  [max]="hoy" type="date" #fInicial  pInputText value="{{hoy}}" id="fidi">
                                        </div>
                                        <div class=" p-field p-col-12 " >
                                            <label for="fidis">Hasta</label>
                                            <input [min]="fInicial.value"  [max]="mrn.getPostDayByDate(fInicial.value)" type="date" #fFinal pInputText value="{{hoy}}" id="fidis">
                                        </div>
                                        <div class="p-col-6 ">
                                            <button (click)=" seguimientoDistribuidores(fInicial.value,fFinal.value)"
                                                    type="button" pButton pRipple styleClass="p-button-danger"
                                                    label="Consultar"></button>
                                        </div>
                                        <div class="p-col-6 ">
                                            <button *ngIf="fInicial.value && fFinal.value"
                                                    (click)="mrn.ventas_web=0;mrn.ventas_movil=0;mrn.grafico_medio_ventas =='';
                                            fInicial.value ='';fFinal.value='';mrn.ventas_exitosas=[];mrn.ventas_fallidas=[];
                                               mrn.valor_ventas_exitosas=0;mrn.valor_ventas_fallidas=0;dataset_ventas_seleccionado=[] "
                                                    type="button" pButton pRipple styleClass="p-button-danger"
                                                    label="Limpiar"></button>
                                        </div>
                                    </div>
                                </div>
                                <div class="p-col-12 p-md-8" style="text-align: right">
                                    <span class="p-input-icon-left ml-auto">
                                      <i class="pi pi-search"></i>
                                      <input style="width: 500px" pInputText type="text"
                                             (input)="dttotalesd.filterGlobal($event.target.value, 'contains')"
                                             placeholder="Buscar por: Representante legal" />
                                    </span>
                                </div>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="header">
                            <tr>
                                <th pSortableColumn="nodo" style="width: 7%">
                                    Cliente Id
                                    <p-sortIcon field="nodo"></p-sortIcon>
                                </th>
                                <th>Representante legal</th>
                                <th style="width: 7%;background: rgba(0,178,204,0.13)">Saldo inicial en plataforma</th>
                                <th style="width: 7%;background: rgba(0,178,204,0.13)">T. solicitud a credito D.</th>
                                <th style="width: 7%;background: rgba(0,178,204,0.13)">T. solicitud a contado D.</th>
                                <th style="width: 7%;background: rgba(0,178,204,0.13)">T. traslados ganancias D.</th>
                                <th style="width: 7%;background: rgba(0,178,204,0.13)">T. reversiones de saldo D.</th>
                                <th style="width: 7%">T. solicitud a credito C.</th>
                                <th style="width: 7%">T. solicitud a contado C.</th>
                                <th style="width: 7%">T. reversiones de saldo C.</th>
                                <th style="width: 7%">Saldo actual en plataforma</th>
                                <th pSortableColumn="diferencia" style="width: 7%">
                                    Diferencia
                                    <p-sortIcon field="diferencia"></p-sortIcon>
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-transaccion>
                            <tr>
                                <td style="width: 7%">{{transaccion.nodo}}</td>
                                <td>{{transaccion.nombre_cliente}}</td>
                                <td style="width: 7%;background: rgba(0,178,204,0.13)">${{transaccion.saldo_inicial|number}}</td>
                                <td style="width: 7%;background: rgba(0,178,204,0.13)">${{transaccion.total_sscr|number}}</td>
                                <td style="width: 7%;background: rgba(0,178,204,0.13)">${{transaccion.total_ssc|number}}</td>
                                <td style="width: 7%;background: rgba(0,178,204,0.13)">${{transaccion.total_canjes_propios|number}}</td>
                                <td style="width: 7%;background: rgba(0,178,204,0.13)">${{transaccion.total_reversiones|number}}</td>
                                <td style="width: 7%">${{transaccion.total_sscr_hijos|number}}</td>
                                <td style="width: 7%">${{transaccion.total_ssc_hijos|number}}</td>
                                <td style="width: 7%">${{transaccion.total_reversiones_hijos|number}}</td>
                                <td style="width: 7%">${{transaccion.saldo_final|number}}</td>
                                <td style="width: 7%;background: {{transaccion.diferencia != 0?'rgba(255,117,117,0.23)':''}}">${{transaccion.diferencia|number}}</td>

                            </tr>
                        </ng-template>
                        <ng-template pTemplate="summary">
                            <div class="flex align-items-center justify-content-between">
                                Se encontraron {{totales_transacciones.length}} transacciones.
                            </div>
                        </ng-template>
                        <ng-template pTemplate="emptymessage" let-columns>
                            <tr>
                                <td [attr.colspan]="6">
                                    <div class="card info_card">
                                        <i style="font-size:1.8em" class="fas fa-info-circle accion-tabla"></i>
                                        <p>Para realizar una consulta por favor seleccione una Fecha inicial y
                                            un Fecha final.</p>
                                    </div>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </p-tabPanel>
        </p-tabView>

    </p-tabPanel>
    <p-tabPanel header="Gestion de creditos">
        <div class="p-grid">
            <div class="p-col-12 p-md-5"></div>
            <div class="p-col-12 p-md-2">
                <div class="p-fluid p-formgrid p-grid">
                    <form [formGroup]="mrn.formCreditos" style="width: 100%">
                        <div class="p-field p-col-12">
                            <div class="p-inputgroup">
                                <input #nodo_id pInputText placeholder="Ingrese el ID del aliado" />
                                <span style="cursor:pointer;" class="p-inputgroup-addon"> <i class="pi pi-search" (click)="seleccionarNodo(nodo_id.value)"></i></span>
                            </div>
                        </div>
                        <div class="p-field p-col-12" *ngIf="credito_editar" >
                            <div class="total_card_container">
                                <div class="total_card">
                                    <span class="total_card_title">{{credito_editar.nodo.tipo}}</span>
                                    <span class="total_card-value">{{credito_editar.nodo.representanteLegal}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="p-field p-col-12" *ngIf="credito_editar">
                            <label for="montoAutorizado">Monto total</label>
                            <p-inputNumber prefix="$ " id="montoAutorizado" formControlName="montoAutorizado"
                                           type="number" ></p-inputNumber>
                        </div>
                        <div class="p-field p-col-12" *ngIf="credito_editar">
                            <label for="montoAutorizado">Monto Utilizado</label>
                            <p-inputNumber prefix="$ " id="montoUtilizado" formControlName="montoUtilizado"
                                           type="number" ></p-inputNumber>
                        </div>
                        <div class="p-field p-col-12" *ngIf="credito_editar">
                            <label for="montoAutorizado">Monto Disponible</label>
                            <p-inputNumber prefix="$ " id="montoDisponible" formControlName="montoDisponible"
                                           type="number" ></p-inputNumber>
                        </div>
                        <div class="p-field p-col-12" *ngIf="credito_editar">
                            <label for="plazoMaximo">Dias de plazo para pago</label>
                            <input pInputText id="plazoMaximo" formControlName="plazoMaximo" type="number" />
                        </div>
                    </form>
                    <div *ngIf="credito_editar" style="width: 100%;text-align: right">
                        <button
                                *ngIf="mrn.formCreditos.valid"
                                pButton
                                pRipple
                                type="button"
                                label="Guardar"
                                (click)="guardarCreditos()"
                        ></button>
                    </div>
                </div>
            </div>
            <div class="p-col-12 p-md-5"></div>
        </div>
    </p-tabPanel>
    <p-tabPanel header="Reporte de versiones">
        <div class="p-grid">
            <div class="p-col-12 p-md-3"></div>
            <div class="p-col-12 p-md-6">
                <p-table [rowHover]="true" #dttotalesc [scrollable]="true" scrollHeight="600px"
                         [globalFilterFields]="['app_ver']"
                         [value]="listaReporteVersiones">
                    <ng-template pTemplate="caption">
                        <div class="p-grid">
                            <div class="p-col-12 p-md-4" >
                                <div class="p-grid">
                                    <div class="p-col-12">
                                        <div class="p-grid p-fluid p-formgrid">
                                            <div class=" p-field p-col-12 " >
                                                <label for="fist">Desde</label>
                                                <input [max]="hoy" type="date" #fInicial  pInputText value="{{hoy}}" id="fist">
                                            </div>
                                            <div class=" p-field p-col-12 " >
                                                <label for="fifn">Hasta</label>
                                                <input [min]="fInicial.value"  [max]="mrn.getPostDayByDate(fInicial.value)" type="date" #fFinal  pInputText value="{{hoy}}" id="fifn">
                                            </div>
                                            <div class="p-col-6 ">
                                                <button (click)=" reporteVersiones(fInicial.value,fFinal.value)"
                                                        type="button" pButton pRipple styleClass="p-button-danger"
                                                        label="Consultar"></button>
                                            </div>
                                            <div class="p-col-6 ">
                                                <button *ngIf="fInicial.value && fFinal.value"
                                                        (click)="mrn.ventas_web=0;mrn.ventas_movil=0;mrn.grafico_medio_ventas =='';
                                            fInicial.value ='';fFinal.value='';mrn.ventas_exitosas=[];mrn.ventas_fallidas=[];
                                               mrn.valor_ventas_exitosas=0;mrn.valor_ventas_fallidas=0;dataset_ventas_seleccionado=[] "
                                                        type="button" pButton pRipple styleClass="p-button-danger"
                                                        label="Limpiar"></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="p-col-12 p-md-8" style="text-align: right">
                                    <span class="p-input-icon-left ml-auto">
                                      <i class="pi pi-search"></i>
                                   <input style="width: 300px" pInputText type="text"
                                          (input)="dttotalesc.filterGlobal($event.target.value, 'contains')"
                                          placeholder="Buscar por: Version app" />
                                    </span>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template pTemplate="header">
                        <tr>
                            <th>Representante legal</th>
                            <th style="width: 15%">Distribuidor</th>
                            <th style="width: 15%">Version App</th>
                            <th style="width: 15%">Telefono</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-transaccion>
                        <tr>
                            <td>{{transaccion.nodo__representanteLegal}}</td>
                            <td style="width: 7%">{{transaccion.nodo__nodoPadre}}</td>
                            <td style="width: 7%">{{transaccion.app_ver}}</td>
                            <td style="width: 7%">{{transaccion.nodo__telefono}}</td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="summary">
                        <div class="flex align-items-center justify-content-between">
                            Se encontraron {{totales_transacciones.length}} transacciones.
                        </div>
                    </ng-template>
                    <ng-template pTemplate="emptymessage" let-columns>
                        <tr>
                            <td [attr.colspan]="6">
                                <div class="card info_card">
                                    <i style="font-size:1.8em" class="fas fa-info-circle accion-tabla"></i>
                                    <p>Para realizar una consulta por favor seleccione una Fecha inicial y
                                        un Fecha final.</p>
                                </div>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
            </div>
            <div class="p-col-12 p-md-3"></div>
    </p-tabPanel>
</p-tabView>
<p-dialog [modal]="true"  header="Editar/Crear Productos" [(visible)]="mrn.verFormProducto" [style]="{width: '30vw'}">
    <form [formGroup]="mrn.formProducto">
        <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-12">
                <div class="card" style="background: rgba(147,226,255,0.67)">
                    <p *ngIf="mrn.empresaSeleccionada"><i style="font-size:1.8em" class="fas fa-info-circle accion-tabla">
                    </i>El nuevo producto sera creado dentro de: {{mrn.empresaSeleccionada.nom_empresa}}</p>
                </div>
            </div>
            <div class="p-field p-col-12">
                <label for="nom_producto">Nombre del producto</label>
                <input id="nom_producto" formControlName="nom_producto" type="text" pInputText>
            </div>
            <div class="p-field p-col-6">
                <label for="valor_producto">Valor del producto</label>
                <input id="valor_producto" formControlName="valor_producto" type="number" pInputText>
            </div>
            <div class="p-field p-col-6">
                <label for="descuento_promosional">Descuento promocional</label>
                <input id="descuento_promosional" formControlName="descuento_promosional" type="number" pInputText>
            </div>
            <div class="p-field p-col-6">
                <label for="estado">Estado del producto</label>
                <p-dropdown appendTo="body" [style]="{width: '100%'}" [options]="mrn.activo"
                            placeholder="Selecciona una estado" id="estado"
                            optionLabel="label" optionValue="value"
                            formControlName="activo"
                ></p-dropdown>
            </div>
        </div>
    </form>
    <p-footer>
        <div style="padding-top: 15px;text-align:right">
            <button *ngIf="mrn.formProducto.valid" pButton pRipple type="button" label="Guardar"
                    (click)="guardarProducto()"></button>
        </div>
    </p-footer>
</p-dialog>
<p-dialog [modal]="true" header="Editar/Crear Proveedores" [(visible)]="mrn.verFormProveedor" [style]="{width: '30vw'}">
    <form [formGroup]="mrn.formProveedor">
        <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-12">
                <label for="nombre_prov">Nombre del proveedor</label>
                <input id="nombre_prov" formControlName="nombre_prov" type="text" pInputText>
            </div>
            <div class="p-field p-col-12">
                <label for="direccion_prov">Deraccion</label>
                <input id="direccion_prov" formControlName="direccion_prov" type="text" pInputText>
            </div>
            <div class="p-field p-col-12">
                <label for="email_prov">Correo electronico</label>
                <input id="email_prov" formControlName="email_prov" type="text" pInputText>
            </div>
            <div class="p-field p-col-12">
                <label for="telefono_prov">Telefono</label>
                <input id="telefono_prov" formControlName="telefono_prov" type="text" pInputText>
            </div>
        </div>
    </form>
    <p-footer>
        <div style="padding-top: 15px;text-align:right">
            <button *ngIf="mrn.formProveedor.valid" pButton pRipple type="button" label="Guardar"
                    (click)="guardarProveedor()"></button>
        </div>
    </p-footer>
</p-dialog>
<p-dialog [modal]="true" header="Editar/Crear Categorias de servicios" [(visible)]="mrn.verFormCatServ" [style]="{width: '30vw'}">
    <form [formGroup]="mrn.formServicioCategoria">
        <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-12">
                <label for="nombre">Nombre de la categoria de srervicio</label>
                <input id="nombre" formControlName="nombre" type="text" pInputText>
            </div>
        </div>
    </form>
    <p-footer>
        <div style="padding-top: 15px;text-align:right">
            <button *ngIf="mrn.formServicioCategoria.valid" pButton pRipple type="button" label="Guardar"
                    (click)="guardarServCategoria()"></button>
        </div>
    </p-footer>
</p-dialog>
<p-dialog [modal]="true" header="Editar/Crear Empresas" [(visible)]="mrn.verFormEmpresa" [style]="{width: '30vw'}">
    <form [formGroup]="mrn.formEmpresa">
        <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-12">
                <label for="nom_empresa">Nombre de la empresa prestadora del servicio.</label>
                <p-dropdown [style]="{width: '100%'}" [options]="mrn.catServicios"
                            placeholder="Selecciona una categoira"
                            optionLabel="nombre" optionValue="id"
                           formControlName="catServicio_id"
                ></p-dropdown>
            </div>
            <div class="p-field p-col-12">
                <label for="nom_empresa">Nombre de la empresa prestadora del servicio.</label>
                <input id="nom_empresa" formControlName="nom_empresa" type="text" pInputText>
            </div>
            <div class="p-field p-col-12">
                <label for="imagen">Logo de la empresa</label>
                <input id="imagen" formControlName="imagen" type="text" pInputText>
            </div>
            <div class="p-field p-col-12">
                <label for="imagenopt">Logo de la empresa para Apps</label>
                <input id="imagenopt" formControlName="imagenopt" type="text" pInputText>
            </div>
        </div>
    </form>
    <p-footer>
        <div style="padding-top: 15px;text-align:right">
            <button *ngIf="mrn.formServicioCategoria.valid" pButton pRipple type="button" label="Guardar"
                    (click)="guardarEmpresa()"></button>
        </div>
    </p-footer>
</p-dialog>
<p-dialog [modal]="true" header="Codificacion de Productos" [(visible)]="mrn.verFormCodificarProducto" [style]="{width: '60vw'}">
            <p-table #dtnocod [value]="mrn.productosCodificar"  [(selection)]="mrn.productoSeleccionado"
                     [rowHover]="true" [paginator]="true" [responsive]="true" [rows]="10"
                      [globalFilterFields]="['codigo','nom_producto']">
                <ng-template pTemplate="caption">
                    <div class="p-d-flex">
                        <h4>{{mrn.empresaSeleccionada?mrn.empresaSeleccionada.nom_empresa:''}}</h4>
                        <span class="p-input-icon-left p-ml-auto">
                        <i class="pi pi-search"></i>
                        <input pInputText type="text" (input)="dtnocod.filterGlobal($event.target.value, 'contains')"
                               placeholder="Buscar Producto" />
                        </span>
                    </div>
                </ng-template>
                <ng-template pTemplate="header">
                    <tr>
                        <th style="width:20%">Codigo Proveedor</th>
                        <th>Producto</th>
                        <th style="width:15%" pSortableColumn="codificado">Codificado<p-sortIcon field="codificado">
                        </p-sortIcon></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-producto>
                    <tr>
                        <td [pEditableColumnDisabled]="producto.codificado" pEditableColumn>
                            <p-cellEditor style="text-align:center">
                                <ng-template pTemplate="input">
                                   <input  (input)="verificarCodigo(producto.codigo)" style="width:50%" pInputText type="text" [(ngModel)]="producto.codigo">
                                   <!-- <input #cod style="width:50%" pInputText type="text" [(ngModel)]="producto.codigo">-->
                                </ng-template>
                                <ng-template pTemplate="output">
                                    {{producto.codigo}}
                                </ng-template>
                            </p-cellEditor>
                        </td>
                        <td>{{producto.nom_producto}}</td>
                        <td style="text-align:center">
                            <span *ngIf="producto.codificado">Si</span>
                            <span *ngIf="!producto.codificado">No</span>
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td [attr.colspan]="1">
                            Aun no hay Productos.
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="summary">
                    <div style="text-align:right">
                        <button  pButton pRipple type="button" label="Guardar"
                                 (click)="GuardarCodificacion()"></button>
                    </div>
                </ng-template>
            </p-table>
</p-dialog>
<p-dialog [modal]="true" header="Editar productos codificados" [(visible)]="mrn.verFormProdCodificados" [style]="{width: '30vw'}">
    <form *ngIf="mrn.productoCodificadoSeleccionado" [formGroup]="mrn.formCodificarProducto">
        <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-12">
                <span style="font-weight: bold">Proveedor: </span>
                <span>{{mrn.productoCodificadoSeleccionado.proveedor.nombre_prov}}</span>
            </div>
            <div class="p-field p-col-12">
                <span style="font_weight:bold">Producto: </span>
                <span>{{mrn.productoCodificadoSeleccionado.producto.nom_producto}}</span>
            </div>
            <div class="p-field p-col-12">
                <label for="codigo_producto">Codigo  del producto</label>
                <input id="codigo_producto" formControlName="codigo_producto"
                       #codprod (input)="verificarCodigo(codprod.value)" type="text" pInputText>
            </div>
        </div>
    </form>
    <p-footer>
        <div style="padding-top: 15px;text-align:right">
            <button [disabled]="!mrn.formCodificarProducto.valid" pButton pRipple type="button" label="Guardar"
                    (click)="mrn.updateCodificarProducto()"></button>
        </div>
    </p-footer>
</p-dialog>
<p-dialog [modal]="true" header="Crear/Editar Comision base" [(visible)]="mrn.verCEComisionBase" [style]="{width: '20vw'}">
    <form [formGroup]="mrn.formProveedorEmpresa">
        <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-12">
                <p-dropdown appendTo="body" [style]="{width: '100%'}" [options]="mrn.empresasAll"
                            placeholder="Selecciona una Empresa"
                            optionLabel="nom_empresa" optionValue="id"
                            formControlName="empresa_id"
                ></p-dropdown>
            </div>
            <div class="p-field p-col-12" *ngIf="!mrn.formProveedorEmpresa.value['comisionBase_valor'] || mrn.formProveedorEmpresa.value['comisionBase_valor'] == 0">
                <label for="combase">Comision Base</label>
                <input  id="combase" formControlName="comisionBase" type="text" pInputText>
            </div>
            <div class="p-field p-col-12" *ngIf="!mrn.formProveedorEmpresa.value['comisionBase'] || mrn.formProveedorEmpresa.value['comisionBase'] == 0">
                <label for="comisionBase_valor">Comisión base en pesos</label>
                <input id="comisionBase_valor" formControlName="comisionBase_valor" type="number" pInputText>
            </div>
            <div class="p-field p-col-12">
                <button *ngIf="mrn.formProveedorEmpresa.valid"  pButton pRipple type="button" label="Guardar"
                        (click)="guardarComisionBase()"></button>
            </div>
        </div>
    </form>
</p-dialog>
<p-dialog [modal]="true" header="Comisiones base {{mrn.proveedorSeleccionado?mrn.proveedorSeleccionado.nombre_prov:''}}" [(visible)]="mrn.verComisionesBase" [style]="{width: '50vw'}">
    <p-table [value]="mrn.comisionesBase" #DtComision
             [rowHover]="true" [paginator]="true" [responsive]="true" [rows]="10"
             [globalFilterFields]="['empresa.nom_empresa']">
        <ng-template pTemplate="caption">
            <div class="flex">
                <button pButton pRipple type="button"
                        label="Crear Comision Base" icon="pi pi-plus"
                        (click)="mrn.getEmpresasNoFilter();mrn.formProveedorEmpresa.reset();mrn.verCEComisionBase = true"></button>
                <span class="p-input-icon-left ml-auto">
                    <i class="pi pi-search"></i>
                    <input pInputText type="text" (input)="DtComision.filterGlobal($event.target.value, 'contains')" placeholder="Buscar empresa" />
                </span>
            </div>
        </ng-template>
        <ng-template pTemplate="header">
            <tr>
                <th style="width:30%">Estado de comision</th>
                <th >Proveedor</th>
                <th style="width:20%">Comision Base</th>
                <th style="width:20%">Comision en pesos</th>
                <th style="width:20%">Accion</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-comision>
            <tr>
                <td style="text-align:center"><span>{{comision.activo?'Activa':'Inactiva'}}</span></td>
                <td>{{comision.empresa.nom_empresa}}</td>
                <td style="text-align:center">{{comision.comisionBase}}%</td>
                <td style="text-align:center">$ {{comision.comisionBase_valor|number}}</td>
                <td style="text-align:center">
                    <i pTooltip="{{comision.activo?'Desactivar':'Activar'}}"
                       class="accion-tabla pi {{comision.activo?'pi-times':'pi-check'}}"
                    (click)="mrn.cambiarEstadoComision(comision)"></i>
                    <i pTooltip="Editar Comision Base" class="accion-tabla pi pi-pencil"
                       (click)="mrn.llenarFormProveedorEmpresa(comision);mrn.verCEComisionBase=true"></i>
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
            <tr>
                <td [attr.colspan]="4">
                    Aun no hay empreesas registrados.
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="summary">
            <div class="flex align-items-center justify-content-between">
                {{mrn.comisionesBase.length}} Empresas registradas con {{mrn.proveedorSeleccionado?mrn.proveedorSeleccionado.nombre_prov:''}}
            </div>
        </ng-template>
    </p-table>
</p-dialog>
<p-dialog [modal]="true" header="Comparacion de productos" [(visible)]="mrn.verComparacionProductos" [style]="{width: '70vw'}">
    <p-table [value]="mrn.productosFiltrados" [globalFilterFields]="['catServicio.nombre','nom_empresa']"
             [responsive]="true" [scrollable]="true" scrollHeight="400px"
             responsiveLayout="scroll">
        <ng-template pTemplate="caption">
            <div class="flex">
                <span class="p-input-icon-left ml-auto">
                                    <i class="pi pi-search"></i>
                                    <input pInputText type="text" (input)="dtEmpresa.filterGlobal($event.target.value, 'contains')" placeholder="Buscar empresa" />
                                </span>
            </div>
        </ng-template>
        <ng-template pTemplate="header">
            <tr>
                <th style="width: 5%">Codigo</th>
                <th>Nombre</th>
                <th style="width: 7%">Valor</th>
                <th style="width: 7%">Valor incentivo</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-producto>
            <tr style="background: {{producto.cambio?'linear-gradient(256deg, #c4656d 0%, #d088a5 100%)':''}}">
                <td>{{producto.codigo_producto}}</td>
                <td>{{producto.nombre}}</td>
                <td>{{producto.precio_cliente_final}}</td>
                <td>{{producto.valor_con_incentivo}}</td>
            </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage" let-columns>
            <tr>
                <td [attr.colspan]="4">
                    <div class="card info_card">
                        <i style="font-size:1.8em" class="fas fa-info-circle accion-tabla"></i>
                        <span>Seleccione un tipo de servicio para ver sus empresas.</span>
                    </div>
                </td>
            </tr>
        </ng-template>
    </p-table>
</p-dialog>
<p-dialog [modal]="true" header="Detalle de transacción" [style]="{width: '30vw'}"
          [(visible)]="mrn.verDetalletransaccionByAliado" [modal]="true">
    <div class="p-grid">
        <div class="p-col-12" style="padding-right:40px ">
            <table class="tabla_detalle" *ngIf="mrn.transaccionSeleccionada">
                <tr>
                    <th>Transaccion N°</th>
                    <td>{{mrn.transaccionSeleccionada.id}}</td>
                </tr>
                <tr>
                    <th>Ultimo saldo :</th>
                    <td>${{mrn.transaccionSeleccionada.ultimoSaldo|number}}</td>
                </tr>
                <tr>
                    <th>Valor :</th>
                    <td>$ {{mrn.transaccionSeleccionada.valor|number}}</td>
                </tr>
                <tr>
                    <th>Saldo actual :</th>
                    <td>${{mrn.transaccionSeleccionada.saldo_actual|number}}</td>
                </tr>
                <tr>
                    <th>Ganancia:</th>
                    <td>${{mrn.transaccionSeleccionada.ganancia|number}}</td>
                </tr>
                <tr>
                    <th>Fecha y hora :</th>
                    <td>{{mrn.transaccionSeleccionada.created_at|date:"dd-MM-yyyy"}} {{mrn.transaccionSeleccionada.hour_at|date:'shortTime'}}</td>
                </tr>
                <tr>
                    <th>Destino :</th>
                    <td>{{mrn.transaccionSeleccionada.numeroDestino}}</td>
                </tr>
                <tr>
                    <th>Canal de Venta :</th>
                    <td>{{mrn.transaccionSeleccionada.medioVenta}}</td>
                </tr>
                <tr>
                    <th>Empresa :</th>
                    <td>{{mrn.transaccionSeleccionada.nombre_empresa}}</td>
                </tr>
                <tr>
                    <th>Estado :</th>
                    <td>{{mrn.transaccionSeleccionada.estado}}</td>
                </tr>
                <tr>
                    <th>Codigo aprobacion :</th>
                    <td>{{mrn.transaccionSeleccionada.codigo_transaccion_externa}}</td>
                </tr>
                <tr>
                    <th>Codigo Producto :</th>
                    <td>{{mrn.transaccionSeleccionada.codigo_producto}}</td>
                </tr>
                <tr>
                    <th>Producto :</th>
                    <td>{{mrn.transaccionSeleccionada.nom_producto}}</td>
                </tr>
                <tr>
                    <th>Convenio :</th>
                    <td>{{ mrn.transaccionSeleccionada.convenio_pago }}</td>
                </tr>
                <tr>
                    <th>Referencia de pago :</th>
                    <td>{{ mrn.transaccionSeleccionada.referencia_pago }}</td>
                </tr>
                <tr>
                    <th>Código de respuesta :</th>
                    <td>{{mrn.transaccionSeleccionada.codigo_resultado}}</td>
                </tr>
                <tr>
                    <th>Tipo de red :</th>
                    <td>{{mrn.transaccionSeleccionada.tipo_red}}</td>
                </tr>
                <tr>
                    <th>Tipo de datos :</th>
                    <td>{{mrn.transaccionSeleccionada.tipo_datos}}</td>
                </tr>
                <tr>
                    <th>Version App :</th>
                    <td>{{mrn.transaccionSeleccionada.app_ver}}</td>
                </tr>
                <tr>
                    <th>
                        Tiempo de respuesta:</th>
                    <td>{{mrn.transaccionSeleccionada.tiempo_respuesta}}</td>
                </tr>
                <tr>
                    <th>Resultado de la transaccion :</th>
                </tr>
                <tr>
                    <td colspan="2"  style="text-align: justify">{{mrn.transaccionSeleccionada.resultado}}</td>
                </tr>
            </table>
        </div>
    </div>
</p-dialog>
