<!--esto es un comentario jijiji-->

<div class="p-grid">
    <div class="p-col-12 p-md-3">
        <div class="card">
            <h5>Aliados registrados</h5>
            <p-tree  [value]="mrn.red" selectionMode="single"
                     [(selection)]="mrn.nodoSeleccionado" [filterPlaceholder]="'Buscar aliado'" [emptyMessage]="' Aun no hay aliados'"
                     (onNodeSelect)="seleccionarNodo()">
            </p-tree>
        </div>
    </div>
    <div *ngIf="mrn.nodoSeleccionado" class="p-col-12 p-md-6">
        <div class="card">
            <h5>Usuarios registrados</h5>
            <span >{{api.nodoActual['tipo']}} {{api.nodoActual['razonSocial']}}</span>
            <div style="margin-top: 20px;">
                <p-table [rowHover]="true" [responsive]="true" [value]="mrn.usuariosByNodo">

                    <ng-template pTemplate="header">
                        <tr>
                            <th style="width:10%">Estado</th>
                            <th>Nombres</th>
                            <th>Apellidos</th>
                            <th style="width:15%">Identificacion</th>
                            <th style="width:15%">Cargo</th>
                            <th style="width:10%"></th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-usuario>
                        <tr>
                            <td>
                                <i pTooltip="{{usuario.is_active?'activo':'inactivo'}}" class="pi pi-circle-on {{usuario.is_active?'activo':'inactivo'}}"></i>
                            </td>
                            <td>{{usuario.first_name}}</td>
                            <td>{{usuario.last_name}}</td>
                            <td>{{usuario.identificacion}}</td>
                            <td>{{usuario.cargo}}</td>
                            <td style="text-align:center">
                                <i *ngIf="usuario.is_active" pTooltip="Ver Equipos Asignados" style="margin-right: 10px"
                                   (click)="mrn.usuarioSeleccionado = usuario;mrn.getEquiposByNodo()" class="pi pi-desktop accion-tabla"></i>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
        </div>
    </div>
    <div *ngIf="mrn.usuarioSeleccionado" class="p-col-12 p-md-3">
        <div class="card">
            <h5>Equipos registrados</h5>
            <span>{{mrn.usuarioSeleccionado.first_name}} {{mrn.usuarioSeleccionado.last_name}}</span>
            <p-table [rowHover]="true" [responsive]="true" [value]="mrn.equiposByNodo"
                   [paginator]="true"  [rows]="10">
                <ng-template pTemplate="caption">
                    <div class="p-grid">
                        <div class="p-col-6 p-md-12"></div>
                        <div class="p-col-6 p-md-12" style="text-align:right">
                            <button (click)="mrn.getEquipos();mrn.verAsignacionDeEquipo=true"
                                    type="button" pButton pRipple styleClass="p-button-danger"
                                    label="Asignar equipo" ></button>
                        </div>
                    </div>
                </ng-template>
                <ng-template pTemplate="header">
                    <tr>
                        <th>Serial</th>
                        <th style="width:30%">Marca</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-equipobyUser>
                    <tr>
                        <td>{{equipobyUser.equipo.marca}}</td>
                        <td style="text-align:center">{{equipobyUser.equipo.serial}}</td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
</div>
<p-dialog header="Asignacion de equipos" [(visible)]="mrn.verAsignacionDeEquipo" [style]="{width: '50vw'}">
    <div class="p-grid">
        <div class="p-col-12 p-md-6">
            <form [formGroup]="mrn.formEquipoNodo">
                <div class="p-fluid p-formgrid p-grid">
                    <div class="p-field p-col-12 p-md-6">
                        <label for="fechaAsignacion">Fecha de asignacion</label>
                        <input id="fechaAsignacion"  formControlName="fechaAsignacion" type="date" pInputText>
                    </div>
                    <div *ngIf="mrn.formEquipoNodo.value['id']&&mrn.formEquipoNodo.valid" class="p-field p-col-12 p-md-6">
                        <label for="fechaRetiro">Fecha de Retiro</label>
                        <input id="fechaRetiro"  formControlName="fechaRetiro" type="date" pInputText>
                    </div>
                    <div  class="p-field p-col-12 p-md-6">
                        <label for="ordenEntrega">Nº Orden de entrega</label>
                        <input id="ordenEntrega"  formControlName="ordenEntrega" type="text" pInputText>
                    </div>
                    <div *ngIf="mrn.formEquipoNodo.value['id']&&mrn.formEquipoNodo.valid" class="p-field p-col-12">
                        <label for="motivo">Motivo del retiro</label>
                        <textarea id="motivo" formControlName="motivo" type="text" pInputText></textarea>
                    </div>
                    <div class="p-field p-col-12">
                        <label for="observacion">Observaciones</label>
                        <textarea id="observacion" formControlName="observacion" type="text" pInputText></textarea>
                    </div>
                </div>
            </form>
        </div>
        <div class="p-col-12 p-md-6">
            <p-table [rowHover]="true"  [rowHover]="true" [paginator]="true" [responsive]="true" [rows]="10"
                     [responsive]="true" [value]="mrn.equipos" selectionMode="single" [(selection)]="mrn.equipoSeleccionado" >
                <ng-template pTemplate="caption">
                    <p>De la siguiente lista seleccione el equipo para asignar.</p>
                </ng-template>
                <ng-template pTemplate="header">
                    <tr>
                        <th>Serial</th>
                        <th>Marca</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-equipo>
                    <tr [pSelectableRow]="equipo">
                        <td>{{equipo.marca}}</td>
                        <td>{{equipo.serial}}</td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
<p-footer style="text-align:right">
    <button *ngIf="mrn.equipoSeleccionado && mrn.formEquipoNodo.valid"
            pButton pRipple type="button" label="Guardar" (click)="guardarAsignacion()"></button>
</p-footer>
</p-dialog>
