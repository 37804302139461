<div class="p-grid">
    <div class="p-col-12 p-md-12">
        <p-tabView>
            <p-tabPanel header="Mis comisiones">
                <div class="card">
                    <h5>Mis comisiones</h5>
                    <p-table [rowHover]="true" [responsive]="true"
                             [value]="mrn.misComisiones" [paginator]="true" [rows]="10">
                        <ng-template pTemplate="caption">

                        </ng-template>
                        <ng-template pTemplate="header">
                            <tr>
                                <th *ngIf="api.nodoActual['id']==1"
                                >Proveedor
                                </th>
                                <th>Empresa</th>
                                <th style="width:15%">Mi Comision</th>
                                <th style="width:15%">Actualizacion</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-comision >
                            <tr>
                                <td *ngIf="api.nodoActual['id']==1">
                                    {{comision.proveedorEmpresa.proveedor.nombre_prov}}</td>
                                <td>{{comision.proveedorEmpresa.empresa.nom_empresa}}</td>
                                <td style="text-align:center">
                                    {{api.nodoActual['tipoComision']=='CA'?api.nodoActual['comisionAnticipada']:comision.micomision}} %
                                </td>
                                <td style="text-align:center">{{comision.updated_at|date}}</td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="emptymessage">
                            <tr>
                                <td [attr.colspan]="2">
                                    Aun no hay comisiones.
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </p-tabPanel>
            <p-tabPanel header="Ventas">
                <div class="card">
                    <h5>Mis Ventas</h5>
                    <p-table [rowHover]="true" [responsive]="true" #dtventas (onFilter)="onFilter($event, dtventas)"
                             [globalFilterFields]="['proveedorEmpresa.empresa.nom_empresa','usuario.first_name']"
                             [value]="mrn.ventas_by_fecha" [paginator]="true" [rows]="10">
                        <ng-template pTemplate="caption">
                            <div class="p-grid">
                                <div class="p-col-12 p-md-4">
                                    <div class="p-grid p-fluid p-formgrid">
                                        <div class=" p-field p-col-12 p-md-6">
                                            <label for="fiv">Fecha inicial del reporte</label>
                                            <input type="date" #fInicial pInputText id="fiv" min="{{fInicial.value}}">
                                        </div>
                                        <div class=" p-field p-col-12 p-md-6">
                                            <label for="ffv">Fecha final del reporte</label>
                                            <input type="date" #fFinal pInputText id="ffv" min="{{fInicial.value}}" max="{{calcular_max(fInicial.value)}}">
                                        </div>
                                        <div class="p-col-12 p-md-6">
                                            <button [disabled]="!fInicial.value" (click)="mrn.get_ventas_by_fecha(fInicial.value,fFinal.value)"
                                                    type="button" pButton pRipple styleClass="p-button-danger"
                                                    label="Consultar"></button>
                                        </div>
                                        <div class="p-col-12 p-md-6">
                                            <button (click)="
                                            fInicial.value = '';
                                            fFinal.value = '';
                                            mrn.ventas_by_fecha = [];
                                            total_ganancias = 0;
                                            total_ventas = 0;
                                            total_ventas_ganancias = 0;
                                            total_ventas_saldo = 0"
                                                    type="button" pButton pRipple styleClass="p-button-danger"
                                                    label="Limpiar"></button>
                                        </div>
                                    </div>
                                </div>
                                <div class="p-col-12 p-md-6">
                                    <div class="p-grid p-fluid p-formgrid">
                                        <div class="p-col-12 p-lg-6 p-xl-3">
                                            <div class="total_card_container">
                                                <div class="total_card">
                                                    <span class="total_card_title">Total ventas</span>
                                                    <span class="total_card-value">${{filtered_data.length?total_ventas:getTotalVentas(mrn.ventas_by_fecha)|number}}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="p-col-12 p-lg-6 p-xl-3">
                                            <div class="total_card_container">
                                                <div class="total_card">
                                                    <span class="total_card_title">Total ganancias</span>
                                                    <span class="total_card-value">${{filtered_data.length?total_ganancias:getTotalGanancias(mrn.ventas_by_fecha)|number}}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="p-col-12 p-lg-6 p-xl-3">
                                            <div class="total_card_container">
                                                <div class="total_card">
                                                    <span class="total_card_title">Ventas desde saldo</span>
                                                    <span class="total_card-value">${{filtered_data.length?total_ventas_saldo:getTotalVentasBySaldo(mrn.ventas_by_fecha)|number}}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="p-col-12 p-lg-6 p-xl-3">
                                            <div class="total_card_container">
                                                <div class="total_card">
                                                    <span class="total_card_title">Ventas desde ganancias</span>
                                                    <span class="total_card-value">${{filtered_data.length?total_ventas_ganancias:getTotalVentasByGanancias(mrn.ventas_by_fecha)|number}}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="p-col-12 p-md-2">
                                    <div class="p-grid p-fluid p-formgrid">
                                        <div style="margin-top: 10px" class=" p-field p-col-12 p-md-12">
                                                    <span class="p-input-icon-left ml-auto">
                                                        <i class="pi pi-search"></i>
                                                        <input pInputText type="text" (input)="dtventas.filterGlobal($event.target.value, 'contains')"
                                                               placeholder="Buscar por Destino o usuario." />
                                                    </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="header">
                            <tr>
                                <th style="width:10%">Id</th>
                                <th style="width:15%">Usuario</th>
                                <th style="width:10%">Empresa</th>
                                <th style="width:13%">Fecha</th>
                                <th style="width:8%">Valor venta</th>
                                <th style="width:8%">Ganancia</th>
                                <th>Resulatdo</th>
                                <th style="width:10%">Estado</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-transaccion>
                            <tr>
                                <td>{{transaccion.id}} <i class="fas fa-search accion-tabla_izq" (click)="mrn.transaccionSeleccionada = transaccion;mrn.verDetalletransaccionByAliado = true"></i></td>
                                <td>{{transaccion.usuario.first_name}}</td>
                                <td>{{transaccion.producto_venta.producto.empresa.nom_empresa}}</td>
                                <td style="text-align:center">{{transaccion.created_at|date:"dd-MM-yyyy"}} {{transaccion.hour_at|date:'shortTime'}}</td>
                                <td style="text-align: right">$ {{transaccion.valor|number}}</td>
                                <td style="text-align: right">$ {{transaccion.ganancia?transaccion.ganancia:0|number}}</td>
                                <td class="wrap">{{transaccion.resultado}}</td>
                                <td style="text-align: center">
                                    {{transaccion.estado}}
                                </td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="summary">
                            <div class="flex align-items-center justify-content-between">
                                Se encontraron {{mrn.ventas_by_fecha.length}} transacciones.
                            </div>
                        </ng-template>
                        <ng-template pTemplate="emptymessage" let-columns>
                            <tr>
                                <td [attr.colspan]="8">
                                    <div class="card info_card">
                                        <i style="font-size:1.8em" class="fas fa-info-circle accion-tabla"></i>
                                        <p>Para realizar una consulta por favor seleccione una Fecha .</p>
                                    </div>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </p-tabPanel>
            <p-tabPanel header="Solicitudes de saldo">
                <div class="card">
                    <p-table dataKey="id" [rowHover]="true" [responsive]="true" [value]="mrn.historial_solicitudes_saldo">
                        <ng-template pTemplate="caption">
                            <div class="p-grid">
                                <div class="p-col-12 p-md-3">
                                    <div class="p-grid p-fluid p-formgrid">
                                        <div class=" p-field p-col-12 p-md-6" >
                                            <label for="fi">Desde</label>
                                            <input type="date" #fInicial pInputText id="fi">
                                        </div>
                                        <div class="p-field p-col-12 p-md-6">
                                            <label for="ff">Hasta</label>
                                            <input type="date" #fFinal pInputText id="ff">
                                        </div>
                                        <div class="p-col-12 p-md-12">
                                            <button (click)="mrn.getHistorialMisSolicitudesSaldo(fInicial.value,fFinal.value)"
                                                    type="button" pButton pRipple styleClass="p-button-danger"
                                                    icon="pi pi-search" label="Mis solicitudes" ></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="header">
                            <tr>
                                <th style="width:5%">Id</th>
                                <th style="width:13%">Fecha solicitud</th>
                                <th>
                                    <p-columnFilter placeholder="Usuario" type="text" field="nodo.usuario.username" [showMenu]="false" ></p-columnFilter>
                                </th>
                                <th style="width:15%">
                                    <p-columnFilter field="tipo_transaccion" [showMenu]="false" matchMode="equals">
                                        <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                            <p-dropdown [ngModel]="value" [options]="mrn.formaPago" (onChange)="filter($event.value)" placeholder="Formas de pago" [showClear]="true">
                                                <ng-template let-option pTemplate="item">
                                                    <span>{{option.label}}</span>
                                                </ng-template>
                                            </p-dropdown>
                                        </ng-template>
                                    </p-columnFilter>
                                </th>
                                <th style="width:8%">Valor solicitado</th>
                                <th style="width:10%">
                                    <p-columnFilter field="estado" matchMode="equals" [showMenu]="false">
                                        <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                            <p-dropdown [ngModel]="value" [options]="mrn.estadosTransaccion" (onChange)="filter($event.value)" placeholder="Estado" [showClear]="true">
                                                <ng-template let-option pTemplate="item">
                                                    <span>{{option.label}}</span>
                                                </ng-template>
                                            </p-dropdown>
                                        </ng-template>
                                    </p-columnFilter>
                                </th>
                                <th>Estado de pago</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-transaccion>
                            <tr>
                                <td>{{transaccion.id}}</td>
                                <td style="text-align:center">{{transaccion.created_at|date:"dd-MM-yyyy"}} {{transaccion.hour_at|date:'shortTime'}}</td>
                                <td>{{transaccion.usuario.username}}</td>
                                <td style="text-align: center">{{(transaccion.tipo_transaccion == 'SSC')?'Contado':'Credito'}}</td>
                                <td style="text-align: right">
                                    <span style="float: right">$ {{transaccion.valor|number}}</span>
                                </td>
                                <td style="text-align: center">
                                    <p-chip *ngIf="transaccion.estado=='Aprobado'" label="{{transaccion.estado}}" styleClass="p-mr-2 chip-aprobado"></p-chip>
                                    <p-chip *ngIf="transaccion.estado=='Rechazado'" label="{{transaccion.estado}}" styleClass="p-mr-2 chip-rechazado"></p-chip>
                                    <p-chip *ngIf="transaccion.estado=='Pendiente'" label="{{transaccion.estado}}" styleClass="p-mr-2 chip-pendiente"></p-chip>
                                    <p-chip *ngIf="transaccion.estado=='Pagado'" label="{{transaccion.estado}}" styleClass="p-mr-2 chip-disponible"></p-chip>
                                </td>
                                <td style="text-align: center">
                                    <p-chip *ngIf="transaccion.estadoPago=='Pendiente'" label="{{transaccion.estadoPago}}" styleClass="p-mr-2 chip-pendiente"></p-chip>
                                    <p-chip *ngIf="transaccion.estadoPago=='Pago aceptado'" label="{{transaccion.estadoPago}}" styleClass="p-mr-2 chip-aprobado"></p-chip>
                                </td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="summary">
                            <div class="flex align-items-center justify-content-between">
                                Se encontraron {{mrn.historial_solicitudes_saldo.length}} transacciones.
                            </div>
                        </ng-template>
                        <ng-template pTemplate="emptymessage" let-columns>
                            <tr>
                                <td [attr.colspan]="8">
                                    <div class="card" style="background: rgba(147,226,255,0.67)">
                                        <p><i style="font-size:1.8em" class="fas fa-info-circle accion-tabla">
                                        </i>Para realizar una consulta por favor seleccione una Fecha inicial y un Fecha final.</p>
                                    </div>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </p-tabPanel>
            <p-tabPanel header="Pagos de saldos">
                <div class="card">
                    <p-table #dtPagos dataKey="id" [rowHover]="true" [responsive]="true" [value]="mrn.pagos_by_fecha"
                             [globalFilterFields]="['transaccion.id','entidad','numero_recibo']">
                        <ng-template pTemplate="caption">
                            <div class="p-grid">
                                <div class="p-col-12 p-md-3">
                                    <div class="p-grid p-fluid p-formgrid">
                                        <div class=" p-field p-col-12 p-md-6" >
                                            <label for="fip">Desde</label>
                                            <input type="date" #fInicial pInputText id="fip">
                                        </div>
                                        <div class=" p-field p-col-12 p-md-6" >
                                            <label for="fip">Hasta</label>
                                            <input type="date" #fFinal pInputText id="fip2">
                                        </div>
                                        <div class="p-col-12 p-md-6">
                                            <button (click)="mrn.get_pagos_by_fecha(fInicial.value,fFinal.value)"
                                                    type="button" pButton pRipple styleClass="p-button-danger"
                                                    icon="pi pi-search" label="Consultar" ></button>
                                        </div>
                                        <div class="p-col-12 p-md-6">
                                            <button (click)="fInicial.value='';fFinal.value='';mrn.pagos_by_fecha = []"
                                                    type="button" pButton pRipple styleClass="p-button-danger"
                                                    icon="fas fa-broom" label="Limpiar" ></button>
                                        </div>
                                    </div>
                                </div>
                                <div class="p-col-12 p-md-9" style="text-align: right">
                                    <input pTooltip="Usted puede buscar por transaccion,entidad,numero de comprobante."
                                           pInputText type="text" (input)="dtPagos.filterGlobal($event.target.value,
                                    'contains')" placeholder="Buscar pago" />
                                </div>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="header">
                            <tr>
                                <th style="width:5%">Id</th>
                                <th>Transaccion</th>
                                <th>Valor solicitado</th>
                                <th>Fecha aprobación</th>
                                <th>Fecha pago</th>
                                <th>Abono</th>
                                <th>Entidad</th>
                                <th>Numero de recibo</th>
                                <th>Soporte</th>

                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-pago>
                            <tr>
                                <td>{{pago.id}}</td>
                                <td>{{pago.transaccion.id}}</td>
                                <td>$ {{pago.transaccion.valor|number}}</td>
                                <td>{{pago.transaccion.fecha_aprobacion}}</td>
                                <td>{{pago.created_at}}</td>
                                <td>$ {{pago.abono|number}}</td>
                                <td>{{pago.entidad}}</td>
                                <td>{{pago.numero_recibo}}</td>
                                <td style="text-align: center"><i pTooltip="Ver soporte" style="cursor: pointer"
                                                                  (click)="mrn.pagoSeleccionado = '';mrn.pagoSeleccionado = pago;verSoportePago = true"
                                                                  *ngIf="pago.soporte" class="fas fa-image"></i></td>

                            </tr>
                        </ng-template>
                        <ng-template pTemplate="summary">
                            <div class="flex align-items-center justify-content-between">
                                Se encontraron {{mrn.pagos_by_fecha.length}} pagos.
                            </div>
                        </ng-template>
                        <ng-template pTemplate="emptymessage" let-columns>
                            <tr>
                                <td [attr.colspan]="9">
                                    <div class="card" style="background: rgba(147,226,255,0.67)">
                                        <p><i style="font-size:1.8em" class="fas fa-info-circle accion-tabla">
                                        </i>Para realizar una consulta por favor seleccione una Fecha.</p>
                                    </div>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </p-tabPanel>
        </p-tabView>
    </div>
</div>
<p-dialog [modal]="true" header="Detalle de transacción" [style]="{width: '30vw'}" [(visible)]="mrn.verDetalletransaccionByAliado" [modal]="true">
    <div class="p-grid">
        <div class="p-col-12" style="padding-right:40px ">
            <ul>
                <div *ngIf="mrn.transaccionSeleccionada">
                    <li style="list-style: none;padding:6px;color: darkred;text-align: center" *ngIf="mrn.transaccionSeleccionada.dias_mora"><h5 style="font-weight: bold">¡Transacción con {{mrn.transaccionSeleccionada.dias_mora}} dias en mora!</h5></li>
                    <li style="list-style: none;padding:6px"><h5 style="font-weight: bold">Transaccion N° {{mrn.transaccionSeleccionada.id}}</h5></li>
                    <li style="list-style: none;padding:6px" *ngIf="mrn.transaccionSeleccionada.tipo_transaccion == 'SSC'"><span style="font-weight: bold">Numero de aprobacion :</span> <span style="float: right">{{mrn.transaccionSeleccionada.numeroAprobacion}}</span></li>
                    <li style="list-style: none;padding:6px"><span style="font-weight: bold">Valor :</span> <span style="float: right">$ {{mrn.transaccionSeleccionada.valor|number}}</span></li>
                    <li style="list-style: none;padding:6px" *ngIf="mrn.transaccionSeleccionada.nodo.comisionIncluida"><span style="font-weight: bold">Comision asignada :</span>
                        <span style="float: right">{{mrn.transaccionSeleccionada.nodo.comisionAnticipada}} %</span></li>
                    <li style="list-style: none;padding:6px" *ngIf="mrn.transaccionSeleccionada.nodo.comisionIncluida"><span style="font-weight: bold" >Valor aprobado :</span>
                        <span style="float: right">
                           $ {{((mrn.transaccionSeleccionada.nodo.comisionAnticipada/100)*mrn.transaccionSeleccionada.valor)+mrn.transaccionSeleccionada.valor|number}}</span>
                        <span *ngIf="!mrn.transaccionSeleccionada.nodo.comisionIncluida" style="float: right">{{mrn.transaccionSeleccionada.valor|number}}</span>
                    </li>
                    <li style="list-style: none;padding:6px" *ngIf="mrn.transaccionSeleccionada.tipo_transaccion == 'VEN'"><span style="font-weight: bold">Canal de Venta :</span> <span style="float: right">{{mrn.transaccionSeleccionada.medioVenta}}</span></li>
                    <li style="list-style: none;padding:6px" *ngIf="mrn.transaccionSeleccionada.tipo_transaccion == 'VEN'"><span style="font-weight: bold">Ultimo saldo :</span> <span style="float: right">${{mrn.transaccionSeleccionada.ultimoSaldo|number}}</span></li>
                    <li style="list-style: none;padding:6px" *ngIf="mrn.transaccionSeleccionada.tipo_transaccion == 'VEN'"><span style="font-weight: bold">Saldo actual :</span> <span style="float: right">${{mrn.transaccionSeleccionada.saldo_actual|number}}</span></li>
                    <li style="list-style: none;padding:6px"><span style="font-weight: bold">Solicitante :</span> <span style="float: right">{{mrn.transaccionSeleccionada.usuario.first_name}} {{mrn.transaccionSeleccionada.usuario.last_name}}</span></li>
                    <li style="list-style: none;padding:6px"><span style="font-weight: bold">Tipo de saldo :</span> <span style="float: right">{{mrn.transaccionSeleccionada.tipoServicio}}</span></li>
                    <li style="list-style: none;padding:6px"  *ngIf="mrn.transaccionSeleccionada.tipo_transaccion == 'VEN'"><span style="font-weight: bold">Destino :</span> <span style="float: right">{{mrn.transaccionSeleccionada.numeroDestino}}</span></li>
                    <li style="list-style: none;padding:6px"  *ngIf="mrn.transaccionSeleccionada.tipo_transaccion == 'VEN'"><span style="font-weight: bold">Empresa :</span> <span style="float: right">{{mrn.transaccionSeleccionada.producto_venta.producto.empresa.nom_empresa}}</span></li>
                    <li style="list-style: none;padding:6px"><span style="font-weight: bold">Código aprobación :</span>
                        <span style="float: right">{{mrn.transaccionSeleccionada.codigo_transaccion_externa}}</span></li>
                    <li style="list-style: none;padding:6px"><span style="font-weight: bold">Estado :</span> <span style="float: right">{{mrn.transaccionSeleccionada.estado}}</span></li>
                    <li style="list-style: none;padding:6px"><span style="font-weight: bold">Tipo de transaccion :</span> <span style="float: right">{{mrn.transaccionSeleccionada.tipo_transaccion}}</span></li>
                    <li style="list-style: none;padding:6px"><span style="font-weight: bold">Fecha de solicitud :</span> <span style="float: right">{{mrn.transaccionSeleccionada.created_at|date:"dd-MM-yyyy"}} {{mrn.transaccionSeleccionada.hour_at|date:'shortTime'}}</span></li>
                    <li style="list-style: none;padding:6px"><span style="font-weight: bold">Código de respuesta :</span> <span style="float: right">{{mrn.transaccionSeleccionada.codigo_resultado}}</span></li>
                    <div *ngIf="mrn.transaccionSeleccionada.tipo_transaccion != 'VEN'">
                        <li style="list-style: none;padding:6px"><span style="font-weight: bold" >Medio de solicitud :</span> <span style="float: right">{{mrn.transaccionSeleccionada.medioSolicitud}}</span></li>
                        <li style="list-style: none;padding:6px"><span style="font-weight: bold">Soporte :</span> <span style="float: right">{{mrn.transaccionSeleccionada.soporte?'Si':'No'}}</span></li>
                        <li style="list-style: none;padding:6px"><span style="font-weight: bold">Fecha de aprobacion :</span> <span style="float: right">{{mrn.transaccionSeleccionada.fecha_aprobacion|date}}</span></li>
                        <li style="list-style: none;padding:6px"><span style="font-weight: bold">Fecha límite de pago :</span> <span style="float: right">{{mrn.transaccionSeleccionada.fecha_pago|date}}</span></li>
                        <li style="list-style: none;padding:6px"><span style="font-weight: bold">Estado de pago :</span> <span style="float: right">{{mrn.transaccionSeleccionada.estadoPago}}</span></li>
                        <li style="list-style: none;padding:6px"><span style="font-weight: bold">Dias de mora :</span> <span style="float: right">{{mrn.transaccionSeleccionada.dias_mora}}</span></li>
                    </div>
                    <li style="list-style: none;padding:6px"><span style="font-weight: bold">Resultado de la transaccion :</span> <span style="float: right;text-align: justify;padding-top: 10px">{{mrn.transaccionSeleccionada.resultado}}</span></li>
                </div>
            </ul>
        </div>
    </div>
</p-dialog>
<p-dialog [modal]="true" header="Soporte de pago" [(visible)]="verSoportePago" [modal]="true"
          [style]="{width: '60%'}">
    <div style="text-align: center;padding: 25px" *ngIf="mrn.pagoSeleccionado">
        <img style="width: 80%" src="{{mrn.pagoSeleccionado.soporte}}" alt="">
    </div>
</p-dialog>